import { DateTime } from 'luxon';

// API Dependencies
import { searchOLAP } from '../services/api';

// Custom Helpers
import { capitalizeFirstLetters, isDefinedAndInitialized } from '../helpers/helpers';


export const currentVersion = window.config.CURRENT_VERSION;

export const config = {
  // Tab Mapping
  welcomePageTabs: {
    LAST_MONTH: {
      type: 'STATIC_QUERY',
      display: 'Initia Recent',
      api_call: searchOLAP,
      index: 0,
      defaultQuery: ''
    },
    MY_LAST_3_MONTHS: {
      type: 'STATIC_QUERY',
      display: 'My Recent',
      api_call: searchOLAP,
      index: 1,
      defaultQuery: ''
    },
    STATUS: {
      type: 'MULTI-SELECT',
      display: 'Search Status',
      api_call: searchOLAP,
      index: 2,
      defaultQuery: '&status=draft&status=review'
    },
    JOB: {
      type: 'SINGLE_FIELD_SEARCH',
      display: 'Search Job',
      api_call: searchOLAP,
      index: 3,
      defaultQuery: ''
    },
    MONTH_SUMMARY: {
      type: 'STATIC_AGGREGATION_QUERY',
      display: 'Month Summary',
      api_call: searchOLAP,
      index: 4,
      defaultQuery: ''
    },
    DEFAULT: {
      type: 'STATIC_QUERY',
      display: 'Initia Recent',
      api_call: searchOLAP,
      index: 0,
      defaultQuery: ''
    },
  },

  getTabNameFromUrlQueryString: (queryStringParameters) => {
    // -----------------------------------------------

    const tabName = queryStringParameters.get('tabName');

    if (!tabName) {
      return 'LAST_MONTH';
    }

    return tabName;
  },
  getTabValueFromUrlQueryString: (queryStringParameters) => {
    // -----------------------------------------------

    const tabName = queryStringParameters.get('tabName');

    if (!tabName) {
      return config.welcomePageTabs.DEFAULT.index;
    }

    return config.welcomePageTabs[tabName].index;
  },
  getJobIdFromUrlQueryString: (queryStringParameters) => {
    // -----------------------------------------------
    const jobId = queryStringParameters.get('lastJobId');

    if (!jobId) {
      return '';
    }

    return jobId;
  },
  getStatusesFromUrlQueryString: (queryStringParameters) => {
    // -----------------------------------------------
    const statuses = queryStringParameters.getAll('status');

    if (statuses.length === 0) {
      return [];
    }

    return statuses;
  },
  getMonthAndYearSelectionFromUrlQueryString: (queryStringParameters) => {
    // -----------------------------------------------
    const date = queryStringParameters.get('date');

    if (!date) {
      // If no timestamp
      return DateTime.local().startOf('month').toFormat('yyyy-MM');
    }

    return date;
  },
  getMonthSummarySearchTypeFromUrlQueryString: (queryStringParameters) => {
    // -----------------------------------------------
    const searchType = queryStringParameters.get('searchType');

    if (!searchType) {
      // If no timestamp
      return null;
    }

    return searchType;
  },
  getMonthSummarySearchParamFromUrlQueryString: (queryStringParameters) => {
    // -----------------------------------------------
    const searchParam = queryStringParameters.get('searchParam');

    if (!searchParam) {
      // If no timestamp
      return null;
    }

    return searchParam;
  },
  getCreationStatusFromQueryString: (queryStringParameters) => {
    // -----------------------------------------------
    const created = queryStringParameters.get('created');

    if (!created) {
      return false;
    }

    return true;
  },
  getNcrCreatingStatusFromQueryString: (queryStringParameters) => {
    // -----------------------------------------------
    const creatingNcrs = queryStringParameters.get('creatingNcrs');

    if (!creatingNcrs) {
      return false;
    }

    return true;
  },

  // Observation Types
  observationTypes: [
    {
      name: 'General Observation',
      colour: '#e0be35',
      status: 'active'
    },
    {
      name: 'Earthworks Observation',
      colour: '#7cc631',
      status: 'active'
    },
    {
      name: 'Foundation Observation',
      colour: '#825000',
      status: 'active'
    },
    {
      name: 'Building Platform Inspection',
      colour: '#3099a5',
      status: 'inactive'
    },
    {
      name: 'Subgrade Observation',
      colour: '#f07e0c',
      status: 'active'
    },
    {
      name: 'Pavement Inspection',
      colour: '#bdbdbd',
      status: 'inactive'
    },
    {
      name: 'Pile Observation',
      colour: '#8e32bc',
      status: 'active'
    },
    {
      name: 'Retaining Wall Observation',
      colour: '#a06436',
      status: 'inactive'
    },
    {
      name: 'Compaction Testing',
      colour: '#0056eb',
      status: 'active'
    },
    {
      name: 'Stockpile Assessment',
      colour: '#d640b3',
      status: 'active'
    },
    {
      name: 'Fill Suitability Assessment',
      colour: '#c20802',
      status: 'active'
    },
    {
      name: 'Landslip Appraisal',
      colour: '#278db3',
      status: 'active'
    }
  ],

  // Status Types
  statusTypes: [
    {
      name: 'draft',
      stepperPastDisplay: 'drafted',
      stepperPendingDisplay: 'draft',
      iconBackgroundColour: '#f44336',
      statusFontColour: '#f44336',
      monthSummaryColour: '#ed746b',
      timestampProp: 'inspectionTimestamp'
    },
    {
      name: 'review',
      stepperPastDisplay: 'review',
      stepperPendingDisplay: 'review',
      iconBackgroundColour: '#ed7802',
      statusFontColour: '#c26100',
      monthSummaryColour: '#eb9e50',
      timestampProp: 'lastReviewTimestamp'
    },
    {
      name: 'approved',
      stepperPastDisplay: 'approved',
      stepperPendingDisplay: 'approval',
      iconBackgroundColour: '#ffc107',
      statusFontColour: '#ab8000',
      monthSummaryColour: '#ffde7d',
      timestampProp: 'lastApprovalTimestamp'
    },
    {
      name: 'downloaded',
      stepperPastDisplay: 'downloaded',
      stepperPendingDisplay: 'download',
      iconBackgroundColour: '#00c853',
      statusFontColour: '#00943d',
      monthSummaryColour: '#79c99a',
      timestampProp: 'lastPublishTimestamp'
    }
  ],

  // Observation Durations
  observationDurations: [
    {
      name: 'Standard',
      status: 'active'
    },
    {
      name: 'Extended',
      status: 'active'
    },
    {
      name: 'Half-day',
      status: 'active'
    },
    {
      name: 'Full-day',
      status: 'active'
    },
    {
      name: 'Standard-combined-site',
      status: 'active'
    }
  ],

  // Components List
  // --------------------------
  // Adding components here adds an item to the component menu only!
  componentList: [
    { componentType: 'heading', clickHandler: 'addHandler' },
    {
      componentType: 'siteData',
      subComponents: [
        { componentType: 'siteAddress', clickHandler: 'toggleHandler' },
        { componentType: 'weather', clickHandler: 'toggleHandler' },
        { componentType: 'siteContacts', clickHandler: 'toggleHandler' },
      ],
    },
    { componentType: 'siteSketch', clickHandler: 'addHandler' },
    { componentType: 'dataTable',
      subComponents: [
        { componentType: 'generalTable', clickHandler: 'addHandler' },
        { componentType: 'shearVaneTable', clickHandler: 'addHandler' },
        { componentType: 'cleggTable', clickHandler: 'addHandler' },
        { componentType: 'healthAndSafetyTakeFive', clickHandler: 'addHandler' },
        { componentType: 'stockpileAssessmentTable', clickHandler: 'addHandler' },        
        { componentType: 'aialStockpileAssessmentTable', clickHandler: 'addHandler' }
      ],
    },
    { componentType: 'photo', clickHandler: 'addHandler' },
    { componentType: 'recommendation', clickHandler: 'addHandler' },
    { componentType: 'generalText', clickHandler: 'addHandler' },
    { componentType: 'pileObservation', clickHandler: 'addHandler' },
  ],

  // Component Details
  // --------------------------
  // Job Summary Table
  jobSummaryTableCells: [
    [
      {
        id: 'job-summary-0',
        type: 'IMMUTABLE',
        field: 'jobNumber',
        name: 'Job number',
        fontWeight: 'normal',
        color: () => 'black',
        value: data => `${data.observationEdit.header.jobId}`,
        readonly: false
      },
      {
        id: 'job-summary-1',
        type: 'DATETIME_PICKER',
        field: 'inspectionTimestamp',
        name: 'Date and time',
        fontWeight: 'normal',
        color: () => 'black',
        value: (data) => {
          if (DateTime.fromISO(data.observationEdit.header.inspectionTimestamp).isValid) {
            return `${DateTime.fromISO(data.observationEdit.header.inspectionTimestamp)
              .toFormat('yyyy-MM-dd')}T${DateTime.fromISO(data.observationEdit.header.inspectionTimestamp)
              .toFormat('HH:mm')}`;
          }
          return '';
        },
        readonly: false
      },
    ],
    [
      {
        id: 'job-summary-2',
        type: 'IMMUTABLE',
        field: 'jobName',
        name: 'Site',
        fontWeight: 'normal',
        color: () => 'black',
        value: data => `${data.observationEdit.header.jobName}`,
        readonly: false
      },
      {
        id: 'job-summary-3',
        type: 'IMMUTABLE',
        field: 'manager',
        name: 'Project Manager',
        fontWeight: 'normal',
        color: () => 'black',
        value: data => `${data.observationEdit.header.manager || ''}`,
        readonly: false
      },
    ],
    [
      {
        id: 'job-summary-4',
        type: 'IMMUTABLE',
        field: 'partner',
        name: 'Director',
        fontWeight: 'normal',
        color: () => 'black',
        value: data => `${data.observationEdit.header.partner || ''}`,
        readonly: false
      },
      {
        id: 'job-summary-5',
        type: 'IMMUTABLE',
        field: 'jobType',
        name: 'Job Type',
        fontWeight: 'normal',
        color: () => 'black',
        value: data => isDefinedAndInitialized(data.observationEdit.header.jobType) ? `${capitalizeFirstLetters(data.observationEdit.header.jobType)}` : '',
        readonly: false
      },
    ],
    [
      {
        id: 'job-summary-6',
        type: 'IMMUTABLE',
        field: 'status',
        name: 'Status (approver)',
        fontWeight: 'bold',
        color: data => {
          const { observationEdit } = data;
          const { header } = observationEdit;
          const { status } = header;
          if (status === 'draft') { return '#f44336'; }
          else if (status === 'review') { return '#c26100'; }
          else if (status === 'approved') { return '#ab8000'; }
          else if (status === 'downloaded') { return '#00943d'; }
          else { return '#474747'; }
        },
        value: data => {
          const { observationEdit } = data;
          const { header } = observationEdit;
          const { status } = header;

          const observationAuthorised = ['downloaded', 'approved'].includes(status);

          let signoffLabel = '';
          if (observationAuthorised) {
            // ------------------------------------------
            // 1) NEW FUNCTIONALITY JUNE 2022 - APPROVER DETAILS STORED FOR SIGNATORY
            if ('approverDetails' in header && isDefinedAndInitialized(header.approverDetails)
                && isDefinedAndInitialized(header.approverDetails.email)
                && isDefinedAndInitialized(header.approverDetails.name)) {
              // ---------------------------------------------------------
              signoffLabel = ` (${header.approverDetails.name})`
            }
            // 2) BACKWARDS COMPATIBILITY
            else {
              signoffLabel = ` (auto: ${header.manager})`
            }
          } 
        
          return `${capitalizeFirstLetters(data.observationEdit.header.status)}${signoffLabel}`;
        },
        readonly: false
      },
      {
        id: 'job-summary-7',
        type: 'IMMUTABLE',
        field: 'inspectionNumber',
        name: 'Inspection Number',
        fontWeight: 'bold',
        color: () => '#1976D2',
        value: data => `${data.observationEdit.header.inspectionNumber || ''}`,
        readonly: false
      },
    ],
    [
      {
        id: 'job-summary-10',
        type: 'TEXTFIELD',
        field: 'createdBy',
        name: 'Created by',
        fontWeight: 'bold',
        color: () => 'black',
        value: data => `${data.jobSummaryTableData.createdBy || ''}`,
        readonly: false
      },
      {
        id: 'job-summary-11',
        type: 'MULTISELECT',
        field: 'inspectionType',
        name: 'Inspection Type',
        fontWeight: 'bold',
        color: () => 'black',
        value: data => `${capitalizeFirstLetters(data.jobSummaryTableData.inspectionType) || ''}`,
        configRef: 'observationTypes',
        readonly: false
      },
    ],
    [
      {
        id: 'job-summary-12',
        type: 'MULTISELECT',
        field: 'observationDuration',
        name: 'Duration',
        fontWeight: 'bold',
        color: () => 'black',
        value: data => `${(data.jobSummaryTableData?.observationDuration ?? null) ? capitalizeFirstLetters(data.jobSummaryTableData?.observationDuration) : ''}`,
        configRef: 'observationDurations',
        readonly: false
      },
      {
        id: 'job-summary-13',
        type: 'IMMUTABLE',
        field: 'buildingConsentNumbers',
        name: 'Building Consents',
        fontWeight: 'normal',
        color: () => 'black',
        value: data => `${(data.observationEdit.header?.buildingConsentNumbers ?? null) ? data.observationEdit.header?.buildingConsentNumbers.split(',').join(', ') : ''}`,
        configRef: 'buildingConsentNumbers',
        readonly: true
      }
    ],
  ],

  geoSpatialConfig: {
    rows: [
      [
        {
          id: 'geospatial-0',
          type: 'TEXTFIELD',
          field: 'latitude',
          name: 'Latitude',
          fontWeight: 'normal',
          color: () => 'black',
          value: data => `${data.jobSummaryTableData.latitude || ''}`,
          readonly: true
        },
        {
          id: 'geospatial-1',
          type: 'TEXTFIELD',
          field: 'longitude',
          name: 'Longitude',
          fontWeight: 'normal',
          color: () => 'black',
          value: data => `${data.jobSummaryTableData.longitude || ''}`,
          readonly: true
        },
      ]
    ],
  },

  // General Text
  generalTextTypes: [
    {
      textType: 'generalText',
      header: 'GENERAL TEXT',
      placeholder: 'General text',
      rows: '6'
    },
    {
      textType: 'recommendation',
      header: 'RECOMMENDATION',
      placeholder: 'Recommendation',
      rows: '6'
    },
    {
      textType: 'heading',
      header: 'SECTION HEADING',
      placeholder: 'Heading',
      rows: '1'
    },
  ],

  // Data Table
  dataTableTypes: [
    {
      tableType: 'generalTable',
      tableHeader: 'GENERAL TABLE',
      columnHeaders: [
        { placeholder: 'Header 0', name: 'header-0', default: null },
        { placeholder: 'Header 1', name: 'header-1', default: null },
        { placeholder: 'Header 2', name: 'header-2', default: null },
        { placeholder: 'Header 3', name: 'header-3', default: null },
      ],
      columnValues: [
        { placeholder: 'Value', name: 'row-0', default: null },
        { placeholder: 'Value', name: 'row-1', default: null },
        { placeholder: 'Value', name: 'row-2', default: null },
        { placeholder: 'Value', name: 'row-3', default: null },
      ],
    },
    {
      tableType: 'shearVaneTable',
      tableHeader: 'SHEAR VANE TABLE',
      columnHeaders: [
        { placeholder: 'Header 0', name: 'header-0', default: 'Test #' },
        { placeholder: 'Header 1', name: 'header-1', default: 'Location / Grid or CH m' },
        { placeholder: 'Header 2', name: 'header-2', default: 'Peak Shear Strength (div)' },
        { placeholder: 'Header 3', name: 'header-3', default: 'Peak Shear Strength (kPa)' },
      ],
      columnValues: [
        { placeholder: 'Test Number', name: 'row-0', default: null },
        { placeholder: 'Location', name: 'row-1', default: null },
        { placeholder: 'Dial reading (div)', name: 'row-2', default: null },
        { placeholder: 'Adjusted (kPa)', name: 'row-3', default: null },
      ],
    },
    {
      tableType: 'cleggTable',
      tableHeader: 'CLEGG TABLE',
      columnHeaders: [
        { placeholder: 'Header 0', name: 'header-0', default: 'Test #' },
        { placeholder: 'Header 1', name: 'header-1', default: 'Location / Grid or CH m' },
        { placeholder: 'Header 2', name: 'header-2', default: 'Impact Value (CIV)' },
        { placeholder: 'Header 3', name: 'header-3', default: 'Fill Height' },
      ],
      columnValues: [
        { placeholder: 'Test Number', name: 'row-0', default: null },
        { placeholder: 'Location', name: 'row-1', default: null },
        { placeholder: 'Impact Value', name: 'row-2', default: null },
        { placeholder: 'Fill Height', name: 'row-3', default: null },
      ],
    },
    {
      tableType: 'nonConformanceRegister',
      tableHeader: 'NON CONFORMANCE REGISTER',
      columnHeaders: [
        { placeholder: 'Header 0', name: 'header-0', default: 'NCR #' },
        { placeholder: 'Header 1', name: 'header-1', default: 'Issue' },
        { placeholder: 'Header 2', name: 'header-2', default: 'Remedial Work Undertaken' },
        { placeholder: 'Header 3', name: 'header-3', default: 'Status (See Reference # for Detail)' },
      ],
      columnValues: [
        { placeholder: 'NCR #', name: 'row-0', default: null },
        { placeholder: 'Issue Description', name: 'row-1', default: null },
        { placeholder: 'Remedial Work Description', name: 'row-2', default: null },
        { placeholder: 'Status (Site Observation #)', name: 'row-3', default: null },
      ],
    },
    {
      tableType: 'healthAndSafetyTakeFive',
      tableHeader: 'HEALTH AND SAFETY - TAKE 5',
      columnHeaders: [
        { placeholder: 'Header 0', name: 'header-0', default: 'No.' },
        { placeholder: 'Header 1', name: 'header-1', default: 'New Hazard' },
        { placeholder: 'Header 2', name: 'header-2', default: 'Basic Control' },
        { placeholder: 'Header 3', name: 'header-3', default: 'Added to JSA' },
      ],
      columnValues: [
        { placeholder: 'No.', name: 'row-0', default: null },
        { placeholder: 'Describe the hazard', name: 'row-1', default: null },
        { placeholder: 'Describe the controls', name: 'row-2', default: null },
        { placeholder: 'Added to JSA?', name: 'row-3', default: null },
      ],
    }
  ],

  v2DataTableTypes: [
    {
      tableType: 'stockpileAssessmentTable',
      tableHeader: 'STOCKPILE ASSESSMENT SUMMARY',
      rowLabel: 'Stockpile Note',
      fontSize: '0.65rem',
      columnWidths: ['30%','30%','20%','20%'],
      rowHeaderHeaderNames: ['header-0', 'header-1'],
      columnHeaders: [
        { label: 'Column 1 Header', placeholder: "Location/Stockpile", name: 'header-0', default: "Location/Stockpile" },
        { label: 'Column 2 Header', placeholder: "Depth", name: 'header-1', default: "Depth" },
        { label: 'Column 3 Header', placeholder: "Visual appraisal of the materials", name: 'header-2', default: "Visual appraisal of the materials" },
        { label: 'Column 4 Header', placeholder: "Classification compliance", name: 'header-3', default: "Classification compliance" },
        { label: 'Column 5 Header', placeholder: "Suitability for re-use", name: 'header-4', default: "Suitability for re-use" },
        { label: 'Column 6 Header', placeholder: "Recommendations", name: 'header-5', default: "Recommendations" }
      ],
      rowHeaderNames: ['col-0', 'col-1'],
      columnValues: [
        { label: 'Column 1 Value', placeholder: 'Value', name: 'col-0', default: null },
        { label: 'Column 2 Value', placeholder: 'Value', name: 'col-1', default: 'Depth: ' },
        { label: 'Column 3 Value', placeholder: 'Value', name: 'col-2', default: null },
        { label: 'Column 4 Value', placeholder: 'Value', name: 'col-3', default: null },
        { label: 'Column 5 Value', placeholder: 'Value', name: 'col-4', default: null },
        { label: 'Column 6 Value', placeholder: 'Value', name: 'col-5', default: null },
      ],
    },
    {
      tableType: 'aialStockpileAssessmentTable',
      tableHeader: 'STOCKPILE ASSESSMENT SUMMARY',
      rowLabel: 'Stockpile Note',
      fontSize: '0.65rem',
      columnWidths: ['30%','30%','20%','20%'],
      rowHeaderHeaderNames: ['header-0', 'header-1'],
      columnHeaders: [
        { label: 'Column 1 Header', placeholder: "Location/Stockpile", name: 'header-0', default: "Location/Stockpile" },
        { label: 'Column 2 Header', placeholder: "Depth", name: 'header-1', default: "Depth" },
        { label: 'Column 3 Header', placeholder: "Visual appraisal of the materials", name: 'header-2', default: "Visual appraisal of the materials" },
        { label: 'Column 4 Header', placeholder: "AIAL EMS Classification compliance", name: 'header-3', default: "AIAL EMS Classification compliance" },
        { label: 'Column 5 Header', placeholder: "Suitability for re-use", name: 'header-4', default: "Suitability for re-use" },
        { label: 'Column 6 Header', placeholder: "Recommendations", name: 'header-5', default: "Recommendations" }
      ],
      rowHeaderNames: ['col-0', 'col-1'],
      columnValues: [
        { label: 'Column 1 Value', placeholder: 'Value', name: 'col-0', default: null },
        { label: 'Column 2 Value', placeholder: 'Value', name: 'col-1', default: 'Depth: ' },
        { label: 'Column 3 Value', placeholder: 'Value', name: 'col-2', default: null },
        { label: 'Column 4 Value', placeholder: 'Value', name: 'col-3', default: null },
        { label: 'Column 5 Value', placeholder: 'Value', name: 'col-4', default: null },
        { label: 'Column 6 Value', placeholder: 'Value', name: 'col-5', default: null },
      ],
    }
  ],
};
