import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import AddCircle from '@material-ui/icons/AddCircle';

// Custom Config
import { config } from '../../config/generalConfig';

// Custom Components
// eslint-disable-next-line
import Row from './row';

// Custom Styles
import './table.css';

const styles = () => ({
  root: {
  },
  tableHeaderRow: {
    borderBottom: '2px solid rgb(128, 128, 128)',
  },
  tableHeaderTitle: {
    paddingTop: '9.5px',
    paddingBottom: '9.5px',
    fontSize: '14px',
  },
  button: {
    marginTop: '12px',
  },
});

const HeaderTextField = (props) => {
  const { id, cellHeaderFormat, cell, classes, onTableUpdate } = props;

  return (
    <TableCell className={classes.root}>
      <TextField
        InputProps={{
          classes: { input: classes.tableHeaderTitle },
        }}
        variant="outlined"
        placeholder={cellHeaderFormat.placeholder}
        name={cellHeaderFormat.name}
        id={id}
        onChange={onTableUpdate}
        value={cell.value || ''}
      />
    </TableCell>
  );
};

HeaderTextField.propTypes = {
  classes: PropTypes.object,
  id: PropTypes.string,
  cellHeaderFormat: PropTypes.object,
  cell: PropTypes.object,
  onTableUpdate: PropTypes.func,
}

function Table(props) {
  const { classes, id, onTableUpdate, onRowAdd, onRowDel, rows, header, tableType } = props;

  return (
    <div>
      <MuiTable className="table table-bordered">
        <TableHead>
          <TableRow className={classes.tableHeaderRow}>
            {header.map((headerCell, i) => {
              const tableFormat = config.dataTableTypes
                .find(tableTypeSearch => tableTypeSearch.tableType === tableType);
              return <HeaderTextField
                id={`${id}-${tableFormat.columnHeaders[i].name}`}
                key={`${id}-${tableFormat.columnHeaders[i].name}`}
                cellHeaderFormat={tableFormat.columnHeaders[i]}
                cell={headerCell}
                classes={classes}
                onTableUpdate={onTableUpdate}
              />;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row =>
            (<Row
              key={row.id}
              tableType={tableType}
              onTableUpdate={onTableUpdate}
              row={row}
              onDelEvent={onRowDel}
            />))}
        </TableBody>
      </MuiTable>

      <IconButton
        className={classes.button}
        aria-label="Add"
        onClick={onRowAdd}
        color="primary"
      >
        <AddCircle color="secondary" className="addButtonColour" fontSize="large" />
      </IconButton>
    </div>
  );
}

Table.propTypes = {
  classes: PropTypes.object,
  id: PropTypes.string,
  onTableUpdate: PropTypes.func,
  onRowAdd: PropTypes.func,
  onRowDel: PropTypes.func,
  rows: PropTypes.array,
  header: PropTypes.array,
  tableType: PropTypes.string
}

export default withStyles(styles)(Table);
