import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

// MSAL imports
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from '@azure/msal-browser';

// Material UI
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

// Context
import { InitiaContext } from '../context/initia-context';

// Custom Components
import DeletablePaperLarge from './deletablePaperLarge';
import PaperLarge from './paperLarge';
import Canvas from './Canvas';
import ReferenceTable from './editableTable/reference-table';
import { isDefinedAndInitialized } from '../helpers/helpers';

const styles = theme => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
});


function SiteSketch(props) {
  // PROPS
  const { deleteHandler, componentData, handleClientObservationStateUpdate, componentUpHandler,
    canvasStore, canvasStoreMetadata, updateCanvasStore, updateCanvasStoreMetadata, shouldAutosave,
    pauseAutoSave, restartAutoSave, firstLoadComplete, sketchCount, includeSketchNumber 
  } = props;

  let setSketchCount = null;
  if (isDefinedAndInitialized(sketchCount)) {
    // ---------------------------------------------------
    setSketchCount = sketchCount;
  }

  let setIncludeSketchNumber = null;
  if (isDefinedAndInitialized(includeSketchNumber)) {
    // ---------------------------------------------------
    setIncludeSketchNumber = includeSketchNumber;
  }

  // USE CONTEXT HOOK
  const { state } = useContext(InitiaContext);

  // USE STATE HOOKS
  const [ sketchDim, setSketchDim ] = useState({ height: 0, width: 0 });

  // MSAL
  const { instance, accounts, inProgress } = useMsal();
  const loginHint = (accounts && accounts[0]?.username) ?? '';
  const request = {
    loginHint,
    scopes: ["User.Read"]
  }
  const isAuthenticated = useIsAuthenticated();

  const headerOnChange = (e, headerProperty) => {
    const componentDataUpdate = componentData;
    const text = e.target.value;
    componentDataUpdate.header[headerProperty] = text;
    handleClientObservationStateUpdate(
      e, componentData.componentType, componentData.componentId, componentDataUpdate,
    );
  };

  const referenceDataOnChange = (e, referencePointId) => {
    const componentDataUpdate = componentData;
    const index = componentDataUpdate.referenceData.findIndex(({ id }) => id === referencePointId);
    const text = e.target.value;
    componentDataUpdate.referenceData[index].text = text || null;
    handleClientObservationStateUpdate(
      e, componentData.componentType, componentData.componentId, componentDataUpdate,
    );
  };

  const addReferenceData = (e, id) => {
    const componentDataUpdate = componentData;

    // If reference data length is zero, we need to add a default title to the table
    if (componentDataUpdate.referenceData.length === 0) {
      // ------------------------------------
      componentData.header.keyHeader = 'Point';
      componentData.header.valueHeader = 'Item';
    }

    componentDataUpdate.referenceData = [...componentDataUpdate.referenceData, {
      text: null, id,
    }];
    handleClientObservationStateUpdate(
      e, componentData.componentType, componentData.componentId, componentDataUpdate,
    );
  };

  const updateReferenceData = (e, referencePointId) => {
    const componentDataUpdate = componentData;
    componentDataUpdate.referenceData = [...componentDataUpdate.referenceData];
    const index = componentDataUpdate.referenceData.findIndex(({ id }) => id === referencePointId);
    componentDataUpdate.referenceData.splice(Number(index), 1);
    handleClientObservationStateUpdate(
      e, componentData.componentType, componentData.componentId, componentDataUpdate,
    );
  };

  const deleteReferenceData = (e) => {
    const componentDataUpdate = componentData;
    componentDataUpdate.referenceData = [];
    handleClientObservationStateUpdate(
      e, componentData.componentType, componentData.componentId, componentDataUpdate,
    );
  };

  const stageCanvasRef = useRef(null);

  // EFFECTS
  useEffect(async () => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      await instance.loginRedirect(request);
    }
  }, [isAuthenticated, inProgress, instance]);

  useEffect( () => {

      // The 'current' property contains info of the reference:
      // align, title, ... , width, height, etc.
      if(stageCanvasRef.current){

          let height = stageCanvasRef.current.offsetHeight;
          let width  = stageCanvasRef.current.offsetWidth;

          setSketchDim({
            height: height * 0.85,
            width: width * 0.85
          })
      }

  }, [stageCanvasRef]);

  return (
    <div>
      <DeletablePaperLarge
        title={<Typography style={{ fontSize: '0.9rem', fontWeight: 'bold', paddingRight: '8px' }}>{`Figure${isDefinedAndInitialized(setSketchCount) && setIncludeSketchNumber ? ` ${setSketchCount}` : ' (empty)'}`}</Typography>}
        onClick={deleteHandler}
        componentId={componentData.componentId}
        componentUpHandler={componentUpHandler}
        ref = {stageCanvasRef}
      >
      {(sketchDim.height !== 0 & sketchDim.width !== 0) &&
        <Canvas
          addReferenceData={addReferenceData}
          updateReferenceData={updateReferenceData}
          deleteReferenceData={deleteReferenceData}
          referenceNumber={componentData.referenceData.length}
          observationEdit={state.observationPage.observationEdit}
          componentData={componentData}
          updateCanvasStore={updateCanvasStore}
          updateCanvasStoreMetadata={updateCanvasStoreMetadata}
          canvasStore={canvasStore}
          canvasStoreMetadata={canvasStoreMetadata}
          sketchDim={sketchDim}
          shouldAutosave={shouldAutosave}
          pauseAutoSave={pauseAutoSave}
          restartAutoSave={restartAutoSave}
          accounts={accounts}
          inProgress={inProgress}
          instance={instance}
          firstLoadComplete={firstLoadComplete}
        />
      }
      </DeletablePaperLarge>
      {!!componentData.referenceData.length && (
        <PaperLarge
          title="REFERENCE DATA"
          onClick={deleteHandler}
        >
          <ReferenceTable
            data={componentData}
            referenceDataOnChange={referenceDataOnChange}
            headerOnChange={headerOnChange}
          />
        </PaperLarge>
      )}
    </div>
  );
}

SiteSketch.propTypes = {
  deleteHandler: PropTypes.func,
  componentData: PropTypes.object,
  handleClientObservationStateUpdate: PropTypes.func,
  componentUpHandler: PropTypes.func,
  canvasStore: PropTypes.object,
  canvasStoreMetadata: PropTypes.object,
  updateCanvasStore: PropTypes.func,
  updateCanvasStoreMetadata: PropTypes.func,
  shouldAutosave: PropTypes.bool,
  pauseAutoSave: PropTypes.func,
  restartAutoSave: PropTypes.func,
  firstLoadComplete: PropTypes.bool,
  sketchCount: PropTypes.number,
  includeSketchNumber: PropTypes.bool
};

export default withStyles(styles)(SiteSketch);
