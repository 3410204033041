
import { fabric } from 'fabric';

const textInput = (canvas, setSelectionTool, color) => {
  const textBox = new fabric.Textbox('Enter Text', {
    fontSize: 20,
    fontFamily: 'Arial',
    textAlign: 'left',
    width: 120,
    height: 180,
    top: 20,
    left: 20,
    backgroundColor: 'white',
    fill: color
  });

  canvas.add(textBox);
  canvas.renderAll();

  setSelectionTool();
};

export default textInput;
