import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';

// Material UI
import {
    useTheme, useMediaQuery, withStyles, Box, Typography, Avatar, Button, List, ListItem,
    ListItemText, ListItemIcon, Dialog, DialogActions,
    DialogContent, DialogContentText, DialogTitle, Collapse
} from '@material-ui/core';
import {
    ExpandLess, ExpandMore, PanTool as PanToolIcon, Pageview as PageviewIcon,
    Create as CreateIcon, Delete as DeleteIcon,
    Fingerprint as FingerprintIcon, PriorityHigh as PriorityHighIcon,
    Done as DoneIcon, ThumbUp as ThumbUpIcon, VerticalAlignBottom
}
    from '@material-ui/icons';

// Custom Components
import ApprovalDialog from './approvalDialog';
import ReviewNotificationDialog from './reviewNotificationDialog';

// Custom Helpers
import { isDefinedAndInitialized, capitalizeFirstLetters } from '../helpers/helpers';

// Custom Config
import { config } from '../config/generalConfig';


const styles = () => ({
    draft: {
        backgroundColor: config.statusTypes.find(statusType => statusType.name === 'draft').iconBackgroundColour,
    },
    review: {
        backgroundColor: config.statusTypes.find(statusType => statusType.name === 'review').iconBackgroundColour,
    },
    approved: {
        backgroundColor: config.statusTypes.find(statusType => statusType.name === 'approved').iconBackgroundColour,
    },
    downloaded: {
        backgroundColor: config.statusTypes.find(statusType => statusType.name === 'downloaded').iconBackgroundColour,
    },
    approvedForDownloadIcon: {
        color: '#00c853',
    },
});

const ObservationSummaryListItem = (props) => {
    // PROPS
    const { classes, tabName, ObservationSummaryData, handleApproveObservation: updateObservationStatusHandler, refreshSummaryList,
        handlePDFObservation, handleDeleteObservation, idx, showActions, handleShowActions, useMobileLayout } = props;

    let setIdx = null;
    if (isDefinedAndInitialized(idx)) {
        // -----------------------------------
        setIdx = idx;
    }
    let setShowActions = false;
    if (isDefinedAndInitialized(showActions)) {
        // -----------------------------------
        setShowActions = showActions;
    }
    let setHandleShowActions = undefined;
    if (isDefinedAndInitialized(handleShowActions)) {
        // -----------------------------------
        setHandleShowActions = handleShowActions;
    }
    let setUseMobileLayout = false;
    if (isDefinedAndInitialized(useMobileLayout)) {
        // -----------------------------------
        setUseMobileLayout = useMobileLayout;
    }

    const { header } = ObservationSummaryData;
    const { status, jobId, jobName, createdBy, inspectionNumber, inspectionType, inspectionTimestamp, updateTimestamp } = header;

    const observationAuthorised = ['downloaded', 'approved'].includes(status);
    const approvalStatusModifier = observationAuthorised ? 'draft' : 'approved';
    const reviewStatusModifier = ['downloaded', 'approved', 'review'].includes(status) ? 'draft' : 'review';
    const isPreReview = status === 'draft';

    // CONST MUI HOOKS
    const theme = useTheme();
    const isSmallerThanXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('md'));

    // DERIVED PROPS
    let listItemTextPrimaryFontSize = '1rem';
    let listItemTextSecondaryFontSize = '1rem';
    let listItemActionsTextFontSize = '1rem';
    let listItemIconFontSize = '1.5rem';
    let listItemPaddingY = '8px'
    if (isSmallerThanXs) {
        // -------------------------------
        listItemActionsTextFontSize = '0.8rem';
        listItemIconFontSize = '1.2rem';
        listItemPaddingY = '2px'
    }
    else if (isSmallerThanMd) {
        // -------------------------------
        listItemActionsTextFontSize = '0.9rem';
        listItemIconFontSize = '1.35rem';
        listItemPaddingY = '4px'
    }

    if (isSmallerThanXs || setUseMobileLayout) {
        // -------------------------------
        listItemTextPrimaryFontSize = '0.8rem';
        listItemTextSecondaryFontSize = '0.7rem';
    }
    else if (isSmallerThanMd) {
        // -------------------------------
        listItemTextPrimaryFontSize = '1rem';
        listItemTextSecondaryFontSize = '0.8rem';
    }

    let approverText = '';
    if (observationAuthorised) {
        // ------------------------------------------
        // 1) NEW FUNCTIONALITY JUNE 2022 - APPROVER DETAILS STORED FOR SIGNATORY
        if ('approverDetails' in header && isDefinedAndInitialized(header.approverDetails)
            && isDefinedAndInitialized(header.approverDetails.email)
            && isDefinedAndInitialized(header.approverDetails.name)) {
            // ---------------------------------------------------------
            approverText = ` (by ${header.approverDetails.name})`
        }
        // 2) BACKWARDS COMPATIBILITY
        else {
            approverText = ` (auto: ${header.manager})`
        }
    }

    // STATE
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [approveConfirmationOpen, setApproveConfirmationOpen] = useState(false);
    const [reviewNotifyPromptOpen, setReviewNotifyPromptOpen] = useState(false);

    const handleClick = () => {
        handleShowActions(setIdx);
    };

    const handleDeleteDialog = () => {
        setDeleteConfirmationOpen(false);
    };

    const handleApproveDialog = () => {
        setApproveConfirmationOpen(false);
    };

    const handleReviewDialog = () => {
        setReviewNotifyPromptOpen(false);
    };

    const inspectionIcon = (status) => {
        switch (status) {
            case 'draft':
                return <PriorityHighIcon />;
            case 'review':
                return <PanToolIcon />;
            case 'approved':
                return <ThumbUpIcon />;
            case 'downloaded':
                return <DoneIcon />;
            default:
                return '?';
        }
    };


    const userGroups = JSON.parse(localStorage.getItem('siteObservationsUserGroups'));
    const authorizedForReview = isDefinedAndInitialized(userGroups) && userGroups.filter(group => group.displayName === 'Site Observations Reviewers').length > 0;

    return (
        <Box>
            <ListItem key={`observation#${ObservationSummaryData.id}`} button onClick={() => setHandleShowActions(setIdx)}>
                <Avatar className={classes[ObservationSummaryData.header.status]}>
                    {inspectionIcon(ObservationSummaryData.header.status)}
                </Avatar>
                <ListItemText
                    style={{ paddingLeft: '16px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflowX: 'hidden' }}
                    primaryTypographyProps={{
                        style: {
                            fontSize: listItemTextPrimaryFontSize,
                            textOverflow: 'ellipsis',
                            overflowX: 'hidden'
                        }
                    }}
                    secondaryTypographyProps={{
                        style: {
                            fontSize: listItemTextSecondaryFontSize,
                            textOverflow: 'ellipsis',
                            overflowX: 'hidden'
                        }
                    }}
                    primary={`${jobId}`
                        + `/${inspectionNumber}`
                        + ` | ${jobName}`}
                    secondary={ `${capitalizeFirstLetters(createdBy.name)} ${isSmallerThanXs || setUseMobileLayout ? '' : `| ${capitalizeFirstLetters(inspectionType)} `}${isSmallerThanXs ? '' : `| ${capitalizeFirstLetters(status)}${approverText}`} | ${DateTime.fromISO(inspectionTimestamp).toFormat('dd/MM/yyyy hh:mm a')}` }
                />
                {setShowActions ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={setShowActions} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <Link
                        key={`edit-observation${ObservationSummaryData.inspectionId}`}
                        to={`/observation/${ObservationSummaryData.inspectionId}`}
                        style={{ textDecoration: 'none' }}
                    >
                        <ListItem button style={{ paddingLeft: '16px', paddingTop: listItemPaddingY, paddingBottom: listItemPaddingY }}>
                            <ListItemIcon style={{ marginLeft: '9px', minWidth: '30px' }}>
                                <CreateIcon style={{ fontSize: listItemIconFontSize }} />
                            </ListItemIcon>
                            <ListItemText 
                                inset primary="Edit"
                                primaryTypographyProps={{ style: { fontSize: listItemActionsTextFontSize } }}
                                style={{ paddingLeft: '25px', color: 'rgb(0,0,0,0.87)' }} 
                            />
                        </ListItem>
                    </Link>

                    {/* REVIEW REQUEST */}
                    <ListItem
                        button
                        style={{ paddingLeft: '16px', paddingTop: listItemPaddingY, paddingBottom: listItemPaddingY }}
                        onClick={async () => {
                            if (isPreReview) {
                                // --------------------------
                                setReviewNotifyPromptOpen(true);
                            }
                            else {
                                updateObservationStatusHandler(props.ObservationSummaryData.inspectionId, reviewStatusModifier, false, updateTimestamp);
                            }
                        }}
                    >
                        <ListItemIcon style={{ marginLeft: '9px', minWidth: '30px' }}>
                            <PageviewIcon
                                style={{ fontSize: listItemIconFontSize, color: (isPreReview) ? config.statusTypes.find(statusType => statusType.name === 'review').statusFontColour : undefined }}
                            />
                        </ListItemIcon>
                        <ListItemText
                            inset
                            primaryTypographyProps={{ color: 'inherit', style: { fontSize: listItemActionsTextFontSize } }}
                            style={{ paddingLeft: '25px', color: (isPreReview) ? config.statusTypes.find(statusType => statusType.name === 'review').statusFontColour : undefined }}
                            primary={isPreReview ? 'Request Review' : 'Cancel Review'}
                        />
                    </ListItem>

                    {authorizedForReview &&
                        <ListItem
                            button
                            style={{ paddingLeft: '16px', paddingTop: listItemPaddingY, paddingBottom: listItemPaddingY }}
                            disabled={!authorizedForReview}
                            onClick={async () => {
                                if (!observationAuthorised) {
                                    setApproveConfirmationOpen(true);
                                } else if (tabName === 'MONTH_SUMMARY') {
                                    let notify = 'approved' === approvalStatusModifier;
                                    updateObservationStatusHandler(props.ObservationSummaryData.inspectionId, approvalStatusModifier,
                                        notify, updateTimestamp);
                                } else {
                                    let notify = 'approved' === approvalStatusModifier;
                                    updateObservationStatusHandler(props.ObservationSummaryData.inspectionId, approvalStatusModifier,
                                        notify, updateTimestamp);
                                }
                            }}
                        >
                            <ListItemIcon style={{ marginLeft: '9px', minWidth: '30px' }}>
                                <FingerprintIcon
                                    style={{ fontSize: listItemIconFontSize, color: (!observationAuthorised) ? config.statusTypes.find(statusType => statusType.name === 'approved').statusFontColour : undefined }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                inset
                                primaryTypographyProps={{ color: 'inherit', style: { fontSize: listItemActionsTextFontSize } }}
                                style={{ paddingLeft: '25px', color: (!observationAuthorised) ? config.statusTypes.find(statusType => statusType.name === 'approved').statusFontColour : undefined }}
                                primary={!observationAuthorised ? 'Approve' : 'Remove Approval'}
                            />
                        </ListItem>
                    }

                    <ListItem
                        button
                        disabled={!observationAuthorised}
                        style={{ paddingLeft: '16px', paddingTop: listItemPaddingY, paddingBottom: listItemPaddingY }}
                        onClick={async () => {
                            handleClick();
                            if (tabName === 'MONTH_SUMMARY') {
                                handlePDFObservation(ObservationSummaryData.inspectionId,
                                    ObservationSummaryData.header.updateTimestamp,
                                    refreshSummaryList);
                            } else {
                                handlePDFObservation(props.ObservationSummaryData.inspectionId,
                                    ObservationSummaryData.header.updateTimestamp);
                            }
                        }}
                    >
                        <ListItemIcon style={{ marginLeft: '9px', minWidth: '30px' }}>
                            <VerticalAlignBottom
                                style={{ fontSize: listItemIconFontSize }}
                                className={!observationAuthorised ? classes.root : classes.approvedForDownloadIcon}
                            />
                        </ListItemIcon>
                        <ListItemText
                            inset
                            style={{ paddingLeft: '25px', color: 'rgb(0,0,0,0.87)' }}
                            disableTypography={observationAuthorised}
                            primaryTypographyProps={{ style: { fontSize: listItemActionsTextFontSize } }}
                            primary={!observationAuthorised
                                ? 'Download Pending Approval'
                                : <Typography type="body1" style={{ fontSize: listItemActionsTextFontSize, color: '#00c853' }}>Download</Typography>}
                        />
                    </ListItem>
                    <ListItem button style={{ paddingLeft: '16px', paddingTop: listItemPaddingY, paddingBottom: listItemPaddingY }} onClick={() => { setDeleteConfirmationOpen(true); }}>
                        <ListItemIcon style={{ marginLeft: '9px', minWidth: '30px' }}>
                            <DeleteIcon style={{ fontSize: listItemIconFontSize }} />
                        </ListItemIcon>
                        <ListItemText inset primary="Delete" primaryTypographyProps={{ style: { fontSize: listItemActionsTextFontSize } }} style={{ paddingLeft: '25px', color: 'rgb(0,0,0,0.87)' }} />
                    </ListItem>
                </List>
            </Collapse>
            <Dialog
                open={deleteConfirmationOpen}
                onClose={handleDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Are you sure you want to delete this inspection?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Deleting this inspection will remove it permanently.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDialog} style={{ color: '#1976d2' }}>
                        Cancel
                    </Button>
                    <Button
                        onClick={async () => {
                            handleDeleteDialog();
                            if (tabName === 'MONTH_SUMMARY') {
                                handleDeleteObservation(ObservationSummaryData.inspectionId,
                                    ObservationSummaryData.header.updateTimestamp, refreshSummaryList);
                            } else {
                                handleDeleteObservation(ObservationSummaryData.inspectionId,
                                    ObservationSummaryData.header.updateTimestamp);
                            }
                        }}
                        style={{ color: '#1976d2' }}
                        autoFocus
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <ApprovalDialog
                tabName={tabName}
                observationId={ObservationSummaryData.inspectionId}
                updateTimestamp={ObservationSummaryData.header.updateTimestamp}
                approvalStatusModifier={approvalStatusModifier}
                approveConfirmationOpen={approveConfirmationOpen}
                handleApproveDialog={handleApproveDialog}
                updateObservationStatusHandler={updateObservationStatusHandler}
                refreshSummaryList={refreshSummaryList}
            />
            <ReviewNotificationDialog
                tabName={tabName}
                observationId={ObservationSummaryData.inspectionId}
                updateTimestamp={ObservationSummaryData.header.updateTimestamp}
                approvalStatusModifier={approvalStatusModifier}
                reviewNotifyPromptOpen={reviewNotifyPromptOpen}
                handleReviewDialog={handleReviewDialog}
                updateObservationStatusHandler={updateObservationStatusHandler}
            />
        </Box>
    );
};

ObservationSummaryListItem.propTypes = {
    idx: PropTypes.number,
    showActions: PropTypes.bool,
    handleShowActions: PropTypes.func,
    useMobileLayout: PropTypes.bool,
    classes: PropTypes.object,
    tabName: PropTypes.string,
    ObservationSummaryData: PropTypes.object,
    handleApproveObservation: PropTypes.func,
    refreshSummaryList: PropTypes.func,
    handlePDFObservation: PropTypes.func,
    handleDeleteObservation: PropTypes.func
}

export default (withStyles)(styles)(ObservationSummaryListItem);