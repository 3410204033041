import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

// Material-UI
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = () => ({
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
    color: '#1976d2',
  },
  loader: {
    color: '#1976d2',
  },
});

function BasicLoader(props) {
  const { classes } = props;

  return (
    <div className={classes.loaderContainer}>
      <CircularProgress className={classes.loader} />
    </div>
  );
}

BasicLoader.propTypes = {
  classes: PropTypes.object
}

export default withStyles(styles)(BasicLoader);
