


// RETURNS 1 IF HAZARD CONSEQUENCE A IS 'MORE CRITICAL' THAN CONSEQUENCE B
export const hazardConsequenceCompare = (a, b) => {
  // -----------------------------------------------------
  const hazardConsquenceArray = ['CRITICAL', 'HIGH', 'MOD', 'MODERATE', 'LOW', 'V_LOW', 'VERY_LOW'];

  // Consequence of A better than B - therefore sort A below B
  if ((hazardConsquenceArray.indexOf(a) - hazardConsquenceArray.indexOf(b)) > 0) {
    return 1
  }
  // Consequence of A worse than B - therefore sort A above B
  else if ((hazardConsquenceArray.indexOf(a) - hazardConsquenceArray.indexOf(b)) < 0) {
    return -1;
  }
  // Consequence of A equal to B - therefore no sort preference
  else {
    return 0;
  }
}


export const sortHazardByResidualRisk = (hazardA, hazardB) => {
  // -------------------------------------------
  const initialHazard = {
    eliminate: false,
    isolate: false,
    minimise: false,
    description: '',
    potentialConsequenceBeforeControl: 'V_LOW',
    potentialConsequenceAfterControl: 'V_LOW'
  };

  const hazardAWorstConsequenceAfter = hazardA.hazardControls.reduce((prev, current) => {
    if (hazardConsequenceCompare(current.potentialConsequenceAfterControl, prev.potentialConsequenceAfterControl) < 0) {
      return current;
    }
    else {
      return prev;
    }
  }, initialHazard).potentialConsequenceAfterControl;

  const hazardAWorstConsequenceBefore = hazardA.hazardControls.reduce((prev, current) => {
    if (hazardConsequenceCompare(current.potentialConsequenceBeforeControl, prev.potentialConsequenceBeforeControl) < 0) {
      return current;
    }
    else {
      return prev;
    }
  }, initialHazard).potentialConsequenceBeforeControl;

  const hazardBWorstConsequenceAfter = hazardB.hazardControls.reduce((prev, current) => {
    if (hazardConsequenceCompare(current.potentialConsequenceAfterControl, prev.potentialConsequenceAfterControl) < 0) {
      return current;
    }
    else {
      return prev;
    }
  }, initialHazard).potentialConsequenceAfterControl;

  const hazardBWorstConsequenceBefore = hazardB.hazardControls.reduce((prev, current) => {
    if (hazardConsequenceCompare(current.potentialConsequenceBeforeControl, prev.potentialConsequenceBeforeControl) < 0) {
      return current;
    }
    else {
      return prev;
    }
  }, initialHazard).potentialConsequenceBeforeControl;

  return hazardConsequenceCompare(hazardAWorstConsequenceAfter, hazardBWorstConsequenceAfter)
    || hazardConsequenceCompare(hazardAWorstConsequenceBefore, hazardBWorstConsequenceBefore);
}
