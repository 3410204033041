import React, { useState } from 'react';
import PropTypes from 'prop-types';

// MUI
import { Box, Card, CardContent, Typography, Tooltip, Button, IconButton, useTheme } from '@material-ui/core';
import UploadFileIcon from '@material-ui/icons/Publish';

// REACT-DROPZONE
import { useDropzone } from 'react-dropzone';

// NOTISTACK
import { useSnackbar } from 'notistack';

// HELPERS
import { isDefinedAndInitialized } from '../../helpers/helpers';
import { ErrorAlert } from './Alerts';



export const BasicIconButton = (props) => {
    // -------------------------------------------
    // PROPS
    const { size, backgroundColor, hoverColor, hoverBackgroundColor, color, icon, onClick, ariaLabel, tooltipLabel } = props;
  
    const setHoverColor = isDefinedAndInitialized(hoverColor) ? hoverColor : 'white';
    const setHoverBackgroundColor = isDefinedAndInitialized(hoverBackgroundColor) ? hoverBackgroundColor : 'darkgrey';
  
    const setSize = isDefinedAndInitialized(size) ? size : 'small'
  
    const withTooltip = isDefinedAndInitialized(tooltipLabel);
  
    if (withTooltip) {
      return (
        <Tooltip title={tooltipLabel}>
          <IconButton
            size={setSize}
            aria-label={ariaLabel}
            style={{ backgroundColor, '&:hover': { backgroundColor: setHoverBackgroundColor, color: setHoverColor }, color }}
            onClick={onClick}
          >
            {icon}
          </IconButton>
        </Tooltip>
      )
    }
    else {
      return (
        <IconButton
          aria-label={ariaLabel}
          style={{ backgroundColor, '&:hover': { backgroundColor: setHoverBackgroundColor, color: setHoverColor }, color }}
          onClick={onClick}
        >
          {icon}
        </IconButton>
      )
    }
  }

  BasicIconButton.propTypes = {
    ariaLabel: PropTypes.string,
    tooltipLabel: PropTypes.string,
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
    hoverColor: PropTypes.string,
    hoverBackgroundColor: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.element]),
    onClick: PropTypes.func,
    ref: PropTypes.object,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    disabled: PropTypes.bool
  }


  export const BasicIconTextButton = (props) => {
    // -------------------------------------------
    // PROPS
    const { children, backgroundColor, color, buttonVariant, startIcon, endIcon, onClick,
      fontSize, disabled, hoverColor, hoverBackgroundColor, ariaLabel } = props;
  
    const variant = isDefinedAndInitialized(buttonVariant) ? buttonVariant : 'contained';
    const isDisabled = isDefinedAndInitialized(disabled) ? disabled : false;
    const setFontSize = isDefinedAndInitialized(fontSize) ? fontSize : '0.85rem';
    const setHoverColor = isDefinedAndInitialized(hoverColor) ? hoverColor : 'primary.main';
    const setHoverBackgroundColor = isDefinedAndInitialized(hoverBackgroundColor) ? hoverBackgroundColor : 'primary.contrastText'
    const setAriaLabel = isDefinedAndInitialized(ariaLabel) ? ariaLabel : 'not-labelled';
  
    return (
      <Button
        size="small"
        aria-label={setAriaLabel}
        variant={variant}
        style={{
          width: '100%', backgroundColor, color, '&:hover': { backgroundColor: setHoverBackgroundColor, color: setHoverColor }, fontSize: setFontSize,
          textDecoration: 'none', border: variant === 'outlined' ? `1px solid ${color}` : undefined, paddingY: '10px'
        }}
        startIcon={startIcon} endIcon={endIcon}
        onClick={onClick}
        disabled={isDisabled} >
        {children}
  
      </Button>
    )
  }

  BasicIconTextButton.propTypes = {
    children: PropTypes.node,
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
    buttonVariant: PropTypes.oneOf(["text", "contained", "outlined"]),
    startIcon: PropTypes.node,
    endIcon: PropTypes.node,
    onClick: PropTypes.func,
    fontSize: PropTypes.string,
    disabled: PropTypes.bool,
    hoverColor: PropTypes.string,
    hoverBackgroundColor: PropTypes.string,
    ariaLabel: PropTypes.string
  }


  export const UploadFileButton = (props) => {
    // -------------------------------------------
    // PROPS
    const { handleFileSelection } = props;
  
    // STATE
    const [ isDraggedOver, setIsDraggedOver ] = useState(false);
  
    // NOTISTACK
    const { enqueueSnackbar } = useSnackbar();
  
    // REACT DROPZONE HOOKS
    const { getRootProps, getInputProps } = useDropzone({
      accept: {
        'image/*': ['.jpeg', '.jpg', '.png'],
        'application/pdf': ['.pdf'],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        'application/vnd.ms-excel': ['.xls'],
        'application/msword': ['.doc'],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
        'video/mp4': ['.mp4']
      },
      maxFiles: 10,
      onDragEnter: () => {
        setIsDraggedOver(true);
      },
      onDragLeave: () => {
        setIsDraggedOver(false);
      },
      onDrop: (acceptedFiles, fileRejections) => {
        // -------------------------------
        setIsDraggedOver(false);
  
        if (fileRejections.length > 0) {
          // ---------------------------------------
          const message = fileRejections.map(({ file, errors }) => (
            <li key={file.name}>
              {file.name} - {`${Math.round(file.size / 1000)}`} KB bytes
              <ul>
                {errors.map(e => (
                  <li key={e.code}>{e.message}</li>
                ))}
              </ul>
            </li>
          ));
  
          enqueueSnackbar("Failed to process some files", {
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            /* eslint-disable react/display-name */
            content: (key) => (
                <Box key={key}><ErrorAlert message={message} /></Box>
            )
          });
        }
        else if (acceptedFiles.length > 0) {
          // ---------------------------------------
          handleFileSelection(acceptedFiles);
        }
      }
    });
  
    // MUI HOOKS
    const theme = useTheme();
  
    return (
      <Box {...getRootProps()}>
        <input { ...getInputProps() } />
        <Card 
          variant="outlined" style={{ 
            width: '100%', 'transition': theme.transitions.create(['background-color', 'transform']),
            backgroundColor: isDraggedOver ? 'primary.main': undefined, border: isDraggedOver ? '2px solid black': '1px solid #cccccc',
            ':hover': { 
            backgroundColor: 'primary.main', 'transition': theme.transitions.create(['background-color', 'transform']) 
            } 
          }}>
          <CardContent>
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" >
              <Typography variant="subtitle2" style={{ fontSize: 14, textAlign: 'center' }} color="secondary" gutterBottom>
                Drag and drop up to 10 files or click to select new files
              </Typography>
              <Box>
                <UploadFileIcon style={{ fontSize: '3rem' }} />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    )
  }

  UploadFileButton.propTypes = {
    handleFileSelection: PropTypes.func
  }