import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// MSAL imports
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from '@azure/msal-browser';

// REACT ROUTER
import { Redirect } from 'react-router-dom';

import classNames from 'classnames';

// NOTISTACK
import { useSnackbar } from 'notistack';

// LUXON
import { DateTime } from 'luxon';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Fab, Box, CircularProgress, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import green from '@material-ui/core/colors/green';

// CUSTOM SERVICES
import { createObservation } from '../services/api';

// CUSTOM HELPERS
import { isDefinedAndInitialized } from '../helpers/helpers';

// Custom Components
import EnhancedMenu from './enhancedMenu/enhancedMenu';

// Custom Classes
import { SalesforceJobAutosuggest } from './salesforceJobAutosuggest';


const snackBarAutoHide = 2000;

const styles = theme => ({
  root: {
    paddingLeft: 0,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  autosuggest: {
    paddingLeft: 0,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    marginLeft: theme.spacing(1),
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonInactive: {
    marginLeft: theme.spacing(1),
  },
  fabFail: {
    marginBottom: '20px',
  },
  fabProgress: {
    color: '#B0BEC5',
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
    marginLeft: theme.spacing(1),
  },
});

function CreateObservationInput(props) {
  const { classes } = props;

  // NOTISTACK
  const { enqueueSnackbar } = useSnackbar();

  // MSAL
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const loginHint = (accounts && accounts[0]?.username) ?? '';
  const request = {
    loginHint,
    scopes: ["User.Read"]
  }
  const isAuthed = inProgress === "none" && isDefinedAndInitialized(accounts) && accounts.length > 0 && isDefinedAndInitialized(accounts[0]) && isDefinedAndInitialized(accounts[0].username);

  const [anchorElement, setAnchorElement] = useState(null);
  const [inputState, setInputState] = useState({
    loading: false,
    success: false,
    completed: false,
    jobId: '',
    textFieldLabel: 'Create an Observation',
    autosuggestError: false,
    autosuggestErrorMessage: '',
    newObservation: null,
    newObservationTemplate: null,
  });

  const buttonClassname = classNames({
    [classes.buttonSuccess]: inputState.success,
    [classes.buttonInactive]: !inputState.success,
    [classes.fabFail]: inputState.autosuggestError,
  });

  // EFFECTS
  useEffect(async () => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      await instance.loginRedirect(request);
    }
  }, [isAuthenticated, inProgress, instance]);

  useEffect(() => {
    if (inputState.loading && isAuthed) {
      // Construct the POST /observation body
      const body = {
        createdBy: {
          id: accounts[0]?.idTokenClaims?.oid, // immutable identifier for Azure AD user (refer: https://docs.microsoft.com/en-us/azure/active-directory/develop/id-tokens)
          name: accounts[0]?.idTokenClaims?.name,
        },
        createJobId: inputState.jobId,
        createInspectionTimestamp: Math.floor(DateTime.now().toSeconds()),
        createObservationTemplate: inputState.newObservationTemplate,
      };
      enqueueSnackbar('Creating observation', { variant: 'info', autoHideDuration: snackBarAutoHide });
      createObservation(body, { instance, accounts, inProgress })
        .then((res) => {
          enqueueSnackbar('Observation created successfully',
            { variant: 'success', autoHideDuration: snackBarAutoHide });
          setInputState({
            ...inputState,
            loading: false,
            success: true,
            completed: true,
            newObservation: res,
            newObservationTemplate: null,
          });
        })
        .catch((error) => {
          console.error('Error creating observation', error);
          enqueueSnackbar(`Error creating observation - please contact support with detail of what happened before this error`,
            { variant: 'error', autoHideDuration: snackBarAutoHide });
          setInputState({
            ...inputState,
            loading: false,
            success: false,
            completed: true,
          });
        });
    }
  }, [inputState, isAuthed]);

  const handleJobIdInputChange = (jobId) => {
    // --------------------------------------------
    // Handle an update of the observation list when a suggestion is selected that is not null
    if (isDefinedAndInitialized(jobId) && jobId !== '') {
      // -----------------------------
      setInputState({
        ...inputState,
        jobId,
      });
    }
  };

  const handleButtonClick = (event) => {
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorElement(null);
  };

  const createHandler = (e, observationTemplate) => {
    setInputState({
      ...inputState,
      newObservationTemplate: observationTemplate,
      loading: true,
    });
  };


  const menuItems = [
    {
      componentType: 'earthworksTemplate',
      caption: 'Earthworks Observation',
      clickHandler: createHandler,
    },
    {
      componentType: 'pileObservationTemplate',
      caption: 'Pile Observation',
      clickHandler: createHandler,
    },
    {
      componentType: 'geologicalFaceMapping',
      caption: 'Face Mapping',
      clickHandler: createHandler,
    },
    {
      componentType: 'aialStockpileAssessment',
      caption: 'AIAL Stockpile Assessment',
      clickHandler: createHandler,
    },    
    {
      componentType: 'standardTemplate',
      caption: 'Standard Observation',
      clickHandler: createHandler,
    },
    {
      componentType: 'aucklandLandslips2023Template',
      caption: 'Landslip Template',
      clickHandler: createHandler,
    },
    {
      componentType: 'blankTemplate',
      caption: 'Blank Observation',
      clickHandler: createHandler,
    }
  ];

  // If a new observation has been created we want to redirect to it
  if (inputState.completed && inputState.newObservation) {
    return <Redirect to={`/observation/${inputState.newObservation.inspectionId}?created=true`} />;
  }


  // HANDLE LOADING STATE
   // MSAL
   if (
    inProgress !== "none" 
  )
  {
    return (
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt="15px">
        <Typography>Loading your profile..</Typography>
        <CircularProgress />
      </Box>
    )
  }


  return (
    <Box display="flex" flexDirection="row" alignItems="center" my="20px" >
      <SalesforceJobAutosuggest
        label={'Create an observation'}
        className={classes.autosuggest}
        handleChange={handleJobIdInputChange}
      />
      <Box className={classes.wrapper}>
        <Fab aria-label="create" color="secondary" className={buttonClassname} onClick={handleButtonClick}>
          {inputState.success ? <CheckIcon /> : <AddIcon />}
        </Fab>
        <EnhancedMenu
          open={Boolean(anchorElement)}
          menuItems={menuItems}
          anchorElement={anchorElement}
          onClose={handleMenuClose}
          handleClientObservationStateUpdate={null}
        />
        {inputState.loading && <CircularProgress size={68} className={classes.fabProgress} />}
      </Box>
    </Box>
  );
}

CreateObservationInput.propTypes = {
  classes: PropTypes.object
}

export default withStyles(styles)(CreateObservationInput);
