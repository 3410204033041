

export const getNcrIcon = (color, size) => `<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="${size}" viewBox="0 0 24 24" width="${size}" fill="${color}"><g><rect fill="none" height="24" width="24"/><path d="M16.54,11L13,7.46l1.41-1.41l2.12,2.12l4.24-4.24l1.41,1.41L16.54,11z M11,7H2v2h9V7z M21,13.41L19.59,12L17,14.59 L14.41,12L13,13.41L15.59,16L13,18.59L14.41,20L17,17.41L19.59,20L21,18.59L18.41,16L21,13.41z M11,15H2v2h9V15z"/></g></svg>`

export const getNcrOpenIcon = (color, size) => `<svg xmlns="http://www.w3.org/2000/svg" height="${size}px" viewBox="0 0 24 24" width="${size}px" fill="${color}"><path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"/></svg>`

export const getNcrClosedIcon = (color, size) => `<svg xmlns="http://www.w3.org/2000/svg" height="${size}px" viewBox="0 0 24 24" width="${size}px" fill="${color}"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z"/></svg>`

export const getNcrUnexpectedIcon = (color, size) => `<svg xmlns="http://www.w3.org/2000/svg" height="${size}px" viewBox="0 0 24 24" width="${size}px" fill="${color}"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z"/></svg>`


