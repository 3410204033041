import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

// MSAL imports
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from '@azure/msal-browser';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import { Button, ButtonGroup, Box, Divider, CircularProgress, Typography } from '@material-ui/core';

import {
  useQuery
} from 'react-query';

// CUSTOM SERVICES AND HELPERS
import { searchOLAP } from '../services/api';
import { isDefinedAndInitialized } from '../helpers/helpers';

// Custom Components
import ObservationSummaryList from './observationSummaryList';

// Custom Config
import { config } from '../config/generalConfig';

// Custom Helpers
import { capitalizeFirstLetters } from '../helpers/helpers';


const styles = theme => ({
  root: {
    paddingLeft: 0,
  },
  wrapper: {
    margin: 5,
    position: 'relative',
  },
  autosuggest: {
    paddingLeft: 0,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    marginLeft: theme.spacing(1),
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonInactive: {
    marginLeft: theme.spacing(1),
  },
  fabFail: {
    marginBottom: '20px',
  },
  fabProgress: {
    color: '#B0BEC5',
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
    marginLeft: theme.spacing(1),
  },
  listItemAvatar: {
    marginRight: '5px',
  },
  listItemText: {
    paddingLeft: '25px',
    color: 'rgb(0,0,0,0.87)',
  },
  draftUnselected: {
    color: 'black',
    backgroundColor: 'white'
  },
  draftSelected: {
    color: 'white',
    backgroundColor: 'red',
    '&:hover': {
      backgroundColor: 'pink',
    }
  },
  reviewUnselected: {
    color: 'black',
    backgroundColor: 'white'
  },
  reviewSelected: {
    color: 'white',
    backgroundColor: '#ed7802',
    '&:hover': {
      backgroundColor: '#cf8b48',
    }
  },
  approvedUnselected: {
    color: 'black',
    backgroundColor: 'white'
  },
  approvedSelected: {
    color: 'white',
    backgroundColor: '#ffc107',
    '&:hover': {
      backgroundColor: '#fce6a2',
    }
  },
  downloadedUnselected: {
    color: 'black',
    backgroundColor: 'white'
  },
  downloadedSelected: {
    color: 'white',
    backgroundColor: '#00c853',
    '&:hover': {
      backgroundColor: '#b5e8ca',
    }
  }
});

function SearchObservationByStatusComponent(props) {
  const { classes, handlePDFObservation, handleApproveObservation,
    handleDeleteObservation, currentStatuses, ...nonClassProps } = props;
  const [inputState, setInputState] = useState({
    loading: false,
    success: false,
    multiStatusSelected: currentStatuses,
    textFieldLabel: 'Search for an Observation',
  });

  // QUERY STRING PARAMETERS
  const queryStringParameters = new URLSearchParams(useLocation().search);
  const currentTabName = config.getTabNameFromUrlQueryString(queryStringParameters);

  // MSAL
  const { instance, accounts, inProgress } = useMsal();
  const loginHint = (accounts && accounts[0]?.username) ?? '';
  const request = {
    loginHint,
    scopes: ["User.Read"]
  }
  const isAuthenticated = useIsAuthenticated();
  const isAuthed = isAuthenticated && inProgress === "none" && isDefinedAndInitialized(accounts) && accounts.length > 0 && isDefinedAndInitialized(accounts[0]) && isDefinedAndInitialized(accounts[0].username);

  // REACT QUERY
  // 1) OBSERVATIONS LIST (STATUS)
  const { isLoading: isStatusObsDataLoading, isError: isStatusObsDataError, data: statusObsData, error: statusObsDataError } = useQuery(
    ['statusObsList', ...currentStatuses],
    async () => {
      const obsResultSet = await searchOLAP('STATUS', currentStatuses, { instance, accounts, inProgress });
      return obsResultSet.data;    
    },
    {
      // AUTH ENABLEMENT
      enabled: isAuthed
      // TAB ENABLEMENT
      && currentTabName === 'STATUS' && isDefinedAndInitialized(currentStatuses)
    }
  );

  // EFFECTS
  useEffect(async () => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      await instance.loginRedirect(request);
    }
  }, [isAuthenticated, inProgress, instance]);

  useEffect(() => {
    // -----------------------------
    setInputState({
      ...inputState,
      loading: false,
    });
  }, [inputState.loading]);

  const handleChange = (e, value) => {

    let multiStatusSelected = [];
    // If selected status is already active, filter out of the array of selection
    if (inputState.multiStatusSelected.find(selectedStatus => selectedStatus === value) ?? null) {
      multiStatusSelected = inputState.multiStatusSelected.filter(selectedStatus => selectedStatus !== value)

      setInputState({
        ...inputState,
        loading: false,
        multiStatusSelected
      });
    }
    else {
      // Otherwise add the selected status to the array
      multiStatusSelected = [...inputState.multiStatusSelected, value]
      setInputState({
        ...inputState,
        loading: false,
        multiStatusSelected
      });
    }

    let pathToPush = [`/home?tabName=STATUS`, ...multiStatusSelected.map(selectedStatus => `status=${selectedStatus}`)].join('&');
    props.history.push(pathToPush);
  };

  const getStatusClassBasedOnSelection = (status, selectedStatuses) => {
    // -----------------------------------------------
    let selected = (typeof selectedStatuses.find(selectedStatus => selectedStatus === status) !== 'undefined') ? 'Selected' : 'Unselected';

    return `${status}${selected}`
  }

  // HANDLE LOADING STATE
  // MSAL
  if (
    inProgress !== "none" 
  )
  {
    return (
      <Box width="100%" display="flex" flexDirection="row" justifyContent="center" >
        <CircularProgress />
      </Box>
    )
  }

  // HANDLE ERROR STATE
  if (isStatusObsDataError) {
      return <span>An unexpected error has occurred with the job observation data {statusObsDataError}</span>
  }

  return (
    <Box display="flex" flexDirection="column" flexGrow="1">
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" >
        <Box mb="10px" ml="18px" display="flex" flexDirection="column" width="100%">
          <Typography variant="button" ><strong>Select status</strong></Typography>
          <Typography variant="caption" >The past 12 months</Typography>
          <ButtonGroup color="secondary" aria-label="outlined primary button group" size="small">
            {
              !isStatusObsDataLoading &&
              config.statusTypes.filter(statusType => statusType.name !== 'downloaded').map(statusType => {
                return (
                  <Button className={classes[getStatusClassBasedOnSelection(statusType.name, inputState.multiStatusSelected)]} key={`${statusType.name}-selection-button`} onClick={(e) => { handleChange(e, statusType.name) }} value={statusType.name} >
                    <Box component="span">{`${capitalizeFirstLetters(statusType.stepperPastDisplay)}`}</Box>
                    {(typeof statusObsData.find(observation => observation.header.status === statusType.name) !== 'undefined')
                        && (<Box component="span" ml="5px">{ statusObsData.filter(observation => observation.header.status === statusType.name).length }</Box>) }
                  </Button>
                )
              })
            }
          </ButtonGroup>
        </Box>
      </Box>
      <Box flexGrow="1" display="flex" flexDirection="column">
        {
          isStatusObsDataLoading &&
          <Box width="100%" display="flex" flexDirection="row" justifyContent="center" >
            <CircularProgress />
          </Box>
        }
        {
          !isStatusObsDataLoading &&
          <>
            <Divider variant="middle" />
            <ObservationSummaryList
              {...nonClassProps}
              handlePDFObservation={handlePDFObservation}
              handleApproveObservation={handleApproveObservation}
              handleDeleteObservation={handleDeleteObservation}
            />
          </>
        }
      </Box>
    </Box>
  );
}

SearchObservationByStatusComponent.propTypes = {
  classes: PropTypes.object,
  tabName: PropTypes.string,
  refreshWelcomePage: PropTypes.func,
  handlePDFObservation: PropTypes.func,
  handleApproveObservation: PropTypes.func,
  handleDeleteObservation: PropTypes.func,
  currentStatuses: PropTypes.array,
  history: PropTypes.object.isRequired
}

export default (withStyles)(styles)(SearchObservationByStatusComponent);
