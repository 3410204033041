import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

// Context
import { InitiaContext } from '../context/initia-context';

// Custom Components
import JobData from './jobData';
import { AddComponentButton, AddComponentFab } from './addComponent';
import SaveComponent from './saveComponent';
import { isDefinedAndInitialized } from '../helpers/helpers';


const styles = () => ({
  dynamicInvestigationContentContainer: {
    paddingBottom: '50px',
  },
});

const DynamicObservationComponents = (props) => {
  const { classes, handleClientObservationStateUpdate, addNewComponent, deleteComponent, saveInspectionHandler,
          componentUpHandler, toggleJobData, updateObservationStatusHandler, downloadPdfHandler,
          updateCanvasStore, updateCanvasStoreMetadata, updatePileObsCanvasStore, canvasStore, canvasStoreMetadata,
          shouldAutosave, pauseAutoSave, restartAutoSave, firstLoadComplete } = props;

  const { state } = useContext(InitiaContext);
  const { observationPage } = state;
  const { observationEdit } = observationPage;
  const { header } = observationEdit;
  const { wsPlanId, wsPrestartId, ncrActive } = header;

  const dynamicObsParentRef = useRef(null);


  let headingCount = 0;
  let imageCount = 0;
  let sketchCount = 0;
  let componentsAndActions = [];
  
  for (const [ idx, section ] of state.observationPage.sections.entries()) {
    // ---------------------------------------------------
    // ADD THE COMPONENT ADD BUTTON IMPROVING UX FROM ORIGINAL FAB ONLY APPROACH
    componentsAndActions.push(
      <Box key={`add-button-${idx}`} width="100%" display="flex" flexDirection="row" justifyContent="center" py="2px">
        <AddComponentButton
          {...props}
          handleClientObservationStateUpdate={handleClientObservationStateUpdate}
          addHandler={addNewComponent}
          toggleHandler={toggleJobData}
          saveHandler={saveInspectionHandler} 
          idx={idx}     
        />
      </Box>
    )

    // HEADING, IMAGE AND FIGURE USABILITY LABELLING LOGIC
    const { component, data } = section;
    const { componentType, image } = data;

    // HANDLE HEADING LOGIC
    let includeHeadingNumber = false;
    const isValidWorkAndSafetySection = ['workAndSafetyV1'].includes(componentType) && isDefinedAndInitialized(wsPlanId) && isDefinedAndInitialized(wsPrestartId);
    const isHeading = ['heading'].includes(componentType);
    const isValidNcrV2 = ['nonConformanceRegisterV2'].includes(componentType) && isDefinedAndInitialized(ncrActive) && ncrActive;
    if (isValidWorkAndSafetySection || isValidNcrV2 || isHeading) {
      // ---------------------------------------------------
      includeHeadingNumber = true;
      headingCount += 1;
    }

    // HANDLE IMAGE LOGIC
    let includeImageNumber = false;
    const isValidImageSection = ['photo', 'caption'].includes(componentType) && isDefinedAndInitialized(image);
    if (isValidImageSection) {
      // ---------------------------------------------------
      includeImageNumber = true;
      imageCount += 1;
    }

    // HANDLE FIGURE LOGIC
    let includeSketchNumber = false;
    const isValidFigureSection = ['siteSketch'].includes(componentType);
    if (isValidFigureSection) {
      // ---------------------------------------------------
      includeSketchNumber = true;
      sketchCount += 1;
    }

    const originalIndex =  state.observationPage.sections.findIndex((s) => s.data.componentId === data.componentId);
    const SectionComponent = component;
    componentsAndActions.push(
      <SectionComponent
        id={data.componentId}
        key={data.componentId}
        {...data}
        componentData={section.data}
        handleClientObservationStateUpdate={handleClientObservationStateUpdate}
        deleteHandler={ originalIndex !== -1 ? deleteComponent(originalIndex) : () => console.error('delete handler not available for this component')}
        componentUpHandler={componentUpHandler}
        saveHandler={saveInspectionHandler}
        updateCanvasStore={updateCanvasStore}
        updateCanvasStoreMetadata={updateCanvasStoreMetadata}
        updatePileObsCanvasStore={updatePileObsCanvasStore}
        canvasStore={canvasStore}
        canvasStoreMetadata={canvasStoreMetadata}
        shouldAutosave={shouldAutosave}
        pauseAutoSave={pauseAutoSave}
        restartAutoSave={restartAutoSave}
        dynamicObsParentRef={dynamicObsParentRef}
        firstLoadComplete={firstLoadComplete}
        headingCount={includeHeadingNumber ? headingCount : null}
        includeImageNumber={includeImageNumber}
        imageCount={imageCount}
        includeSketchNumber={includeSketchNumber}
        sketchCount={sketchCount}
      />
    );
  }
  // Add an add button at the end as well
  componentsAndActions.push(
    <Box key={`last-add-button`} width="100%" display="flex" flexDirection="row" justifyContent="center" py="10px">
      <AddComponentButton
        {...props}
        handleClientObservationStateUpdate={handleClientObservationStateUpdate}
        addHandler={addNewComponent}
        toggleHandler={toggleJobData}
        saveHandler={saveInspectionHandler} 
        idx={null}     
      />
    </Box>
  )

  return (
    <div className={classes.dynamicInvestigationContentContainer} ref={dynamicObsParentRef}>
      <JobData
        classes={classes}
        handleClientObservationStateUpdate={handleClientObservationStateUpdate}
      />
      {
        componentsAndActions
      }
      <Box display="flex" justifyContent="flex-start" mb="100px">
        <AddComponentFab
          {...props}
          handleClientObservationStateUpdate={handleClientObservationStateUpdate}
          addHandler={addNewComponent}
          toggleHandler={toggleJobData}
          saveHandler={saveInspectionHandler}
        />
        <SaveComponent
          {...props}
          observationId={(typeof state.observationPage.observationEdit !== 'undefined'
          && state.observationPage.observationEdit && 'inspectionId' in state.observationPage.observationEdit)
            ? state.observationPage.observationEdit.inspectionId : null}
          updateTimestamp={(typeof state.observationPage.observationEdit !== 'undefined'
          && state.observationPage.observationEdit && 'updateTimestamp' in state.observationPage.observationEdit.header)
            ? state.observationPage.observationEdit.header.updateTimestamp : null}
          observationHeader={(typeof state.observationPage.observationEdit !== 'undefined'
          && state.observationPage.observationEdit && 'header' in state.observationPage.observationEdit)
            ? state.observationPage.observationEdit.header : null}
          addHandler={addNewComponent}
          toggleHandler={toggleJobData}
          saveHandler={saveInspectionHandler}
          updateObservationStatusHandler={updateObservationStatusHandler}
          downloadPdfHandler={downloadPdfHandler}
        />
      </Box>
    </div>
  );
};

DynamicObservationComponents.propTypes = {
  classes: PropTypes.object,
  canvasStore: PropTypes.object,
  canvasStoreMetadata: PropTypes.object,
  addNewComponent: PropTypes.func,
  toggleJobData: PropTypes.func,
  handleClientObservationStateUpdate: PropTypes.func,
  deleteComponent: PropTypes.func,
  componentUpHandler: PropTypes.func,
  updateCanvasStore: PropTypes.func,
  updateCanvasStoreMetadata: PropTypes.func,
  updatePileObsCanvasStore: PropTypes.func,
  updateObservationStatusHandler: PropTypes.func,
  saveInspectionHandler: PropTypes.func,
  downloadPdfHandler: PropTypes.func,
  shouldAutosave: PropTypes.bool,
  pauseAutoSave: PropTypes.func,
  restartAutoSave: PropTypes.func,
  firstLoadComplete: PropTypes.bool
}


export default (withStyles)(styles)(DynamicObservationComponents);
