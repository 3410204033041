import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    position: 'relative',
  },

  button: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
});

const PaperLarge = (props) => {
  const { classes, children, title } = props;

  return (
    <Paper className={`${classes.root} box paper`}>
      <Box mb="10px">{title}</Box>
      {children}
    </Paper>
  );
};

PaperLarge.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.object,
  title: PropTypes.string
}

export default withStyles(styles)(PaperLarge);
