
/* eslint-disable no-param-reassign */

const freeDraw = (canvas, pauseAutoSave, restartAutoSave, color) => {
  canvas.isDrawingMode = true;
  canvas.freeDrawingBrush.color = color;
  canvas.freeDrawingBrush.width = 2;
  canvas.renderAll();

  canvas.on('mouse:down', () => {
    pauseAutoSave();
  });

  canvas.on('mouse:up', () => {
    restartAutoSave();
  });
};

export default freeDraw;
