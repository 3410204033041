import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import IconButton from '@material-ui/core/IconButton';

import { InitiaContext } from '../context/initia-context';

const styles = () => ({
  orderArrowsParent: {
    width: '48px',
  },
});

function OrderArrows(props) {
  const { classes, componentId, componentUpHandler } = props;
  const { state } = useContext(InitiaContext);
  // state.observationPage.sections
  const position = state.observationPage.sections.findIndex(section => section.data.componentId === componentId);

  return (

    <div className={classes.orderArrowsParent}>
      {position !== 0 && (
        <React.Fragment>
          <IconButton onClick={(e) => { componentUpHandler(e, componentId, true); }}>
            <KeyboardArrowUp />
          </IconButton>
        </React.Fragment>
      )}
      {position !== state.observationPage.sections.length - 1 && (
        <React.Fragment>
          <IconButton onClick={(e) => { componentUpHandler(e, componentId, false); }}>
            <KeyboardArrowDown />
          </IconButton>
        </React.Fragment>
      )}
    </div>
  );
}

OrderArrows.propTypes = {
  classes: PropTypes.object,
  componentId: PropTypes.string,
  componentUpHandler: PropTypes.func
}

export default withStyles(styles)(OrderArrows);
