import React, { useState } from 'react';
import PropTypes from 'prop-types';

import MuiMenu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import withStyles from '@material-ui/core/styles/withStyles';
import { isDefinedAndInitialized } from '../../helpers/helpers';

const styles = {
  subMenuItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};


function SubMenu(props) {
  const { caption, menuItems, classes, handleClientObservationStateUpdate, closeParent, idx } = props;
  const [anchorElement, setAnchorElement] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  let setIdx = null;
  if (isDefinedAndInitialized(idx)) {
    // --------------------------------------------
    setIdx = idx;
  }

  const handleItemClick = (event) => {
    if (!anchorElement) {
      setAnchorElement(event.currentTarget);
    }
    setMenuOpen(!menuOpen);
  };

  const handleSubMenuClose = () => {
    setMenuOpen(false);
    closeParent();
  };

  return (
    <React.Fragment>
      <MenuItem
        onClick={handleItemClick}
        className={classes.subMenuItem}
      >
        {caption}
        <ArrowRightIcon />
      </MenuItem>
      <EnhancedMenu
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={menuOpen}
        menuItems={menuItems}
        anchorElement={anchorElement}
        onClose={handleSubMenuClose}
        handleClientObservationStateUpdate={handleClientObservationStateUpdate}
        idx={setIdx}
      />
    </React.Fragment>
  );
}

SubMenu.propTypes = {
  classes: PropTypes.object,
  caption: PropTypes.string.isRequired,
  menuItems: PropTypes.array,
  handleClientObservationStateUpdate: PropTypes.func,
  closeParent: PropTypes.func,
  idx: PropTypes.number
};


function EnhancedMenu(props) {
  // --------------------------------------------
  const { anchorElement, open, onClose, menuItems, classes, handleClientObservationStateUpdate, idx } = props;

  let setIdx = null;
  if (isDefinedAndInitialized(idx)) {
    // --------------------------------------------
    setIdx = idx;
  }


  const renderMenuItems = () => menuItems.map((menuItem) => {
    if ('subComponentItems' in menuItem) {
      return (
        <SubMenu
          classes={classes}
          key={menuItem.componentType}
          caption={menuItem.caption}
          menuItems={menuItem.subComponentItems}
          handleClientObservationStateUpdate={handleClientObservationStateUpdate
            ? handleClientObservationStateUpdate : null}
          closeParent={onClose}
          idx={setIdx}
        />
      );
    }

    const onClick = (e) => {
      // --------------------------------------------
      if (isDefinedAndInitialized(setIdx)) {
        menuItem.clickHandler(e, menuItem.componentType, setIdx);
      }
      else {
        menuItem.clickHandler(e, menuItem.componentType);
      }
      onClose();
    };

    return (
      <MenuItem
        key={menuItem.componentType}
        onClick={onClick}
      >
        {menuItem.caption}
      </MenuItem>
    );
  });

  return (
    <MuiMenu
      anchorEl={anchorElement}
      open={open}
      onClose={onClose}
    >
      {renderMenuItems()}
    </MuiMenu>
  );
}

EnhancedMenu.propTypes = {
  classes: PropTypes.object,
  anchorElement: PropTypes.object,
  menuItems: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleClientObservationStateUpdate: PropTypes.func,
  idx: PropTypes.number
};

export default withStyles(styles)(EnhancedMenu);
