import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import './table.css';

const styles = () => ({
  input: {
    paddingTop: '9.5px',
    paddingBottom: '9.5px',
  },
});

function EditableCell(props) {
  const { classes } = props;

  return (
    <TableCell className="table-cell">
      <TextField
        variant="outlined"
        InputProps={{
          classes: { input: classes.input },
        }}
        name={props.cellData.type}
        id={props.cellData.id}
        value={props.cellData.value || ''}
        onChange={props.onTableUpdate}
        placeholder={props.cellData.placeholder}
      />
    </TableCell>
  );
}

EditableCell.propTypes = {
  classes: PropTypes.object,
  cellData: PropTypes.object,
  onTableUpdate: PropTypes.func
}

export default withStyles(styles)(EditableCell);
