import React, { useContext } from 'react';
import PropTypes from 'prop-types';

// Material UI
import TextField from '@material-ui/core/TextField';

// Context
import { InitiaContext } from '../context/initia-context';

// Custom Config
import { config } from '../config/generalConfig';

// Custom Components
import DeletablePaperSmall from './deletablePaperSmall';

// Helpers
import { convertCamelToSpaced } from '../helpers/helpers';

function JobData(props) {
  const { handleClientObservationStateUpdate } = props;
  const { state, } = useContext(InitiaContext);

  const jobDataComponents = config.componentList
    .find(component => component.componentType === 'siteData').subComponents;

  return (
    <div className="grid">
      {jobDataComponents.map(component => state.observationPage.jobData[component.componentType].isShown && (
        <DeletablePaperSmall
          key={component.componentType}
          onClick={(e) => { handleClientObservationStateUpdate(e, component.componentType, null, null); }}
        >
          <div>{convertCamelToSpaced(component.componentType).toUpperCase()}</div>
          <TextField
            variant="outlined"
            value={state.observationPage.jobData[component.componentType].value || ''}
            onChange={e => handleClientObservationStateUpdate(e, component.componentType)}
          />
        </DeletablePaperSmall>
      ))}
    </div>
  );
}

JobData.propTypes = {
  handleClientObservationStateUpdate: PropTypes.func
}

export default (JobData);
