import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

// MSAL imports
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from '@azure/msal-browser';

// Material UI
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';

// Custom Services
import { pollForObservationStatusChange } from '../services/api';
import { isDefinedAndInitialized } from '../helpers/helpers';


function ApprovalDialog(props) {
  // PROPS
  const { observationId, approvalStatusModifier, updateTimestamp,
    approveConfirmationOpen, handleApproveDialog, updateObservationStatusHandler } = props;

  // USE MSAL HOOK
  const { instance, accounts, inProgress } = useMsal();
  const loginHint = (accounts && accounts[0]?.username) ?? '';
  const request = {
    loginHint,
    scopes: ["User.Read"]
  }
  const isAuthenticated = useIsAuthenticated();
  const isAuthed = isAuthenticated && inProgress === "none" && isDefinedAndInitialized(accounts) && accounts.length > 0 && isDefinedAndInitialized(accounts[0]) && isDefinedAndInitialized(accounts[0].username);

  // EFFECTS
  useEffect(async () => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      await instance.loginRedirect(request);
    }
  }, [isAuthenticated, inProgress, instance]);


  // OTHER HOOKS
  const history = useHistory();

  return (
    <Dialog
      open={approveConfirmationOpen}
      onClose={handleApproveDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Approve this inspection for download and issue?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Observations must be reviewed and approved by a Manager
        </DialogContentText>
        <DialogContentText id="alert-dialog-description">
          Approving this observation will notify the creator that it is ready to download and issue
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleApproveDialog} style={{ color: '#1976d2' }}>
          Cancel
        </Button>
        <Button
          onClick={async () => {
            if (isAuthed) {
              // -------------------------------
              let notify = approvalStatusModifier === 'approved';
              handleApproveDialog();
              if (props.tabName === 'MONTH_SUMMARY') {
                await updateObservationStatusHandler(observationId, approvalStatusModifier, notify, updateTimestamp);
              }
              else if (props.tabName === 'INSPECTION_DETAIL') {
                await updateObservationStatusHandler(observationId, approvalStatusModifier, notify, updateTimestamp);
                await pollForObservationStatusChange(observationId, 'approved', { instance, accounts, inProgress });
                history.push(`/home`);
              }
              else {
                await updateObservationStatusHandler(observationId, approvalStatusModifier, notify, updateTimestamp);
              }
            }
            else {
              // -------------------------------
              console.info('Not authenticated for this API action');
            }
          }}
          style={{ color: '#1976d2' }}
          autoFocus
        >
          Approve
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ApprovalDialog.propTypes = {
  observationId: PropTypes.string,
  updateTimestamp: PropTypes.string,
  approvalStatusModifier: PropTypes.oneOf(['draft', 'approved']),
  approveConfirmationOpen: PropTypes.bool,
  handleApproveDialog: PropTypes.func,
  updateObservationStatusHandler: PropTypes.func,
  tabName: PropTypes.string,
  refreshSummaryList: PropTypes.func
}

export default ApprovalDialog;
