import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { DateTime } from "luxon";

import { Box, Button, Switch, FormControlLabel, Typography, IconButton } from '@material-ui/core';
import { withStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { 
  KeyboardBackspace as KeyboardBackspaceIcon, 
  ExpandMore as ExpandMoreIcon, 
  ExpandLess as ExpandLessIcon 
} from '@material-ui/icons';

// Context
import { InitiaContext } from '../context/initia-context';

// Custom components
import { JobSummaryTableExport as JobSummaryTable }  from './jobSummaryTable';
import DynamicObservationComponents from './dynamicObservationComponents';
import GeospatialCoordinates from './geospatialCoordinates';
import { capitalizeFirstLetters, isDefinedAndInitialized } from '../helpers/helpers';

const styles = (theme) => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderTop: "1px solid rgba(224, 224, 224, 1)",
    marginTop: "0px",
  },
  backToSearchButton: {
    backgroundColor: '#1976d2',
    color: 'white',
    marginTop: '16px',
    marginBottom: '16px',
    textDecoration: 'none',
  },
  contentWrapper: {
    width: '900px',
    margin: 'auto'
  },
  informationTitle: {
    paddingBottom: "18px",
  },
});

export const BackToJobButton = (props) => {
  // -------------------------------
  const { classes, observation } = props;

  const theme = useTheme();
  const isSmallerThanXs = useMediaQuery(theme.breakpoints.down('xs'));

  const displayString = `${observation.header.jobId}`;

  if (observation) {
    return (
      <Box>
        <Link
          to={`/home?tabName=JOB&lastJobId=${observation.header.jobId}`}
          style={{ textDecoration: 'none' }}
        >
          <Button variant="contained" color="primary" className={classes.backToSearchButton}>
            <KeyboardBackspaceIcon style={{ fontSize: isSmallerThanXs ? '0.65rem' : '0.8rem' }} />
            <Typography style={{ fontSize: isSmallerThanXs ? '0.65rem' : '0.8rem' }}>&nbsp;&nbsp;{ displayString }</Typography>          
          </Button>
        </Link>
      </Box>
    );
  }
  return <></>;
};
BackToJobButton.propTypes = {
  classes: PropTypes.object,
  observation: PropTypes.object
}


function ObservationContent(props) {
  const { match, classes, handleClientObservationStateUpdate, toggleJobData, addNewComponent,
    deleteComponent, saveObservationHandler, updateObservationStatusHandler, downloadPdfHandler, componentUpHandler, updateCanvasStore, updatePileObsCanvasStore,
    updateCanvasStoreMetadata, canvasStore, canvasStoreMetadata, settings, handleAutoSaveToggle, shouldAutosave, pauseAutoSave, restartAutoSave,
    firstLoadComplete, apiRefreshObservationJobData } = props;
  const { observationId } = match.params;
  const { state } = useContext(InitiaContext);

  // STATE
  const [ showFullHeader, setShowFullHeader ] = useState(true);

  // MUI HOOKS
  const theme = useTheme();
  const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('sm'));

  // Handle which last save date to display
  let lastSaveDisplayDate = '-';
  if (isDefinedAndInitialized(state?.observationPage?.observationEdit?.header?.updateTimestamp)) {
    lastSaveDisplayDate = DateTime.fromISO(state.observationPage.observationEdit.header.updateTimestamp).toFormat('dd/MM/yyyy hh:mm a');
  }
    if (isDefinedAndInitialized(settings.lastAutoSaves) && observationId in settings.lastAutoSaves && isDefinedAndInitialized(state?.observationPage?.observationEdit?.header?.updateTimestamp) && DateTime.fromISO(settings.lastAutoSaves[observationId]) > DateTime.fromISO(state.observationPage.observationEdit.header.updateTimestamp)) {
    // -------------------------------------------------
    lastSaveDisplayDate = DateTime.fromISO(settings.lastAutoSaves[observationId]).toFormat('dd/MM/yyyy hh:mm a');
  }

  return (
    <Box>
      { isDefinedAndInitialized(state.observationPage.observationEdit) ?
      <>
      <Box style={{ position: 'sticky', top: '0', zIndex: '5', backgroundColor: 'white', border: '1px solid rgba(224, 224, 224, 1)', borderRadius: '4px', marginBottom: '5px', paddingLeft: '10px', paddingRight: '10px' }}>
        <Box display="flex" flexDirection="column">
          {
            isSmallerThanSm &&
            <Box flex={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center" paddingTop="8px" paddingBottom={showFullHeader ? "2px" : "4px" }>
              <Box display="flex" flexDirection="row" alignItems="center">
                <Typography variant="h4" style={{ fontSize: "1rem", textAlign: "center" }} color="secondary">
                  {
                    capitalizeFirstLetters(state.observationPage.observationEdit.header.inspectionType)
                  }
                </Typography>
                <IconButton size="small" style={{ position: "absolute", right: '5px' }} aria-label="delete" color="primary" onClick={() => setShowFullHeader(!showFullHeader)}>
                  {
                    !showFullHeader &&
                    <ExpandMoreIcon style={{ fontSize: "1.2rem" }} />
                  }
                  {
                    showFullHeader &&
                    <ExpandLessIcon style={{ fontSize: "1.2rem" }} />
                  }
                </IconButton>
              </Box>
              <Typography variant="h4" style={{ fontSize: "0.7rem", textAlign: "center" }}>
                {
                  capitalizeFirstLetters(state.observationPage.observationEdit.header.jobName)
                }
              </Typography>
            </Box>
          }
          {
            (!isSmallerThanSm || (isSmallerThanSm && showFullHeader)) &&
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box flex={1}>
                <BackToJobButton observation={state.observationPage.observationEdit} classes={classes} />
              </Box>
              {
                !isSmallerThanSm &&
                <Box flex={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                  <Typography variant="h4" style={{ fontSize: "1.2rem", textAlign: "center" }} color="secondary">
                    {
                      capitalizeFirstLetters(state.observationPage.observationEdit.header.inspectionType)
                    }
                  </Typography>
                  <Typography variant="h4" style={{ fontSize: "0.8rem", textAlign: "center" }}>
                    {
                      capitalizeFirstLetters(state.observationPage.observationEdit.header.jobName)
                    }
                  </Typography>
                </Box>
              }
              <Box flex={1} display="flex" flexDirection="row" justifyContent="flex-end">
                <Box>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={settings.autoSave}
                        onChange={handleAutoSaveToggle}
                        name="auto-save-toggle"
                        inputProps={{ 'aria-label': 'toggle auto save' }}
                      />
                    }
                    style={{ fontSize: '0.9rem' }}
                    label={
                      <Box>
                        <Typography style={{ fontSize: isSmallerThanSm ? '0.7rem' : '0.8rem', textAlign: "center" }}>{`${(settings.autoSave) ? `Autosave Enabled` : 'Autosave Enabled'}`}</Typography>
                        {
                          isSmallerThanSm &&
                          <Typography variant="body2" style={{fontSize: '0.6rem', textAlign: "center" }}>{`${lastSaveDisplayDate}`}</Typography>
                        }
                      </Box>
                    }
                  />
                  {
                    !isSmallerThanSm &&
                    <Typography variant="body2" style={{fontSize: '0.6rem' }}>Last saved: {lastSaveDisplayDate}</Typography>
                  }
                </Box>
              </Box>
            </Box>
          }
        </Box>
      </Box>
      <JobSummaryTable
        handleClientObservationStateUpdate={handleClientObservationStateUpdate}
        apiRefreshObservationJobData={apiRefreshObservationJobData}
      />
      <GeospatialCoordinates
        handleClientObservationStateUpdate={handleClientObservationStateUpdate}
      />
      <DynamicObservationComponents
        handleClientObservationStateUpdate={handleClientObservationStateUpdate}
        toggleJobData={toggleJobData}
        addNewComponent={addNewComponent}
        deleteComponent={deleteComponent}
        saveInspectionHandler={saveObservationHandler}
        updateObservationStatusHandler={updateObservationStatusHandler}
        downloadPdfHandler={downloadPdfHandler}
        componentUpHandler={componentUpHandler}
        updateCanvasStore={updateCanvasStore}
        updateCanvasStoreMetadata={updateCanvasStoreMetadata}
        updatePileObsCanvasStore={updatePileObsCanvasStore}
        canvasStore={canvasStore}
        canvasStoreMetadata={canvasStoreMetadata}
        shouldAutosave={shouldAutosave}
        pauseAutoSave={pauseAutoSave}
        restartAutoSave={restartAutoSave}
        firstLoadComplete={firstLoadComplete}
      />
      </>
      : '' }
    </Box>
  );
}

ObservationContent.propTypes = {
  classes: PropTypes.object,
  handleClientObservationStateUpdate: PropTypes.func,
  toggleJobData: PropTypes.func,
  addNewComponent: PropTypes.func,
  deleteComponent: PropTypes.func,
  saveObservationHandler: PropTypes.func,
  updateObservationStatusHandler: PropTypes.func,
  downloadPdfHandler: PropTypes.func,
  componentUpHandler: PropTypes.func,
  updateCanvasStore: PropTypes.func,
  updateCanvasStoreMetadata: PropTypes.func,
  updatePileObsCanvasStore: PropTypes.func,
  canvasStore: PropTypes.object,
  canvasStoreMetadata: PropTypes.object,
  settings: PropTypes.object,
  handleAutoSaveToggle: PropTypes.func,
  match: PropTypes.object,
  shouldAutosave: PropTypes.bool,
  pauseAutoSave: PropTypes.func,
  restartAutoSave: PropTypes.func,
  firstLoadComplete: PropTypes.bool,
  apiRefreshObservationJobData: PropTypes.func
}

export default withStyles(styles)(ObservationContent);
