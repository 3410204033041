import React, { useState, useEffect, useContext } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// REACT-QUERY
import {
    useQuery,
    useQueryClient
} from 'react-query';

// MSAL imports
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";

// MUI
import {
    Grid, Box, Paper, ButtonGroup, Button, withStyles, Tooltip, IconButton,
    Avatar, Typography, CircularProgress, Checkbox,
    Chip, List, ListItem, ImageList, ImageListItem,
    useTheme, useMediaQuery, Switch
} from '@material-ui/core';
import {
    AddCircle,
    Refresh,
    PriorityHigh,
    OpenInNew,
    AccountCircle,
    CheckCircle,
    ThumbUp,
    PanTool,
    Error,
    ArrowRightAlt,
    ExpandMore,
    ExpandLess,
    Publish,
    Delete,
    Edit,
    Save
} from "@material-ui/icons";
import { lightBlue, green, orange, red, yellow, teal, pink, grey } from '@material-ui/core/colors';

import { DateTime } from 'luxon';
import uuidv4 from 'uuid';
import { useSnackbar } from 'notistack';

// Context
import { InitiaContext } from '../context/initia-context';

// Custom Helpers
import { hasOwnProperty, isDefinedAndInitialized, prepNullableFieldToString, s3ReplaceUnsupportedChars, standardiseString } from '../helpers/helpers';

// Custom Services
import { getObservationDetail, getJobWorkAndSafetyPlanList, getTeamMemberListMin, getWorkAndSafetyPlanHighlightedHazardList, uploadNodeFileToS3 } from '../services/api';

// Custom Components
import OrderArrows from './orderArrows';
import { hazardConsequenceCompare, sortHazardByResidualRisk } from '../helpers/work-and-safety-helpers';
import { BasicIconButton, BasicIconTextButton, UploadFileButton } from './genericComponents/Buttons';
import { FileSelectionCard, FileUploadCard, GenericPresignedImg, getFileTypeExtension } from './genericComponents/Files';
import { LabelledTextField, OverrideableSlider } from './genericComponents/Inputs';

// Configuration
const PUBLIC_BUCKET_NAME = window.config.PUBLIC_BUCKET_NAME;
const snackBarAutoHide = 2000;

const styles = (theme) => ({
    root: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        borderTop: "1px solid rgba(224, 224, 224, 1)",
        marginTop: "0px",
    }
});


export const WSPlanStatusIcon = (props) => {
    // ----------------------------------
    const { status } = props;

    switch (status) {
        case 'DOWNLOADED':
            return <CheckCircle style={{ fontSize: '1.3rem' }} htmlColor={lightBlue[500]} />
        case 'COMPLETED':
        case 'APPROVED':
            return <ThumbUp style={{ fontSize: '1.1rem' }} htmlColor={green[500]} />
        case 'REVIEW':
            return <PanTool style={{ fontSize: '1.1rem' }} htmlColor={orange[700]} />
        case 'DRAFT':
        default:
            return <Error style={{ fontSize: '1.3rem' }} htmlColor={red[500]} />
    }
}

WSPlanStatusIcon.propTypes = {
    status: PropTypes.string
};


export const WSPotentialHazardHarmSlider = (props) => {
    // -------------------------------------------
    const { name, value, label, handleChange, width, fontSize } = props;
    const marks = [
        {
            value: 0,
            label: 'V low',
            color: green['500']
        },
        {
            value: 25,
            label: 'Low',
            color: teal['500']
        },
        {
            value: 50,
            label: 'Mod',
            color: yellow['700']
        },
        {
            value: 75,
            label: 'High',
            color: orange['600']
        },
        {
            value: 100,
            label: 'Critical',
            color: red['500']
        }
    ];

    const setWidth = isDefinedAndInitialized(width) ? width : 225;
    const setFontSize = isDefinedAndInitialized(fontSize) ? fontSize : '0.8rem';

    const valueText = (value) => {
        // -----------------
        const selectedMark = marks.find(mark => mark.value === value);
        return isDefinedAndInitialized(selectedMark) ? selectedMark.label : '';
    }

    const valueLabelFormat = (value) => {
        // -----------------
        const selectedMark = marks.find(mark => mark.value === value);
        return isDefinedAndInitialized(selectedMark) ? selectedMark.label : '';
    }

    const getSliderColor = (value) => {
        // -----------------
        const selectedMark = marks.find(mark => mark.label.toUpperCase().split(' ').join('_') === value);
        return isDefinedAndInitialized(selectedMark) ? selectedMark.color : 'secondary.main';
    }

    return (
        <Box display="flex" flexDirection="row">
            <OverrideableSlider
                inputColor={getSliderColor(value)}
                name={name}
                value={value}
                label={label}
                marks={marks}
                valueText={valueText}
                valueLabelFormat={valueLabelFormat}
                handleChange={handleChange}
                width={setWidth}
                fontSize={setFontSize}
            />
        </Box>
    )
}

WSPotentialHazardHarmSlider.propTypes = {
    handleChange: PropTypes.func,
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    width: PropTypes.number,
    fontSize: PropTypes.string
}

export const getStatusChipStyling = (status) => {
    // -------------------------------
    switch (status) {
        case 'DOWNLOADED':
            return { backgroundColor: lightBlue[500], color: 'secondary.contrastText' }
        case 'COMPLETED':
        case 'APPROVED':
            return { backgroundColor: green[500], color: 'secondary.contrastText' }
        case 'REVIEW':
            return { backgroundColor: orange[700], color: 'secondary.contrastText' }
        case 'DRAFT':
        default:
            return { backgroundColor: red[500], color: 'secondary.contrastText' }
    }
}

const getTake5ComponentStyling = ({ hsPlanListLoading, hsPlanListData, isObsDetailLoading, observation }) => {
    // -------------------------------------------
    let take5ComponentStyling = {
        take5ParentComponentStyling: {
            border: '1px solid lightgrey',
            borderRadius: '5px',
            padding: '10px'
        }
    };



    if (hsPlanListLoading || isObsDetailLoading) {
        // -------------------------------
        take5ComponentStyling = {
            take5ParentComponentStyling: {
                border: '1px solid lightgrey',
                borderRadius: '5px',
                padding: '10px'
            }
        }
    }
    else if (isDefinedAndInitialized(hsPlanListData) && hsPlanListData.length === 0) {
        // -------------------------------
        take5ComponentStyling = {
            take5ParentComponentStyling: {
                border: '1px solid #eb345e',
                borderRadius: '5px',
                padding: '10px'
            }
        }
    }
    else if (
        isDefinedAndInitialized(hsPlanListData) && hsPlanListData.length > 0
        && isDefinedAndInitialized(observation) && (!Object.keys(observation.header).includes('wsPlanId') || !isDefinedAndInitialized(observation.header.wsPlanId))
    ) {
        // -------------------------------
        take5ComponentStyling = {
            take5ParentComponentStyling: {
                border: '1px solid #f9a825',
                borderRadius: '5px',
                padding: '10px'
            }
        }
    }
    else if (isDefinedAndInitialized(hsPlanListData) && hsPlanListData.length > 0
        && isDefinedAndInitialized(observation) && (Object.keys(observation.header).includes('wsPlanId') || isDefinedAndInitialized(observation.header.wsPlanId))
    ) {
        // -------------------------------
        take5ComponentStyling = {
            take5ParentComponentStyling: {
                border: '1px solid #00c853',
                borderRadius: '5px',
                padding: '10px'
            }
        }
    }

    return take5ComponentStyling;
}


export const getHazardConsequenceIcon = (hazardConsequence) => {
    switch (hazardConsequence) {
        case 'CRITICAL':
            return <Avatar alt="critical" style={{ backgroundColor: red['500'], width: 24, height: 24, fontSize: '0.8rem' }}>C</Avatar>;
        case 'HIGH':
            return <Avatar alt="high" style={{ backgroundColor: orange['600'], width: 24, height: 24, fontSize: '0.8rem' }}>H</Avatar>;
        case 'MOD':
        case 'MODERATE':
            return <Avatar alt="moderate" style={{ backgroundColor: yellow['700'], width: 24, height: 24, fontSize: '0.8rem' }}>M</Avatar>;
        case 'LOW':
            return <Avatar alt="low" style={{ backgroundColor: teal['500'], width: 24, height: 24, fontSize: '0.8rem' }}>L</Avatar>;
        case 'V_LOW':
        case 'VERY_LOW':
            return <Avatar alt="very low" style={{ backgroundColor: green['500'], width: 24, height: 24, fontSize: '0.8rem' }}>VL</Avatar>;
        default:
            return '';
    }
}


const ConsequenceReductionIcon = (props) => {
    // -----------------------------------------------------
    const { hazardControls, displayControlNumber } = props;

    const numControls = hazardControls.length;
    const controlLabel = numControls === 1 ? 'control' : 'controls';

    const setDisplayControlNumber = isDefinedAndInitialized(displayControlNumber) ? displayControlNumber : true;

    // MUI HOOKS
    const theme = useTheme();
    const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('sm'));

    if (hazardControls.length === 0) {
        // -------------------------------------------------
        return (
            <Box
                display="flex" flexDirection="row"
                alignItems="center" justifyContent={isSmallerThanSm ? "flex-start" : "flex-end"}
                style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                <Box style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                    <Avatar alt="very low" style={{ backgroundColor: red['500'], color: 'secondary.contrastText', width: 24, height: 24, fontSize: '0.8rem' }}>!</Avatar>
                </Box>
                <Typography variant={isSmallerThanSm ? "body2" : "subtitle1"}><strong>{`(${numControls} ${controlLabel})`}</strong></Typography>
            </Box>
        )
    }

    return (
        <Box
            display="flex" flexDirection="row"
            alignItems="center" justifyContent={isSmallerThanSm ? "flex-start" : "flex-end"}
            style={{ paddingLeft: '10px', paddingRight: '10px' }}>
            <Grid container>
                <Grid item xs={12}>
                    <Box display="flex" flexDirection="row" justifyContent={isSmallerThanSm ? "flex-start" : "flex-end"}>
                        <Box display="flex" flexDirection={isSmallerThanSm ? "row" : "column"} alignItems="center">
                            <Box display="flex" flexDirection="row">
                                <Box pl={isSmallerThanSm ? "0px" : "10px"}>
                                    {
                                        getHazardConsequenceIcon(hazardControls.reduce((prev, current) => {
                                            if (hazardConsequenceCompare(current.potentialConsequenceBeforeControl, prev.potentialConsequenceBeforeControl) < 0) {
                                                return current;
                                            }
                                            else {
                                                return prev;
                                            }
                                        }).potentialConsequenceBeforeControl)
                                    }
                                </Box>
                                <Box display="flex" flexDirection="row" alignItems="center">
                                    <ArrowRightAlt />
                                </Box>
                                <Box>
                                    {
                                        getHazardConsequenceIcon(hazardControls.reduce((prev, current) => {
                                            if (hazardConsequenceCompare(current.potentialConsequenceAfterControl, prev.potentialConsequenceAfterControl) < 0) {
                                                return current;
                                            }
                                            else {
                                                return prev;
                                            }
                                        }).potentialConsequenceAfterControl)
                                    }
                                </Box>
                            </Box>
                            {
                                setDisplayControlNumber &&
                                <Box pl="15px">
                                    <Typography style={{ fontSize: "0.75rem" }}><strong>{`(${numControls} ${controlLabel})`}</strong></Typography>
                                </Box>
                            }
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

ConsequenceReductionIcon.propTypes = {
    hazardControls: PropTypes.array,
    displayControlNumber: PropTypes.bool
};


export const WSEditableHazardControlDetail = (props) => {
    // -------------------------------
    // PROPS
    const { hazardControl,
        handleTextfieldChange, handleHazardControlListChangeEvent,
        handleHazardControlRiskManagementToggle,
        handleHazardControlSliderChangeEvent, potentialHarmWidth,
        potentialHarmFontSize, namePrefix } = props;
    const { id } = hazardControl;

    const setNamePrefix = isDefinedAndInitialized(namePrefix) ? namePrefix : '';

    const setPotentialHarmWidth = isDefinedAndInitialized(potentialHarmWidth) ? potentialHarmWidth : 225;
    const setPotentialHarmFontSize = isDefinedAndInitialized(potentialHarmFontSize) ? potentialHarmFontSize : '0.8rem';

    // MUI HOOKS
    const theme = useTheme();
    const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <Box width="100%" display="flex" justifyContent="flex-end">
                <Tooltip title="Delete" placement="top">
                    <IconButton
                        edge="start"
                        onClick={() => handleHazardControlListChangeEvent({
                            type: 'REMOVE',
                            name: `${setNamePrefix}hazardControls`,
                            id
                        })}
                        aria-label="close"
                        style={{ color: pink[400] }}
                    >
                        <Delete />
                    </IconButton>
                </Tooltip>
            </Box>
            <Box width="100%" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                <LabelledTextField
                    value={hazardControl.description}
                    name={`${setNamePrefix}hazardControls_${id}_description`}
                    label={'Hazard Control Description'}
                    placeholder={`Describe a control which will reduce the potential consequence of the hazard`}
                    handleChange={handleTextfieldChange}
                />
            </Box>
            <Box width="100%" display="flex" flexDirection="column" justifyContent="center">
                <Box display="flex" flexDirection="column" justifyContent="center" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <Box style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                        <Typography variant="body2">
                            Risk Management Approach
                        </Typography>
                    </Box>
                    <Box style={{ paddingLeft: isSmallerThanSm ? "0px" : "20px", paddingRight: isSmallerThanSm ? "0px" : "20px" }}>
                        <ButtonGroup variant="contained" color="primary">
                            <BasicIconTextButton
                                color={(hazardControl.eliminate) ? theme.palette.secondary.contrastText : theme.palette.primary.contrastText}
                                backgroundColor={(hazardControl.eliminate) ? pink['500'] : grey['200']}
                                onClick={() => handleHazardControlRiskManagementToggle({
                                    target: {
                                        name: `${setNamePrefix}hazardControls_${id}_eliminate`
                                    }
                                })}
                                fontSize={isSmallerThanSm ? '0.65rem' : '0.8rem'}
                            >
                                <Box display="flex" flexDirection="column">
                                    <Box>ELIMINATE</Box>
                                    <Box>{(hazardControl.eliminate) ? <CheckCircle /> : ""}</Box>
                                </Box>
                            </BasicIconTextButton>
                            <BasicIconTextButton
                                color={(hazardControl.isolate) ? theme.palette.secondary.contrastText : theme.palette.primary.contrastText}
                                backgroundColor={(hazardControl.isolate) ? pink['500'] : grey['200']}
                                onClick={() => handleHazardControlRiskManagementToggle({
                                    target: {
                                        name: `${setNamePrefix}hazardControls_${id}_isolate`
                                    }
                                })}
                                fontSize={isSmallerThanSm ? '0.65rem' : '0.8rem'}
                            >
                                <span>ISOLATE {(hazardControl.isolate) ? <CheckCircle /> : ""}</span>
                            </BasicIconTextButton>
                            <BasicIconTextButton
                                color={(hazardControl.minimise) ? theme.palette.secondary.contrastText : theme.palette.primary.contrastText}
                                backgroundColor={(hazardControl.minimise) ? pink['500'] : grey['200']}
                                onClick={() => handleHazardControlRiskManagementToggle({
                                    target: {
                                        name: `${setNamePrefix}hazardControls_${id}_minimise`
                                    }
                                })}
                                fontSize={isSmallerThanSm ? '0.65rem' : '0.8rem'}
                            >
                                <span>MINIMISE {(hazardControl.minimise) ? <CheckCircle /> : ""}</span>
                            </BasicIconTextButton>
                        </ButtonGroup>
                    </Box>
                </Box>
            </Box>
            <Box width="100%" marginY="10px">
                <WSPotentialHazardHarmSlider
                    name={`${setNamePrefix}hazardControls_${id}_potentialConsequenceBeforeControl`}
                    value={hazardControl.potentialConsequenceBeforeControl}
                    label="Potential consequence before controls"
                    handleChange={handleHazardControlSliderChangeEvent}
                    width={setPotentialHarmWidth}
                    fontSize={setPotentialHarmFontSize}
                />
            </Box>
            <Box width="100%" marginY="15px">
                <WSPotentialHazardHarmSlider
                    name={`${setNamePrefix}hazardControls_${id}_potentialConsequenceAfterControl`}
                    value={hazardControl.potentialConsequenceAfterControl}
                    label="Potential consequence after controls"
                    handleChange={handleHazardControlSliderChangeEvent}
                    width={setPotentialHarmWidth}
                    fontSize={setPotentialHarmFontSize}
                />
            </Box>
        </>
    )
}

const IEditableHazardControlProps = {
    hazardControl: PropTypes.object,
    handleTextfieldChange: PropTypes.func,
    handleHazardControlListChangeEvent: PropTypes.func,
    handleHazardControlRiskManagementToggle: PropTypes.func,
    handleHazardControlSliderChangeEvent: PropTypes.func,
    idx: PropTypes.number,
    potentialHarmWidth: PropTypes.number,
    potentialHarmFontSize: PropTypes.string,
    namePrefix: PropTypes.string
}

WSEditableHazardControlDetail.propTypes = IEditableHazardControlProps;


const WSHazardItem = (props) => {
    // -------------------------------------------
    const { isSelected, withMediaUpload, idx, hazard, type, handleSetSelected, additionalIconButtons,
        handleFileSelection, handleFileItemStateChange, handleFileListItemAddOrRemove, handleFileUpload,
        selectedFiles, uploadedFiles } = props;
    const { id, description, hazardName, hazardControls } = hazard;

    // OPTIONAL PROP HANDLING
    let setWithMediaUpload = false;
    if (isDefinedAndInitialized(withMediaUpload)) {
        // ----------------------------------------------
        setWithMediaUpload = withMediaUpload;
    }
    let setHandleFileSelection = (files, criticalHazard) => console.error(`Handle file selection not implemented, attempted to upload ${files.length} files for ${JSON.stringify(criticalHazard)}`);
    if (isDefinedAndInitialized(handleFileSelection)) {
        // ------------------------------------
        setHandleFileSelection = handleFileSelection;
    }
    let setHandleFileItemStateChange = () => console.error(`Handle file item state change is not implemented`);
    if (isDefinedAndInitialized(handleFileItemStateChange)) {
        // ------------------------------------
        setHandleFileItemStateChange = handleFileItemStateChange;
    }
    let setHandleFileListItemAddOrRemove = () => console.error(`Handle file list item add or remove not implemented`);
    if (isDefinedAndInitialized(handleFileListItemAddOrRemove)) {
        // ------------------------------------
        setHandleFileListItemAddOrRemove = handleFileListItemAddOrRemove;
    }
    let setHandleFileUpload = () => { console.error(`Handle file list item add or remove not implemented`); return new Promise(resolve => resolve()); }
    if (isDefinedAndInitialized(handleFileUpload)) {
        // ------------------------------------
        setHandleFileUpload = handleFileUpload;
    }
    let setSelectedFiles = {};
    if (isDefinedAndInitialized(selectedFiles)) {
        // ------------------------------------
        setSelectedFiles = selectedFiles;
    }
    let setUploadedFiles = [];
    if (isDefinedAndInitialized(uploadedFiles)) {
        // ------------------------------------
        setUploadedFiles = uploadedFiles;
    }

    // DERIVED PROPS
    const selectedHazardMedia = isDefinedAndInitialized(id) && Object.keys(setSelectedFiles).includes(id) ? setSelectedFiles[id] : [];
    const hasSelectedMedia = selectedHazardMedia.length > 0;

    const uploadedHazardMedia = setUploadedFiles.filter(hazardMedia => hazardMedia.hazardId === id);
    const hasUploadedMedia = uploadedHazardMedia.length > 0;

    let hazardObservationImages = uploadedHazardMedia.filter(hazardMediaUpload => ['jpg', 'jpeg', 'png', 'gif'].includes(getFileTypeExtension(hazardMediaUpload.name) || ''));

    // MUI HOOKS
    const theme = useTheme();
    const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('sm'));
    const isSmallerThanXs = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <Box>
            <Box
                mx="2px" marginTop="5px" style={{ backgroundColor: "primary.main", color: "primary.contrastText", padding: '10px' }} border={isSelected ? `2px solid ${theme.palette.secondary.main}` : "1px solid lightGrey"} borderRadius="15px"
                component="div">
                <Box display="flex" flexDirection={isSmallerThanSm ? "column" : "row"} justifyContent="space-between" alignItems="center">
                    <Grid container alignItems="center">
                        <Grid item xs={12} md={7}>
                            <Box
                                display="flex" flexDirection="row"
                                alignItems="center" marginY="5px">
                                <Typography variant="subtitle1" style={{ fontSize: isSmallerThanSm ? (type === 'REGISTER' ? '0.85rem' : '0.95rem') : (type === 'REGISTER' ? '0.95rem' : '1.05rem') }}><strong>{`${hazardName}`}</strong></Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <ConsequenceReductionIcon hazardControls={hazardControls} />
                        </Grid>
                    </Grid>
                    {
                        !isSelected &&
                        <Box marginRight="10px" justifySelf="flex-end" alignSelf="flex-end">
                            <BasicIconButton
                                icon={<ExpandMore fontSize="inherit" />}
                                color="secondary.contrastText" backgroundColor="secondary.main"
                                hoverColor='secondary.main'
                                hoverBackgroundColor='primary.main'
                                tooltipLabel={`View ${type === 'REGISTER' ? 'register ' : ''}hazard ${idx + 1} detail`}
                                ariaLabel={`view-${type === 'REGISTER' ? 'register-' : ''}hazard-${idx + 1}-detail`}
                                onClick={() => handleSetSelected(isDefinedAndInitialized(id) ? id : null)} />
                        </Box>
                    }
                    {
                        isSelected && !isSmallerThanSm &&
                        <Box display="flex" flexDirection="row" justifyContent="flex-end">
                            <BasicIconButton
                                icon={<ExpandLess fontSize="inherit" />}
                                color="secondary.contrastText" backgroundColor="secondary.main"
                                hoverColor='secondary.main'
                                hoverBackgroundColor='primary.main'
                                tooltipLabel={`Close ${type === 'REGISTER' ? 'register ' : ''}hazard ${idx + 1} detail`}
                                ariaLabel={`close-${type === 'REGISTER' ? 'register-' : ''}hazard-${idx + 1}-detail`}
                                onClick={() => handleSetSelected(null)} />
                        </Box>
                    }
                    {
                        isDefinedAndInitialized(additionalIconButtons) &&
                        additionalIconButtons
                    }
                </Box>
                {
                    isSelected &&
                    <Box style={{ backgroundColor: 'primary.main', paddingTop: '5px', paddingBottom: '5px' }}>
                        {
                            isDefinedAndInitialized(description) && description !== '' &&
                            <Typography style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2), fontSize: '0.8rem' }}>
                                {description}
                            </Typography>
                        }
                        <List>
                            {
                                hazard.hazardControls.map((control, innerIdx) => {
                                    // -----------------------------------------
                                    const { description } = control;

                                    return (
                                        <Box display="flex" flexDirection="column" key={`register-hazard-${idx}-control-${innerIdx}`}>
                                            <ListItem style={{ border: '1px solid lightgrey', borderRadius: '10px', paddingY: "5px", paddingX: "8px", marginY: "5px" }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} sm={7}>
                                                        <Box
                                                            display="flex" flexDirection="row"
                                                            alignItems="center">
                                                            <Typography variant="subtitle1" style={{ fontSize: isSmallerThanSm ? '0.85rem' : '0.875rem' }}><strong>{`${description}`}</strong></Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} sm={5} style={{ paddingTop: '8px' }}>
                                                        <Box width="100%" display="flex" flexDirection="row" justifyContent={isSmallerThanXs ? "normal" : "flex-end"}>
                                                            <ConsequenceReductionIcon hazardControls={[control]} displayControlNumber={false} />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                        </Box>
                                    )
                                })
                            }
                        </List>
                        {
                            isSelected &&
                            setWithMediaUpload &&
                            <Box marginY="10px" mx="5px">
                                <Typography fontSize="0.85rem">Upload any photos related to this hazard</Typography>
                                <UploadFileButton
                                    handleFileSelection={(files) => setHandleFileSelection(files, hazard)}
                                />
                            </Box>
                        }
                        {
                            isSelected &&
                            setWithMediaUpload &&
                            <Grid container marginBottom="40px">
                                {
                                    hasSelectedMedia &&
                                    <>
                                        {
                                            selectedHazardMedia.map((file, idx) => {
                                                const { hazardId } = file;
                                                return (
                                                    <Grid key={`file-selection-${idx}`} item xs={12} lg={6} style={{ padding: '5px' }}>
                                                        <FileSelectionCard
                                                            namePrefix={`${hazardId}`} file={file}
                                                            handleFileItemStateChange={setHandleFileItemStateChange}
                                                            handleFileListItemAddOrRemove={setHandleFileListItemAddOrRemove}
                                                        />
                                                    </Grid>
                                                )
                                            }
                                            )
                                        }
                                        <Grid item xs={12} />
                                        {
                                            !isSmallerThanXs &&
                                            <Grid item sm={3} />
                                        }
                                        <Grid item xs={12} sm={6} style={{ marginTop: "25px", marginBottom: "25px" }}>
                                            <BasicIconTextButton startIcon={<Publish />} color="secondary.contrastText" backgroundColor={red['700']}
                                                onClick={setHandleFileUpload}
                                            >
                                                Upload new files
                                            </BasicIconTextButton>
                                        </Grid>
                                        {
                                            !isSmallerThanXs &&
                                            <Grid item sm={3} />
                                        }
                                    </>
                                }
                                {
                                    !hasSelectedMedia &&
                                    <Box display="flex" flexDirection="row" marginY="25px" width="100%" justifyContent="center">
                                        <Typography>No files are currently selected for upload</Typography>
                                    </Box>
                                }
                                {
                                    hasUploadedMedia &&
                                    uploadedHazardMedia.map((file, idx) => (
                                        <Grid key={`file-upload-${idx}`} item xs={12} lg={6} style={{ padding: '5px' }}>
                                            <FileUploadCard
                                                namePrefix={`hazardObservationMedia`}
                                                file={file}
                                                handleFileItemStateChange={setHandleFileItemStateChange}
                                                handleFileListItemAddOrRemove={setHandleFileListItemAddOrRemove}
                                            />
                                        </Grid>
                                    ))
                                }
                                {
                                    !hasUploadedMedia &&
                                    <Box display="flex" flexDirection="row" width="100%" justifyContent="center">
                                        <Typography>There are currently no file uploads for this plan</Typography>
                                    </Box>
                                }
                            </Grid>
                        }
                        <Grid container justifyContent="center">
                            <Grid item xs={6} sm={4} lg={3}>
                                <BasicIconTextButton
                                    endIcon={<ExpandLess fontSize="inherit" />}
                                    color="secondary.contrastText" backgroundColor="secondary.main"
                                    onClick={() => handleSetSelected(null)} >
                                    Close
                                </BasicIconTextButton>
                            </Grid>
                        </Grid>
                    </Box>
                }
            </Box>
            {
                setWithMediaUpload &&
                hasUploadedMedia &&
                !isSelected &&
                hazardObservationImages.length > 0 &&
                <Box mx="15px" marginBottom="10px">
                    <ImageList cols={isSmallerThanSm ? 2 : 4} style={{ marginTop: 0 }} >
                        {
                            hazardObservationImages.map(imageFile => {
                                // ---------------------------------
                                return (
                                    <ImageListItem key={imageFile.id}>
                                        <GenericPresignedImg
                                            file={imageFile}
                                        />
                                    </ImageListItem>
                                )
                            })
                        }
                    </ImageList>
                </Box>
            }
        </Box>
    )
}

WSHazardItem.propTypes = {
    isSelected: PropTypes.bool,
    withMediaUpload: PropTypes.bool,
    idx: PropTypes.number,
    hazard: PropTypes.object,
    type: PropTypes.oneOf(['CRITICAL', 'REGISTER']),
    handleSetSelected: PropTypes.func,
    additionalIconButtons: PropTypes.element,
    handleFileSelection: PropTypes.func,
    handleFileItemStateChange: PropTypes.func,
    handleFileListItemAddOrRemove: PropTypes.func,
    handleFileUpload: PropTypes.func,
    selectedFiles: PropTypes.object,
    uploadedFiles: PropTypes.array
};


export const WSEditableCriticalHazard = (props) => {
    // -------------------------------------------
    const { name, criticalHazard, listState, idx,
        handleItemEditClick, handleItemCloseClick,
        handleCriticalHazardControlTextFieldChangeProxy, handleCriticalHazardControlListChangeEventProxy,
        handleCriticalHazardControlRiskManagementToggleProxy, handleCriticalHazardControlSliderChangeEventProxy,
        handleListItemStateChange, handleListItemAddOrRemove
    } = props;
    const { id, hazardName, description, hazardControls } = criticalHazard;

    let setName = 'criticalHazards';
    if (isDefinedAndInitialized(name)) {
        // -----------------------------
        setName = name;
    }

    // MUI HOOKS
    const theme = useTheme();
    const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('sm'));

    if (isDefinedAndInitialized(listState.activeHazard) && criticalHazard.id === listState.activeHazard) {
        // --------------------------------------
        return (
            <Grid item xs={12} >
                <Box p="15px" border={`2px solid ${theme.palette.secondary.main}`} borderRadius="15px">
                    <Box p="2px" display="flex" flexDirection="row" justifyContent="flex-end">
                        <Box>
                            <BasicIconButton
                                icon={<CheckCircle fontSize="inherit" />}
                                color="secondary.main" backgroundColor="secondary.contrastText"
                                hoverColor='primary.contrastText'
                                hoverBackgroundColor='primary.main'
                                tooltipLabel={`Close critical hazard ${idx + 1}`}
                                ariaLabel={`close-critical-hazard-${idx + 1}`}
                                onClick={handleItemCloseClick} />
                        </Box>
                        <Box>
                            <BasicIconButton
                                icon={<Delete fontSize="inherit" />}
                                color="secondary.main" backgroundColor="secondary.contrastText"
                                hoverColor='secondary.main'
                                hoverBackgroundColor='primary.main'
                                tooltipLabel={`Delete critical hazard ${idx + 1}`}
                                ariaLabel={`delete-critical-hazard-${idx + 1}`}
                                onClick={() => {
                                    handleListItemAddOrRemove({
                                        target: {
                                            name: `${setName}_${id}`,
                                            value: null
                                        }
                                    })
                                }} />
                        </Box>
                    </Box>
                    <LabelledTextField
                        value={`${hazardName}`} name={`${setName}_${id}_hazardName`}
                        label={`Hazard name`} minRows={1} handleChange={handleListItemStateChange}
                        placeholder={`Short name for the hazard`} />
                    <LabelledTextField
                        value={`${description}`} name={`${setName}_${id}_description`}
                        label={`Description`} minRows={2} handleChange={handleListItemStateChange}
                        placeholder={`Describe the hazard (i.e. what poses a danger to yourself, others and/or the surrounding environment)`} />
                    <Grid container>
                        {
                            hazardControls.map((hazardControl, controlIdx) => {
                                const { id: controlId } = hazardControl;
                                return (
                                    <Grid key={`${setName}_${id}_hazardControls_${controlId}`} item xs={12} lg={6} >
                                        <Box m="10px" p="15px" border="1px solid lightGrey" borderRadius="15px">
                                            <WSEditableHazardControlDetail
                                                hazardControl={hazardControl}
                                                idx={controlIdx}
                                                handleTextfieldChange={handleCriticalHazardControlTextFieldChangeProxy}
                                                handleHazardControlListChangeEvent={handleCriticalHazardControlListChangeEventProxy}
                                                handleHazardControlRiskManagementToggle={handleCriticalHazardControlRiskManagementToggleProxy}
                                                handleHazardControlSliderChangeEvent={handleCriticalHazardControlSliderChangeEventProxy}
                                                potentialHarmWidth={160}
                                                potentialHarmFontSize={isSmallerThanSm ? "0.7rem" : '0.8rem'}
                                                namePrefix={`${setName}_${id}_`} />
                                        </Box>
                                    </Grid>
                                )
                            })
                        }
                        <Grid item xs={12} sm={6} lg={4}>
                            <Box m="15px" display="flex" justifyContent="center" alignItems="center" height="100%">
                                <BasicIconTextButton
                                    backgroundColor={theme.palette.secondary.main}
                                    color={theme.palette.secondary.contrastText}
                                    startIcon={<AddCircle />}
                                    onClick={() => {
                                        // ------------------------------------    
                                        if (isDefinedAndInitialized(handleListItemStateChange)) {                                                        // 
                                            const subId = uuidv4();
                                            handleCriticalHazardControlListChangeEventProxy({
                                                type: 'ADD',
                                                name: `${setName}_${id}_hazardControls`,
                                                value: {
                                                    id: subId,
                                                    eliminate: false,
                                                    isolate: false,
                                                    minimise: false,
                                                    description: '',
                                                    potentialConsequenceBeforeControl: 'CRITICAL',
                                                    potentialConsequenceAfterControl: 'CRITICAL'
                                                }
                                            })
                                        }
                                    }} >
                                    mitigation
                                </BasicIconTextButton>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <Box m="15px" display="flex" justifyContent="center">
                                <BasicIconTextButton
                                    backgroundColor={theme.palette.secondary.main}
                                    color={theme.palette.secondary.contrastText}
                                    startIcon={<CheckCircle />}
                                    onClick={handleItemCloseClick} >
                                    Done
                                </BasicIconTextButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        )
    }
    else {
        // --------------------------------------

        return (
            <Grid key={idx} item xs={12} >
                <Box
                    style={{ backgroundColor: "secondary.main", color: "secondary.contrastText", padding: '10px' }} border="1px solid lightGrey" borderRadius="15px"
                    component="div">
                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={7}>
                                <Box
                                    display="flex" flexDirection="row"
                                    alignItems="center">
                                    <Typography variant="subtitle1" style={{ fontSize: isSmallerThanSm ? '0.85rem' : '0.875rem' }}><strong>{`${hazardName}`}</strong></Typography>
                                </Box>
                                <Box
                                    display="flex" flexDirection="row"
                                    alignItems="center">
                                    <Typography variant="subtitle1" style={{ fontSize: isSmallerThanSm ? '0.85rem' : '0.875rem' }}>{`${description}`}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <ConsequenceReductionIcon hazardControls={hazardControls} />
                            </Grid>
                        </Grid>
                        <Box>
                            <BasicIconButton
                                icon={<Edit fontSize="inherit" />}
                                color="secondary.contrastText" backgroundColor="secondary.main"
                                hoverColor='secondary.main'
                                hoverBackgroundColor='primary.main'
                                tooltipLabel={`Edit critical hazard ${idx + 1}`}
                                ariaLabel={`edit-critical-hazard-${idx + 1}`}
                                onClick={(e) => handleItemEditClick(e, { id })} />
                        </Box>
                        <Box>
                            <BasicIconButton
                                icon={<Delete fontSize="inherit" />}
                                color="secondary.contrastText" backgroundColor="secondary.main"
                                hoverColor='secondary.main'
                                hoverBackgroundColor='primary.main'
                                tooltipLabel={`Delete critical hazard ${idx + 1}`}
                                ariaLabel={`delete-critical-hazard-${idx + 1}`}
                                onClick={() => {
                                    handleListItemAddOrRemove({
                                        target: {
                                            name: `${setName}_${id}`,
                                            value: null
                                        }
                                    })
                                }} />
                        </Box>
                    </Box>
                </Box>
            </Grid>
        )
    }
}

const ICriticalHazardProps = {
    name: PropTypes.string,
    criticalHazard: PropTypes.object,
    listState: PropTypes.object,
    idx: PropTypes.number,
    handleItemEditClick: PropTypes.func,
    handleItemCloseClick: PropTypes.func,
    handleCriticalHazardControlTextFieldChangeProxy: PropTypes.func,
    handleCriticalHazardControlListChangeEventProxy: PropTypes.func,
    handleCriticalHazardControlRiskManagementToggleProxy: PropTypes.func,
    handleCriticalHazardControlSliderChangeEventProxy: PropTypes.func,
    handleListItemStateChange: PropTypes.func,
    handleListItemAddOrRemove: PropTypes.func
}

WSEditableCriticalHazard.propTypes = ICriticalHazardProps;


export const WSEditableHazardObservationWithMedia = (props) => {
    // -------------------------------------------
    const { name, criticalHazard, listState, idx, includeToggle,
        handleItemEditClick, handleItemCloseClick,
        handleCriticalHazardControlTextFieldChangeProxy, handleCriticalHazardControlListChangeEventProxy,
        handleCriticalHazardControlRiskManagementToggleProxy, handleCriticalHazardControlSliderChangeEventProxy,
        handleListItemStateChange, handleListItemAddOrRemove, selectedFiles, handleFileSelection,
        handleFileListItemAddOrRemove, handleFileItemStateChange, handleFileUpload, uploadedFiles,
        handleToggle
    } = props;
    const { id, hazardName, description, hazardControls } = criticalHazard;

    const { state } = useContext(InitiaContext);
    const hasHazardObservationDisplayIds = ('hazardObservationDisplayIds' in state.observationPage.observationEdit.header 
                                            && isDefinedAndInitialized(state.observationPage.observationEdit.header.hazardObservationDisplayIds));
    const { hazardObservationDisplayIds } =  hasHazardObservationDisplayIds ? state.observationPage.observationEdit.header : { hazardObservationDisplayIds: [] };


    let setName = 'criticalHazards';
    if (isDefinedAndInitialized(name)) {
        // -----------------------------
        setName = name;
    }
    let setIncludeToggle = false;
    if (isDefinedAndInitialized(includeToggle)) {
        // ------------------------------------
        setIncludeToggle = includeToggle;
    }
    let setHandleFileSelection = (files, criticalHazard) => console.error(`Handle file selection not implemented, attempted to upload ${files.length} files for ${JSON.stringify(criticalHazard)}`);
    if (isDefinedAndInitialized(handleFileSelection)) {
        // ------------------------------------
        setHandleFileSelection = handleFileSelection;
    }
    let setSelectedFiles = [];
    if (isDefinedAndInitialized(selectedFiles)) {
        // ------------------------------------
        setSelectedFiles = selectedFiles;
    }
    let setUploadedFiles = [];
    if (isDefinedAndInitialized(uploadedFiles)) {
        // ------------------------------------
        setUploadedFiles = uploadedFiles;
    }
    let setHandleToggle = () => console.error(`Handle toggle not implemented`);
    if (isDefinedAndInitialized(handleToggle)) {
        // ------------------------------------
        setHandleToggle = handleToggle;
    }

    // MUI HOOKS
    const theme = useTheme();
    const isSmallerThanXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('sm'));

    if (isDefinedAndInitialized(listState.activeHazard) && criticalHazard.id === listState.activeHazard) {
        // --------------------------------------
        return (
            <Box p="15px" border={`2px solid ${theme.palette.secondary.main}`} borderRadius="15px">
                <Box p="2px" display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">
                    {
                        setIncludeToggle &&
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <Switch
                                checked={hazardObservationDisplayIds.includes(criticalHazard.id)}
                                onChange={setHandleToggle}
                                name={`critical_hazard_${criticalHazard.id}`}
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                            <Typography style={{ fontSize: "0.6rem", textAlign: "center" }}>
                                { hazardObservationDisplayIds.includes(criticalHazard.id) ? `Including in report` : `Excluding from report` }
                            </Typography>
                        </Box>
                    }
                    <Box>
                        <BasicIconButton
                            icon={<CheckCircle fontSize="inherit" />}
                            color="secondary.main" backgroundColor="secondary.contrastText"
                            hoverColor='primary.contrastText'
                            hoverBackgroundColor='primary.main'
                            tooltipLabel={`Close critical hazard ${idx + 1}`}
                            ariaLabel={`close-critical-hazard-${idx + 1}`}
                            onClick={handleItemCloseClick} />
                    </Box>
                    <Box>
                        <BasicIconButton
                            icon={<Delete fontSize="inherit" />}
                            color="secondary.main" backgroundColor="secondary.contrastText"
                            hoverColor='secondary.main'
                            hoverBackgroundColor='primary.main'
                            tooltipLabel={`Delete critical hazard ${idx + 1}`}
                            ariaLabel={`delete-critical-hazard-${idx + 1}`}
                            onClick={() => {
                                handleListItemAddOrRemove({
                                    target: {
                                        name: `${setName}_${id}`,
                                        value: null
                                    }
                                })
                            }} />
                    </Box>
                </Box>
                <LabelledTextField
                    value={`${hazardName}`} name={`${setName}_${id}_hazardName`}
                    label={`Hazard name`} minRows={1} handleChange={handleListItemStateChange}
                    placeholder={`Short name for the hazard`} />
                <LabelledTextField
                    value={`${description}`} name={`${setName}_${id}_description`}
                    label={`Description`} minRows={2} handleChange={handleListItemStateChange}
                    placeholder={`Describe the hazard (i.e. what poses a danger to yourself, others and/or the surrounding environment)`} />
                <Box marginY="10px" mx="5px">
                    <Typography fontSize="0.85rem">Upload any photos related to this hazard</Typography>
                    <UploadFileButton
                        handleFileSelection={(files) => setHandleFileSelection(files, criticalHazard)}
                    />
                </Box>
                <Grid container>
                    {
                        setSelectedFiles.length > 0 &&
                        <>
                            {
                                setSelectedFiles.map((file, idx) => {
                                    const { hazardId } = file;
                                    return (
                                        <Grid key={`file-selection-${idx}`} item xs={12} lg={6} style={{ padding: '5px' }}>
                                            <FileSelectionCard
                                                namePrefix={`${hazardId}`} file={file}
                                                handleFileItemStateChange={handleFileItemStateChange}
                                                handleFileListItemAddOrRemove={handleFileListItemAddOrRemove}
                                            />
                                        </Grid>
                                    )
                                }
                                )
                            }
                            <Grid item xs={12} />
                            {
                                !isSmallerThanXs &&
                                <Grid item sm={3} />
                            }
                            <Grid item xs={12} sm={6} style={{ marginTop: "25px", marginBottom: '25px' }}>
                                <BasicIconTextButton startIcon={<Publish />} color="secondary.contrastText" backgroundColor={red['700']}
                                    onClick={handleFileUpload}
                                >
                                    Upload new files
                                </BasicIconTextButton>
                            </Grid>
                            {
                                !isSmallerThanXs &&
                                <Grid item sm={3} />
                            }
                        </>
                    }
                    {
                        setSelectedFiles.length === 0 &&
                        <Box display="flex" flexDirection="row" marginY="25px" width="100%" justifyContent="center">
                            <Typography>No files are currently selected for upload</Typography>
                        </Box>
                    }
                    {
                        setUploadedFiles.length > 0 &&
                        setUploadedFiles.map((file, idx) => (
                            <Grid key={`file-upload-${idx}`} item xs={12} lg={6} style={{ padding: '5px' }}>
                                <FileUploadCard
                                    namePrefix={`hazardObservationMedia`}
                                    file={file}
                                    handleFileItemStateChange={handleFileItemStateChange}
                                    handleFileListItemAddOrRemove={handleFileListItemAddOrRemove}
                                />
                            </Grid>
                        ))
                    }
                    {
                        setUploadedFiles.length === 0 &&
                        <Box display="flex" flexDirection="row" width="100%" justifyContent="center">
                            <Typography>There are currently no file uploads for this hazard</Typography>
                        </Box>
                    }
                </Grid>
                <Grid container>
                    {
                        hazardControls.map((hazardControl, controlIdx) => {
                            const { id: controlId } = hazardControl;
                            return (
                                <Grid key={`${setName}_${id}_hazardControls_${controlId}`} item xs={12} lg={6} >
                                    <Box m="10px" p="15px" border="1px solid lightGrey" borderRadius="15px">
                                        <WSEditableHazardControlDetail
                                            hazardControl={hazardControl}
                                            idx={controlIdx}
                                            handleTextfieldChange={handleCriticalHazardControlTextFieldChangeProxy}
                                            handleHazardControlListChangeEvent={handleCriticalHazardControlListChangeEventProxy}
                                            handleHazardControlRiskManagementToggle={handleCriticalHazardControlRiskManagementToggleProxy}
                                            handleHazardControlSliderChangeEvent={handleCriticalHazardControlSliderChangeEventProxy}
                                            potentialHarmWidth={160}
                                            potentialHarmFontSize={isSmallerThanSm ? "0.7rem" : '0.8rem'}
                                            namePrefix={`${setName}_${id}_`} />
                                    </Box>
                                </Grid>
                            )
                        })
                    }
                    <Grid item xs={12} sm={6} lg={4}>
                        <Box m="15px" display="flex" justifyContent="center">
                            <BasicIconTextButton
                                backgroundColor={theme.palette.secondary.main}
                                color={theme.palette.secondary.contrastText}
                                startIcon={<AddCircle />}
                                onClick={() => {
                                    // ------------------------------------    
                                    if (isDefinedAndInitialized(handleListItemStateChange)) {                                                        // 
                                        const subId = uuidv4();
                                        handleCriticalHazardControlListChangeEventProxy({
                                            type: 'ADD',
                                            name: `${setName}_${id}_hazardControls`,
                                            value: {
                                                id: subId,
                                                eliminate: false,
                                                isolate: false,
                                                minimise: false,
                                                description: '',
                                                potentialConsequenceBeforeControl: 'CRITICAL',
                                                potentialConsequenceAfterControl: 'CRITICAL'
                                            }
                                        })
                                    }
                                }} >
                                mitigation
                            </BasicIconTextButton>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Box m="15px" display="flex" justifyContent="center">
                            <BasicIconTextButton
                                backgroundColor={theme.palette.secondary.main}
                                color={theme.palette.secondary.contrastText}
                                startIcon={<CheckCircle />}
                                onClick={handleItemCloseClick} >
                                Done
                            </BasicIconTextButton>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    }
    else {
        // --------------------------------------
        let hazardObservationImages = setUploadedFiles.filter(hazardMediaUpload => ['jpg', 'jpeg', 'png', 'gif'].includes(getFileTypeExtension(hazardMediaUpload.name) || ''));
        return (
            <Box display="flex" flexDirection="column">
                <Box
                    style={{ backgroundColor: "secondary.main", color: "secondary.contrastText", padding: '10px' }} 
                    border="1px solid lightGrey" borderRadius="15px"
                >
                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={7}>
                                <Box
                                    display="flex" flexDirection="row"
                                    alignItems="center">
                                    <Typography variant="subtitle1" style={{ fontSize: isSmallerThanSm ? '0.85rem' : '0.875rem' }}><strong>{`${hazardName}`}</strong></Typography>
                                </Box>
                                <Box
                                    display="flex" flexDirection="row"
                                    alignItems="center">
                                    <Typography variant="subtitle1" style={{ fontSize: isSmallerThanSm ? '0.85rem' : '0.875rem' }}>{`${description}`}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <ConsequenceReductionIcon hazardControls={hazardControls} />
                            </Grid>
                        </Grid>
                        <Box>
                            <Box display="flex" flexDirection="row" width="100%" justifyContent="center">
                                <Box>
                                    <BasicIconButton
                                        icon={<Edit fontSize="inherit" />}
                                        color="secondary.contrastText" backgroundColor="secondary.main"
                                        hoverColor='secondary.main'
                                        hoverBackgroundColor='primary.main'
                                        tooltipLabel={`Edit critical hazard ${idx + 1}`}
                                        ariaLabel={`edit-critical-hazard-${idx + 1}`}
                                        onClick={(e) => handleItemEditClick(e, { id: (id) })} />
                                </Box>
                                <Box>
                                    <BasicIconButton
                                        icon={<Delete fontSize="inherit" />}
                                        color="secondary.contrastText" backgroundColor="secondary.main"
                                        hoverColor='secondary.main'
                                        hoverBackgroundColor='primary.main'
                                        tooltipLabel={`Delete critical hazard ${idx + 1}`}
                                        ariaLabel={`delete-critical-hazard-${idx + 1}`}
                                        onClick={() => {
                                            handleListItemAddOrRemove({
                                                target: {
                                                    name: `${setName}_${id}`,
                                                    value: null
                                                }
                                            })
                                        }} />
                                </Box>   
                            </Box>                     
                        {
                            setIncludeToggle &&
                            <Box display="flex" flexDirection="column" alignItems="center">
                                <Switch
                                    checked={hazardObservationDisplayIds.includes(criticalHazard.id)}
                                    onChange={setHandleToggle}
                                    name={`critical_hazard_${criticalHazard.id}`}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />
                                <Typography style={{ fontSize: "0.6rem", textAlign: "center" }}>
                                    { hazardObservationDisplayIds.includes(criticalHazard.id) ? `Including in report` : `Excluding from report` }
                                </Typography>
                            </Box>
                        }
                        </Box>
                    </Box>
                </Box>
                {
                    hazardObservationImages.length > 0 &&
                    <Box mx="15px" marginBottom="10px">
                        <ImageList cols={isSmallerThanSm ? 2 : 4} style={{ marginTop: 0 }} >
                            {
                                hazardObservationImages.map(imageFile => {
                                    // ---------------------------------
                                    return (
                                        <ImageListItem key={imageFile.id}>
                                            <GenericPresignedImg
                                                file={imageFile}
                                            />
                                        </ImageListItem>
                                    )
                                })
                            }
                        </ImageList>
                    </Box>
                }
            </Box>
        )
    }
}

WSEditableHazardObservationWithMedia.propTypes = IHazardObservationWithMediaProps;


export const IHazardObservationWithMediaProps = {
    name: PropTypes.string,
    criticalHazard: PropTypes.object,
    listState: PropTypes.object,
    idx: PropTypes.number,
    includeToggle: PropTypes.bool,
    handleItemEditClick: PropTypes.func,
    handleItemCloseClick: PropTypes.func,
    handleCriticalHazardControlTextFieldChangeProxy: PropTypes.func,
    handleCriticalHazardControlListChangeEventProxy: PropTypes.func,
    handleCriticalHazardControlRiskManagementToggleProxy: PropTypes.func,
    handleCriticalHazardControlSliderChangeEventProxy: PropTypes.func,
    handleListItemStateChange: PropTypes.func,
    handleListItemAddOrRemove: PropTypes.func,
    selectedFiles: PropTypes.array,
    handleFileSelection: PropTypes.func,
    handleFileListItemAddOrRemove: PropTypes.func,
    handleFileItemStateChange: PropTypes.func,
    handleFileUpload: PropTypes.func,
    uploadedFiles: PropTypes.array,
    handleToggle: PropTypes.func
}


export const WSInputCriticalAndSiteSpecificHazardList = (props) => {
    // -------------------------------------------
    // PROPS
    const { name, label, buttonText, criticalHazardList, allowMedia, includeToggle, handleListItemAddOrRemove, handleListItemStateChange,
        handleNestedListItemStateChange, handleNestedListItemAddOrRemove, newItemTemplate, selectedFiles, uploadedFiles, handleFileSelection,
        handleFileListItemAddOrRemove, handleFileItemStateChange, handleFileUpload, handleToggle } = props;
    const numWithoutControls = (criticalHazardList).filter(hazard => hazard.hazardControls.length === 0).length;

    // OPTIONAL PROP HANDLING
    let setLabel = `Critical or site specific hazards`;
    if (isDefinedAndInitialized(label)) {
        // ------------------------------------
        setLabel = label;
    }
    let setName = `criticalHazards`;
    if (isDefinedAndInitialized(name)) {
        // ------------------------------------
        setName = name;
    }
    let setButtonText = `Critical Hazard`;
    if (isDefinedAndInitialized(buttonText)) {
        // ------------------------------------
        setButtonText = buttonText;
    }
    let setAllowMedia = false;
    if (isDefinedAndInitialized(allowMedia)) {
        // ------------------------------------
        setAllowMedia = allowMedia;
    }
    let setIncludeToggle = false;
    if (isDefinedAndInitialized(includeToggle)) {
        // ------------------------------------
        setIncludeToggle = includeToggle;
    }
    let setHandleFileSelection = (files, criticalHazard) => console.error(`Handle file selection not implemented, attempted to upload ${files.length} files for ${JSON.stringify(criticalHazard)}`);
    if (isDefinedAndInitialized(handleFileSelection)) {
        // ------------------------------------
        setHandleFileSelection = handleFileSelection;
    }
    let setHandleFileListItemAddOrRemove = () => console.error(`Handle file list add/remove not implemented`);
    if (isDefinedAndInitialized(handleFileListItemAddOrRemove)) {
        // ------------------------------------
        setHandleFileListItemAddOrRemove = handleFileListItemAddOrRemove;
    }
    let setHandleFileItemStateChange = () => console.error(`Handle file list add/remove not implemented`);
    if (isDefinedAndInitialized(handleFileItemStateChange)) {
        // ------------------------------------
        setHandleFileItemStateChange = handleFileItemStateChange;
    }
    let setHandleFileUpload = () => { console.error(`Handle file list add/remove not implemented`); return new Promise(resolve => resolve()); }
    if (isDefinedAndInitialized(handleFileUpload)) {
        // ------------------------------------
        setHandleFileUpload = handleFileUpload;
    }
    let setHandleToggle = () => console.error(`Handle toggle not implemented`);
    if (isDefinedAndInitialized(handleToggle)) {
        // ------------------------------------
        setHandleToggle = handleToggle;
    }


    // STATE
    const [listState, setListState] = useState({ activeHazard: null });

    // MUI HOOKS
    const theme = useTheme();
    const isSmallerThanXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('sm'));

    // EVENT HANDLERS
    const handleItemEditClick = (_, data) => {
        // ----------------------------------------------
        const { id } = data;

        setListState({
            ...listState,
            activeHazard: id
        })
    }

    const handleItemCloseClick = () => {
        // ----------------------------------------------
        setListState({
            ...listState,
            activeHazard: null
        })
    }

    const handleCriticalHazardControlTextFieldChangeProxy = (event) => {
        // ----------------------------------------------
        handleNestedListItemStateChange(event);
    }


    const handleCriticalHazardControlListChangeEventProxy = (event) => {
        // ----------------------------------------------
        const { type, name, id, value } = event;

        if (type === 'REMOVE') {
            // ---------------------------
            handleNestedListItemAddOrRemove({
                target: {
                    name: `${name}_${id}`,
                    value: value
                },
            });
        }
        else {
            // ---------------------------
            handleNestedListItemAddOrRemove({
                target: {
                    name: `${name}`,
                    value: value
                },
            });
        }
    }


    const handleCriticalHazardControlRiskManagementToggleProxy = (event) => {
        // ----------------------------------------------
        const { name } = event.target;
        const [prop, id, childProp, childId, grandChildProp] = name.split('_');

        if (!isDefinedAndInitialized(prop)
            || !isDefinedAndInitialized(id)
            || !isDefinedAndInitialized(childProp)
            || !isDefinedAndInitialized(childId)
            || !isDefinedAndInitialized(grandChildProp)) {
            throw new Error('List item name could not be parsed');
        }

        const value = (criticalHazardList).find(item => item.id === id);

        if (isDefinedAndInitialized(value) && childProp in value) {
            const subValue = ((value)[(childProp)]).find(item => item.id === childId);
            handleNestedListItemStateChange({
                target: {
                    name,
                    value: !(subValue)[grandChildProp]
                }
            });
        }
    }


    const handleCriticalHazardControlSliderChangeEventProxy = (event) => {
        // ----------------------------------------------
        const { name, value } = event.target;

        handleNestedListItemStateChange({
            target: {
                name,
                value: value.toUpperCase().split(' ').join('_')
            }
        });
    }


    return (
        <>
            <Box pl="15px" pb="10px" display="flex" flexDirection={isSmallerThanSm ? 'column' : 'row'}>
                <Typography style={{ fontSize: '1rem' }}>
                    {setLabel}
                </Typography>
                <Typography style={{ fontSize: '1rem' }}>
                    &nbsp;{`(${numWithoutControls} without controls)`}
                </Typography>
            </Box>
            <Box>
                <Grid container>
                    {
                        criticalHazardList
                            .sort(sortHazardByResidualRisk)
                            .map((criticalHazard, idx) => {
                                const { id } = criticalHazard;
                                // DERIVED PROPS
                                let selectedHazardMedia = isDefinedAndInitialized(selectedFiles) && isDefinedAndInitialized(id)
                                    && Object.keys(selectedFiles).includes(id) ? selectedFiles[id] : ([]);
                                let uploadedHazardMedia = isDefinedAndInitialized(uploadedFiles) && isDefinedAndInitialized(id)
                                    ? uploadedFiles.filter(hazardMedia => hazardMedia.hazardId === id) : ([]);

                                return (
                                    <Box key={idx} marginY="5px" width="100%">
                                        {
                                            setAllowMedia ?
                                                <WSEditableHazardObservationWithMedia
                                                    name={setName}
                                                    key={idx}
                                                    idx={idx}
                                                    listState={listState}
                                                    criticalHazard={criticalHazard}
                                                    includeToggle={setIncludeToggle}
                                                    handleItemEditClick={handleItemEditClick}
                                                    handleItemCloseClick={handleItemCloseClick}
                                                    handleCriticalHazardControlTextFieldChangeProxy={handleCriticalHazardControlTextFieldChangeProxy}
                                                    handleCriticalHazardControlListChangeEventProxy={handleCriticalHazardControlListChangeEventProxy}
                                                    handleCriticalHazardControlRiskManagementToggleProxy={handleCriticalHazardControlRiskManagementToggleProxy}
                                                    handleCriticalHazardControlSliderChangeEventProxy={handleCriticalHazardControlSliderChangeEventProxy}
                                                    handleListItemStateChange={handleListItemStateChange}
                                                    handleListItemAddOrRemove={handleListItemAddOrRemove}
                                                    selectedFiles={selectedHazardMedia}
                                                    uploadedFiles={uploadedHazardMedia}
                                                    handleFileSelection={setHandleFileSelection}
                                                    handleFileListItemAddOrRemove={setHandleFileListItemAddOrRemove}
                                                    handleFileItemStateChange={setHandleFileItemStateChange}
                                                    handleFileUpload={setHandleFileUpload}
                                                    handleToggle={setHandleToggle}
                                                />
                                                :
                                                <WSEditableCriticalHazard
                                                    name={setName}
                                                    key={idx}
                                                    idx={idx}
                                                    listState={listState}
                                                    criticalHazard={criticalHazard}
                                                    handleItemEditClick={handleItemEditClick}
                                                    handleItemCloseClick={handleItemCloseClick}
                                                    handleCriticalHazardControlTextFieldChangeProxy={handleCriticalHazardControlTextFieldChangeProxy}
                                                    handleCriticalHazardControlListChangeEventProxy={handleCriticalHazardControlListChangeEventProxy}
                                                    handleCriticalHazardControlRiskManagementToggleProxy={handleCriticalHazardControlRiskManagementToggleProxy}
                                                    handleCriticalHazardControlSliderChangeEventProxy={handleCriticalHazardControlSliderChangeEventProxy}
                                                    handleListItemStateChange={handleListItemStateChange}
                                                    handleListItemAddOrRemove={handleListItemAddOrRemove}
                                                />
                                        }
                                    </Box>
                                )
                            })
                    }
                    {
                        !isSmallerThanXs &&
                        <Grid item sm={3} md={4}/>
                    }
                    <Grid item xs={12} sm={6} md={4}>
                        <Box marginTop="15px">
                            <BasicIconTextButton startIcon={<AddCircle />} color={theme.palette.primary.contrastText} backgroundColor={theme.palette.primary.main}
                                onClick={() => {
                                    const id = uuidv4();
                                    handleListItemAddOrRemove({
                                        target: {
                                            name: setName,
                                            value: {
                                                id,
                                                ...newItemTemplate
                                            }
                                        }
                                    })
                                    setListState({
                                        ...listState,
                                        activeHazard: id
                                    })
                                }}
                            >
                                {setButtonText}
                            </BasicIconTextButton>
                        </Box>
                    </Grid>
                    {
                        !isSmallerThanXs &&
                        <Grid item sm={3} md={4}/>
                    }                
                </Grid>
            </Box>
        </>
    );
}

const IInputCriticalAndSiteSpecificHazardList = {
    name: PropTypes.string,
    label: PropTypes.string,
    buttonText: PropTypes.string,
    allowMedia: PropTypes.bool,
    includeToggle: PropTypes.bool,
    criticalHazardList: PropTypes.array,
    newItemTemplate: PropTypes.object,
    handleListItemAddOrRemove: PropTypes.func,
    handleListItemStateChange: PropTypes.func,
    handleNestedListItemStateChange: PropTypes.func,
    handleNestedListItemAddOrRemove: PropTypes.func,
    uploadedFiles: PropTypes.array,
    selectedFiles: PropTypes.object,
    handleFileSelection: PropTypes.func,
    handleFileListItemAddOrRemove: PropTypes.func,
    handleFileItemStateChange: PropTypes.func,
    handleFileUpload: PropTypes.func,
    handleToggle: PropTypes.func
}

WSInputCriticalAndSiteSpecificHazardList.propTypes = IInputCriticalAndSiteSpecificHazardList;


const WSPlanListItem = (props) => {
    // -------------------------------------------
    const { componentData, plan, staffList, handleClientObservationStateUpdate, planSequenceNumber } = props;
    const { id, createdBy, title, jobId, startDate, endDate, status } = plan;

    // MUI HOOKS
    const theme = useTheme();
    const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('sm'));
    const isSmallerThanXs = useMediaQuery(theme.breakpoints.down('xs'));

    const { state } = useContext(InitiaContext);
    const isSelected = isDefinedAndInitialized(state.observationPage.observationEdit.header.wsPlanId) && state.observationPage.observationEdit.header.wsPlanId === id;
    const prestartTimestamp = isDefinedAndInitialized(state.observationPage.observationEdit.header.inspectionTimestamp) ? state.observationPage.observationEdit.header.inspectionTimestamp : DateTime.local().toISO();

    let staffMember = isDefinedAndInitialized(createdBy) ? staffList.find(teamMember => standardiseString(teamMember.mail) === standardiseString(createdBy)) : { id: '', displayName: '' };
    staffMember = isDefinedAndInitialized(staffMember) ? staffMember : { id: '', displayName: '' };
    let staffName = staffMember.displayName === '' ? createdBy : prepNullableFieldToString(staffMember.displayName);
    const staffIconSrc = (isDefinedAndInitialized(staffMember) && staffMember.id !== '') ? `https://${PUBLIC_BUCKET_NAME}.s3.ap-southeast-2.amazonaws.com/staff-media/${staffMember.id}.jpg` : null;

    const planLabel = isDefinedAndInitialized(title) && title !== '' ? title : `${jobId} - ${planSequenceNumber}`;
    const durationCalculated = isDefinedAndInitialized(startDate) && isDefinedAndInitialized(endDate);
    const duration = durationCalculated ? DateTime.fromISO(endDate).endOf('day').diff(DateTime.fromISO(startDate).startOf('day'), 'days', { conversionAccuracy: 'longterm' }) : null;
    const durationLabel = `${durationCalculated ? `(${Math.round(duration.days)} day${Math.round(duration.days) === 1 ? ')' : 's)'}` : ''}`;

    return (
        <ListItem
            button
            style={{
                border: `1px solid ${ isSelected ? theme.palette.secondary.main : 'lightgrey' }`,
                borderRadius: "5px", backgroundColor: isSelected ? '#e3ffef' : 'white'
            }}
            onClick={(e) => {
                const stateUpdate = isSelected ? null : {
                    id: uuidv4(),
                    jobId,
                    planId: id,
                    planStartDate: startDate,
                    prestartTimestamp,
                    status: 'COMPLETED',
                    leadingTeam: false,
                    parentPrestartId: '',
                    continuationDetail: '',
                    contractorsAndSubcontractors: [],
                    siteContacts: { rows: [] },
                    teamAwareOfHighlightedHazards: false,
                    needToAddTeamsHighlightedHazards: false,
                    teamsHighlightedHazards: [],
                    newHazardsAndControls: [],
                    hazardObservationMedia: [],
                    teamWorkDetail: '',
                    otherPrestartMedia: [],
                    otherNotes: '',
                    observationId: state.observationPage.observationEdit.inspectionId
                }
                handleClientObservationStateUpdate(e, componentData.componentType, componentData.componentId, stateUpdate)
            }}
        >
            <Box
                display="flex" flexDirection="row" justifyContent="space-between"
                margin="10px" padding="10px"
                style={{ 'cursor': 'pointer' }}
                width="100%"
            >
                <Box
                    display="flex" flexDirection={isSmallerThanSm ? "column" : "row"}
                    alignItems={isSmallerThanSm ? "flex-start" : "center"}>
                    <Box display="flex" flexDirection={isSmallerThanXs ? "column" : "row"} >
                        <Box pl={isSmallerThanSm ? "30px" : "0px"} marginRight="5px" marginBottom={isSmallerThanSm ? "10px" : "0px"}>
                            <Chip
                                style={{ ...getStatusChipStyling(status), fontSize: isSmallerThanSm ? '0.8rem' : '1rem' }}
                                avatar={<Avatar alt={`plan status ${status}`} style={{ backgroundColor: 'white' }} ><WSPlanStatusIcon {...props} status={status} /></Avatar>}
                                label={status}
                            />
                        </Box>
                        <Box pl={isSmallerThanXs ? "30px" : "0px"}>
                            {
                                staffIconSrc ?
                                    <Box marginRight="5px">
                                        <Chip
                                            avatar={<Avatar alt={`profile image for ${staffName}`} src={staffIconSrc}>{staffName.charAt(0)}</Avatar>}
                                            label={staffName}
                                            style={{ fontSize: isSmallerThanSm ? '0.8rem' : '1rem' }}
                                        />
                                    </Box>
                                    :
                                    <Box marginRight="5px">
                                        <Chip
                                            avatar={<Avatar alt={`profile image for ${staffName}`}><AccountCircle /></Avatar>}
                                            label={staffName}
                                            style={{ fontSize: isSmallerThanSm ? '0.8rem' : '1rem' }}
                                        />
                                    </Box>
                            }
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection={isSmallerThanSm ? "column" : "row"} marginBottom={isSmallerThanSm ? "10px" : "0px"}>
                        <Box pl={isSmallerThanSm ? "30px" : "0px"} marginRight="5px">
                            <Typography style={{ fontSize: isSmallerThanSm ? '0.9rem' : '1rem' }}><strong>{planLabel}</strong></Typography>
                        </Box>
                        <Box marginRight="5px" pl={isSmallerThanSm ? "30px" : "0px"}>
                            <Typography style={{ fontSize: isSmallerThanSm ? '0.9rem' : '1rem' }}>{durationLabel}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" flexDirection="row" alignItems="center">
                    <Checkbox
                        checked={isSelected}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </Box>
            </Box>
        </ListItem>
    )
}

WSPlanListItem.propTypes = {
    planSequenceNumber: PropTypes.number,
    componentData: PropTypes.object,
    plan: PropTypes.object,
    staffList: PropTypes.array,
    handleClientObservationStateUpdate: PropTypes.func
};

const Take5Component = (props) => {
    // -------------------------------------------
    // PROPS
    const { classes, componentId, componentData, componentUpHandler, handleClientObservationStateUpdate, saveHandler, headingCount } = props;

    let setHeadingCount = null;
    if (isDefinedAndInitialized(headingCount)) {
      // ----------------------------------------------
      setHeadingCount = headingCount;
    }  

    // STATE
    const [selectedHazardId, setSelectedHazardId] = useState(null);
    const [selectedHazardObservationMedia, setSelectedHazardObservationMedia] = useState({});

    // REACT ROUTER
    let { observationId } = useParams();
    // const history = useHistory();

    // NOTISTACK
    const { enqueueSnackbar } = useSnackbar();

    // MUI HOOKS
    const theme = useTheme();
    const isSmallerThanXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('sm'));
    const anchorSnackbar = {
        horizontal: 'left',
        vertical: isSmallerThanSm ? 'top' : 'bottom'
    }

    // USE MSAL HOOK
    const { instance, accounts, inProgress } = useMsal();
    const loginHint = (accounts && accounts[0]?.username) ?? '';
    const request = {
        loginHint,
        scopes: ["User.Read"]
    }
    const isAuthenticated = useIsAuthenticated();
    const isAuthed = isAuthenticated && inProgress === "none" && isDefinedAndInitialized(accounts) && accounts.length > 0 && isDefinedAndInitialized(accounts[0]) && isDefinedAndInitialized(accounts[0].username);

    // CONTEXT
    const { state } = useContext(InitiaContext);
    const { observationPage } = state;
    const { observationEdit, workAndSafety } = observationPage;

    // REACT-QUERY
    // - Query client used for invalidation of queries
    const queryClient = useQueryClient();
    // OBSERVATION DETAIL DATA
    const { data: obsDetailData, isLoading: isObsDetailLoading, isError: isObsDetailDataError, error: obsDetailDataError } = useQuery(
        ['observation', observationId],
        () => getObservationDetail(observationId, { instance, accounts, inProgress }),
        {
            enabled: isAuthed && isDefinedAndInitialized(observationId),
            refetchOnWindowFocus: false
        }
    );
    const jobId = !isObsDetailLoading && isDefinedAndInitialized(obsDetailData) ? obsDetailData.data.header.jobId : null;

    // JOB PLAN LIST DATA
    const { data: hsPlanListData, isLoading: isHsPlanListLoading, isError: isHsPlanListError, error: hsPlanListError, isFetching: isHsPlanListDataFetching } = useQuery(
        ['workAndSafety', 'plan', jobId],
        () => getJobWorkAndSafetyPlanList(jobId, { instance, accounts, inProgress }),
        {
            enabled: isAuthed && isDefinedAndInitialized(jobId),
            refetchOnWindowFocus: false
        }
    );

    // STAFF LIST DATA
    const { data: staffListData, isLoading: isStaffListLoading, isError: isStaffListError, error: staffListError } = useQuery(
        ['teamMemberListMin'],
        () => getTeamMemberListMin({ instance, accounts, inProgress }),
        {
            enabled: isAuthed,
            refetchOnWindowFocus: false
        }
    );

    // PLAN HIGHLIGHTED HAZARD LIST
    const { data: hsPlanHighlightedHazardListData, isLoading: isHsPlanHighlightedHazardListLoading, isError: isHsPlanHighlightedHazardListError, error: hsPlanHighlightedHazardListError, isFetching: isHsPlanHighlightedHazardListDataFetching } = useQuery(
        ['workAndSafety', observationEdit.header.wsPlanId, 'highlightedHazards'],
        () => getWorkAndSafetyPlanHighlightedHazardList(observationEdit.header.wsPlanId, { instance, accounts, inProgress }),
        {
            enabled: isAuthed && isDefinedAndInitialized(observationEdit.header.wsPlanId),
            refetchOnWindowFocus: false
        }
    );

    // PRESTART DETAIL


    // EFFECTS
    useEffect(async () => {
        if (!isAuthenticated && inProgress === InteractionStatus.None) {
            await instance.loginRedirect(request);
        }
    }, [isAuthenticated, inProgress, instance]);

    // EVENT HANDLERS
    const handleWorkAndSafetyRefresh = () => {
        // -----------------------------------
        queryClient.invalidateQueries(['workAndSafety', 'plan', jobId]);
        queryClient.invalidateQueries(['workAndSafety', observationEdit.header.wsPlanId, 'highlightedHazards']);
    }

    // HANDLE SELECTION 'DRILL-DOWN' of a particular hazard
    const handleHazardSelection = (id) => {
        // --------------------------------------
        setSelectedHazardId(id);
    }

    // PRESTART STATE CHANGES
    const handleBasicStateChange = (event) => {
        // ---------------------------------------------
        handleClientObservationStateUpdate(event, componentData.componentType, componentData.componentId, {
          ...workAndSafety,
          [event.target.name]: event.target.value
        })
    }

    const handleListItemStateChange = (event) => {
        // ---------------------------------------------
        const { name, value } = event.target;

        let setValue = value;
        if ('type' in event.target && event.target.type === 'checkbox') {
            setValue = event.target.checked;
        }

        if (!isDefinedAndInitialized(name)) {
            throw new Error('Name must be a string');
        }

        const [prop, id, subProp] = name.split('_');

        if (!isDefinedAndInitialized(prop)
            || !isDefinedAndInitialized(id)
            || !isDefinedAndInitialized(subProp)
            || !hasOwnProperty(workAndSafety, prop)) {
            throw new Error('List item name could not be parsed');
        }

        const propUpdate = (workAndSafety[prop]).map(item => {
            // --------------------------
            if (item.id === id) {
                return {
                    ...item,
                    [subProp]: setValue
                }
            }
            else {
                return item;
            }
        });

        handleClientObservationStateUpdate(event, componentData.componentType, componentData.componentId, {
            ...workAndSafety,
            [prop]: propUpdate
        });
    }


    const handleListItemAddOrRemove = (event) => {
        // ---------------------------------------------
        const { name, value } = event.target;
        let eventType = 'ADD';

        if (!isDefinedAndInitialized(name)) {
            throw new Error('Name must be a string');
        }

        if (!isDefinedAndInitialized(value)) {
            // ----------------------------------------------
            eventType = 'DELETE';
        }

        if (eventType === 'DELETE') {
            // ------------------------------
            const [prop, id] = name.split('_');
            if (!isDefinedAndInitialized(prop)
                || !isDefinedAndInitialized(id)
                || !hasOwnProperty(workAndSafety, prop)) {
                throw new Error('List item name could not be parsed');
            }

            const propUpdate = (workAndSafety[prop]).filter(item => item.id !== id);

            handleClientObservationStateUpdate(event, componentData.componentType, componentData.componentId, {
                ...workAndSafety,
                [prop]: propUpdate
            })
        }
        else {
            // ------------------------------
            const [prop] = name.split('_');
            if (!isDefinedAndInitialized(prop)
                || !hasOwnProperty(workAndSafety, prop)) {
                throw new Error('List item name could not be parsed');
            }

            let propUpdate = [];
            if (isDefinedAndInitialized(workAndSafety[prop])) {
                propUpdate = [
                    ...workAndSafety[prop]
                ]
            }
            propUpdate = [
                ...propUpdate,
                value
            ]


            handleClientObservationStateUpdate(event, componentData.componentType, componentData.componentId, {
                ...workAndSafety,
                [prop]: propUpdate
            });
        }
    }

    const handleNestedListItemStateChange = (event) => {
        // ---------------------------------------------
        const { name, value } = event.target;

        if (!isDefinedAndInitialized(name)) {
            throw new Error('Name must be a string');
        }

        const [prop, id, childProp, childId, grandChildProp] = name.split('_');

        if (!isDefinedAndInitialized(prop)
            || !isDefinedAndInitialized(id)
            || !isDefinedAndInitialized(childProp)
            || !isDefinedAndInitialized(childId)
            || !isDefinedAndInitialized(grandChildProp)
            || !hasOwnProperty(workAndSafety, prop)) {
            throw new Error('List item name could not be parsed');
        }

        const propUpdate = workAndSafety[prop].map(item => {
            if (item.id === id) {
                // ----------------------
                return {
                    ...item,
                    [childProp]: item[childProp].map((subItem) => {
                        if (subItem.id === childId) {
                            return {
                                ...subItem,
                                [grandChildProp]: value
                            }
                        }
                        else {
                            return subItem;
                        }
                    })
                }
            }
            else {
                return item;
            }
        });

        handleClientObservationStateUpdate(event, componentData.componentType, componentData.componentId, {
            ...workAndSafety,
            [prop]: propUpdate
        })
    }

    const handleNestedListItemAddOrRemove = (event) => {
        // ---------------------------------------------
        const { name, value } = event.target;

        if (!isDefinedAndInitialized(name)) {
            throw new Error('Name must be a string');
        }

        let eventType = 'ADD';
        if (!isDefinedAndInitialized(value)) {
            // ----------------------------------------------
            eventType = 'DELETE';
        }

        if (eventType === 'DELETE') {
            // ------------------------------
            const [prop, id, childProp, childId] = name.split('_');
            if (!isDefinedAndInitialized(prop)
                || !isDefinedAndInitialized(id)
                || !isDefinedAndInitialized(childProp)
                || !isDefinedAndInitialized(childId)
                || !hasOwnProperty(workAndSafety, prop)) {
                throw new Error('List item name could not be parsed');
            }


            const propUpdate = workAndSafety[prop]
                .map(item => {
                    if (item.id === id) {
                        return {
                            ...item,
                            [childProp]: [
                                ...item[childProp].filter(subItem => subItem.id !== childId)
                            ]
                        }
                    }
                    else {
                        return item;
                    }
                })

            handleClientObservationStateUpdate(event, componentData.componentType, componentData.componentId, {
                ...workAndSafety,
                [prop]: propUpdate
            })
        }
        else {
            // ------------------------------
            const [prop, id, childProp] = name.split('_');
            if (!isDefinedAndInitialized(prop)
                || !isDefinedAndInitialized(id)
                || !isDefinedAndInitialized(childProp)
                || !hasOwnProperty(workAndSafety, prop)) {
                throw new Error('List item name could not be parsed');
            }

            const propUpdate = workAndSafety[prop]
                .map(item => {
                    if (item.id === id) {
                        // ----------------------------
                        return {
                            ...item,
                            [childProp]: [
                                ...item[childProp],
                                value
                            ]
                        }
                    }
                    else {
                        // ----------------------------
                        return item
                    }
                });


            handleClientObservationStateUpdate(event, componentData.componentType, componentData.componentId, {
                ...workAndSafety,
                [prop]: propUpdate
            })
        }
    }


    const handleHazardObservationMediaFileSelection = (files, hazardDetail) => {
        // ---------------------------------------------
        const { hazardObservationMedia } = workAndSafety;
        let selectedFiles = [];

        let fileType = 'HIGHLIGHTED';
        if (isDefinedAndInitialized(hazardDetail)
            && Object.keys(hazardDetail).includes('type')
            && hazardDetail.type === 'NEW_HAZARD') {
            fileType = 'NEW_OBSERVATION'
        }

        if (!isDefinedAndInitialized(hazardDetail) || !isDefinedAndInitialized(hazardDetail.id)) {
            // -------------------------------------------
            throw new Error('Hazard detail or id is not defined');
        }

        // 1) Check if name needs index cause of filename clash
        for (const file of files) {
            // -----------------------------------------
            let filenameDifferential = [''];
            // CHECK IF ANY UPLOADED FILES UNDER THIS HAZARD ALREADY, IF SO MAY REQUIRE FILENAME CLASH RENAMING
            if (hazardObservationMedia.filter(mediaItem => mediaItem.hazardId === hazardDetail.id).length > 0) {
                // ---------------------------------------------------------
                const specificHazardObservationMedia = hazardObservationMedia.filter(mediaItem => mediaItem.hazardId === hazardDetail.id);
                const clashingFilenameSearch = specificHazardObservationMedia.find(existingHazardObservationMedia => existingHazardObservationMedia.name === file.name);
                let isFilenameClash = isDefinedAndInitialized(clashingFilenameSearch);

                while (isFilenameClash) {
                    // -----------------------------------------
                    filenameDifferential.push(`copy-of-`);
                    const newFileName = `${filenameDifferential.join('')}${file.name}`;
                    const clashingCopyFilenameSearch = specificHazardObservationMedia.find(existingHazardObservationMedia => existingHazardObservationMedia.name === newFileName);
                    isFilenameClash = isDefinedAndInitialized(clashingCopyFilenameSearch);
                }
            }

            const fileId = uuidv4();
            selectedFiles.push({
                id: fileId,
                type: fileType,
                hazardId: isDefinedAndInitialized(hazardDetail) ? hazardDetail.id : null,
                hazardName: isDefinedAndInitialized(hazardDetail) ? hazardDetail.hazardName : '',
                path: `${window.config.ENV}-work-and-safety/${jobId}/prestart/hazard-observation/${hazardDetail.id}/hazard-observation-media-${fileId}/${s3ReplaceUnsupportedChars(`${filenameDifferential.join('')}${file.name}`)}`,
                name: `${filenameDifferential.join('')}${file.name}`,
                notes: ``,
                size: file.size,
                file,
                jobId,
                planId: observationEdit.header.wsPlanId,
                prestartId: null,
                uploadStatus: "PENDING",
                includeInAppendix: false
            });
        }

        setSelectedHazardObservationMedia({
            ...selectedHazardObservationMedia,
            [hazardDetail.id]: selectedFiles
        });
    }

    const handleHazardObservationMediaFileListItemAddOrRemove = (event) => {
        // ---------------------------------------------
        const { name, value } = event.target;
        let eventType = 'ADD';

        if (!isDefinedAndInitialized(name)) {
            throw new Error('Name must be a string');
        }

        if (!isDefinedAndInitialized(value)) {
            // ----------------------------------------------
            eventType = 'DELETE';
        }

        if (eventType === 'DELETE') {
            // ------------------------------
            const [prop, id] = name.split('_');
            if (isDefinedAndInitialized(prop) && prop.includes('hazardObservationMedia')) {
                // ---------------------------------------
                let hazardObservationMediaUpdate = workAndSafety.hazardObservationMedia.filter(hazardMedia => hazardMedia.id !== id);
                handleClientObservationStateUpdate(event, componentData.componentType, componentData.componentId, {
                    ...workAndSafety,
                    hazardObservationMedia: hazardObservationMediaUpdate
                })
            }
            else {
                // ---------------------------------------
                if (!isDefinedAndInitialized(prop)
                    || !isDefinedAndInitialized(id)
                    || !hasOwnProperty(selectedHazardObservationMedia, prop)) {
                    throw new Error('List item name could not be parsed');
                }

                let propUpdate = {};
                Object.keys(selectedHazardObservationMedia).map(key => {
                    propUpdate = {
                        ...propUpdate,
                        [key]: selectedHazardObservationMedia[key].filter(item => item.id !== id)
                    }
                });
                setSelectedHazardObservationMedia(propUpdate);
            }
        }
        else {
            // ------------------------------
            console.error('No ADD action implemented for the files feature');
        }
    }

    const handleHazardObservationMediaFileItemStateChange = (event) => {
        // ---------------------------------------------
        const { name, value } = event.target;

        let setValue = value;
        if ('type' in event.target && event.target.type === 'checkbox') {
            setValue = event.target.checked;
        }

        if (!isDefinedAndInitialized(name)) {
            throw new Error('Name must be a string');
        }

        const [prop, id, subProp] = name.split('_');


        if (isDefinedAndInitialized(prop) && prop.includes('hazardObservationMedia')) {
            // ---------------------------------------
            let { hazardObservationMedia } = workAndSafety;
            let stateUpdate = {
                ...workAndSafety,
                hazardObservationMedia: hazardObservationMedia.map(item => {
                    // ---------------------------------
                    if (item.id === id) {
                        return {
                            ...item,
                            [subProp]: setValue
                        }
                    }
                    else {
                        return item;
                    }
                })
            }
            handleClientObservationStateUpdate(event, componentData.componentType, componentData.componentId, stateUpdate);
        }
        else {
            // ---------------------------------------
            if (!isDefinedAndInitialized(prop)
                || !isDefinedAndInitialized(id)
                || !isDefinedAndInitialized(subProp)
                || !hasOwnProperty(selectedHazardObservationMedia, prop)) {
                // --------------------------------------------
                throw new Error('List item name could not be parsed');
            }
            let propUpdate = {};
            Object.keys(selectedHazardObservationMedia).map(key => {
                propUpdate = {
                    ...propUpdate,
                    [key]: selectedHazardObservationMedia[key].map(item => {
                        // ---------------------------------
                        if (item.id === id) {
                            const { hazardId, name } = item;
                            return {
                                ...item,
                                [subProp]: setValue,
                                path: `${window.config.ENV}-work-and-safety/${jobId}/prestart/hazard-observation/${hazardId}/hazard-observation-media-${item.id}/${s3ReplaceUnsupportedChars(`${name}`)}`,
                            }
                        }
                        else {
                            return item;
                        }
                    })
                }
            });
            setSelectedHazardObservationMedia(propUpdate);
        }
    }

    const handleHazardObservationMediaFileUpload = async () => {
        // ---------------------------------------------
        let numberOfFiles = 0;
        for (const key of Object.keys(selectedHazardObservationMedia)) {
            if (selectedHazardObservationMedia[key].filter(selectedFile => !isDefinedAndInitialized(selectedFile.file)).length !== 0) {
                // -------------------------------------------------
                throw new Error('Some file content is missing prior to the upload, please contact support');
            }
            numberOfFiles += selectedHazardObservationMedia[key].length;
        }

        let successfulUploads = 0;
        let fileUploadsUpdate = [];

        for (const key of Object.keys(selectedHazardObservationMedia)) {
            for (const hazardObservationMedia of selectedHazardObservationMedia[key]) {
                try {
                    enqueueSnackbar(
                        `Starting ${numberOfFiles} upload${numberOfFiles === 1 ? '' : 's'}`,
                        { variant: 'info', autoHideDuration: snackBarAutoHide, anchorOrigin: anchorSnackbar }
                    );
                
                    await uploadNodeFileToS3(hazardObservationMedia, { instance, accounts, inProgress });
                    successfulUploads += 1;
                    let fileUploadUpdate = {
                        ...hazardObservationMedia,
                        uploadStatus: "SUCCESS"
                    };
                    delete fileUploadUpdate.file;
                    fileUploadsUpdate.push(fileUploadUpdate)
                    enqueueSnackbar(
                        `${successfulUploads}/${numberOfFiles} successful uploads`, 
                        { variant: 'success', autoHideDuration: snackBarAutoHide, anchorOrigin: anchorSnackbar }
                    );
                }
                catch (err) {
                    console.error(err);
                    let fileUploadUpdate = {
                        ...hazardObservationMedia,
                        uploadStatus: "FAILED"
                    };
                    delete fileUploadUpdate.file;
                    fileUploadsUpdate.push(fileUploadUpdate)
                    enqueueSnackbar(
                        `Failed to upload ${hazardObservationMedia.name}. Please contact support`, 
                        { variant: 'error', autoHideDuration: snackBarAutoHide, anchorOrigin: anchorSnackbar }
                    );
                }
            }
        }

        handleBasicStateChange({ target: { name: 'hazardObservationMedia', value: [...fileUploadsUpdate, ...workAndSafety.hazardObservationMedia] } });
        setSelectedHazardObservationMedia({});
        // Save the observation for simplicity
        await saveHandler(null, false, false, null, {
            ...state?.observationPage?.workAndSafety,
            hazardObservationMedia: [...fileUploadsUpdate, ...workAndSafety.hazardObservationMedia]
        });
    }

    const handleHazardVisibilityToggle = (e) => {
        // ------------------------------------------
        const nameSplit = e.target.name.split('_');
        const id = nameSplit[2];
        

        const hasHazardObservationDisplayIds = ('hazardObservationDisplayIds' in observationEdit.header 
        && isDefinedAndInitialized(observationEdit.header.hazardObservationDisplayIds));
        const { hazardObservationDisplayIds } =  hasHazardObservationDisplayIds ? observationEdit.header : { hazardObservationDisplayIds: [] };

        // Cleanup (eliminates potential for duplicates)
        let selectedHazardsUpdate = hazardObservationDisplayIds.filter(hazardId => hazardId !== id);

        // Only add the hazard-id back in IF event is a 'checked' event
        if (e.target.checked) {
            // ---------------------
            selectedHazardsUpdate = [ ...selectedHazardsUpdate, id ];
        }

        handleClientObservationStateUpdate(e, `workAndSafetyV1Presentation`, null, selectedHazardsUpdate);
    }


    // // HANDLE ERROR STATE
    if (isObsDetailDataError) {
        return <span>An unexpected error has occurred with the observation data {obsDetailDataError}</span>
    }
    if (isHsPlanListError) {
        return <span>An unexpected error has occurred with the work and safety data {hsPlanListError}</span>
    }
    if (isStaffListError) {
        return <span>An unexpected error has occurred with the work and safety staff data {staffListError}</span>
    }
    if (isHsPlanHighlightedHazardListError) {
        return <span>An unexpected error has occurred with the work and safety data {hsPlanHighlightedHazardListError}</span>
    }

    // DERIVED STATE
    // ------------------
    // OBSERVATION DETAIL
    // ------------------
    const observation = !isObsDetailLoading ? obsDetailData.data : null;
    // const { jobId: jobNumber, jobName, inspectionTimestamp: observationTimestamp, updateTimestamp } = observation.header;
    const componentParentStyles = getTake5ComponentStyling({ hsPlanListLoading: isHsPlanListLoading, hsPlanListData, isObsDetailLoading, observation: observationEdit });

    const planSelected = isDefinedAndInitialized(observationEdit.header.wsPlanId);
    const hasPrestartDetail = isDefinedAndInitialized(workAndSafety);


    return (
        <Box marginY="5px">
            <Paper>
                <Box display="flex" flexDirection="row" alignItems="center">
                    <Box display="flex" flexDirection="row" alignItems="center"
                        width="100%" style={{ ...componentParentStyles.take5ParentComponentStyling }}>
                        <Box display="flex" flexDirection="column" width="100%" >
                            <Box display="flex" flexDirection="row" alignItems="center" width="100%">
                                <Box flexGrow={1} display="flex" flexDirection="row" alignItems="center">
                                    <Box marginRight="10px" display="flex" flexDirection="row" alignItems="center">
                                        <Tooltip title="Work and Safety">
                                            <IconButton
                                                target="_blank"
                                                href={`https://${(window.config.ENV === 'prod') ? '' : 'sandpit.'}work-and-safety.initia-digital.co.nz`}
                                                size="small"
                                            >
                                                <Avatar style={{ backgroundColor: '#eb345e' }}>
                                                    <span style={{ fontSize: '17px', fontWeight: '800' }} className={classes.hsText}>H+S</span>
                                                </Avatar>
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                    <Box className={classes.informationTitle} display="flex" flexDirection="row" alignItems="center">
                                        {
                                            setHeadingCount &&
                                            <Typography style={{ fontSize: '1.2rem', fontWeight: 'bold', paddingRight: '8px' }}>
                                                { setHeadingCount.toString().padStart(2, '0') }
                                            </Typography>
                                        }
                                        <Typography>
                                            WORK AND SAFETY
                                        </Typography>
                                    </Box>
                                </Box>
                                <OrderArrows componentId={componentId} componentUpHandler={componentUpHandler} />
                            </Box>
                            {
                                isObsDetailLoading &&
                                <Box width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center" >
                                    Loading Observation Data
                                    <CircularProgress />
                                </Box>
                            }
                            {
                                isHsPlanListLoading &&
                                <Box width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center" >
                                    Loading Work and Safety Data
                                    <CircularProgress />
                                </Box>
                            }
                            {
                                !isHsPlanListLoading && isDefinedAndInitialized(hsPlanListData) && hsPlanListData.length === 0
                                && !isHsPlanListLoading && isDefinedAndInitialized(obsDetailData) &&
                                <Box style={{ paddingTop: '5px', paddingBottom: '5px', paddingLeft: isSmallerThanXs ? "20px" : "80px", paddingRight: '50px' }}>
                                    {
                                        !isHsPlanListDataFetching &&
                                        <>
                                            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="flex-start">
                                                <Box display="flex" flexDirection={isSmallerThanSm ? "column" : "row"} alignItems={isSmallerThanSm ? "flex-start" : "center"} justifyContent="flex-start">
                                                    <Box flexGrow={3} display="flex" flexDirection="row" alignItems="center" justifyContent="center" marginRight="15px" >
                                                        <Typography style={{ fontSize: isSmallerThanXs ? '0.9rem' : '1rem' }}>
                                                            <strong>
                                                                No plans have been created by the team on this job
                                                            </strong>
                                                        </Typography>
                                                        <PriorityHigh style={{ fontSize: '1.2rem', color: '#eb345e' }} />
                                                    </Box>
                                                    <Box marginY="10px" marginLeft={isSmallerThanSm ? "20px" : "0px"} display="flex" flexDirection="row" alignItems="center">
                                                        <Button
                                                            variant="contained" color="primary"
                                                            target="_blank"
                                                            href={`https://${(window.config.ENV === 'prod') ? '' : 'sandpit.'}work-and-safety.initia-digital.co.nz/job/${observation.header.jobId}/plan`}
                                                            endIcon={<OpenInNew />}
                                                        >
                                                            Create plan
                                                        </Button>
                                                    </Box>
                                                </Box>
                                                <Box justifySelf="flex-end" flexGrow={1} display="flex" justifyContent="flex-end" alignItems="flex-start">
                                                    <Tooltip title="Refresh Work and Safety data">
                                                        <IconButton
                                                            onClick={handleWorkAndSafetyRefresh}
                                                            size="small"
                                                        >
                                                            <Refresh />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>
                                            </Box>
                                        </>
                                    }
                                    {
                                        (isHsPlanListDataFetching || isHsPlanHighlightedHazardListDataFetching) &&
                                        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" marginY="20px">
                                            <Box  marginRight="10px" height="100%" display="flex" flexDirection="row" alignItems="center">
                                                <CircularProgress size={15} />
                                            </Box>
                                            <Typography>Refreshing Work and Safety data</Typography>
                                        </Box>
                                    }
                                </Box>
                            }
                            {
                                !isHsPlanListLoading && isDefinedAndInitialized(hsPlanListData) && hsPlanListData.length > 0
                                && !isHsPlanListLoading && isDefinedAndInitialized(obsDetailData) &&
                                <Box style={{ paddingTop: '5px', paddingBottom: '5px', paddingLeft: isSmallerThanXs ? "20px" : "80px", paddingRight: "50px" }}>
                                    {
                                        !isHsPlanListDataFetching &&
                                        <>
                                            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="flex-start">
                                                <Box display="flex" flexDirection={isSmallerThanSm ? "column" : "row"} alignItems={isSmallerThanSm ? "flex-start" : "center"} justifyContent="flex-start">
                                                    <Box flexGrow={3}>
                                                        {
                                                            !planSelected &&
                                                            <Typography style={{ fontSize: isSmallerThanXs ? '0.9rem' : '1rem', marginBottom: '3px' }}>
                                                                <strong style={{ color: '#f9a825' }}>Link a plan</strong>&nbsp;from&nbsp;<strong style={{ color: '#f9a825' }}>{`${jobId.replace(/-/g, "\u2011")}`}</strong>&nbsp;to this site observation
                                                            </Typography>
                                                        }
                                                        {
                                                            planSelected &&
                                                            <Typography style={{ fontSize: isSmallerThanXs ? '0.9rem' : '1rem', marginBottom: '3px' }}>
                                                                <strong style={{ color: '#00c853' }}>You have linked a plan</strong>&nbsp;to this site observation
                                                            </Typography>
                                                        }
                                                    </Box>
                                                </Box>
                                                <Box justifySelf="flex-end" flexGrow={1} display="flex" justifyContent="flex-end" alignItems="flex-start">
                                                    <Tooltip title="Refresh Work and Safety data">
                                                        <IconButton
                                                            onClick={handleWorkAndSafetyRefresh}
                                                            size="small"
                                                        >
                                                            <Refresh />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>
                                            </Box>
                                            <Box display="flex" flexDirection="column">
                                                {
                                                    isStaffListLoading &&
                                                    <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                                                        <Box marginRight="10px" height="100%" display="flex" flexDirection="row" alignItems="center">
                                                            <CircularProgress size={15} />
                                                        </Box>
                                                        <Typography>Loading staff list...</Typography>
                                                    </Box>
                                                }
                                                {
                                                    !isStaffListLoading &&
                                                    <List>
                                                        {
                                                            hsPlanListData.map((item, idx) => {
                                                                // -----------------------   
                                                                const { jobId, id } = item;
                                                                return (
                                                                    <Box key={`plan-${idx}`} display="flex" flexDirection="column" marginBottom="10px">
                                                                        <Box alignSelf="flex-end">
                                                                            <ButtonGroup color="primary" aria-label="outlined primary button group">
                                                                                <Button
                                                                                    variant="contained"
                                                                                    target="_blank"
                                                                                    href={`https://${(window.config.ENV === 'prod') ? '' : 'sandpit.'}work-and-safety.initia-digital.co.nz/job/${jobId}/edit-plan/${id}?stage=action`}
                                                                                >
                                                                                    View Plan
                                                                                </Button>
                                                                            </ButtonGroup>
                                                                        </Box>
                                                                        <WSPlanListItem
                                                                            plan={item}
                                                                            planSequenceNumber={idx + 1}
                                                                            staffList={staffListData.value}
                                                                            componentData={componentData}
                                                                            handleClientObservationStateUpdate={handleClientObservationStateUpdate}
                                                                        />
                                                                    </Box>
                                                                )
                                                            })
                                                        }
                                                    </List>
                                                }
                                                {
                                                    !planSelected &&
                                                    <Box display="flex" flexDirection={isSmallerThanSm ? "column" : "row"} alignItems={isSmallerThanSm ? "flex-start" : "center"} justifyContent="flex-start">
                                                        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" marginRight="15px" >
                                                            <Typography style={{ fontSize: isSmallerThanXs ? '0.9rem' : '1rem' }}>
                                                                <strong>
                                                                    OR&nbsp;
                                                                </strong>
                                                            </Typography>
                                                        </Box>
                                                        <Box marginY="10px" marginLeft={isSmallerThanSm ? "20px" : "0px"} display="flex" flexDirection="row" alignItems="center">
                                                            <Button
                                                                variant="contained" color="primary"
                                                                target="_blank"
                                                                href={`https://${(window.config.ENV === 'prod') ? '' : 'sandpit.'}work-and-safety.initia-digital.co.nz/job/${observation.header.jobId}/plan`}
                                                                endIcon={<OpenInNew />}
                                                            >
                                                                Create plan
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                }
                                            </Box>
                                        </>
                                    }
                                    {
                                        isHsPlanListDataFetching &&
                                        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" marginY="20px">
                                            <Box marginRight="10px" height="100%" display="flex" flexDirection="row" alignItems="center">
                                                <CircularProgress size={15} />
                                            </Box>
                                            <Typography>Refreshing job work and safety data</Typography>
                                        </Box>
                                    }
                                    {
                                        planSelected &&
                                        !isHsPlanHighlightedHazardListLoading && isDefinedAndInitialized(hsPlanHighlightedHazardListData) &&
                                        <Box marginY="10px">
                                            <Box marginBottom="5px">
                                                <Typography fontSize="1rem">
                                                    <strong>HIGHLIGHTED HAZARDS (from linked plan)</strong>
                                                </Typography>
                                            </Box>
                                            {
                                                isHsPlanListDataFetching &&
                                                <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" marginY="20px">
                                                    <Box marginRight="10px" height="100%" display="flex" flexDirection="row" alignItems="center">
                                                        <CircularProgress size={15} />
                                                    </Box>
                                                    <Typography>Refreshing work and safety plan highlighted hazards</Typography>
                                                </Box>
                                            }
                                            {
                                                hsPlanHighlightedHazardListData.length === 0 &&
                                                <Box display="flex" flexDirection="column">
                                                    <Typography style={{ fontSize: '0.9rem', paddingLeft: theme.spacing(), paddingRight: theme.spacing() }}>There are no highlighted hazards for this plan. Please refer to the plan for hazards from the register</Typography>
                                                </Box>
                                            }
                                            {
                                                hsPlanHighlightedHazardListData.length > 0 &&
                                                hsPlanHighlightedHazardListData.map((item, idx) => {
                                                    // -----------------------------------------
                                                    const { id: hazardId } = item;

                                                    return (
                                                        <WSHazardItem
                                                            key={`highlighted-hazard-${idx}`}
                                                            idx={idx} hazard={item}
                                                            type="CRITICAL"
                                                            isSelected={hazardId === selectedHazardId}
                                                            handleSetSelected={handleHazardSelection}
                                                        />
                                                    )
                                                })
                                            }
                                        </Box>
                                    }
                                    {
                                        planSelected &&
                                        <Box marginY="20px">
                                            <Box marginBottom="5px">
                                                <Typography fontSize="1rem">
                                                    <strong>NEW HAZARDS</strong>
                                                </Typography>
                                            </Box>
                                            <Box style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                                                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                                                    <Typography fontSize={"1rem"}><strong>{'Step back from the work area, note any new or changed hazards you observe and their controls'}</strong></Typography>
                                                </Box>
                                            </Box>
                                            <WSInputCriticalAndSiteSpecificHazardList
                                                name={`newHazardsAndControls`}
                                                label={`New hazards`}
                                                buttonText={`New hazard`}
                                                allowMedia={true}
                                                includeToggle={true}
                                                criticalHazardList={hasPrestartDetail ? workAndSafety.newHazardsAndControls : []}
                                                handleListItemAddOrRemove={handleListItemAddOrRemove}
                                                handleListItemStateChange={handleListItemStateChange}
                                                handleNestedListItemStateChange={handleNestedListItemStateChange}
                                                handleNestedListItemAddOrRemove={handleNestedListItemAddOrRemove}
                                                uploadedFiles={hasPrestartDetail ? workAndSafety.hazardObservationMedia : []}
                                                selectedFiles={selectedHazardObservationMedia}
                                                handleFileSelection={handleHazardObservationMediaFileSelection}
                                                handleFileListItemAddOrRemove={handleHazardObservationMediaFileListItemAddOrRemove}
                                                handleFileItemStateChange={handleHazardObservationMediaFileItemStateChange}
                                                handleFileUpload={handleHazardObservationMediaFileUpload}
                                                newItemTemplate={
                                                    {
                                                        type: 'NEW_HAZARD',
                                                        hazardName: '',
                                                        prestartId: null,
                                                        description: '',
                                                        hazardControls: [],
                                                        jobId,
                                                        planId: observationEdit.header.wsPlanId,
                                                        prestartTimestamp: null,
                                                        planStartDate: null,
                                                        tags: []
                                                    }
                                                }
                                                handleToggle={handleHazardVisibilityToggle}
                                            />
                                        </Box>
                                    }
                                    {
                                        planSelected &&
                                        <Box marginY="10px">
                                            <Box marginBottom="5px">
                                                <Typography fontSize="1rem">
                                                    <strong>OVERLAPPING DUTIES</strong>
                                                </Typography>
                                            </Box>
                                            <Box px="10px" mb="20px">
                                                <LabelledTextField
                                                    value={hasPrestartDetail ? workAndSafety.teamWorkDetail || '' : ''}
                                                    name={`teamWorkDetail`}
                                                    label={'Description of overlapping duties and how you will work together with any others'}
                                                    minRows={3}
                                                    placeholder={`Describe any overlapping duties and record how you will work together`}
                                                    handleChange={event => handleBasicStateChange({
                                                        target: {
                                                            name: 'teamWorkDetail',
                                                            value: event.target.value
                                                        }
                                                    })}
                                                />
                                            </Box>
                                        </Box>
                                    }
                                    {
                                        planSelected &&
                                        <Grid container>
                                        {
                                            !isSmallerThanXs &&
                                            <Grid item sm={3} md={4}/>
                                        }
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Box marginBottom="15px">
                                                <BasicIconTextButton startIcon={<Save />} color={theme.palette.secondary.contrastText} backgroundColor={theme.palette.secondary.main}
                                                    onClick={saveHandler}
                                                >
                                                    Save
                                                </BasicIconTextButton>
                                            </Box>
                                        </Grid>
                                        {
                                            !isSmallerThanXs &&
                                            <Grid item sm={3} md={4}/>
                                        }
                                        </Grid>
                                    }
                                </Box>
                            }
                        </Box>
                    </Box>
                </Box>
            </Paper>
        </Box>
    )
}

Take5Component.propTypes = {
    classes: PropTypes.object,
    componentId: PropTypes.string,
    componentData: PropTypes.object,
    componentUpHandler: PropTypes.func,
    saveHandler: PropTypes.func,
    handleClientObservationStateUpdate: PropTypes.func,
    headingCount: PropTypes.number
};

export const WSTake5Comonent = withRouter(withStyles(styles)(Take5Component));