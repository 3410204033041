import React, { useEffect, useState } from 'react';
import { withRouter, Link, useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

// REACT-QUERY
import {
  useQuery,
  useMutation,
  useQueryClient
} from 'react-query';

// MSAL imports
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";

// Material UI
import {
  Paper, Box, Grid, FormControlLabel, Switch, CircularProgress,
  Typography, IconButton, ButtonGroup, Button, Tooltip,
  Dialog, DialogActions, DialogContent, DialogTitle,
  TextField, FormHelperText, FormControl, List, ListItem,
  Select, MenuItem, InputLabel
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, withStyles } from '@material-ui/core/styles';

// Material Icons
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HelpIcon from '@material-ui/icons/Help';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import { DateTime } from 'luxon';
import uuidv4 from 'uuid';

// Custom Helpers
import { isDefinedAndInitialized, formatValueAsDefinedStringOrNull } from '../helpers/helpers';

// Custom Services
import {
  searchNcr, createNcr, batchCreateNcr, batchDeleteNcr, getNcrHistoryDetail,
  putNcrUpdate, deleteNcrUpdate, pollForLargerNumberOfObservationNcrs,
  pollForLowerNumberOfObservationNcrs, pollForNcrUpdateResult, getObservationDetail, pollForObservationUpdateTimestampChange
} from '../services/api';

// Custom Config
import { config } from '../config/generalConfig';

// Custom Components
import OrderArrows from './orderArrows';
import { ConditionalWrapper } from './ConditionalWrapper';
import { useSnackbar } from 'notistack';

const snackBarAutoHide = 2000;

const styles = (theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    borderTop: "1px solid rgba(224, 224, 224, 1)",
    marginTop: "0px",
  }
});

// CONSTANTS
const INITIAL_NCR_CREATE_TEMPLATE = {
  ncrId: null, ncrNumber: null, ncrReference: null, ncrType: null,
  jobId: null, jobName: null, jobNumber: null,
  observationId: null, observationTimestamp: null,
  creationTimestamp: null,
  openedBy: null, openedTimestamp: null, observationIdOpened: null,
  closedBy: null, closedTimestamp: null, observationIdClosed: null,
  show: false, status: null,
  issueDescription: null, remedyDescription: null, remediationCompletionDetail: null,
  error: null
}

/**
 * isNcrComponentActive - assesses whether the NCR component should be active based on the componentData for a given observation
 *
 * @param {object} componentData - An object representing all of the components for a given observation
 * @return {boolean} whether the NCR component should be active
 *
 * @example
 *
 *     isNcrComponentActive(componentData)
 */
const isNcrComponentActive = (observation) => {
  // -------------------------------------------
  const { ncrActive } = observation.header;
  return isDefinedAndInitialized(ncrActive) && ncrActive;
}

// Returns a colour palette for a given NCR item
const getNcrColorPalette = (status, show) => {
  // -------------------------------------------
  if (!show) {
    return {
      color: "#dedede",
      icon: <VisibilityOffIcon />
    }
  }
  switch (status) {
    case 'OPEN':
      return {
        color: "#f44336",
        icon: <WarningIcon />
      }
    case 'CLOSED':
      return {
        color: '#4caf50',
        icon: <CheckCircleIcon />
      }
    default:
      return {
        color: '#dedede',
        icon: <HelpIcon />
      }
  }
}


const NonConformanceRemediationDetail = (props) => {
  // -------------------------------------------
  const { ncrItem } = props;
  const { ncrUpdateId, status, remedyDescription, remediationCompletionDetail, observationId } = ncrItem;

  // USE MSAL HOOK
  const { instance, accounts, inProgress } = useMsal();
  const loginHint = (accounts && accounts[0]?.username) ?? '';
  const request = {
    loginHint,
    scopes: ["User.Read"]
  }
  const isAuthenticated = useIsAuthenticated();
  const isAuthed = isAuthenticated && inProgress === "none" && isDefinedAndInitialized(accounts) && accounts.length > 0 && isDefinedAndInitialized(accounts[0]) && isDefinedAndInitialized(accounts[0].username);

  // REACT QUERY
  const { isLoading: isNcrUpdateHistoryDataLoading, isError: isNcrUpdateHistoryDataError, data: ncrUpdateHistoryData, error: ncrUpdateHistoryDataError, isRefetching: isNcrUpdateHistoryDataRefetching } = useQuery(
    ['ncrHistory', ncrUpdateId],
    () => getNcrHistoryDetail(ncrUpdateId, { instance, accounts, inProgress }),
    {
      enabled: isAuthed && isDefinedAndInitialized(ncrUpdateId),
      refetchOnWindowFocus: false
    }
  );

  useEffect(async () => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      await instance.loginRedirect(request);
    }
  }, [isAuthenticated, inProgress, instance]);


  // HANDLE LOADING STATES
  // MSAL
  if (
    !isAuthed
  ) {
    return (
      <Box width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center" >
        <CircularProgress />
      </Box>
    )
  }
  if (isNcrUpdateHistoryDataLoading || isNcrUpdateHistoryDataRefetching) {
    return (
      <Box width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center" >
        Loading NCR Item history
        <CircularProgress />
      </Box>
    )
  }

  // HANDLE ERROR STATE
  if (isNcrUpdateHistoryDataError) {
    return <span>An unexpected error has occurred with the job data {ncrUpdateHistoryDataError}</span>
  }


  return (
    <>
      <Box alignSelf="center" >
        <Typography variant="button" style={{ fontSize: '0.7rem', fontWeight: 600 }} >
          Remedial Work Required
        </Typography>
        <Typography variant="body2" >
          {(isDefinedAndInitialized(remedyDescription)) ? remedyDescription : 'Remediation detail pending'}
        </Typography>
      </Box>
      <Box alignSelf="center" mt={2}>
        <Typography variant="button" style={{ fontSize: '0.7rem', fontWeight: 600 }} >
          Notes on Remedial Work Completed {(status === 'OPEN') ? 'To Date' : ''}
        </Typography>
        <Typography variant="body2" >
          {(isDefinedAndInitialized(remediationCompletionDetail)) ? remediationCompletionDetail : '-'}
        </Typography>
      </Box>
      <Box alignSelf="center" mt={2}>
        <Typography variant="button" style={{ fontSize: '0.7rem', fontWeight: 600 }} >
          NCR Timeline and Remedial Progress
        </Typography>
        {
          ncrUpdateHistoryData && ncrUpdateHistoryData?.length === 0 &&
          <Typography variant="body2" >No timeline updates found</Typography>
        }
        {
          ncrUpdateHistoryData && isDefinedAndInitialized(ncrUpdateHistoryData?.length) && ncrUpdateHistoryData.length > 0 &&
          <List>
            {
              ncrUpdateHistoryData.sort((timelineItemA, timelineItemB) => timelineItemB.observationTimestamp - timelineItemA.observationTimestamp).map(timelineItem => {
                const statusPalette = getNcrColorPalette(timelineItem.status, timelineItem.show);

                return (
                  <Link key={`${timelineItem.ncrUpdateId}-list-item`} to={`/observation/${timelineItem.observationId}`} style={{ textDecoration: 'none', color: 'black' }}>
                    <ListItem button >
                      <Box display="flex" justifyContent="space-between" bgcolor={(observationId === timelineItem.observationId) ? '#e3f2fd' : 'white'} p={2} >
                        <Box display="flex" flexDirection="column" alignItems="center">
                          <Box alignItems="center" component="span" color={statusPalette.color}>{statusPalette.icon}</Box>
                        </Box>
                        <Box ml={3}>
                          <Box color={statusPalette.color}>
                            <Typography variant="button">{timelineItem.status}</Typography>
                          </Box>
                          <Box>
                            <Typography variant="body2">{`${timelineItem.updatedBy} ${DateTime.fromSeconds(timelineItem.observationTimestamp).toFormat('dd/MM/yyyy hh:mm a')}`}</Typography>
                          </Box>
                          <Box>
                            <Typography variant="caption">{`${isDefinedAndInitialized(timelineItem.remediationCompletionDetail) ? timelineItem.remediationCompletionDetail : '-'}`}</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </ListItem>
                  </Link>
                )
              })}
          </List>
        }

      </Box>
    </>
  )
}

NonConformanceRemediationDetail.propTypes = {
  ncrItem: PropTypes.object,
};


const NonConformanceItem = (props) => {
  // -------------------------------------------
  // PROPS
  const { ncrItem, observation, ncrItems } = props;
  const { ncrUpdateId, ncrId, observationIdOpened, observationIdClosed, status, ncrReference, ncrNumber, issueDescription,
    show, ncrHeadTimestamp, observationId } = ncrItem;

  // DERIVED PROPS
  const ncrStyles = getNcrColorPalette(status, show);
  const isOpen = (status !== 'CLOSED');
  const timestampLabel = (isOpen) ? 'Opened:' : 'Closed: ';
  const timestampProp = (isOpen) ? 'openedTimestamp' : 'closedTimestamp';
  const observationLinkId = (isOpen) ? observationIdOpened : observationIdClosed;
  const disableLink = observation.inspectionId === observationLinkId;
  const jobNumber = observation.header.jobId;
  const tooltipTitle = (disableLink) ? 'This observation has the latest status change' : 'Go to observation with last status change';
  const canUpdateStatus = (show && DateTime.fromISO(observation.header.inspectionTimestamp).toSeconds() >= ncrHeadTimestamp);
  const canDelete = (show && observationIdOpened === observation.inspectionId && (DateTime.fromISO(observation.header.inspectionTimestamp).toSeconds() >= ncrHeadTimestamp));

  // USE STATE HOOKS
  const [isExpanded, setIsExpanded] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [ncrPayloadState, setNcrPayloadState] = useState(null);

  // MUI HOOKS
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // NOTISTACK
  const { enqueueSnackbar } = useSnackbar();

  // USE MSAL HOOK
  const { instance, accounts, inProgress } = useMsal();
  const loginHint = (accounts && accounts[0]?.username) ?? '';
  const request = {
    loginHint,
    scopes: ["User.Read"]
  }
  const isAuthenticated = useIsAuthenticated();
  const isAuthed = isAuthenticated && inProgress === "none" && isDefinedAndInitialized(accounts) && accounts.length > 0 && isDefinedAndInitialized(accounts[0]) && isDefinedAndInitialized(accounts[0].username);
  const personName = accounts[0]?.idTokenClaims?.name ?? '';

  // REACT QUERY
  // - Query client used for invalidation of queries
  const queryClient = useQueryClient();

  // MUTATIONS
  const updateNcrItem = useMutation(async (ncrItemUpdate) => {
    // --------------------------------------------
    await putNcrUpdate(ncrUpdateId, ncrItemUpdate, { instance, accounts, inProgress });
    await pollForNcrUpdateResult(observationId, JSON.stringify(ncrItems), { instance, accounts, inProgress });
  },
    {
      onSuccess: () => {
        enqueueSnackbar('NCR updated successfully', { variant: 'success', autoHideDuration: snackBarAutoHide });
        queryClient.invalidateQueries(['observation', observationId, 'ncr']);
        setEditDialogOpen(false);
      },
      onError: () => {
        enqueueSnackbar(`NCR couldn't be updated - please contact support with detail of what happened before this error`, { variant: 'error', autoHideDuration: snackBarAutoHide });

      }
    });

  const deleteNcrItem = useMutation(async () => {
    // --------------------------------------------
    await deleteNcrUpdate(ncrUpdateId, { instance, accounts, inProgress });
    await pollForNcrUpdateResult(observationId, JSON.stringify(ncrItems), { instance, accounts, inProgress });
  },
    {
      onSuccess: () => {
        enqueueSnackbar('NCR deleted successfully', { variant: 'success', autoHideDuration: snackBarAutoHide });
        queryClient.invalidateQueries(['observation', observationId, 'ncr']);
      },
      onError: () => {
        enqueueSnackbar(`NCR couldn't be deleted - please contact support with detail of what happened before this error`, { variant: 'error', autoHideDuration: snackBarAutoHide });

      }
    });
  // EFFECTS
  useEffect(async () => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      await instance.loginRedirect(request);
    }
  }, [isAuthenticated, inProgress, instance]);

  // Set initial form state
  useEffect(() => {
    if (isDefinedAndInitialized(ncrItem)) {
      // ----------------------------
      setNcrPayloadState(ncrItem)
    }
  }, [JSON.stringify(ncrItem)]);

  const isMutating = updateNcrItem.isLoading || deleteNcrItem.isLoading;

  // EVENT HANDLERS
  const handleClickOpen = () => {
    setEditDialogOpen(true);
  };

  const handleClose = () => {
    setEditDialogOpen(false);
  };

  const handleUpdate = async () => {
    // ----------------------
    if (isAuthed) {
      // -----------------------------------------
      updateNcrItem.mutate(ncrPayloadState)
    }
    else {
      // -------------------------------
      console.info('Not authenticated for this API action');
    }
  }

  const handleNcrUpdateChange = (e) => {
    // ----------------------
    let updatePayload = {
      ...ncrPayloadState,
      updatedBy: personName,
      [e.target.name]: e.target.value
    }

    // Additional config required if status being updated to closed
    if (e.target.name === 'status') {
      // ----------------------------------------
      updatePayload = {
        ...updatePayload,
        closedBy: (e.target.value === 'CLOSED') ? personName : null,
        closedTimestamp: (e.target.value === 'CLOSED') ? DateTime.fromISO(observation.header.inspectionTimestamp).toSeconds() : null,
        observationIdClosed: (e.target.value === 'CLOSED') ? observation.inspectionId : null
      }
    }

    setNcrPayloadState(updatePayload);
  }

  const handleStatusUpdate = async () => {
    // ----------------------
    if (isAuthed) {
      // ---------------------------------
      const ncrUpdatePayload = {
        ...ncrPayloadState,
        status: (status === 'OPEN') ? 'CLOSED' : 'OPEN',
        closedBy: (status === 'OPEN') ? personName : null,
        closedTimestamp: (status === 'OPEN') ? DateTime.fromISO(observation.header.inspectionTimestamp).toSeconds() : null,
        observationIdClosed: (status === 'OPEN') ? observation.inspectionId : null
      };

      updateNcrItem.mutate(ncrUpdatePayload)
    }
    else {
      // -------------------------------
      console.info('Not authenticated for this API action');
    }
  }

  const handleShowToggle = async () => {
    // ----------------------------------------
    if (isAuthed) {
      // --------------------------------------
      const ncrUpdatePayload = {
        ...ncrPayloadState,
        show: !show
      };

      updateNcrItem.mutate(ncrUpdatePayload);
    }
    else {
      // -------------------------------
      console.info('Not authenticated for this API action');
    }
  }

  const handleDelete = async () => {
    // ------------------------------
    if (isAuthed) {
      // --------------------------------
      deleteNcrItem.mutate();
    }
    else {
      // -------------------------------
      console.info('Not authenticated for this API action');
    }
  }

  // LOADING STATES
  if (!isDefinedAndInitialized(ncrPayloadState)) {
    // ---------------------------------
    return (
      <Box width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center" >
        Loading NCR Item
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Grid item xs={12} md={6}>
      <Box p={1} m={1}
        border={1}
        borderRadius={5}
        borderColor={ncrStyles.color}
        display="flex"
        flexDirection="column" >
        {
          updateNcrItem.isLoading &&
          <Box width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center" >
            Updating NCR Item
            <CircularProgress />
          </Box>
        }
        {
          deleteNcrItem.isLoading &&
          <Box width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center" >
            Deleting NCR Item
            <CircularProgress />
          </Box>
        }
        {
          !updateNcrItem.isLoading && !deleteNcrItem.isLoading &&
          <>
            <Box display="flex" flexDirection="row" justifyContent="space-between" alignSelf="flex-end" width="100%" >
              <ConditionalWrapper
                wrapper={children => <Tooltip title={tooltipTitle}>{children}</Tooltip>}
                condition={!disableLink}
              >
                <Button size="small" disabled={disableLink}>
                  <Box ml="2px" color={ncrStyles.color} display="flex" flexDirection="row" >
                    <Box>
                      {ncrStyles.icon}
                    </Box>
                    <Link to={`/observation/${observationLinkId}`} style={{ textDecoration: 'none' }}>
                      <Box ml="5px">
                        <Typography variant="caption">
                          {timestampLabel} {DateTime.fromSeconds(ncrItem[timestampProp]).toFormat('dd/MM/yyyy hh:mm a')}
                        </Typography>
                      </Box>
                    </Link>
                  </Box>
                </Button>
              </ConditionalWrapper>
              <Box display="flex" flexDirection="column" alignItems="flex-end">
                <Box color="black">
                  <Typography variant="button">
                    {`NCR-${jobNumber}-${new String(ncrNumber).padStart(3, '0')}`}
                  </Typography>
                </Box>
                <Box color={ncrStyles.color}>
                  <Typography variant="button" color="secondary" >
                    {ncrReference}
                  </Typography>
                </Box>
                <Box color={ncrStyles.color}>
                  <Typography variant="caption"  >
                    {status}
                  </Typography>
                  <Typography variant="caption"  >
                    {' and '}
                  </Typography>
                  <Typography variant="caption"  >
                    {(show) ? 'SHOWING' : 'HIDDEN'}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box display="flex" flexDirection="row">
              <Box width="100%" ml={5}>
                <Typography variant="button" style={{ fontSize: '0.7rem', fontWeight: 600 }} >
                  Issue Description
                </Typography>
                <Typography variant="body2" >
                  {(isDefinedAndInitialized(issueDescription)) ? issueDescription : '-'}
                </Typography>
                <Box display="flex" justifyContent="center" mr={2}>
                  <IconButton aria-label="expand-ncr-item" size="small" onClick={() => setIsExpanded(!isExpanded)}>
                    {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </Box>
                {
                  isExpanded &&
                  <NonConformanceRemediationDetail ncrItem={ncrItem} />
                }
              </Box>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="center" my="10px">
              <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                <Button onClick={handleShowToggle} >{(show) ? 'Hide' : 'Show'}
                  {
                    (isMutating) &&
                    <Box component="span" display="flex" ml={2} justifyContent="center" alignItems="center" >
                      <CircularProgress color="secondary" size={20} />
                    </Box>
                  }
                </Button>
                <Button onClick={handleClickOpen} disabled={!show} >
                  Edit
                </Button>
                <Button onClick={handleDelete} disabled={!canDelete} >
                  Delete
                  {
                    isMutating &&
                    <Box component="span" display="flex" ml={2} justifyContent="center" alignItems="center" >
                      <CircularProgress color="secondary" size={20} />
                    </Box>
                  }
                </Button>
              </ButtonGroup>
              <Dialog
                fullScreen={fullScreen}
                open={editDialogOpen}
                onClose={handleClose}
                aria-labelledby={`ncr-${observation.header.jobId}-${ncrNumber}-parent`}
              >
                <DialogTitle id={`ncr-${ncrId}-title`} >
                  <Box color={ncrStyles.color} display="flex" justifyContent="space-between">
                    <Box>
                      {observation.header.jobId} (NCR{ncrNumber})
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems="center" >
                      <Button startIcon={ncrStyles.icon}
                        style={{ backgroundColor: ncrStyles.color, color: '#fff' }} disabled={!canUpdateStatus}
                        onClick={handleStatusUpdate} >
                        {status}
                      </Button>
                    </Box>
                  </Box>
                </DialogTitle>
                <DialogContent>
                  <Grid container>
                    <Grid item xs={12}>
                      <Box m={1}>
                        <TextField
                          autoFocus
                          variant="outlined"
                          id={`ncr-${ncrId}-reference`}
                          name="ncrReference"
                          label="NCR Title"
                          placeholder="Brief descriptive title e.g. Pile Shaft Collapse"
                          type="text"
                          fullWidth
                          value={ncrPayloadState.ncrReference}
                          onChange={handleNcrUpdateChange}
                          inputProps={{
                            style: {
                              fontSize: fullScreen ? '0.8rem' : '1rem'
                            }
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box m={1}>
                        <TextField
                          minRows={2}
                          autoFocus
                          variant="outlined"
                          id={`ncr-${ncrId}-issue-description`}
                          name="issueDescription"
                          label="Issue Description"
                          placeholder="Describe how is this observation is non-conforming"
                          type="text"
                          fullWidth
                          multiline
                          value={ncrPayloadState.issueDescription}
                          onChange={handleNcrUpdateChange}
                          inputProps={{
                            style: {
                              fontSize: fullScreen ? '0.8rem' : '1rem'
                            }
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box m={1}>
                        <TextField
                          minRows={2}
                          autoFocus
                          variant="outlined"
                          id={`ncr-${ncrId}-remedy-description`}
                          name="remedyDescription"
                          label="Description of Remedial Work Required"
                          placeholder="Describe how the issue can be improved to meet the requirements"
                          type="text"
                          fullWidth
                          multiline
                          value={ncrPayloadState.remedyDescription}
                          onChange={handleNcrUpdateChange}
                          inputProps={{
                            style: {
                              fontSize: fullScreen ? '0.8rem' : '1rem'
                            }
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box m={1}>
                        <FormControl variant="outlined">
                          <InputLabel id={`ncr-${ncrId}-status-input-label`}>Status</InputLabel>
                          <Select
                            label="Status"
                            labelId={`ncr-${ncrId}-status-input-label`}
                            id={`ncr-${ncrId}-status-input`}
                            name="status"
                            value={ncrPayloadState.status}
                            onChange={handleNcrUpdateChange}
                            disabled={!canUpdateStatus}
                            SelectDisplayProps={{
                              style: {
                                fontSize: fullScreen ? '0.8rem' : '1rem'
                              }
                            }}
                            inputProps={{
                              style: {
                                fontSize: fullScreen ? '0.8rem' : '1rem'
                              }
                            }}
                          >
                            <MenuItem value={'OPEN'}>OPEN</MenuItem>
                            <MenuItem value={'CLOSED'}>CLOSED</MenuItem>
                          </Select>
                          <Typography variant="caption">
                            {(canUpdateStatus) ? '' : 'You cannot update the status of this NCR because it is updated in a later observation'}
                          </Typography>
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box m={1}>
                        <TextField
                          minRows={3}
                          autoFocus
                          variant="outlined"
                          id={`ncr-${ncrId}-remedial-work-notes`}
                          name="remediationCompletionDetail"
                          label="Notes on Remedial Work Completed"
                          placeholder="Comment on the progress of any remedial work (as well as any risks which may result from this issue and remediation)"
                          type="text"
                          fullWidth
                          multiline
                          value={ncrPayloadState.remediationCompletionDetail}
                          onChange={handleNcrUpdateChange}
                          inputProps={{
                            style: {
                              fontSize: fullScreen ? '0.8rem' : '1rem'
                            }
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleUpdate} color="primary" disabled={isMutating} autoFocus>
                    Update
                    {
                      isMutating &&
                      <Box component="span" display="flex" ml={2} justifyContent="center" alignItems="center" >
                        <CircularProgress color="black" size={20} />
                      </Box>
                    }
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="center" my="10px">
              <Typography variant="caption">
                {(canDelete) ? '' : 'You cannot delete this NCR because it was created with an earlier observation OR it is included in a later observation'}
              </Typography>
            </Box>
          </>
        }
      </Box>
    </Grid>
  )
}

NonConformanceItem.propTypes = {
  ncrItem: PropTypes.object,
  observation: PropTypes.object,
  ncrItems: PropTypes.array
};


const NonConformanceRegister = (props) => {
  // -------------------------------------------
  // PROPS
  const { classes, componentId, componentUpHandler, saveHandler, headingCount } = props;

  let setHeadingCount = null;
  if (isDefinedAndInitialized(headingCount)) {
    // ----------------------------------------------
    setHeadingCount = headingCount;
  }  

  // USE STATE HOOKS
  const [createDialog, setCreateDialog] = useState({
    open: false,
    content: INITIAL_NCR_CREATE_TEMPLATE
  });

  // REACT ROUTER
  let { observationId } = useParams();
  const history = useHistory();

  // NOTISTACK
  const { enqueueSnackbar } = useSnackbar();

  // MUI HOOKS
  const theme = useTheme();
  const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallerThanXs = useMediaQuery(theme.breakpoints.down('xs'));

  // USE MSAL HOOK
  const { instance, accounts, inProgress } = useMsal();
  const loginHint = (accounts && accounts[0]?.username) ?? '';
  const request = {
    loginHint,
    scopes: ["User.Read"]
  }
  const isAuthenticated = useIsAuthenticated();
  const isAuthed = isAuthenticated && inProgress === "none" && isDefinedAndInitialized(accounts) && accounts.length > 0 && isDefinedAndInitialized(accounts[0]) && isDefinedAndInitialized(accounts[0].username);
  const personName = accounts[0]?.idTokenClaims?.name ?? '';

  // REACT-QUERY
  // - Query client used for invalidation of queries
  const queryClient = useQueryClient();
  // OBSERVATION DETAIL DATA (REMOVE DEPENDENCY ON ORIGINAL CONTEXT BASED STATE MANAGEMENT)
  const { isLoading: isObsDetailDataLoading, isError: isObsDetailDataError, data: obsDetailData, error: obsDetailDataError, isRefetching: isObsDetailRefetching } = useQuery(
    ['observation', observationId],
    () => getObservationDetail(observationId, { instance, accounts, inProgress }),
    {
      enabled: isAuthed && isDefinedAndInitialized(observationId),
      refetchOnWindowFocus: false
    }
  );
  // NCR LIST FOR THIS OBSERVATION
  let queryStringParameters = new URLSearchParams(window.location.search);
  const { isLoading: isObsNcrListDataLoading, isError: isObsNcrListDataError, data: obsNcrListData, error: obsNcrListDataError, isRefetching: isObsNcrListRefetching, isIdle: isObsNcrListIdle } = useQuery(
    ['observation', observationId, 'ncr'],
    () => searchNcr({ observationId }, { instance, accounts, inProgress }),
    {
      enabled: isAuthed && isDefinedAndInitialized(observationId) && !config.getCreationStatusFromQueryString(queryStringParameters) && !config.getNcrCreatingStatusFromQueryString(queryStringParameters),
      refetchOnWindowFocus: false
    }
  );

  const createNcrItem = useMutation(async ncr => {
    // --------------------------------------------
    await createNcr(ncr, { instance, accounts, inProgress });
    // We now need to poll to ensure we capture the propagation of data through to Elasticsearch
    await pollForLargerNumberOfObservationNcrs(observationId, obsNcrListData.length, { instance, accounts, inProgress });
  },
    {
      onSuccess: () => {
        enqueueSnackbar('NCR update created', { variant: 'success', autoHideDuration: snackBarAutoHide });
        queryClient.invalidateQueries(['observation']);
        setCreateDialog({
          open: false,
          content: INITIAL_NCR_CREATE_TEMPLATE
        });
      },
      onError: () => {
        enqueueSnackbar(`NCR update couldn't be created - please contact support with detail of what happened before this error`, { variant: 'error', autoHideDuration: snackBarAutoHide });

      }
    });

  const batchDeleteNcrs = useMutation(async () => {
    // --------------------------------------------
    await batchDeleteNcr(observationId, { instance, accounts, inProgress });
    // We now need to poll to ensure we capture the propagation of data through to Elasticsearch
    await pollForLowerNumberOfObservationNcrs(observationId, obsNcrListData.length, { instance, accounts, inProgress });
  },
    {
      onSuccess: () => {
        enqueueSnackbar('NCR list cleared successfully', { variant: 'success', autoHideDuration: snackBarAutoHide });
        queryClient.invalidateQueries(['observation']);
      },
      onError: () => {
        enqueueSnackbar(`NCR list couldn't be cleared - please contact support with detail of what happened before this error`, { variant: 'error', autoHideDuration: snackBarAutoHide });

      }
    });

  const batchCreateNcrs = useMutation(async (ncrList) => {
    // --------------------------------------------
    await batchCreateNcr(ncrList, { instance, accounts, inProgress });
    // We now need to poll to ensure we capture the propagation of data through to Elasticsearch
    await pollForLargerNumberOfObservationNcrs(observationId, 0, { instance, accounts, inProgress });
  },
    {
      onSuccess: () => {
        enqueueSnackbar('NCR list linked to job successfully', { variant: 'success', autoHideDuration: snackBarAutoHide });
        queryClient.invalidateQueries(['observation']);
      },
      onError: () => {
        enqueueSnackbar(`NCR list couldn't be linked to job - please contact support with detail of what happened before this error`, { variant: 'error', autoHideDuration: snackBarAutoHide });

      }
    });

  const resetNcrs = useMutation(async () => {
    // --------------------------------------------
    // 1) Clear out any existing NCRs
    if (isDefinedAndInitialized(obsDetailData) && isDefinedAndInitialized(obsNcrListData) && obsNcrListData.length > 0) {
      // --------------------------------
      await batchDeleteNcr(observationId, { instance, accounts, inProgress });
      // We now need to poll to ensure we capture the propagation of data through to Elasticsearch
      await pollForLowerNumberOfObservationNcrs(observationId, obsNcrListData.length, { instance, accounts, inProgress });
    }

    // 2) Re-import the NCRs for the job
    const { jobId: jobNumber, inspectionTimestamp: observationTimestamp } = obsDetailData.data.header;
    const observationTimestampInUnix = DateTime.fromISO(observationTimestamp).toSeconds();
    let importedNcrItems = await searchNcr({ jobNumber, status: 'OPEN', datetimeJustLt: observationTimestampInUnix }, { instance, accounts, inProgress });
    let ncrToCreateList = importedNcrItems.map(ncrItem => {
      return {
        ...ncrItem,
        ncrUpdateId: uuidv4(),
        observationId,
        observationTimestamp: observationTimestampInUnix,
        updatedBy: personName,
        remediationCompletionDetail: null
      }
    });

    // If any new NCRs to bring in -> batch create them for this observation
    if (ncrToCreateList.length > 0) {
      await batchCreateNcr(ncrToCreateList, { instance, accounts, inProgress });
      // We now need to poll to ensure we capture the propagation of data through to Elasticsearch
      await pollForLargerNumberOfObservationNcrs(observationId, 0, { instance, accounts, inProgress });
    }
  },
    {
      onSuccess: () => {
        enqueueSnackbar('NCR list reset successfully', { variant: 'success', autoHideDuration: snackBarAutoHide });
        queryClient.invalidateQueries(['observation']);
        let queryStringParameters = new URLSearchParams(window.location.search);
        queryStringParameters.delete('creatingNcrs');
        history.replace({
          search: queryStringParameters.toString(),
        });
      },
      onError: () => {
        enqueueSnackbar(`NCR list couldn't be reset - please contact support with detail of what happened before this error`, { variant: 'error', autoHideDuration: snackBarAutoHide });

      }
    });

  const ncrActiveToggle = useMutation(async (updateTimestamp) => {
    // --------------------------------------------
    if (isDefinedAndInitialized(obsDetailData)) {
      // -------------------------------------------
      const active = isNcrComponentActive(obsDetailData.data);
      const ncrActiveUpdate = !active;

      // If active NCRs are displayed batch delete any existing NCRs and reset the view
      if (!ncrActiveUpdate) {
        // ----------------------
        await batchDeleteNcr(observationId, { instance, accounts, inProgress });
        // We now need to poll to ensure we capture the propagation of data through to Elasticsearch
        await pollForLowerNumberOfObservationNcrs(observationId, obsNcrListData.length, { instance, accounts, inProgress });
      }
      // If activated, query the previous NCRs and do a batch update
      else {
        // ----------------------
        if (isDefinedAndInitialized(obsDetailData) && isDefinedAndInitialized(obsNcrListData) && obsNcrListData.length > 0) {
          // --------------------------------
          await batchDeleteNcr(observationId, { instance, accounts, inProgress });
          // We now need to poll to ensure we capture the propagation of data through to Elasticsearch
          await pollForLowerNumberOfObservationNcrs(observationId, obsNcrListData.length, { instance, accounts, inProgress });
        }

        // 2) Re-import the NCRs for the job
        const { jobId: jobNumber, inspectionTimestamp: observationTimestamp } = obsDetailData.data.header;
        const observationTimestampInUnix = DateTime.fromISO(observationTimestamp).toSeconds();
        let importedNcrItems = await searchNcr({ jobNumber, status: 'OPEN', datetimeJustLt: observationTimestampInUnix }, { instance, accounts, inProgress });
        let ncrToCreateList = importedNcrItems.map(ncrItem => {
          return {
            ...ncrItem,
            ncrUpdateId: uuidv4(),
            observationId,
            observationTimestamp: observationTimestampInUnix,
            updatedBy: personName,
            remediationCompletionDetail: null
          }
        });

        // If any new NCRs to bring in -> batch create them for this observation
        if (ncrToCreateList.length > 0) {
          await batchCreateNcr(ncrToCreateList, { instance, accounts, inProgress });
          // We now need to poll to ensure we capture the propagation of data through to Elasticsearch
          await pollForLargerNumberOfObservationNcrs(observationId, 0, { instance, accounts, inProgress });
        }
      }
      await saveHandler(null, false, false, ncrActiveUpdate);
      await pollForObservationUpdateTimestampChange(observationId, updateTimestamp, { instance, accounts, inProgress });
    }
  },
    {
      onSuccess: () => {
        enqueueSnackbar('NCR toggled successfully', { variant: 'success', autoHideDuration: snackBarAutoHide });
        queryClient.invalidateQueries(['observation']);
      },
      onError: () => {
        enqueueSnackbar(`NCR could not be toggled - please contact support with detail of what happened before this error`, { variant: 'error', autoHideDuration: snackBarAutoHide });
      }
    });

  const isMutating = createNcrItem.isLoading || batchDeleteNcrs.isLoading || batchCreateNcrs.isLoading || resetNcrs.isLoading || ncrActiveToggle.isLoading;
  const isQueryLoading = isObsDetailDataLoading || isObsNcrListDataLoading;


  useEffect(async () => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      await instance.loginRedirect(request);
    }
  }, [isAuthenticated, inProgress, instance]);

  useEffect(() => {
    // --------------------------------------------------
    if (isAuthed) {
      // -------------------------------------------------
      // Reset register if this is a newly created observation
      let queryStringParameters = new URLSearchParams(window.location.search);
      if (isDefinedAndInitialized(obsDetailData?.data?.header?.ncrActive)
        && obsDetailData.data.header.ncrActive && config.getCreationStatusFromQueryString(queryStringParameters)) {
        // ---------------------------
        queryStringParameters.delete('created');
        queryStringParameters.append('creatingNcrs', 'true');
        history.replace({
          search: queryStringParameters.toString(),
        });
        resetNcrs.mutate();
      }
    }
    else {
      // -------------------------------
      console.info('Not authenticated for this API action');
    }
  }, [observationId, JSON.stringify(obsDetailData), isAuthed]);


  const handleClickOpen = () => {
    setCreateDialog({
      ...createDialog,
      open: true
    });
  };

  const handleClose = () => {
    setCreateDialog({
      ...createDialog,
      open: false
    });
  };

  const handleNewNcrDetailChange = (e) => {
    // --------------------------------------------------
    setCreateDialog({
      ...createDialog,
      content: {
        ...createDialog.content,
        [e.target.name]: e.target.value
      }
    });
  }

  const handleNcrActiveStateToggle = async (updateTimestamp) => {
    // --------------------------------------------------
    if (isAuthed) {
      // -------------------------------------------------
      ncrActiveToggle.mutate(updateTimestamp);
    }
    else {
      // -------------------------------
      console.info('Not authenticated for this API action');
    }
  }

  const handleRegisterReset = async () => {
    // --------------------------------------------------
    if (isAuthed) {
      // -------------------------------------------------
      resetNcrs.mutate();
    }
    else {
      // -------------------------------
      console.info('Not authenticated for this API action');
    }
  }

  const handleCreateReport = async () => {
    // --------------------------------------------------
    if (isAuthed) {
      // ------------------------------------------------
      // Validate the minimum contents is provided (issueDescription only at this stage)
      if (!isDefinedAndInitialized(createDialog.content.issueDescription) || createDialog.content.issueDescription === '') {
        setCreateDialog({
          ...createDialog,
          content: {
            ...createDialog.content,
            error: 'To create an NCR, issue description is the minimum information required'
          }
        });
      }
      // If a valid payload, create the NCR, ensure changes have propagated to Opensearch and then refresh REACT-QUERY for obs NCR list
      else {
        const ncrCreatePayload = {
          ncrId: null, ncrNumber: null,
          ncrReference: createDialog.content.ncrReference, ncrType: null,
          jobId: null, jobName, jobNumber,
          observationId, observationTimestamp: DateTime.fromISO(observationTimestamp).toSeconds(),
          creationTimestamp: DateTime.fromISO(observationTimestamp).toSeconds(),
          openedBy: personName, openedTimestamp: DateTime.fromISO(observationTimestamp).toSeconds(),
          updatedBy: personName,
          observationIdOpened: observationId, closedBy: null, closedTimestamp: null, observationIdClosed: null,
          show: true, status: 'OPEN',
          issueDescription: createDialog.content.issueDescription,
          remedyDescription: formatValueAsDefinedStringOrNull(createDialog.content.remedyDescription),
          remediationCompletionDetail: formatValueAsDefinedStringOrNull(createDialog.content.remediationCompletionDetail)
        }

        createNcrItem.mutate(ncrCreatePayload);
      }
    }
    else {
      // -------------------------------
      console.info('Not authenticated for this API action');
    }
  }

  // HANDLE LOADING STATES
  // MSAL
  if (
    !isAuthed
  ) {
    return (
      <Paper>
        <Box width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center" >
          <CircularProgress />
        </Box>
      </Paper>
    )
  }
  // QUERY DATA
  if (isObsDetailDataLoading || isObsDetailRefetching) {
    return (
      <Paper className={classes.root}>
        <Box width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center" >
          Loading Observation Data
          <CircularProgress />
        </Box>
      </Paper>
    )
  }
  if (isObsNcrListDataLoading || isObsNcrListRefetching || isObsNcrListIdle) {
    return (
      <Paper className={classes.root}>
        <Box width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center" >
          Loading Observation NCR List
          <CircularProgress />
        </Box>
      </Paper>
    )
  }
  // MUTATIONS DATA
  if (createNcrItem.isLoading) {
    return (
      <Paper className={classes.root}>
        <Box width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center" >
          Creating NCR Items
          <CircularProgress />
        </Box>
      </Paper>
    )
  }
  if (batchDeleteNcrs.isLoading) {
    return (
      <Paper className={classes.root}>
        <Box width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center" >
          Clearing NCR Items
          <CircularProgress />
        </Box>
      </Paper>
    )
  }
  if (batchCreateNcrs.isLoading) {
    return (
      <Paper className={classes.root}>
        <Box width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center" >
          Pulling in NCR Items
          <CircularProgress />
        </Box>
      </Paper>
    )
  }
  if (resetNcrs.isLoading) {
    return (
      <Paper className={classes.root}>
        <Box width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center" >
          Resettting NCR register
          <CircularProgress />
        </Box>
      </Paper>
    )
  }
  if (ncrActiveToggle.isLoading) {
    return (
      <Paper className={classes.root}>
        <Box width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center" >
          Toggling NCR register
          <CircularProgress />
        </Box>
      </Paper>
    )
  }

  // HANDLE ERROR STATE
  if (isObsDetailDataError) {
    return <span>An unexpected error has occurred with the job data {obsDetailDataError}</span>
  }
  if (isObsNcrListDataError) {
    return <span>An unexpected error has occurred with the job data {obsNcrListDataError}</span>
  }

  // DERIVED STATE
  // ------------------
  // OBSERVATION DETAIL
  // ------------------
  const observation = obsDetailData.data;
  const { jobId: jobNumber, jobName, inspectionTimestamp: observationTimestamp, updateTimestamp } = observation.header;

  // OBSERVATION NCR LIST
  // ------------------
  const latestJobHeadTimestamp = (isDefinedAndInitialized(obsNcrListData) && obsNcrListData.length > 0) ? obsNcrListData.reduce((value, currentNcrItem) => {
    if ((!value && isDefinedAndInitialized(currentNcrItem.jobNcrHeadTimestamp))
      || (isDefinedAndInitialized(currentNcrItem.jobNcrHeadTimestamp) && currentNcrItem.jobNcrHeadTimestamp > value)) {
      return currentNcrItem.jobNcrHeadTimestamp;
    }
    else {
      return value;
    }
  }, null) : null;
  const active = isNcrComponentActive(observation);
  // We want to prevent toggling of the NCR register when:
  // 1) There are NCRs that have been created and subsequently updated
  const canToggleNcrRegister = isDefinedAndInitialized(obsNcrListData) && obsNcrListData.filter(ncrItem => ncrItem.observationIdOpened === observationId && ncrItem.ncrUpdateCount > 1).length === 0;

  // We want to prevent opening new NCRs for observations
  // where future observations with NCR registers already exist (and therefore not include any new NCRs)
  // This is currently managed by enforcing that new NCRs can only be added at the HEAD of the Job NCR update register (or where there are no NCRs at all)
  const canAddNewNcr = !latestJobHeadTimestamp || (isDefinedAndInitialized(latestJobHeadTimestamp) && DateTime.fromISO(observationTimestamp).toSeconds() >= latestJobHeadTimestamp);

  let ncrToggleTooltipMessage = '';
  if (canToggleNcrRegister && active) {
    ncrToggleTooltipMessage = "Delete all NCR updates for this observation";
  }
  else if (canToggleNcrRegister && !active) {
    ncrToggleTooltipMessage = "Pull in existing or create new NCRs";
  }
  else if (!canToggleNcrRegister) {
    ncrToggleTooltipMessage = "There are later NCR updates that depend on these, please remove them before updating this NCR register";
  }


  return (
    <Box my="5px">
      <Paper className={classes.root}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box width="calc(100% - 48px)">
            <Box display="flex" flexDirection="column">
              <Box display="flex" flexDirection="row" alignItems="center">
                {
                  setHeadingCount &&
                  <Typography style={{ fontSize: '1.2rem', fontWeight: 'bold', paddingRight: '8px' }}>
                      { setHeadingCount.toString().padStart(2, '0') }
                  </Typography>
                }
                <Typography>
                  NON-CONFORMANCE REPORT (NCR) REGISTER
                </Typography>
              </Box>
              <Box ml="15px" paddingTop={ isSmallerThanXs ? "10px" : "0px" }>
                <ConditionalWrapper
                  condition={canToggleNcrRegister}
                  wrapper={children => <Tooltip title={ncrToggleTooltipMessage} >{children}</Tooltip>}
                >
                  <FormControlLabel
                    className={classes.textField}
                    control={
                      <Switch
                        checked={active}
                        onChange={() => handleNcrActiveStateToggle(updateTimestamp)}
                        name={"ncr-toggle"}
                        disabled={!canToggleNcrRegister || isMutating}
                      />}
                    label={<Typography color="textSecondary" style={{ fontSize: isSmallerThanXs ? '0.9rem' : '1rem' }}>Include non-conformance register?</Typography>}
                  />
                </ConditionalWrapper>
              </Box>
            </Box>
            <Box marginBottom="10px">
              {
                (active) &&
                <Grid container>
                  {(!isMutating && !isQueryLoading && obsNcrListData.length === 0) ?
                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="center" alignItems="center" color="#4caf50" mb={2} >
                        <Box component="span" mr={1} display="flex" alignItems="center"><CheckCircleIcon /></Box>
                        <Box component="span"><Typography variant="body1" >No open non-conformance reports</Typography></Box>
                      </Box>
                    </Grid>
                    : obsNcrListData.sort((ncrItemA, ncrItemB) => ncrItemA.ncrNumber - ncrItemB.ncrNumber).map(item =>
                      <NonConformanceItem key={`ncr-id-${item.ncrId}`} ncrItem={item} ncrItems={obsNcrListData} observation={observation} />
                    )}
                </Grid>
              }
            </Box>
            {
              (active) &&
              <>
                <Box display="flex" justifyContent="center" >
                  <Box mr="5px">
                    <Button variant="contained" color="primary" onClick={handleClickOpen} disabled={!canAddNewNcr || isMutating} >Open new NCR</Button>
                  </Box>
                  <Box ml="5px">
                    <ConditionalWrapper
                      condition={canToggleNcrRegister}
                      wrapper={children => <Tooltip title="Resets the NCR register based on the previous observation">{children}</Tooltip>}
                    >
                      <Button variant="contained" color="primary" onClick={handleRegisterReset} disabled={!canToggleNcrRegister || isMutating} >
                        Reset register
                        {
                          isMutating &&
                          <Box component="span" display="flex" flexDirection="column" ml={2} justifyContent="center" alignItems="center" >
                            <CircularProgress size={20} />
                          </Box>
                        }
                      </Button>
                    </ConditionalWrapper>
                  </Box>
                  <Dialog
                    fullScreen={isSmallerThanSm}
                    open={createDialog.open}
                    onClose={handleClose}
                    aria-labelledby={`ncr-create-parent`}
                  >
                    <DialogTitle id={`ncr-create-title`} >
                      <Box color="#f44336" display="flex" justifyContent="space-between">
                        <Box>
                          New NCR for: {jobNumber}
                        </Box>
                        <Box display="flex" flexDirection="row" alignItems="center" >
                          {<WarningIcon />}
                          {"OPEN"}
                        </Box>
                      </Box>
                    </DialogTitle>
                    <DialogContent>
                      <FormControl error={createDialog.content.error}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Box m={1}>
                              <TextField
                                autoFocus
                                name="ncrReference"
                                variant="outlined"
                                id={`ncr-new-reference`}
                                label="NCR Title"
                                placeholder="Brief descriptive title e.g. Pile Shaft Collapse"
                                type="text"
                                fullWidth
                                value={(createDialog.content.ncrReference) ? createDialog.content.ncrReference : ''}
                                onChange={handleNewNcrDetailChange}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box m={1}>
                              <TextField
                                autoFocus
                                name="issueDescription"
                                variant="outlined"
                                id={`ncr-create-issue-description`}
                                label="Issue Description"
                                placeholder="Describe how is this observation is non-conforming"
                                type="text"
                                fullWidth
                                multiline
                                value={(createDialog.content.issueDescription) ? createDialog.content.issueDescription : ''}
                                onChange={handleNewNcrDetailChange}
                                required
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box m={1}>
                              <TextField
                                autoFocus
                                name="remedyDescription"
                                variant="outlined"
                                id={`ncr-create-remedy-description`}
                                label="Description of Remedial Work Required"
                                placeholder="Describe how the issue can be improved to meet the requirements"
                                type="text"
                                fullWidth
                                multiline
                                value={(createDialog.content.remedyDescription) ? createDialog.content.remedyDescription : ''}
                                onChange={handleNewNcrDetailChange}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box m={1}>
                              <TextField
                                autoFocus
                                name="remediationCompletionDetail"
                                variant="outlined"
                                id={`ncr-create-remedial-work-notes`}
                                label="Notes on Remedial Work Completed"
                                placeholder="Comment on the progress of any remedial work (as well as any risks which may result from this issue and remediation)"
                                type="text"
                                fullWidth
                                multiline
                                value={(createDialog.content.remediationCompletionDetail) ? createDialog.content.remediationCompletionDetail : ''}
                                onChange={handleNewNcrDetailChange}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box m={1} >
                              <FormHelperText >{createDialog.content.error ? createDialog.content.error : ''}</FormHelperText>
                            </Box>
                          </Grid>
                        </Grid>
                      </FormControl>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCreateReport} color="primary" disabled={isMutating} autoFocus>
                        Create
                        {
                          createNcr.isLoading &&
                          <Box component="span" display="flex" flexDirection="column" ml={2} justifyContent="center" alignItems="center" >
                            <CircularProgress size={20} />
                          </Box>
                        }
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Box>
                <Box display="flex" flexDirection="row" justifyContent="center" my="10px">
                  <Typography variant="caption">
                    {(canAddNewNcr) ? '' : 'You cannot add a new NCR because there are later NCR updates, please remove these first'}
                  </Typography>
                </Box>
              </>
            }
          </Box>
          <OrderArrows componentId={componentId} componentUpHandler={componentUpHandler} />
        </Box>
      </Paper>
    </Box>
  )
}

NonConformanceRegister.propTypes = {
  classes: PropTypes.object,
  componentId: PropTypes.string,
  componentUpHandler: PropTypes.func,
  saveHandler: PropTypes.func,
  headingCount: PropTypes.number
};

export default withRouter(withStyles(styles)(NonConformanceRegister));
