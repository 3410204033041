import { fabric } from 'fabric';
import uuidv4 from 'uuid/v4';

let polygonMode;
let pointArray;
let lineArray;
let firstPointId;
let activeLine;
let activeShape;

const initialisePolygon = () => {
  polygonMode = true;
  pointArray = [];
  lineArray = [];
  firstPointId = null;
};

const generatePolygon = (existingPoints, canvas, color) => {
  const points = [];
  existingPoints.forEach((point) => {
    points.push({
      x: point.left,
      y: point.top,
    });
    canvas.remove(point);
  });
  lineArray.forEach((line) => {
    canvas.remove(line);
  });
  canvas.remove(activeShape).remove(activeLine);
  const polygon = new fabric.Polygon(points, {
    stroke: '#333333',
    strokeWidth: 0.5,
    fill: color,
    opacity: 1,
  });
  canvas.add(polygon);

  activeLine = null;
  activeShape = null;
  polygonMode = false;
};

const addPoint = (options, canvas, color) => {
  const touchOrMousePointer = canvas.getPointer(options.e);

  const circle = new fabric.Circle({
    radius: 5,
    fill: '#ffffff',
    stroke: '#333333',
    strokeWidth: 0.5,
    left: touchOrMousePointer.x,
    top: touchOrMousePointer.y,
    selectable: false,
    hasBorders: false,
    hasControls: false,
    originX: 'center',
    originY: 'center',
    objectCaching: false,
    label: 'polygon-point',
    id: uuidv4()
  });

  if (pointArray.length === 0) {
    circle.set({
      fill: 'red',
    });
  }
  const points = [
    touchOrMousePointer.x,
    touchOrMousePointer.y,
    touchOrMousePointer.x,
    touchOrMousePointer.y,
  ];
  const line = new fabric.Line(points, {
    strokeWidth: 2,
    fill: '#999999',
    stroke: '#999999',
    class: 'line',
    originX: 'center',
    originY: 'center',
    selectable: false,
    hasBorders: false,
    hasControls: false,
    evented: false,
    objectCaching: false,
  });
  if (activeShape) {
    const pos = canvas.getPointer(options.e);
    const existingPoints = activeShape.get('points');
    existingPoints.push({
      x: pos.x,
      y: pos.y,
    });
    const polygon = new fabric.Polygon(existingPoints, {
      stroke: '#333333',
      strokeWidth: 1,
      fill: color,
      opacity: 0.3,
      selectable: false,
      hasBorders: false,
      hasControls: false,
      evented: false,
      objectCaching: false,
    });
    canvas.remove(activeShape);
    canvas.add(polygon);
    activeShape = polygon;
    canvas.renderAll();
  } else {
    const polyPoint = [{
      x: touchOrMousePointer.x,
      y: touchOrMousePointer.y,
    }];
    const polygon = new fabric.Polygon(polyPoint, {
      stroke: '#333333',
      strokeWidth: 1,
      fill: color,
      opacity: 0.3,
      selectable: false,
      hasBorders: false,
      hasControls: false,
      evented: false,
      objectCaching: false,
    });
    activeShape = polygon;
    canvas.add(polygon);
  }
  activeLine = line;

  pointArray.push(circle);
  lineArray.push(line);

  canvas.add(line);
  canvas.add(circle);

  return circle.id;
};

export const drawPolygon = (canvas, setSelectionTool, color) => {
  canvas.on('mouse:down', (options) => {
    if (options.target && pointArray.length > 0 && firstPointId === options.target.id) {
      // Close the polygon
      generatePolygon(pointArray, canvas, color);
      initialisePolygon();
      setSelectionTool();
    }
    else if (polygonMode) {
      if (pointArray.length === 0) {
        firstPointId = addPoint(options, canvas, color);
      }
      else {
        addPoint(options, canvas, color);
      }
    }
  });

  if (typeof pointArray === 'undefined' || pointArray.length === 0) {
    // If no existing polygon, restart
    initialisePolygon();
  }
};

export function clearPolygon() {
  polygonMode = false;
  pointArray = [];
  lineArray = [];
  activeLine = null;
  activeShape = null;
}
