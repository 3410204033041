import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';

// Material UI
import {
  Box, Divider, Button, Avatar,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  ListItem, ListItemText, useTheme, useMediaQuery,
  Chip
} from '@material-ui/core';
import {
  PanTool as PanToolIcon,
  Pageview as PageviewIcon, Create as CreateIcon,
  Delete as DeleteIcon, Fingerprint as FingerprintIcon,
  PriorityHigh as PriorityHighIcon, Done as DoneIcon,
  ThumbUp as ThumbUpIcon, VerticalAlignBottom,
} from '@material-ui/icons';

// Custom Components
import ApprovalDialog from './approvalDialog';
import ReviewNotificationDialog from './reviewNotificationDialog';

// Custom Helpers
import { isDefinedAndInitialized, capitalizeFirstLetters } from '../helpers/helpers';

// Custom Config
import { config } from '../config/generalConfig';


const styleConfig = {
  draft: {
    backgroundColor: config.statusTypes.find(statusType => statusType.name === 'draft').iconBackgroundColour,
  },
  review: {
    backgroundColor: config.statusTypes.find(statusType => statusType.name === 'review').iconBackgroundColour,
  },
  approved: {
    backgroundColor: config.statusTypes.find(statusType => statusType.name === 'approved').iconBackgroundColour,
  },
  downloaded: {
    backgroundColor: config.statusTypes.find(statusType => statusType.name === 'downloaded').iconBackgroundColour,
  },
  approvedForDownloadIcon: {

  },
};

export const renderObservationSummaryListItem = (props) => {
  // ------------------
  // PROPS
  const { index, style, data } = props;
  const item = data[index];
  const {
    tabName, ObservationSummaryData, handleApproveObservation: updateObservationStatusHandler,
    refreshSummaryList, handlePDFObservation, handleDeleteObservation, idx, showActions, handleShowActions,
    useMobileLayout
  } = item;

  // DEFAULT TO SHOWING ACTIONS
  let setIdx = null;
  if (isDefinedAndInitialized(idx)) {
    // -----------------------------------
    setIdx = idx;
  }
  let setShowActions = true;
  if (isDefinedAndInitialized(showActions)) {
    // -----------------------------------
    setShowActions = showActions;
  }
  let setHandleShowActions = undefined;
  if (isDefinedAndInitialized(handleShowActions)) {
    // -----------------------------------
    setHandleShowActions = handleShowActions;
  }
  let setUseMobileLayout = false;
  if (isDefinedAndInitialized(useMobileLayout)) {
    // -----------------------------------
    setUseMobileLayout = useMobileLayout;
  }

  const { header } = ObservationSummaryData;
  const { status, jobId, jobName, createdBy, inspectionNumber, inspectionType, inspectionTimestamp, updateTimestamp } = header;

  const observationAuthorised = ['downloaded', 'approved'].includes(status);
  const approvalStatusModifier = observationAuthorised ? 'draft' : 'approved';
  const reviewStatusModifier = ['downloaded', 'approved', 'review'].includes(status) ? 'draft' : 'review';
  const isPreReview = status === 'draft';

  // CONST MUI HOOKS
  const theme = useTheme();
  const isSmallerThanXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('sm'));

  let approverText = '';
  if (observationAuthorised) {
    // ------------------------------------------
    // 1) NEW FUNCTIONALITY JUNE 2022 - APPROVER DETAILS STORED FOR SIGNATORY
    if ('approverDetails' in header && isDefinedAndInitialized(header.approverDetails)
      && isDefinedAndInitialized(header.approverDetails.email)
      && isDefinedAndInitialized(header.approverDetails.name)) {
      // ---------------------------------------------------------
      approverText = ` (by ${header.approverDetails.name})`
    }
    // 2) BACKWARDS COMPATIBILITY
    else {
      approverText = ` (auto: ${header.manager})`
    }
  }

  // STATE
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [approveConfirmationOpen, setApproveConfirmationOpen] = useState(false);
  const [reviewNotifyPromptOpen, setReviewNotifyPromptOpen] = useState(false);

  const handleDeleteDialog = () => {
    setDeleteConfirmationOpen(false);
  };

  const handleApproveDialog = () => {
    setApproveConfirmationOpen(false);
  };

  const handleReviewDialog = () => {
    setReviewNotifyPromptOpen(false);
  };

  const inspectionIcon = (status) => {
    switch (status) {
      case 'draft':
        return <PriorityHighIcon />;
      case 'review':
        return <PanToolIcon />;
      case 'approved':
        return <ThumbUpIcon />;
      case 'downloaded':
        return <DoneIcon />;
      default:
        return '?';
    }
  };


  const userGroups = JSON.parse(localStorage.getItem('siteObservationsUserGroups'));
  const authorizedForReview = isDefinedAndInitialized(userGroups) && userGroups.filter(group => group.displayName === 'Site Observations Reviewers').length > 0;

  return (
    <Box style={style}>
      <ListItem key={`observation#${ObservationSummaryData.id}`}>
        <Box display="flex" flexDirection="column" width="100%">
          <Box display="flex" flexDirection="row" alignItems="center" width="100%" onClick={() => setHandleShowActions(setIdx)}>
            <Avatar style={styleConfig[ObservationSummaryData.header.status]}>
              {inspectionIcon(ObservationSummaryData.header.status)}
            </Avatar>
            <ListItemText
              style={{ paddingLeft: '16px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflowX: 'hidden' }}
              primaryTypographyProps={{
                style: {
                  fontSize: isSmallerThanXs || setUseMobileLayout ? '0.8rem' : '1rem',
                  textOverflow: 'ellipsis',
                  overflowX: 'hidden'
                }
              }}
              secondaryTypographyProps={{
                style: {
                  fontSize: isSmallerThanXs || setUseMobileLayout ? '0.7rem' : '1rem',
                  textOverflow: 'ellipsis',
                  overflowX: 'hidden'
                }
              }}
              primary={`${jobId}`
                + `/${inspectionNumber}`
                + ` | ${jobName}`}
              secondary={
                `${capitalizeFirstLetters(createdBy.name)} ${isSmallerThanXs || setUseMobileLayout ? '' : `| ${capitalizeFirstLetters(inspectionType)} `}${isSmallerThanXs ? '' : `| ${capitalizeFirstLetters(status)}${approverText}`} | ${DateTime.fromISO(inspectionTimestamp).toFormat('dd/MM/yyyy hh:mm a')}`
              }
            />
          </Box>
          {
            setShowActions &&
            <Box display="flex" flexDirection={isSmallerThanXs || setUseMobileLayout ? "column" : "row"}>
              <Box display="flex" flexDirection={isSmallerThanSm || setUseMobileLayout ? "column" : "row"} paddingLeft="55px" paddingRight={isSmallerThanSm || setUseMobileLayout ? "10px" : "0px"}>

                {/* EDIT CONTROL */}
                <Box>
                  <Chip
                    variant="outlined"
                    size="small"
                    style={{ width: '100%', marginRight: '10px', marginTop: '5px', marginBottom: '5px' }}
                    icon={<CreateIcon style={{ color: '#1976d2' }} />}
                    label="Edit"
                    component={Link}
                    to={`/observation/${ObservationSummaryData.inspectionId}`}
                    clickable={true}
                  />
                </Box>

                {/* REVIEW CONTROL */}
                <Box>
                  <Chip
                    variant="outlined"
                    size="small"
                    style={{ width: '100%', marginRight: '10px', marginTop: '5px', marginBottom: '5px' }}
                    icon={<PageviewIcon style={{ color: (isPreReview) ? config.statusTypes.find(statusType => statusType.name === 'review').iconBackgroundColour : 'red' }} />}
                    label={isPreReview ? 'Request Review' : 'Cancel Review'}
                    clickable={true}
                    onClick={async () => {
                      if (isPreReview) {
                        // --------------------------
                        setReviewNotifyPromptOpen(true);
                      }
                      else {
                        updateObservationStatusHandler(ObservationSummaryData.inspectionId, reviewStatusModifier, false, updateTimestamp);
                      }
                    }}
                  />
                </Box>

                {/* APPROVE CONTROL */}
                {
                  authorizedForReview &&
                  <Box>
                    <Chip
                      variant="outlined"
                      size="small"
                      style={{ width: '100%', marginRight: '10px', marginTop: '5px', marginBottom: '5px' }}
                      icon={<FingerprintIcon style={{ color: (!observationAuthorised) ? config.statusTypes.find(statusType => statusType.name === 'approved').iconBackgroundColour : 'red' }} />}
                      label={!observationAuthorised ? 'Approve' : 'Remove Approval'}
                      clickable={true}
                      disabled={!authorizedForReview}
                      onClick={async () => {
                        if (!observationAuthorised) {
                          setApproveConfirmationOpen(true);
                        } else if (tabName === 'MONTH_SUMMARY') {
                          let notify = 'approved' === approvalStatusModifier;
                          updateObservationStatusHandler(ObservationSummaryData.inspectionId, approvalStatusModifier,
                            notify, updateTimestamp);
                        } else {
                          let notify = 'approved' === approvalStatusModifier;
                          updateObservationStatusHandler(ObservationSummaryData.inspectionId, approvalStatusModifier,
                            notify, updateTimestamp);
                        }
                      }}
                    />
                  </Box>
                }

              </Box>
              <Box display="flex" flexDirection={isSmallerThanSm || setUseMobileLayout ? "column" : "row"} paddingLeft={isSmallerThanXs || setUseMobileLayout ? "55px" : "0px"} paddingRight={isSmallerThanXs || setUseMobileLayout ? "10px" : "55px"}>

                {/* DOWNLOAD CONTROL */}
                <Box>
                  <Chip
                    variant="outlined"
                    size="small"
                    style={{ width: '100%', marginRight: '10px', marginTop: '5px', marginBottom: '5px' }}
                    icon={<VerticalAlignBottom style={{ color: observationAuthorised ? '#00c853' : 'black' }} />}
                    label={!observationAuthorised ? 'Requires Approval' : 'Download'}
                    clickable={true}
                    disabled={!observationAuthorised}
                    onClick={async () => {
                      if (tabName === 'MONTH_SUMMARY') {
                        handlePDFObservation(ObservationSummaryData.inspectionId,
                          ObservationSummaryData.header.updateTimestamp,
                          refreshSummaryList);
                      } else {
                        handlePDFObservation(ObservationSummaryData.inspectionId,
                          ObservationSummaryData.header.updateTimestamp);
                      }
                    }}
                  />
                </Box>

                {/* DELETE CONTROL */}
                <Box>
                  <Chip
                    variant="outlined"
                    size="small"
                    style={{ width: '100%', marginRight: '10px', marginTop: '5px', marginBottom: '5px' }}
                    icon={<DeleteIcon />}
                    label={'Delete'}
                    clickable={true}
                    onClick={() => { setDeleteConfirmationOpen(true); }}
                  />
                </Box>

              </Box>
            </Box>
          }
        </Box>
      </ListItem>
      <Dialog
        open={deleteConfirmationOpen}
        onClose={handleDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure you want to delete this inspection?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deleting this inspection will remove it permanently.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialog} style={{ color: '#1976d2' }}>
            Cancel
          </Button>
          <Button
            onClick={async () => {
              handleDeleteDialog();
              if (tabName === 'MONTH_SUMMARY') {
                handleDeleteObservation(ObservationSummaryData.inspectionId,
                  ObservationSummaryData.header.updateTimestamp, refreshSummaryList);
              } else {
                handleDeleteObservation(ObservationSummaryData.inspectionId,
                  ObservationSummaryData.header.updateTimestamp);
              }
            }}
            style={{ color: '#1976d2' }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <ApprovalDialog
        tabName={tabName}
        observationId={ObservationSummaryData.inspectionId}
        updateTimestamp={ObservationSummaryData.header.updateTimestamp}
        approvalStatusModifier={approvalStatusModifier}
        approveConfirmationOpen={approveConfirmationOpen}
        handleApproveDialog={handleApproveDialog}
        updateObservationStatusHandler={updateObservationStatusHandler}
        refreshSummaryList={refreshSummaryList}
      />
      <ReviewNotificationDialog
        tabName={tabName}
        observationId={ObservationSummaryData.inspectionId}
        updateTimestamp={ObservationSummaryData.header.updateTimestamp}
        approvalStatusModifier={approvalStatusModifier}
        reviewNotifyPromptOpen={reviewNotifyPromptOpen}
        handleReviewDialog={handleReviewDialog}
        updateObservationStatusHandler={updateObservationStatusHandler}
      />
      <Divider variant="inset" />
    </Box>
  );
};
