
import { DateTime } from "luxon";

/**
 * Sorts an array of observations by inspection timestamp in either ascending or descending order.
 * @param {string} order - The order in which to sort the observations. Can be either 'asc' or 'desc'.
 * @param {object} a - The first observation to compare.
 * @param {object} b - The second observation to compare.
 * @returns {number} - Returns -1 if a should appear before b, 1 if b should appear before a, and 0 if they are equal.
 */
export const sortObservationByInspectionTimestamp = (order='desc', a, b) => {
    if (DateTime.fromISO(a.header.inspectionTimestamp) > (DateTime.fromISO(b.header.inspectionTimestamp))) {
        return order.toLowerCase() === 'desc' ? -1 : 1;
    }
    if (DateTime.fromISO(b.header.inspectionTimestamp) > (DateTime.fromISO(a.header.inspectionTimestamp))) {
        return order.toLowerCase() === 'desc' ? 1 : -1;
    }
    // a must be equal to b
    return 0;
}



/**
 * Sorts observation by their status in a given order.
 *
 * @param {string} [order='desc'] - The order to sort. Either 'asc' or 'desc'. Default value is 'desc'.
 * @param {Object} a - The first observation object to compare.
 * @param {Object} b - The second observation object to compare.
 * @returns {number} - A negative number if a should be before b, 0 if they are equal, or a positive number if b should be before a.
 * @example
 * const observations = [
 *   { status: 'approved' },
 *   { status: 'draft' },
 *   { status: 'downloaded' },
 *   { status: 'review' },
 * ];
 *
 * const sortedObservations = observations.sort(sortObservationByStatus);
 */
export const sortObservationByStatus = (order='desc', a, b) => {
    // --------------------------------------
    const statuses = ['draft', 'review', 'approved', 'downloaded'];

    return order === 'desc' ? statuses.indexOf(b.header.status) - statuses.indexOf(a.header.status) : statuses.indexOf(a.header.status) - statuses.indexOf(b.header.status);
}


/**
 * Sorts an array of observations by creator name.
 *
 * @param {string} [order='desc'] - The order in which to sort the observations. Defaults to 'desc'.
 * @param {object} a - The first observation to compare.
 * @param {object} b - The second observation to compare.
 * @return {number} - Returns -1, 0, or 1 indicating the sort order of the observations.
 */
export const sortObservationByCreator = (order='desc', a, b) => {
    // --------------------------------------
    const creatorNameA = a.header.createdBy.name;
    const creatorNameB = b.header.createdBy.name;

    return creatorNameA.localeCompare(creatorNameB) * (order === 'desc' ? -1 : 1);
}