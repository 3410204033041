import { fabric } from 'fabric';
import ids from 'short-id';

const referencePoint = (canvas, referenceNumber, addReferenceData) => {
  canvas.on('mouse:up', (fabricEvent) => {
    const mouseEvent = fabricEvent.e;
    const pointer = canvas.getPointer(mouseEvent);


    const number = referenceNumber + 1;
    const circle = new fabric.Circle({

      top: pointer.y,
      left: pointer.x,
      radius: 15,
      fill: 'white',
      stroke: 'blue',
      strokeWidth: 2,
      originX: 'center',
      originY: 'center',
    });


    const text = new fabric.Text((number).toString(), {

      fontFamily: 'Calibri',
      fontSize: 20,
      textAlign: 'center',
      originX: 'center',
      originY: 'center',
      left: pointer.x,
      top: pointer.y,
    });

    const generatedId = ids.generate();
    const g = new fabric.Group([circle, text], {
      selectable: false,
      // any group attributes here
      id: generatedId,
      referenceType: 'referencePoint',
    });

    canvas.add(g);
    canvas.renderAll();
    addReferenceData(mouseEvent, generatedId);
  });
};

export default referencePoint;
