
import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';

// Custom Styles
import './table.css';

const styles = () => ({
  root: {
    fontSize: '20px',
  },
  tableHeaderRow: {
    borderBottom: '2px solid rgb(128, 128, 128)',
  },
  tableHeaderTitle: {
    paddingTop: '9.5px',
    paddingBottom: '9.5px',
    fontSize: '20px',
  },
  button: {
    marginTop: '12px',
  },
});

function ReferenceTable(props) {
  const { classes, data, referenceDataOnChange } = props;

  return (
    <div>
      <MuiTable className="table table-bordered">
        <TableHead>
          <TableRow className={classes.tableHeaderRow}>
            <TableCell className={classes.root}>
              <TextField
                InputProps={{
                  classes: { input: classes.tableHeaderTitle },
                }}
                variant="outlined"
                value={data.header.keyHeader || 'Point'}
                onChange={(e) => { props.headerOnChange(e, 'keyHeader'); }}
                placeholder="Title"
              />
            </TableCell>
            <TableCell className={classes.root}>
              <TextField
                InputProps={{
                  classes: { input: classes.tableHeaderTitle },
                }}
                variant="outlined"
                value={data.header.valueHeader || 'Item'}
                onChange={(e) => { props.headerOnChange(e, 'valueHeader'); }}
                placeholder="Title"
              />
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {data.referenceData.map((point, index) => (
            <TableRow key={point.id}>
              <TableCell className={classes.root}>
                {index + 1}
              </TableCell>
              <TableCell className={classes.root}>
                <TextField
                  InputProps={{
                    classes: { input: classes.rowStyle },
                  }}
                  variant="outlined"
                  value={point.text || ''}
                  onChange={(e) => { referenceDataOnChange(e, point.id); }}
                  placeholder="Enter value"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
    </div>
  );
}

ReferenceTable.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  referenceDataOnChange: PropTypes.func,
  headerOnChange: PropTypes.func
}

export default withStyles(styles)(ReferenceTable);
