import React from 'react';
import PropTypes from 'prop-types';

// @REACT-PDF
import { Page, Text, Font, Image, View, Document, StyleSheet } from '@react-pdf/renderer';
import { SvgComponent } from './PdfSvgTransformer';
import { getNcrClosedIcon, getNcrIcon, getNcrOpenIcon, getNcrUnexpectedIcon } from './Icons';

// DATETIME
import { DateTime } from 'luxon';
import { isDefinedAndInitialized } from '../../helpers/helpers';

const pdfStyles = StyleSheet.create({
    page: {
        paddingBottom: 50,
        paddingTop: 36,
        paddingLeft: 47,
        paddingRight: 50
    },
    pageContent: {
        fontFamily: 'Montserrat',
        fontSize: 9.5
    },
    pageBackground: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        objectFit: 'fill'
    },
    pageFooterContainer: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        paddingBottom: 20
    },
    pageFooterTextContainer: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 10,
        paddingLeft: 12,
        paddingRight: 12,
        borderTop: '1px solid black'
    },
    pageFooterPrimaryText: {
        fontWeight: 'semibold',
        fontFamily: 'Montserrat',
        fontSize: 9,
    },
    pageFooterSecondaryText: {
        fontWeight: 'bold',
        fontFamily: 'Montserrat',
        fontSize: 9,
        color: '#1d9add',
        paddingLeft: 10
    },
    topLogo: {
        width: 95,
        paddingBottom: 5
    },
    metadataSectionLayout: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 15
    },
    metadataSectionLeftColumn: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width: '50%',
        paddingBottom: 10
    },
    leftSubColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        flexGrow: 1
    },
    metadataSectionLeftColumnRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    metadataSectionRightColumn: {
        display: 'flex',
        flexDirection: 'column',
        width: '50%',
        height: '100%'
    },
    title: {
        fontSize: 15,
        fontWeight: 'medium',
        color: '#1d9add'
    },
    leftLabelText: {
        paddingLeft: 5
    },
    leftLabelTextEmphasis: {
        color: '#1d9add',
        fontWeight: 'semibold'
    },
    rightTitle: {
        fontSize: 11,
        fontWeight: 'semibold',
        color: '#1d9add',
        paddingBottom: 5
    },
    rightSubColumns: {
        display: 'flex',
        flexDirection: 'row',
        padding: '10 10 10 10',
        border: '1px solid #cccccc',
        backgroundColor: 'white',
        borderRadius: 5
    },
    rightSubLeftColumn: {
        flexGrow: 1
    },
    rightSubRightColumn: {
        flexGrow: 1,
        borderLeft: '0.5px solid black',
        paddingLeft: 10
    },
    rightLabelText: {

    },
    rightLabelTextEmphasis: {
        fontWeight: 'medium'
    },
    sectionHeaderContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    sectionHeaderNumber: {
        fontSize: 22,
        color: '#1d9add',
    },
    sectionHeaderText: {
        fontSize: 11,
        paddingLeft: 14,
        fontWeight: 'medium',
        paddingTop: 4
    },
    rowContainer: { display: 'flex', flexDirection: 'row' },
    sectionContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: 5,
        marginLeft: 32
    },
    sectionMargin: {
        marginBottom: 10
    },
    subSectionContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginLeft: 32
    },
    singleColumnSectionContainer: {
        width: '100%',
        paddingLeft: 5,
        paddingRight: 5
    },
    twoColumnSectionContainer: {
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 5,
        paddingLeft: 5,
        paddingRight: 5
    },
    columnSectionLabelEmphasis: {
        fontSize: 9,
        fontWeight: 'semibold',
        paddingLeft: 5,
        paddingTop: 3
    },
    columnSectionLabel: {
        fontSize: 7,
        paddingLeft: 5,
        paddingTop: 3,
    },
    columnSectionBorderedTextContainer: {
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 7,
        paddingRight: 7,
        border: '1px solid #cccccc',
        borderRadius: 5,
        flexGrow: 1
    },
    columnSectionTextContainer: {
        paddingBottom: 7,
        paddingLeft: 7,
        paddingRight: 7
    },
    columnSectionText: {
        fontSize: 9
    },
    columnSectionTextEmphasis: {
        fontSize: 9,
        fontWeight: 'medium',
        color: '#1d9add'
    },
    generalTextEmphasis: {
        fontSize: 9,
        fontWeight: 'semibold'
    },
    footNote: {
        paddingTop: 20,
        fontSize: 7
    },
    tagListContainer: {
        paddingLeft: 5,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    primaryTagItem: {
        marginTop: 2,
        marginBottom: 2,
        marginLeft: 2,
        marginRight: 2,
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: 4,
        paddingRight: 4,
        backgroundColor: '#1d9add',
        color: 'white',
        borderRadius: 5
    },
    secondaryTagItem: {
        marginTop: 2,
        marginBottom: 2,
        marginLeft: 2,
        marginRight: 2,
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: 4,
        paddingRight: 4,
        color: '#1d9add',
        border: '0.5px solid #1d9add',
        borderRadius: 5
    },
    hybridPrimaryTag: {
        marginTop: 2,
        marginBottom: 2,
        marginLeft: 2,
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: 4,
        paddingRight: 4,
        color: '#1d9add',
        border: '0.5px solid #1d9add',
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        fontWeight: 'medium'
    },
    hybridSecondaryTag: {
        marginTop: 2,
        marginBottom: 2,
        marginRight: 2,
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: 4,
        paddingRight: 4,
        color: 'black',
        border: '0.5px solid #cccccc',
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5
    },
    neutralTagItem: {
        marginTop: 2,
        marginBottom: 2,
        marginLeft: 2,
        marginRight: 2,
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: 4,
        paddingRight: 4,
        color: 'black',
        border: '0.5px solid black',
        borderRadius: 5
    },
    overviewCriticalHazardContainer: {
        width: '100%', border: '1px solid #cccccc', borderRadius: 4, marginBottom: 5
    },
    overviewCriticalHazardLabel: {
        backgroundColor: '#1d9add', color: 'white', paddingTop: 2,
        paddingBottom: 2, paddingLeft: 4, paddingRight: 4, borderTopLeftRadius: 5, borderTopRightRadius: 5
    },
    overviewCriticalHazardDescription: {
        paddingTop: 3, paddingBottom: 3, paddingLeft: 7, paddingRight: 7
    },
    hazardContainer: {
        width: '100%', borderRadius: 4, marginBottom: 5
    },
    hazardClassContainer: {
        width: '100%'
    },
    hazardRowContainer: {
        width: '100%', borderRadius: 4
    },
    criticalHazardLabel: {
        backgroundColor: '#1d9add', color: 'white', paddingTop: 5, fontWeight: 'semibold',
        paddingBottom: 5, paddingLeft: 4, paddingRight: 4, borderTopLeftRadius: 5, borderTopRightRadius: 5
    },
    planRegisterHazardLabel: {
        color: 'black', paddingTop: 5, border: '1px solid #cccccc', fontWeight: 'semibold',
        paddingBottom: 5, paddingLeft: 4, paddingRight: 4, borderTopLeftRadius: 5, borderTopRightRadius: 5
    },
    planRegisterHazardClassLabel: {
        fontSize: 11,
        color: 'black', paddingTop: 5, fontWeight: 'semibold',
        paddingBottom: 5, paddingLeft: 4, paddingRight: 4
    },
    hazardSubsectionLabel: {
        backgroundColor: '#cccccc', color: 'black', paddingTop: 3,
        paddingBottom: 3, paddingLeft: 8, paddingRight: 8, borderRadius: 2
    },
    hazardDescription: {
        paddingTop: 3, paddingBottom: 3, paddingLeft: 7, paddingRight: 7
    },
    workbriefBoldText: {
        fontWeight: 'semibold',
    },
    workbriefDetailErrorText: {
        color: '#e91e63'
    },
    workbriefDetailWarnText: {
        color: '#ff8d0a'
    },
    workbriefDetailDoneText: {
        color: '#66bb6a'
    },
    hazardAssessmentWarnText: {
        color: '#ff8d0a'
    },
    topAlignedFlexRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start'
    },
    centeredFlexRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    stretchedFlexRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    centeredFlexColumn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    flexStartFlexColumn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center'
    },
    padding3: {
        paddingLeft: 3,
        paddingRight: 3,
        paddingTop: 3,
        paddingBottom: 3
    },
    bottomPadding5: {
        paddingBottom: 5
    },
    xPadding5: {
        paddingLeft: 5,
        paddingRight: 5
    },
    xPadding10: {
        paddingLeft: 10,
        paddingRight: 10
    },
    yPadding5: {
        paddingTop: 5,
        paddingBottom: 5
    },
    lightGreyBorder: {
        border: '1px solid #cccccc'
    },
    tableParentLayout: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    tableColumnParentLayout: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    },
    tableColumnHeaderItemLayout: {
        backgroundColor: '#1d9add', color: 'white', paddingTop: 2,
        paddingBottom: 2, paddingLeft: 4, paddingRight: 4,
        padding: 3, fontSize: 6
    },
    tableColumnTopLeft: {
        borderTopLeftRadius: 5
    },
    tableColumnTopRight: {
        borderTopRightRadius: 5
    },
    tableColumnItemLayout: {
        paddingTop: 2, border: '1px solid #cccccc',
        paddingBottom: 2, paddingLeft: 4, paddingRight: 4,
        padding: 3, fontSize: 6
    },
    tableColumnBottomLeft: {
        borderBottomLeftRadius: 5
    },
    tableColumnBottomRight: {
        borderBottomRightRadius: 5
    },
    fileNotesText: {
        fontSize: 6
    }
});

// Register font
Font.register({ family: 'Montserrat', src: `/Montserrat-Regular.ttf`, fontWeight: 'normal' });
Font.register({ family: 'Montserrat', src: `/Montserrat-Medium.ttf`, fontWeight: 'medium' });
Font.register({ family: 'Montserrat', src: `/Montserrat-SemiBold.ttf`, fontWeight: 'semibold' });
Font.register({ family: 'Montserrat', src: `/Montserrat-Bold.ttf`, fontWeight: 'bold' });

// Returns an React-PDF compatible icon for a given status 
const getNcrStatusIcon = (status) => {
    // -------------------------------------------
    switch(status) {
      case 'OPEN':
        return <SvgComponent svgXml={getNcrOpenIcon('#f44336', 12)} />
      case 'CLOSED':
        return <SvgComponent svgXml={getNcrClosedIcon('#4caf50', 12)} />
      default:
        return <SvgComponent svgXml={getNcrUnexpectedIcon('black', 12)} />
    }
  }


const getNCRRowData = (jobNcrListData) => {
    // --------------------------------
    // 1) Sort all NCR updates by observation timestamp
    let sortedJobNcrUpdateList = jobNcrListData.filter(ncrUpdateItem => {
        // -------------------------------------------
        return ncrUpdateItem.show
    }).sort((ncrUpdateA, ncrUpdateB) => {
        // -------------------------------------------
        return ncrUpdateA.observationTimestamp - ncrUpdateB.observationTimestamp;
    }).sort((ncrUpdateA, ncrUpdateB) => {
        // -------------------------------------------
        return ncrUpdateA.ncrNumber - ncrUpdateB.ncrNumber;
    });


    let ncrRowData = {};
    sortedJobNcrUpdateList.map((ncrUpdateItem, idx) => {
        // -------------------------------------------
        const { ncrId, ncrNumber, ncrReference, issueDescription, ncrUpdateId, observationId,
            observationTimestamp, openedBy, updatedBy, remedyDescription, remediationCompletionDetail,
            show, status } = ncrUpdateItem;
        if (Object.keys(ncrRowData).includes(ncrUpdateItem.ncrId)) {
            // -------------------------------
            // Update to an existing item
            let existingIssueDescription = ncrRowData[ncrUpdateItem.ncrId].issueDescription;
            let includeIssueDescription = isDefinedAndInitialized(issueDescription) && issueDescription !== '' && !existingIssueDescription.includes(issueDescription);
            let existingRemedyDescription = ncrRowData[ncrUpdateItem.ncrId].remedyDescription;
            let includeRemedyDescription = isDefinedAndInitialized(remedyDescription) && remedyDescription !== '' && !existingRemedyDescription.includes(remedyDescription);
            let existingRemediationCompletionDetail = ncrRowData[ncrUpdateItem.ncrId].remediationCompletionDetail;
            let includeRemediationCompletionDetail = isDefinedAndInitialized(remediationCompletionDetail) && remediationCompletionDetail !== '' && !existingRemediationCompletionDetail.includes(remediationCompletionDetail);

            ncrRowData[ncrUpdateItem.ncrId] = {
                ...ncrRowData[ncrUpdateItem.ncrId],
                endNcrUpdateId: ncrUpdateId,
                endObservationId: observationId,
                endObservationTimestamp: observationTimestamp,
                endedBy: updatedBy,
                issueDescription: includeIssueDescription ? `${existingIssueDescription !== '' ? `${existingIssueDescription}\n\n` : ''}${idx > 0 ? `[Update ${DateTime.fromSeconds(observationTimestamp).toFormat('dd/MM/yyyy hh:mm a')}]\n` : ``}${issueDescription}` : existingIssueDescription,
                remedyDescription: includeRemedyDescription ? `${existingRemedyDescription !== '' ? `${existingRemedyDescription}\n\n` : ''}${idx > 0 ? `[Update ${DateTime.fromSeconds(observationTimestamp).toFormat('dd/MM/yyyy hh:mm a')}]\n` : ``}${remedyDescription}` : existingRemedyDescription,
                remediationCompletionDetail: includeRemediationCompletionDetail ? `${ existingRemediationCompletionDetail !== '' ? `${existingRemediationCompletionDetail}\n\n` : '' }${idx > 0 ? `[Update ${DateTime.fromSeconds(observationTimestamp).toFormat('dd/MM/yyyy hh:mm a')}]\n` : ``}${remediationCompletionDetail}` : existingRemediationCompletionDetail,
                endShow: show,
                endStatus: status,
            }
        }
        else {
            ncrRowData[ncrUpdateItem.ncrId] = {
                ncrId,
                ncrNumber,
                ncrReference,
                startNcrUpdateId: ncrUpdateId,
                startObservationId: observationId,
                startObservationTimestamp: observationTimestamp,
                endNcrUpdateId: ncrUpdateId,
                endObservationId: observationId,
                endObservationTimestamp: observationTimestamp,
                endedBy: updatedBy,
                openedBy,
                issueDescription,
                remedyDescription,
                remediationCompletionDetail: isDefinedAndInitialized(remediationCompletionDetail) && remediationCompletionDetail !== '' ?
                    remediationCompletionDetail : '',
                startShow: show,
                endShow: show,
                startStatus: status,
                endStatus: status
            }
        }
    });

    return ncrRowData;
}

export const NCRSummaryPdf = (props) => {
    // --------------------------------
    const { jobDetail, jobNcrListData } = props;
    const { jobId, jobName } = jobDetail;

    const ncrRowData = getNCRRowData(jobNcrListData);

    return (
        <Document>
            <Page size="A4" orientation="landscape" style={pdfStyles.page}>
                <View fixed={true} style={pdfStyles.pageBackground}>
                    <Image src={'/landscape-letter-head-background.png'} style={pdfStyles.pageBackground} />
                </View>
                <View fixed={true} style={pdfStyles.pageFooterContainer}>
                    <View style={pdfStyles.pageFooterTextContainer}>
                        <Text style={pdfStyles.pageFooterPrimaryText}>Unit 6, Level 1, 114 St Georges Bay Road, Parnell 1052, New Zealand</Text>
                        <Text style={pdfStyles.pageFooterSecondaryText}>www.initia.co.nz</Text>
                    </View>
                </View>
                <View fixed={false} style={pdfStyles.pageBackground}>
                    <Image src={'/landscape-letter-head-first-page-extra.png'} style={pdfStyles.pageBackground} />
                </View>
                <View style={pdfStyles.pageContent}>

                    {/* METADATA SECTION (LEFT JOB INFORMATION + RIGHT SUMMARY TABLE) */}
                    <View style={pdfStyles.metadataSectionLayout} >
                        <View style={pdfStyles.metadataSectionLeftColumn}>
                            {/* COMPANY LOGO */}
                            <View>
                                <Image src={`/logo_minified.png`} style={pdfStyles.topLogo} />
                            </View>
                            <View style={pdfStyles.leftSubColumn}>
                                <View style={pdfStyles.metadataSectionLeftColumnRow}>
                                    <Text style={pdfStyles.leftLabelTextEmphasis}>{`INITIA PROJECT REF: `}</Text>
                                    <Text style={pdfStyles.leftLabelText}>{jobId}</Text>
                                </View >
                                <View style={pdfStyles.metadataSectionLeftColumnRow}>
                                    <Text style={pdfStyles.leftLabelTextEmphasis}>{`PROJECT: `}</Text>
                                    <Text style={pdfStyles.leftLabelText}>{jobName.toUpperCase()}</Text>
                                </View>
                            </View>
                        </View>
                    </View>

                    <View style={pdfStyles.metadataSectionLayout} >
                        <View style={pdfStyles.planTitleContainer} >
                            <View style={pdfStyles.stretchedFlexRow}>
                                <View style={{ paddingRight: 3 }}><SvgComponent svgXml={getNcrIcon('#1d9add', 18)} /></View>
                                <Text style={pdfStyles.title}>{`NON-CONFORMANCE SUMMARY - as at ${DateTime.local().toFormat('dd/MM/yyyy hh:mm a')}`}</Text>
                            </View>
                        </View>
                    </View>

                    {/* TABLE HEADER ROW CONTAINER */}
                    <View style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <View style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                            <View style={{ ...pdfStyles.tableColumnHeaderItemLayout, borderTopLeftRadius: 5, width: '5%' }}>
                                <Text>NCR No.</Text>
                            </View>
                            <View style={{ ...pdfStyles.tableColumnHeaderItemLayout, width: '10%' }}>
                                <Text>Title</Text>
                            </View>
                            <View style={{ ...pdfStyles.tableColumnHeaderItemLayout, width: '5%' }}>
                                <Text>Date/Time Opened</Text>
                            </View>
                            <View style={{ ...pdfStyles.tableColumnHeaderItemLayout, width: '5%' }}>
                                <Text>Opened By</Text>
                            </View>
                            <View style={{ ...pdfStyles.tableColumnHeaderItemLayout, width: '20%' }}>
                                <Text>Issue Description</Text>
                            </View>
                            <View style={{ ...pdfStyles.tableColumnHeaderItemLayout, width: '20%' }}>
                                <Text>Description of Remedial Work Required</Text>
                            </View>
                            <View style={{ ...pdfStyles.tableColumnHeaderItemLayout, width: '20%' }}>
                                <Text>Notes on Remedial Work Completed</Text>
                            </View>
                            <View style={{ ...pdfStyles.tableColumnHeaderItemLayout, width: '5%' }}>
                                <Text>Status</Text>
                            </View>
                            <View style={{ ...pdfStyles.tableColumnHeaderItemLayout, width: '5%' }}>
                                <Text>Date/Time Closed</Text>
                            </View>
                            <View style={{ ...pdfStyles.tableColumnHeaderItemLayout, borderTopRightRadius: 5, width: '5%' }}>
                                <Text>Closed By</Text>
                            </View>
                        </View>
                    </View>

                    {/* TABLE ROWS CONTAINER */}
                    {
                        Object.keys(ncrRowData).map(ncrId => {
                            // -----------------------------------
                            const ncrItem = ncrRowData[ncrId];
                            const { ncrNumber, ncrReference, startObservationTimestamp, endObservationTimestamp, endStatus,
                                    openedBy, endedBy, issueDescription, remedyDescription, remediationCompletionDetail } = ncrItem;

                            return (
                                <View key={`ncr-id-${ncrId}`} style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                    <View style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                        <View style={{ ...pdfStyles.tableColumnItemLayout, width: '5%' }}>
                                            <Text>{ `${new String( ncrNumber ).padStart(3, '0')}` }</Text>
                                        </View>
                                        <View style={{ ...pdfStyles.tableColumnItemLayout, width: '10%' }}>
                                            <Text>{ ncrReference }</Text>
                                        </View>
                                        <View style={{ ...pdfStyles.tableColumnItemLayout, width: '5%' }}>
                                            <Text>{ DateTime.fromSeconds(startObservationTimestamp).toFormat('dd/MM/yyyy hh:mm a') }</Text>
                                        </View>
                                        <View style={{ ...pdfStyles.tableColumnItemLayout, width: '5%' }}>
                                            <Text>{ openedBy }</Text>
                                        </View>
                                        <View style={{ ...pdfStyles.tableColumnItemLayout, width: '20%' }}>
                                            <Text>{ issueDescription }</Text>
                                        </View>
                                        <View style={{ ...pdfStyles.tableColumnItemLayout, width: '20%' }}>
                                            <Text>{ remedyDescription }</Text>
                                        </View>
                                        <View style={{ ...pdfStyles.tableColumnItemLayout, width: '20%' }}>
                                            <Text>{ remediationCompletionDetail }</Text>
                                        </View>
                                        <View style={{ ...pdfStyles.tableColumnItemLayout, width: '5%', display: 'flex', flexDirection: 'column', alignItems: "center", justifyContent: 'center' }}>
                                            { getNcrStatusIcon(endStatus) }<Text>{ endStatus }</Text>
                                        </View>
                                        <View style={{ ...pdfStyles.tableColumnItemLayout, width: '5%' }}>
                                            <Text>{ (endStatus === 'CLOSED') ? DateTime.fromSeconds(endObservationTimestamp).toFormat('dd/MM/yyyy hh:mm a') : '-' }</Text>
                                        </View>
                                        <View style={{ ...pdfStyles.tableColumnItemLayout, width: '5%' }}>
                                            <Text>{ (endStatus === 'CLOSED') ? `${endedBy}` : `-` }</Text>
                                        </View>
                                    </View>
                                </View>
                            )
                        })
                    }


                </View>
            </Page>
        </Document>
    )
};

NCRSummaryPdf.propTypes = {
    jobDetail: PropTypes.object,
    jobNcrListData: PropTypes.array
}