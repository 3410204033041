import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// MSAL imports
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from '@azure/msal-browser';

import uuidv4 from 'uuid/v4';
import ids from 'short-id';

// Material UI
import AddAPhoto from '@material-ui/icons/AddAPhoto';
import { withStyles } from '@material-ui/core/styles';
import { ButtonBase } from '@material-ui/core';
import { useSnackbar } from 'notistack';

// Custom Components
import BasicLoader from './basicLoader';

// Custom Services
import { readImageFilePromisified, uploadImageToS3, getPresignedUrlS3GetObject } from '../services/api';
import { dataURIToBlob } from '../helpers/helpers';

// Context
import { InitiaContext } from '../context/initia-context';

// Helpers

import { isDefinedAndInitialized } from '../helpers/helpers';

const snackBarAutoHide = 2000;

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    border: 'dashed',
    borderColor: '#1976d2',
    padding: '20px',
    borderRadius: '10px',
  },
  button: {
    marginTop: '12px',
  },
  input: {
    display: 'none',
  },
  uploadIcon: {
    textAlign: 'center',
    color: '#1976d2',
  },
});

const addPhoto = (file, extension, albumName, { instance, accounts, inProgress }, isSketch) => {
  if (!file) {
    throw new Error('Please choose a file to upload first.');
  }

  return readImageFilePromisified(file)
    .then((img) => {

      const canvas = document.createElement('canvas');

      let MAX_WIDTH = 800;
      let MAX_HEIGHT = 600;
      // Handle enhanced image quality for sketch components
      if (isDefinedAndInitialized(isSketch) && isSketch) {
        MAX_WIDTH = 1000;
        MAX_HEIGHT = 750;
      }

      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else if (height > MAX_HEIGHT) {
        width *= MAX_HEIGHT / height;
        height = MAX_HEIGHT;
      }

      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, width, height);

      const imageUrl = canvas.toDataURL('image/jpeg');
      const imageBlob = dataURIToBlob(imageUrl);

      return uploadImageToS3(imageBlob, `img-${uuidv4()}`, extension, `${albumName}`, { instance, accounts, inProgress });
    });
};

function UploadImage(props) {
  // PROPS
  const { classes } = props;

  // USE CONTEXT HOOK
  const { state } = useContext(InitiaContext);

  // USE STATE HOOK
  const [imageLoading, setImageLoading] = useState(false);

  // MSAL
  const { instance, accounts, inProgress } = useMsal();
  const loginHint = (accounts && accounts[0]?.username) ?? '';
  const request = {
    loginHint,
    scopes: ["User.Read"]
  }
  const isAuthenticated = useIsAuthenticated();
  const isAuthed = isAuthenticated && inProgress === "none" && isDefinedAndInitialized(accounts) && accounts.length > 0 && isDefinedAndInitialized(accounts[0]) && isDefinedAndInitialized(accounts[0].username);

  // OTHER HOOKS
  const { enqueueSnackbar } = useSnackbar();

  const uniqueId = ids.generate();

  // EFFECTS
  useEffect(async () => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      await instance.loginRedirect(request);
    }
  }, [isAuthenticated, inProgress, instance]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // TODO: do something with -> state.file
  };

  const handleImageChange = (e) => {
    if (isAuthed) {
      // --------------------------------
      const file = e.target.files[0];
      const pathRegex = /\.([0-9a-z]+)$/i;
      const extension = e.target.files[0].name.match(pathRegex)[1].toLowerCase();
      let image = null;

      setImageLoading(true);

      enqueueSnackbar('Uploading image', { variant: 'info', autoHideDuration: snackBarAutoHide });
      addPhoto(file, extension, `inspection-media/inspection-${state.observationPage.observationEdit.inspectionId}/image`, { instance, accounts, inProgress }, props.isSketch)
        .then(res => res.location)
        .then(res => getPresignedUrlS3GetObject(res, 'url', { instance, accounts, inProgress }))
        .then(res => {
          image = res;
          setImageLoading(false);
          e.target.value=null
          enqueueSnackbar('Successfully uploaded image', { variant: 'success', autoHideDuration: snackBarAutoHide });
        })
        .then(() => props.setBackgroundImage(e, image))
        .catch((error) => {
          console.error('Error uploading image');
          console.error(error);
          enqueueSnackbar(`Can't upload image. ${error.message}`,
            { variant: 'error', autoHideDuration: snackBarAutoHide + 1000 });
          throw new Error(error);
        });
    }
    else {
      console.info('Not authenticated for this API action');
    }
  };

  return (
    <div>
      <form onSubmit={e => handleSubmit(e)}>
        <label htmlFor={uniqueId}>
          <input
            accept="image/*"
            className={classes.input}
            id={uniqueId}
            type="file"
            onChange={(e) => { e.persist(); handleImageChange(e); }}
          />
          <ButtonBase
            component="span"
            className={classes.root}
          >
            <div className={classes.uploadIcon}>
              {imageLoading
                ? <BasicLoader />
                : <AddAPhoto fontSize="large" />
              }
            </div>
            <div>
              Upload Image
            </div>
          </ButtonBase>
        </label>
      </form>
    </div>
  );
}

UploadImage.propTypes = {
  classes: PropTypes.object,
  setBackgroundImage: PropTypes.func.isRequired,
  isSketch: PropTypes.bool
};

export default withStyles(styles)(UploadImage);
