import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

import OrderArrows from './orderArrows';

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    position: 'relative',
  },
  button: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '96px'
  },
  content: {
    width: 'calc(100% - 48px)',
  },
});

const DeletablePaperLarge = forwardRef((props, ref) => {
  const { classes, children, onClick, title, componentId } = props;

  return (
    <Box my="5px">
      <Paper ref={ref} className={classes.root}>
        <Box mb="10px">{title}</Box>
        <IconButton className={classes.button} aria-label="Delete" onClick={onClick}>
          <DeleteIcon />
        </IconButton>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {children}
          </div>
          <OrderArrows componentId={componentId} componentUpHandler={props.componentUpHandler} />
        </div>
      </Paper>
    </Box>
  );
});

DeletablePaperLarge.displayName = 'DeletablePaperLarge';
DeletablePaperLarge.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number
  ]),
  title: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
  componentId: PropTypes.string,
  componentUpHandler: PropTypes.func,
  onClick: PropTypes.func
}

export default withStyles(styles)(DeletablePaperLarge);
export const propType = DeletablePaperLarge.PropTypes;
