


export const supportedSteelSections = [
    { label: "100UC14.8", value: "100UC14.8" },
    { label: "150UC23.4", value: "150UC23.4" },
    { label: "150UC30", value: "150UC30" },
    { label: "150UC37.2", value: "150UC37.2" },
    { label: "200UC46.2", value: "200UC46.2" },
    { label: "200UC52.2", value: "200UC52.2" },
    { label: "200UC59.5", value: "200UC59.5" },
    { label: "250UC72.9", value: "250UC72.9" },
    { label: "250UC89.5", value: "250UC89.5" },
    { label: "310UC96.8", value: "310UC96.8" },
    { label: "310UC118", value: "310UC118" },
    { label: "310UC137", value: "310UC137" },
    { label: "310UC158", value: "310UC158" },
    { label: 'OTHER - REFER NOTES', value: "OTHER - REFER NOTES" }
]