import React from 'react';
import PropTypes from 'prop-types';
import { Route, useHistory } from "react-router-dom";

// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import { CustomNavigationClient } from "../utils/NavigationClient";

// Material UI
import { Container, Paper, Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

// Custom Components
import ProtectedContent from '../auth/ProtectedContent';
import WelcomePage from '../pages/welcomePage';
import ObservationPage from '../pages/observationPage';
import InitiaAppBar from './initiaAppBar';
import NcrSummaryPage from '../pages/ncrSummaryPage';

// CUSTOM UTILITIES
import { isDefinedAndInitialized } from '../helpers/helpers';

// Media
import logo from '../images/official_logo_minified.png';

const styles = () => ({
});

const SomethingWentWrongContent = () => {
  const environmentLabel = (window.config.ENV !== 'prod') ? window.config.ENV.toUpperCase() : '';

  return (
    <Container maxWidth="md">

      {/* MAIN CONTENT */}
      <Box>
        <Paper>
          <Box p={2}>
            {
              isDefinedAndInitialized(window?.config?.ENV) &&
              window.config.ENV === 'sandpit' &&
              <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Typography color="secondary">{environmentLabel}<br/></Typography>
              </Box>
            }
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mb="50px">
              <Typography variant="h4" color="secondary"><strong>{window.config.SYS_NAME_DISPLAY}</strong></Typography>
            </Box>
            <Box p={2} display="flex" flexDirection="row" justifyContent="center" alignItems="center" mb="50px">
              <Typography color="secondary">Oops something went wrong - please contact support</Typography>
            </Box>
            <Box p={2} display="flex" flexDirection="row" justifyContent="center">
              <img src={logo} alt="Company Logo" style={{ objectFit: 'contain', maxWidth: "40%", maxHeight: "100%" }} />
            </Box>
          </Box>
        </Paper>
      </Box>
    </Container>
  )
}


function Nav(props) {
  const { classes, msalpca } = props;

  // The next lines mean the SPA take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const history = useHistory();
  const navigationClient = new CustomNavigationClient(history);
  msalpca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={msalpca}>
      <Box minHeight="100vh" maxWidth="100vw" display="flex" flexDirection="column">
        <div className={classes.appBar}>
          <InitiaAppBar {...props} />
        </div>
        <Route
          exact
          path={['/home', '/']}
        >
          <ProtectedContent>
            <WelcomePage />
          </ProtectedContent>
        </Route>
        <Route
          path="/observation/:observationId"
        >
          <ProtectedContent>
            <ObservationPage {...props} />
          </ProtectedContent>
        </Route>
        <Route
          path="/job/:jobId/ncr"
        >
          <ProtectedContent>
            <NcrSummaryPage {...props} />
          </ProtectedContent>
        </Route>
        <Route
          path="/oops"
        >
          <SomethingWentWrongContent />
        </Route>
      </Box>
    </MsalProvider>
  );
}

Nav.propTypes = {
  classes: PropTypes.object,
  msalpca: PropTypes.object
}

export default withStyles(styles)(Nav);
