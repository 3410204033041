import React, { useState, useMemo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// MASL React
import { InteractionStatus } from "@azure/msal-browser";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";

// MUI
import { Box, Typography, TextField, MenuItem, CircularProgress, useTheme, useMediaQuery } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import throttle from 'lodash/throttle';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

// INTEGRATION
import { searchJob } from '../services/api';
import { isDefinedAndInitialized } from '../helpers/helpers';



export const SalesforceJobAutosuggest = (props) => {
  // -------------------------------------------
  // PROPS
  const { label, handleChange } = props;

  // STATE
  const [value, setValue] = useState(null); // The selected value of the autocomplete
  const [inputValue, setInputValue] = useState(''); // The input value as typed by the user
  const [options, setOptions] = useState([]); // The options returned from the cloud/server
  const [optionsLoading, setOptionsLoading] = useState(false);

  // MUI HOOKS
  const theme = useTheme();
  const isSmallerThanXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('sm')); 

  // REFS
  const activeFetchCount = useRef(0);

  // MSAL
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const loginHint = (accounts[0]?.idTokenClaims)?.preferred_username ?? '';

  // ASYNC DATA FETCH FUNCTION
  const fetch = useMemo(
    () =>
      throttle(async (request, { instance, accounts, inProgress }, callback) => {
        setOptionsLoading(true);
        activeFetchCount.current++;
        let jobList = await searchJob(request.input, { instance, accounts, inProgress });
        callback(jobList);
        activeFetchCount.current--;
        if (activeFetchCount.current === 0) {
            // --------------------------------
            setOptionsLoading(false);
        }
      }, 400),
    [],
  );


  // EFFECTS
  useEffect(() => {
    // ---------------------------------
    let active = true;

    (async () => {
      // ----------------------------------------
      if (!isAuthenticated && inProgress === InteractionStatus.None) {
        await instance.loginRedirect({
          loginHint,
          scopes: ["User.Read"]
        });
        return undefined;
      }

      if (inputValue === '') {
        setOptions(value ? [value] : []);
        return undefined;
      }

      fetch({ input: inputValue }, { instance, accounts, inProgress },
        (results) => {
          if (active) {
            let newOptions = [];
            setOptions(newOptions)

            if (value) {
              newOptions = [value];
            }

            if (results) {
              newOptions = [...newOptions, ...results];
            }

            setOptions(newOptions);
          }
          return undefined;
        }
      );
    })();

    return () => {
      active = false;
    };
  }, [instance, accounts, inProgress, value, inputValue, fetch]);


  // EVENT HANDLERS
  // N/A

  return (
    <Autocomplete
      id="async-job-autocomplete"
      fullWidth={true}
      getOptionLabel={(option) => (typeof option === 'string' ? option : (isDefinedAndInitialized(option.jobId) ? option.jobId : ''))}
      filterOptions={(x) => x}
      options={optionsLoading ? [] : options}
      loading={optionsLoading}
      autoComplete
      includeInputInList
      filterSelectedOptions
      forcePopupIcon={false}
      clearOnBlur={false}
      value={value}
      onChange={(_, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        if (isDefinedAndInitialized(newValue) && isDefinedAndInitialized(handleChange)) {
          // -------------------------
          let jobId = isDefinedAndInitialized(newValue.jobId) ? newValue.jobId : '';
          handleChange(jobId);
        }
      }}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField 
          {...params} label={label} 
          placeholder='Job number or job name' 
          variant="outlined" fullWidth 
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                { optionsLoading ? <CircularProgress color="secondary" size={20} /> : null}
                { params.InputProps.endAdornment }
              </>
            )
          }}
        />
      )}
      renderOption={(option, { inputValue }) => {

        const jobSearchParts = ['jobId', 'jobName'].filter(optionProp => {
          return isDefinedAndInitialized(option[optionProp]);
        }).map((optionProp) => {
          let matches = match(option[optionProp], inputValue, { findAllOccurrences: true, insideWords: true });

          
          return {
            name: optionProp,
            parts: parse(option[optionProp], matches),
          };
        });

        return (
          <MenuItem component="div">
            <Box style={{ zIndex: 5 }}>
              {
                jobSearchParts.map((jobCharacteristic, idx) => {
                  // ---------------------------------
                  const { name } = jobCharacteristic;

                  let optionsFontSize = '1rem';

                  if ( isSmallerThanXs && name === 'jobId' ) {
                    // ---------------------------------
                    optionsFontSize = '0.7rem';
                  }
                  else if ( isSmallerThanXs ) {
                    // ---------------------------------
                    optionsFontSize = '0.8rem';
                  }
                  else if ( isSmallerThanSm && name === 'jobId' ) {
                    // ---------------------------------
                    optionsFontSize = '0.8rem';
                  }
                  else if ( isSmallerThanSm ) {
                    // ---------------------------------
                    optionsFontSize = '0.9rem';
                  }
                  else if ( name === 'jobId' ) {
                    // ---------------------------------
                    optionsFontSize = '0.75rem';
                  }
                  else {
                    // ---------------------------------
                    optionsFontSize = '0.9rem';
                  }


                  return (
                    <Box key={`${idx}`} display="flex" flexDirection="row">
                      {
                        jobCharacteristic.parts.map((part, index) => (
                          part.highlight ? 
                          (
                            <Typography key={String(index)} component="span" style={{ color: '#1976d2', fontWeight: 800, fontSize: optionsFontSize }}>
                              {part.text.replace(/^ +/, m => '\u00A0'.repeat(m.length)).replace(/ +$/, m => '\u00A0'.repeat(m.length))}
                            </Typography>
                          ) 
                          : 
                          (
                            <Typography key={String(index)} component="span" style={{ fontWeight: 300, fontSize: optionsFontSize }}>
                              {part.text.replace(/^ +/, m => '\u00A0'.repeat(m.length)).replace(/ +$/, m => '\u00A0'.repeat(m.length))}
                            </Typography>
                          )
                        ))
                      }
                    </Box>
                  )
                })
              }
            </Box>
          </MenuItem>
        );
      }}
    />
  );
}

SalesforceJobAutosuggest.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.string,
  handleChange: PropTypes.func
}