import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// MASL
import { useMsal } from "@azure/msal-react";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Box, Divider, Chip, Typography, Avatar, IconButton, SwipeableDrawer } from '@material-ui/core';
import { List, ListItem, ListItemIcon, ListItemText }  from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MapIcon from '@material-ui/icons/Public'

// Custom Components
import AppLogo from './appLogo';

// Context
import { InitiaContext } from '../context/initia-context';

// Custom CONFIG
import { currentVersion } from '../config/generalConfig';

const styles = {
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  swipeableLogo: {
    display: 'flex',
    justifyContent: 'center',
  },
};

function sideList(classes, logo) {
  const { instance } = useMsal();

  return (
    <div className={classes.list}>
      <List>
        <div className="logo">
          <Link to="/home" className={classes.swipeableLogo}>
            <AppLogo height="50px" logo={logo} />
          </Link>
        </div>
      </List>
      <Divider />
      <List>
        <Link to={{ pathname: `https://${(window.config.ENV === 'prod') ? '' : 'sandpit.'}work-and-safety.initia-digital.co.nz` }} target="_blank" style={{ textDecoration: 'none', color: 'black' }}>
          <ListItem button>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Avatar style={{ backgroundColor: '#eb345e' }}>
                <span style={{ fontSize: '17px', fontWeight: '800' }}>H+S</span>
              </Avatar>
              <Box ml="10px">
                <Typography>Work and Safety</Typography>
              </Box>
            </Box>
          </ListItem>
        </Link>
        <Link to={{ pathname: "https://initianz.lightning.force.com/lightning/o/CGCB__Job__c/list" }} target="_blank" style={{ textDecoration: 'none', color: 'black' }}>
          <ListItem button>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Avatar style={{ backgroundColor: '#46b248' }}>
                <span style={{ fontSize: '17px', fontWeight: '800' }}>{ `JOB` }</span>
              </Avatar>
              <Box ml="10px">
                <Typography>{ "Salesforce" }</Typography>
              </Box>
            </Box>
          </ListItem>
        </Link>
        <Link to={{ pathname: `https://initia.maps.arcgis.com/apps/webappviewer/index.html?id=f6175a6d472d4daf9bd95e0daf55af32` }} target="_blank" style={{ textDecoration: 'none', color: 'black' }}>
          <ListItem button>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Avatar style={{ backgroundColor: '#0277bd' }}>
                <MapIcon />
              </Avatar>
              <Box ml="10px">
                <Typography>Map Viewer</Typography>
              </Box>
            </Box>
          </ListItem>
        </Link>

        <Box display="flex" flexDirection="row" my="10px" justifyContent="center">
          <Chip color="secondary" label={(window.config.ENV === 'prod') ? `${currentVersion}` : `${window.config.ENV} ${currentVersion}`}/>
        </Box>

        <Divider />

        <ListItem button onClick={() => instance.logoutRedirect({}) }>
          <ListItemIcon><ExitToAppIcon /></ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </div>
  );
}

function SwipeableMenu(props) {
  const { classes } = props;
  const { state } = useContext(InitiaContext);
  const [left, setLeft] = useState(false);

  const toggleDrawer = (side, open) => {
    switch (side) {
      case 'left':
        setLeft(open);
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <IconButton
        className={classes.menuButton}
        onClick={() => { toggleDrawer('left', true); }}
        color="inherit"
        aria-label="Menu"
      >
        <AppLogo height="50px" logo={state.simplifiedLogo} />
      </IconButton>
      <SwipeableDrawer
        open={left}
        onClose={() => { toggleDrawer('left', false); }}
        onOpen={() => { toggleDrawer('left', true); }}
      >
        <div
          tabIndex={0}
          role="button"
          onClick={() => { toggleDrawer('left', false); }}
          onKeyDown={() => { toggleDrawer('left', false); }}
        >
          {sideList(classes, state.nameLogo)}
        </div>
      </SwipeableDrawer>
    </div>
  );
}

SwipeableMenu.propTypes = {
  classes: PropTypes.object
}

export default withStyles(styles)(SwipeableMenu);
