import React from 'react';
import PropTypes from 'prop-types';

function AppLogo(props) {
  return (
    <div style={{ height: props.height }}>
      <img src={props.logo} style={{ height: props.height }} alt="initia-logo" />
      {props.logoLabel
        && <h2 className="logo-label">{props.logoLabel}</h2>
      }
    </div>
  );
}

AppLogo.propTypes = {
  height: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  logoLabel: PropTypes.string
}

export default AppLogo;
