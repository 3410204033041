import React from 'react';
import { withRouter } from 'react-router-dom';

// Material-UI React
import { Container } from '@material-ui/core'

import ObservationContentContainer from '../components/observationContentContainer';

function ObservationPage(props) {
  return (
    <Container maxWidth="md" >
      <ObservationContentContainer {...props} />
    </Container>
  );
}

export default withRouter(ObservationPage);
