
import { fabric } from 'fabric';


const generateLine = (canvas, pauseAutoSave, restartAutoSave, color) => {
  let line;
  let isDown;


  canvas.on('mouse:down', (fabricEvent) => {
    if (canvas.getActiveObjects().length === 0) {
      isDown = true;
      // Pause autosave to stop data loss at autosave time
      pauseAutoSave();

      const mouseEvent = fabricEvent.e;
      const pointer = canvas.getPointer(mouseEvent);
      const points = [pointer.x, pointer.y, pointer.x, pointer.y];

      line = new fabric.Line(points, {
        strokeWidth: 5,
        fill: color,
        stroke: color,
        originX: 'center',
        originY: 'center',
      });
      canvas.add(line);
    }
  });

  canvas.on('mouse:move', (fabricEvent) => {
    if (!isDown) return;

    const mouseEvent = fabricEvent.e;
    const pointer = canvas.getPointer(mouseEvent);

    line.set({ x2: pointer.x, y2: pointer.y });
    canvas.renderAll();
  });

  canvas.on('mouse:up', () => {
    isDown = false;
    // Restart autosave
    restartAutoSave();

    if (line) {
      line.setCoords();
      line.set('active', false);
      line.selectable = false;
    }
  });
};

export default generateLine;
