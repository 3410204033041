import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import { Grid, Box, Typography, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

// Custom Config
import { config } from '../../config/generalConfig';

// Custom Components
import EditableCell from './cell';
import { isDefinedAndInitialized } from '../../helpers/helpers';



const V2Row = (props) => {
  // ----------------------------------------------
  const { tableType, idx, onTableUpdate } = props;

  const tableFormat = config.v2DataTableTypes.find(tableTypeSearch => tableTypeSearch.tableType === tableType);

  return (
    <Box display="flex" flexDirection="column" border="1px solid lightgrey" borderRadius="3px" paddingY="8px" paddingX="5px" marginY="8px">
      <Typography style={{ paddingLeft: '8px', paddingTop: '5px', paddingBottom: '5px', fontSize: '0.75rem' }}>{tableFormat.rowLabel} {idx + 1}</Typography>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Grid container spacing={1}>
          { 
            props.row.values.map((cell, i) => {
              const cellKey = `${props.row.id}-${i}`;
              const isRowHeader = isDefinedAndInitialized(tableFormat?.rowHeaderNames) && tableFormat?.rowHeaderNames.includes(tableFormat.columnValues[i].name);
              return (
                <Grid key={cellKey} item xs={12} sm={6}>
                  <EditableCell                    
                    onTableUpdate={onTableUpdate}
                    cellData={{
                      type: tableFormat.columnValues[i].name,
                      value: cell.value,
                      id: `${props.row.id}-${i}`,
                      colNum: i,
                      placeholder: tableFormat.columnValues[i].placeholder,
                      label: isRowHeader ? `${tableFormat.columnHeaders[i].default} (row header)` : tableFormat.columnHeaders[i].default,
                      isRowHeader
                    }}
                  />
                </Grid>
              );
            }) 
          }
        </Grid>
        <Box>
          <IconButton
            size="medium"
            type="button"
            aria-label="Delete"
            onClick={(e) => { props.onDelEvent(e, props.row.id); }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}

V2Row.propTypes = {
  idx: PropTypes.number,
  row: PropTypes.object,
  tableType: PropTypes.string,
  onDelEvent: PropTypes.func,
  onTableUpdate: PropTypes.func
}

export default V2Row;
