import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';

// Custom Components
import DeletablePaperLarge from './deletablePaperLarge';
import EditableTable from './fixedHeaderEditableTable/fixed-header-editable-table';

const styles = theme => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
});


function V2DataTable(props) {
  const { deleteHandler } = props;

  return (
    <div>
      {props.componentData.tableIsShown
        && (
        <DeletablePaperLarge
          componentId={props.componentData.componentId}
          title={props.componentData.componentHeader}
          onClick={deleteHandler}
          componentUpHandler={props.componentUpHandler}
        >
          <EditableTable {...props} />

        </DeletablePaperLarge>
        )
      }
    </div>
  );
}

V2DataTable.propTypes = {
  componentData: PropTypes.object,
  componentUpHandler: PropTypes.func,
  deleteHandler: PropTypes.func
};

export default withStyles(styles)(V2DataTable);
