import React from 'react';

import PropTypes from 'prop-types';

// MUI
import { Alert } from '@material-ui/lab';


export const SuccessAlert = (props) => {
    // --------------------------------------
    const { message } = props;

    return (
        <Alert severity="success">{message}</Alert>
    )
}

SuccessAlert.propTypes = {
    message: PropTypes.string
}


export const InfoAlert = (props) => {
    // --------------------------------------
    const { message } = props;

    return (
        <Alert severity="info">{message}</Alert>
    )
}

InfoAlert.propTypes = {
    message: PropTypes.string
}



export const WarnAlert = (props) => {
    // --------------------------------------
    const { message } = props;

    return (
        <Alert severity="warning">{message}</Alert>
    )
}

WarnAlert.propTypes = {
    message: PropTypes.string
}



export const ErrorAlert = (props) => {
    // --------------------------------------
    const { message } = props;

    return (
        <Alert severity="error">{message}</Alert>
    )
}

ErrorAlert.propTypes = {
    message: PropTypes.string
}
