import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// MSAL imports
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from '@azure/msal-browser';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

// Custom Components
import UploadImage from './UploadImage';
import DeletablePaperLarge from './deletablePaperLarge';

// Custom Services
import { getPresignedUrlS3GetObject } from '../services/api';
import { isDefinedAndInitialized } from '../helpers/helpers';
import { Typography } from '@material-ui/core';

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),

  },
  textField: {
    marginTop: '0px',
    marginBottom: '0px',
  },
  button: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  imgCaption: {
    objectFit: 'contain',
    maxWidth: '100%',
    maxHeight: '100%'
  },
  hidden: {
    display: 'none'
  }
});

function Caption(props) {
  // PROPS
  const { classes, deleteHandler, text, componentData, imageCount, includeImageNumber } = props;

  let setImageCount = null;
  if (isDefinedAndInitialized(imageCount)) {
    // ----------------------------------------------
    setImageCount = imageCount;
  }
  let setIncludeImageNumber = false;
  if (isDefinedAndInitialized(includeImageNumber)) {
    // ----------------------------------------------
    setIncludeImageNumber = includeImageNumber;
  }


  // USE STATE HOOKS
  const [imageSrc, setImageSrc] = useState('');

  // USE MSAL HOOK
  const { instance, accounts, inProgress } = useMsal();
  const loginHint = (accounts && accounts[0]?.username) ?? '';
  const request = {
    loginHint,
    scopes: ["User.Read"]
  }
  const isAuthenticated = useIsAuthenticated();
  const isAuthed = isAuthenticated && inProgress === "none" && isDefinedAndInitialized(accounts) && accounts.length > 0 && isDefinedAndInitialized(accounts[0]) && isDefinedAndInitialized(accounts[0].username);

  // EFFECTS
  useEffect(async () => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      await instance.loginRedirect(request);
    }
  }, [isAuthenticated, inProgress, instance]);

  // Need to generate a presigned url for our image to display it on load!
  useEffect(() => {
    if (isAuthed && componentData.image) {
      getPresignedUrlS3GetObject(componentData.image, 'url', { instance, accounts, inProgress })
        .then((res) => {
          setImageSrc(res.presigned);
        });
    }
    else {
      console.info('Not authenticated for this API action');
    }
  }, [componentData.image], isAuthed);

  const handleTextChange = (e) => {
    // Take the inital componentData and mutate before bubbling back up the component tree
    const componentDataUpdate = componentData;
    componentDataUpdate.text = e.target.value || null;
    props.handleClientObservationStateUpdate(
      e, componentData.componentType, componentData.componentId, componentDataUpdate,
    );
  };

  const handleImageChange = (e, image) => {
    const componentDataUpdate = componentData;
    componentDataUpdate.image = image.path || null;
    props.handleClientObservationStateUpdate(
      e, componentData.componentType, componentData.componentId, componentDataUpdate,
    );
  };

  const removeImage = (e) => {
    const componentDataUpdate = componentData;
    componentDataUpdate.image = null;
    props.handleClientObservationStateUpdate(
      e, componentData.componentType, componentData.componentId, componentDataUpdate,
    );
  };

  return (
    <DeletablePaperLarge
      title={<Typography style={{ fontSize: '0.9rem', fontWeight: 'bold', paddingRight: '8px' }}>{`Image${isDefinedAndInitialized(setImageCount) && setIncludeImageNumber ? ` ${setImageCount}` : ' (empty)'}`}</Typography>}
      onClick={deleteHandler}
      componentId={componentData.componentId}
      componentUpHandler={props.componentUpHandler}
    >
      <Grid container>
          <Grid item xs={12} md={6}>
            <Box position="relative"  display={componentData.image ? 'flex' : 'none'} justifyContent="center" alignItems="center" my="15px" mx="5px" >
              <img src={imageSrc} alt="" className={classes.imgCaption} />
              <IconButton className={classes.button} aria-label="Delete" onClick={removeImage}>
                <DeleteIcon />
              </IconButton>
            </Box>
            <Box display={componentData.image ? 'none' : 'flex'} height="85%" justifyContent="center" alignItems="center" my="15px" mx="5px" >
              <UploadImage setBackgroundImage={handleImageChange} />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box justifyContent="center" alignItems="center" my="15px" mx="5px" >
              <TextField
                id="standard-multiline-static"
                label="Caption"
                multiline
                fullWidth
                className={`${classes.textField} `}
                margin="normal"
                variant="outlined"
                onChange={handleTextChange}
                value={text || ''}
              />
            </Box>
          </Grid>
      </Grid>
    </DeletablePaperLarge>
  );
}

Caption.propTypes = {
  classes: PropTypes.object,
  text: PropTypes.string,
  componentData: PropTypes.object,
  handleClientObservationStateUpdate: PropTypes.func,
  componentUpHandler: PropTypes.func,
  deleteHandler: PropTypes.func,
  imageCount: PropTypes.number,
  includeImageNumber: PropTypes.bool
}

export default withStyles(styles)(Caption);
