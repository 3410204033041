import React from 'react';
import PropTypes from 'prop-types';

// MUI
import { Box, Typography, TextField, Slider, Tooltip } from '@material-ui/core';

// HUMPS
import humps from 'humps';

import { isDefinedAndInitialized, prepNullableFieldToString } from '../../helpers/helpers';


export const LabelledTextField = (props) => {
    const { value, name, label, minRows, handleChange, disabled, readOnly, placeholder } = props;

    let setLabel = null;
    if (isDefinedAndInitialized(label)) {
        // ----------------------------
        setLabel = label;
    }

    let setPlaceholder = '';
    if (isDefinedAndInitialized(placeholder)) {
        // ----------------------------
        setPlaceholder = placeholder;
    }

    return (
        <Box width='100%'>
            {
                isDefinedAndInitialized(setLabel) &&
                <Typography variant="body2">
                    {label}
                </Typography>
            }
            <TextField
                variant="outlined"
                fullWidth
                multiline
                value={prepNullableFieldToString(value)}
                minRows={isDefinedAndInitialized(minRows) ? minRows : 1}
                onChange={handleChange}
                name={name}
                size="small"
                label=""
                InputProps={{ readOnly }}
                InputLabelProps={{ shrink: false }}
                style={{
                    bgcolor: 'primary.main', color: 'primary.contrastText',
                    '& .MuiOutlinedInputRoot': { borderRadius: '5px' }
                }}
                disabled={disabled}
                placeholder={setPlaceholder}
            />
        </Box>
    )
}

LabelledTextField.propTypes = {
    value: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    minRows: PropTypes.number,
    handleChange: PropTypes.func,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    defaultValue: PropTypes.string,
    placeholder: PropTypes.string,
    checked: PropTypes.bool
}


const ValueLabelComponent = props => {
    const { children, open, value } = props;

    return (
        <Tooltip open={open} enterDelay={1000} placement="top" title={value}>
            {children}
        </Tooltip>
    );
}

ValueLabelComponent.propTypes = {
    children: PropTypes.node,
    open: PropTypes.bool,
    value: PropTypes.string
}


export const OverrideableSlider = (props) => {
    const { value, name, label, marks, valueText, valueLabelFormat, handleChange, width, inputColor, fontSize } = props;

    const inputWidth = isDefinedAndInitialized(width) ? width : "100%";
    const setFontSize = isDefinedAndInitialized(fontSize) ? fontSize : '0.8rem';

    let derivedValue = marks.find(mark => mark.label.toUpperCase().split(' ').join('_') === value)?.value;
    derivedValue = isDefinedAndInitialized(derivedValue) ? derivedValue : 0;


    return (
        <Box width='100%'>
            <Box>
                <Typography variant="body2">
                    {label}
                </Typography>
            </Box>
            <Box width={inputWidth} mx="30px">
                <Slider
                    name={name}
                    value={derivedValue}
                    style={{ color: isDefinedAndInitialized(inputColor) ? inputColor : 'secondary.main', '& .MuiSliderMarkLabel': { fontSize: setFontSize } }}
                    aria-label={humps.decamelize(label.split(' ').join('-'))}
                    valueLabelFormat={valueLabelFormat}
                    getAriaValueText={valueText}
                    step={null}
                    marks={marks}
                    valueLabelDisplay="auto"
                    ValueLabelComponent={ValueLabelComponent}
                    onChangeCommitted={(_, value) => {
                        let selectedValue = marks.find(mark => mark.value === value)?.label;
                        selectedValue = isDefinedAndInitialized(selectedValue) ? selectedValue : '';
                        let event = {
                            target: {
                                value: selectedValue,
                                name: name
                            }
                        }
                        return handleChange(event);
                    }}
                />
            </Box>
        </Box>
    )
}

OverrideableSlider.propTypes = {
    label: PropTypes.string,
    marks: PropTypes.array,
    valueText: PropTypes.func,
    valueLabelFormat: PropTypes.func,
    handleChange: PropTypes.func,
    width: PropTypes.number,
    inputColor: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    fontSize: PropTypes.string
}