import React, { Component } from "react";
import PropTypes from "prop-types";

import { fabric } from "fabric";

// Colour picker
import { CirclePicker } from 'react-color';

// Material UI
import { Box, Typography, Button, Tooltip, withWidth, isWidthDown } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import GestureIcon from '@material-ui/icons/Gesture';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import ClearIcon from '@material-ui/icons/Clear';

// React-icons
import { FaDrawPolygon, FaMousePointer } from "react-icons/fa";
import { GiPencilRuler } from "react-icons/gi";
import { BiText } from 'react-icons/bi';
import { VscActivateBreakpoints } from 'react-icons/vsc';

// Custom Components
import UploadImage from "./UploadImage";
import generateLine from "../services/generateLine";
import referencePoint from "../services/referencePoint";
import { drawPolygon, clearPolygon } from "../services/drawPolygon";
import freeDraw from "../services/freeDraw";
import textInput from "../services/textInput";

// Custom Services
import { getPresignedUrlS3GetObject } from "../services/api";
import { isDefinedAndInitialized } from "../helpers/helpers";

const MIN_IMAGE_WIDTH = 500;
const MIN_SKETCH_HEIGHT = 500;

const styles = (theme) => ({
  uploadButton: {
    position: "absolute",
    top: "200px",
    left: "360px",
  },
  button: {
    margin: theme.spacing(1),
  },
});

class Canvas extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    activeTool: null,
    showImageUpload: true,
    color: '#fc0303'
  };

  updateCanvasForObjectsAndBackgroundImage = async () => {
    // ---------------------------------------
    const { instance, accounts, inProgress, canvasStore, canvasStoreMetadata } = this.props;
    const isAuthed = inProgress === "none" && isDefinedAndInitialized(accounts) && accounts.length > 0 && isDefinedAndInitialized(accounts[0]) && isDefinedAndInitialized(accounts[0].username);

    const { componentId } = this.props.componentData;

    if (isAuthed
        && isDefinedAndInitialized(canvasStore[componentId])
        && isDefinedAndInitialized(canvasStoreMetadata[componentId])
        && 'imageHeight' in canvasStoreMetadata[componentId]
        && isDefinedAndInitialized(canvasStoreMetadata[componentId].imageHeight)) {
      // ---------------------------------------
      // 1) Load the canvas content from JSON
      this.canvas.loadFromJSON(
        canvasStore[componentId],
        this.canvas.renderAll.bind(this.canvas)
      );

      // 2) Load the background image from S3 and update the canvas with it
      if (
        (canvasStoreMetadata[componentId]?.hasBackgroundImage ??
          null) &&
        canvasStoreMetadata[componentId].hasBackgroundImage
      ) {
        let image = await getPresignedUrlS3GetObject(
          canvasStoreMetadata[componentId].backgroundImage,
          'url',
          { instance, accounts, inProgress }
        );
        await this.setBackgroundImage(
          null,
          image,
          canvasStoreMetadata[componentId].imageHeight
        );
        await this.setCanvasComponentSize();
      } else {
        let image = {
          path: `${window.location.origin}/blank_sketch_background.png`,
        };
        await this.setBackgroundImage(
          null,
          image,
          canvasStoreMetadata[componentId].imageHeight
        );
        await this.setCanvasComponentSize();
      }
    }
    else if (isAuthed) {
      // -------------------------------
      let image = {
        path: `${window.location.origin}/blank_sketch_background.png`,
      };
      await this.setBackgroundImage(
        null,
        image,
        500
      );
      await this.setCanvasComponentSize();
    }
    else {
      console.info('Not authenticated for this API action');
    }
  };

  resizeCanvasComponentBasedOnImage = async (image) => {
    // -------------------------------------------
    let imagePath = image?.presigned ?? null ? image.presigned : image.path;
    return new Promise((resolve) => {
      fabric.Image.fromURL(imagePath, (fabricImage) => {
        const fabricImageWidth = fabricImage.getOriginalSize().width;
        const fabricImageHeight = fabricImage.getOriginalSize().height;

        const scaledImageWidth =
          this.props?.sketchDim?.width ?? null
            ? this.props.sketchDim.width
            : MIN_IMAGE_WIDTH;
        fabricImage.scaleToWidth(scaledImageWidth);

        const aspectRatio = fabricImageHeight / fabricImageWidth;
        this.canvas.setWidth(scaledImageWidth);
        this.canvas.setHeight(scaledImageWidth * aspectRatio);
        this.canvas.renderAll();
        resolve();
      });
    });
  };

  componentDidMount = async () => {
    // -------------------------------
    await this.updateCanvasForObjectsAndBackgroundImage();
  };

  shouldComponentUpdate = (nextProps) => {
    const { shouldAutosave } = this.props;
    const nextShouldAutosave = nextProps.shouldAutosave;

    // Block canvas re-render if the props change is only the autosave being temporarily disabled
    if (shouldAutosave !== nextShouldAutosave) { return false; }
    return true;
  }

  componentDidUpdate = async (prevProps) => {
    // Typical usage (don't forget to compare props):
    const { firstLoadComplete } = this.props;
    const { firstLoadComplete: prevFirstLoadComplete } = prevProps;

    if (!prevFirstLoadComplete && firstLoadComplete) {
      await this.updateCanvasForObjectsAndBackgroundImage();
    }
  }

  setAsActiveTool = (tool) => {
    this.setAllObjectsSelectable(false);
    this.canvas.discardActiveObject();
    this.canvas.requestRenderAll();
    this.canvas.selection = false;
    this.setState({
      activeTool: tool,
    });
  };

  setBackgroundImage = (e, image, imageHeight) => {
    return new Promise((resolve) => {
      let imagePath = image?.presigned ?? null ? image.presigned : image.path;
      fabric.Image.fromURL(
        imagePath,
        (fabricImage) => {
          const fabricImageWidth = fabricImage.getOriginalSize().width;
          const fabricImageHeight = fabricImage.getOriginalSize().height;
          const aspectRatio = fabricImageHeight / fabricImageWidth;

          const scaledImageWidth =
            this.props?.sketchDim ?? null
              ? this.props.sketchDim.width
              : MIN_IMAGE_WIDTH;

          // Get zoom level
          let zoomLevel =
          (scaledImageWidth) / fabricImageWidth;

          this.canvas.setZoom(zoomLevel);

          this.canvas.setBackgroundImage(
            fabricImage,
            this.canvas.renderAll.bind(this.canvas)
          );

          this.canvas.setWidth(scaledImageWidth);
          this.canvas.setHeight(scaledImageWidth * aspectRatio);

          // Conditionally update the canvas store metadata if the background image has changed
          // ------------------------------------------------
          this.props.updateCanvasStoreMetadata(
            this.props.componentData.componentId,
            {
              hasBackgroundImage:
                image.path !==
                `${window.location.origin}/blank_sketch_background.png`
                  ? true
                  : false,
              backgroundImage: image.path,
              imageHeight:
                imageHeight ?? null
                  ? imageHeight
                  : scaledImageWidth * aspectRatio,
            }
          );
          this.canvas.requestRenderAll();
          resolve(0);
        },
        { crossOrigin: "Anonymous" }
      );
    })
    .then(() => {
      this.setState({
        activeTool: null,
        showImageUpload: image.path === `${window.location.origin}/blank_sketch_background.png`
      });
    });
  };

  setAllObjectsSelectable = (isSelectable) => {
    this.canvas
      .getObjects()
      .map((object) => object.set("selectable", isSelectable));
  };

  setSelectionTool = () => {
    this.setState({
      activeTool: null,
    });
    this.setAllObjectsSelectable(true);
  };

  clearAll = (e) => {
    this.canvas.remove.apply(this.canvas, this.canvas.getObjects().concat());
    clearPolygon();
    this.removeCanvasBackground();
    this.setState({
      activeTool: null,
      showImageUpload: true
    });
    this.props.deleteReferenceData(e);
  };

  removeCanvasObject = (e) => {
    // assumption that elements cannot be multi selected and removed as a group.
    const activeObject = this.canvas.getActiveObject();
    if (!activeObject) return;
    if (activeObject.referenceType === "referencePoint") {
      this.props.updateReferenceData(e, activeObject.id);

      const activeTextObjects = activeObject.getObjects("text");
      const deletedItemNumber = Number(activeTextObjects[0].text);

      this.canvas.getObjects().forEach((object) => {
        if (object.referenceType === "referencePoint") {
          const textObjects = object.getObjects("text");
          const objectNumber = Number(textObjects[0].text);
          if (objectNumber > deletedItemNumber) {
            textObjects[0].set("text", (objectNumber - 1).toString());
            this.canvas.renderAll();
          }
        }
      });
    }
    this.canvas.remove(activeObject);
    this.canvas.selection = false;
  };

  // This is called once when the component is first rendered
  initialiseCanvas = (canvasNode) => {
    // ----------------------------------------
    this.canvas = new fabric.Canvas(canvasNode, { selection: false });
    this.canvas.setWidth(MIN_IMAGE_WIDTH);
    this.canvas.setHeight(MIN_SKETCH_HEIGHT);
    this.canvas.on("after:render", this.modifiedHandler);

    document.onkeydown = (event) => {
      event = window.event;

      if (event.code === 'Delete') {
        // ----------------------
        this.removeCanvasObject(event);  
      }
    }
  };

  setCanvasComponentSize = async () => {
    // ----------------------------------------
    const { instance, accounts, inProgress } = this.props;
    const isAuthed = inProgress === "none" && isDefinedAndInitialized(accounts) && accounts.length > 0 && isDefinedAndInitialized(accounts[0]) && isDefinedAndInitialized(accounts[0].username);

    const { componentId } = this.props.componentData;

    let image = null;

    if (isAuthed
       && (this.props?.canvasStoreMetadata[componentId]?.hasBackgroundImage ??
        null) &&
      this.props.canvasStoreMetadata[componentId].hasBackgroundImage
    ) {
      image = await getPresignedUrlS3GetObject(
        this.props.canvasStoreMetadata[componentId].backgroundImage,
        'url',
        { instance, accounts, inProgress }
      );
    } else if (isAuthed) {
      image = {
        path: `${window.location.origin}/blank_sketch_background.png`,
      };
    }
    else {
      console.info('Not authenticated for this API action');
    }

    this.resizeCanvasComponentBasedOnImage(image);
    return;
  };

  // handler for done modifying objects on canvas
  modifiedHandler = () => {
    // This part is a little tricky!
    // While we want to keep the background image displayed (at a presigned S3 URL)
    // We want to delete the background image from the canvas object prior stringifying it
    // This means when we load the canvas freshly, we can call 'setBackgroundImage'
    // to ensure we populate the background image with a presigned s3Url
    const objectCanvas = this.canvas.toObject();
    delete objectCanvas.backgroundImage;
    const stringifiedCanvas = JSON.stringify(objectCanvas);
    this.props.updateCanvasStore(
      this.props.componentData.componentId,
      stringifiedCanvas,
      this.canvas
    );
  };

  removeCanvasBackground = () => {
    const { componentData, canvasStoreMetadata } = this.props;
    const { componentId } = componentData;

    this.props.updateCanvasStoreMetadata(componentId, {
      hasBackgroundImage: false,
      backgroundImage: `${window.location.origin}/blank_sketch_background.png`,
      imageHeight: MIN_SKETCH_HEIGHT,
    });

    let image = {
      path: `${window.location.origin}/blank_sketch_background.png`,
    };
    this.setBackgroundImage(
      null,
      image,
      canvasStoreMetadata[componentId].imageHeight
    );
  };

  handleChangeColorTool = (color) => {
    // -----------------------------

    this.setState({
      color: color.hex
    });
  }


  render() {
    const { classes, referenceNumber, addReferenceData,
            pauseAutoSave, restartAutoSave, width } = this.props;
    const { color } = this.state;

    const isSmallerThanXs = isWidthDown('xs', width);

    if (this.canvas) {
      this.canvas.off("mouse:down");
      this.canvas.off("mouse:move");
      this.canvas.off("mouse:up");
      this.canvas.isDrawingMode = false;

      if (this.state.activeTool === "line") {
        // --------------------------------
        generateLine(this.canvas, pauseAutoSave, restartAutoSave, color);
      } 
      else if (this.state.activeTool === "polygon") {
        // --------------------------------
        drawPolygon(this.canvas, this.setSelectionTool, color);
      } 
      else if (this.state.activeTool === "freeDraw") {
        // --------------------------------
        freeDraw(this.canvas, pauseAutoSave, restartAutoSave, color);
      } 
      else if (this.state.activeTool === "textInput") {
        // --------------------------------
        textInput(this.canvas, this.setSelectionTool, color);
        // This is required to ensure additional text boxes are not stacked up
        this.setState({
          activeTool: null
        })
      } 
      else if (this.state.activeTool === "point") {
        // --------------------------------
        referencePoint(this.canvas, referenceNumber, addReferenceData);
      }
    }

    return (
      <Box>
        <canvas ref={this.initialiseCanvas} />
        {this.state.showImageUpload
         && (<Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            my="15px"
            mx="5px"
          >
            <UploadImage
              setBackgroundImage={this.setBackgroundImage}
              observationEdit={this.props.observationEdit}
              isSketch={true}
            />
          </Box>
        )}
        <Box display="flex" flexDirection="row" justifyContent="flex-start" flexWrap="wrap">
          <Tooltip title="Click and drag to draw a line">
            <Button
              startIcon={<GiPencilRuler />}
              className={classes.button}
              variant="contained"
              onClick={() => {
                this.setAsActiveTool("line");
              }}
              color={(this.state.activeTool === 'line') ? 'primary' : 'default'}
            >
              { isSmallerThanXs ? `` : `Line` }
            </Button>
          </Tooltip>
          <Tooltip title="Click to add points. Close the polygon by clicking the first point (red)">
            <Button
              startIcon={<FaDrawPolygon />}
              className={classes.button}
              variant="contained"
              onClick={() => {
                this.setAsActiveTool("polygon");
              }}
              color={(this.state.activeTool === 'polygon') ? 'primary' : 'default'}
            >
              { isSmallerThanXs ? `` : `Polygon` }
            </Button>
          </Tooltip>
          <Tooltip title="Click and drag to freehand draw">
            <Button
              startIcon={<GestureIcon />}
              className={classes.button}
              variant="contained"
              onClick={() => {
                this.setAsActiveTool("freeDraw");
              }}
              color={(this.state.activeTool === 'freeDraw') ? 'primary' : 'default'}
            >
              { isSmallerThanXs ? `` : `Free draw` }
            </Button>
          </Tooltip>
          <Tooltip title="Click to add a moveable textbox in the top-left">
            <Button
              startIcon={<BiText />}
              className={classes.button}
              variant="contained"
              onClick={() => {
                this.setAsActiveTool("textInput");
              }}
              color={(this.state.activeTool === 'textInput') ? 'primary' : 'default'}
            >
              { isSmallerThanXs ? `` : `Add text` }
            </Button>
          </Tooltip>
          <Tooltip title="Click to add a reference point">
            <Button
              startIcon={<VscActivateBreakpoints />}
              className={classes.button}
              variant="contained"
              onClick={() => {
                this.setAsActiveTool("point");
              }}
              color={(this.state.activeTool === 'point') ? 'primary' : 'default'}
            >
              { isSmallerThanXs ? `` : `Point` }
            </Button>
          </Tooltip>
          <Tooltip title="Selection a sketch item to delete or move">
            <Button
              startIcon={<FaMousePointer />}
              className={classes.button}
              variant="contained"
              onClick={this.setSelectionTool}
              color={(this.state.activeTool === null) ? 'primary' : 'default'}
            >
              { isSmallerThanXs ? `` : `Select` }
            </Button>
          </Tooltip>
        </Box>
        <Box mx="50px" my="10px">
          <Box my="10px" display="flex" flexDirection="row" justifyContent="center" alignItems="center">
            <Typography style={{ fontSize: isSmallerThanXs ? '0.8rem' : '0.9rem' }} >First pick a colour then a shade for the line, polygon, free draw and text tools</Typography>
          </Box>
          <Box my="10px" display="flex" flexDirection="row" justifyContent="center" alignItems="center">
            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
              <CirclePicker
                width={isSmallerThanXs ? 180 : 305}
                color={color} 
                onChange={this.handleChangeColorTool}
                circleSpacing={8}
                colors={[
                  "#000000", "#fc0303",  
                  "#e27300", "#fcc400", 
                  "#68bc00", "#16a5a5",
                  "#009ce0", "#7b64ff",
                  "#e9b0a9", "#ebc39a",
                  "#fdeaa7", "#d6f7ad",
                  "#bcecec", "#b5def1",
                  "#d9d3fd", "#cccccc"
                ]}
              />
            </Box>
          </Box>
        </Box>
        <Tooltip title="Removes a selected sketch item">
          <Button
            startIcon={<RemoveCircleOutlineIcon />}
            className={classes.button}
            variant="contained"
            onClick={(e) => {
              this.removeCanvasObject(e);
            }}
          >
            { isSmallerThanXs ? `` : `Remove` }
          </Button>
        </Tooltip>
        <Tooltip title="Clears the sketch area including the background image">
          <Button
            startIcon={<ClearIcon />}
            className={classes.button}
            variant="contained"
            onClick={(e) => {
              this.clearAll(e);
            }}
          >
            { isSmallerThanXs ? `` : `Clear all` }
          </Button>
        </Tooltip>
      </Box>
    );
  }
}

Canvas.propTypes = {
  classes: PropTypes.object,
  componentData: PropTypes.object,
  observationEdit: PropTypes.object,
  canvasStore: PropTypes.object,
  canvasStoreMetadata: PropTypes.object,
  updateCanvasStore: PropTypes.func,
  updateCanvasStoreMetadata: PropTypes.func,
  addReferenceData: PropTypes.func,
  updateReferenceData: PropTypes.func,
  deleteReferenceData: PropTypes.func,
  referenceNumber: PropTypes.number,
  sketchDim: PropTypes.object,
  shouldAutosave: PropTypes.bool,
  pauseAutoSave: PropTypes.func,
  restartAutoSave: PropTypes.func,
  accounts: PropTypes.array,
  instance: PropTypes.object,
  inProgress: PropTypes.string,
  firstLoadComplete: PropTypes.bool,
  width: PropTypes.string
};

export default withStyles(styles)(withWidth()(Canvas));
