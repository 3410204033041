import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';

// MSAL imports
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from '@azure/msal-browser';

// Material UI
import { useTheme, withStyles } from '@material-ui/core/styles';
import { CircularProgress, Divider, Box, Link, Paper, Avatar, Chip, Typography, useMediaQuery } from '@material-ui/core';

// Material UI Icons
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import GetAppIcon from '@material-ui/icons/GetApp';
import LoopIcon from '@material-ui/icons/Loop';
import LaunchIcon from '@material-ui/icons/Launch';

// Material UI Colours
import green from '@material-ui/core/colors/green';

import {
  useQuery,
  useQueryClient
} from 'react-query';

// Custom Components
import ObservationSummaryList from './observationSummaryList';
import LinearProgressWithLabel from './linearLoaderWithLabel';
import { ErrorComponent } from './Error';

// Custom Classes
import { SalesforceJobAutosuggest } from './salesforceJobAutosuggest';

// Custom Config
import { config } from '../config/generalConfig';
const snackBarAutoHide = 2000;

// Custom Services
import { searchOLAP, getJobDetail, 
          createBulkDownload, pollForLoadingStateCompletionResult,
          postObservationBulkUpdate } from '../services/api';

// Custom Helpers
import { isDefinedAndInitialized } from '../helpers/helpers';


const styles = theme => ({
  root: {
    paddingLeft: 0,
  },
  wrapper: {
    margin: 5,
    position: 'relative',
  },
  autosuggest: {
    paddingLeft: 0,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    marginLeft: theme.spacing(1),
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonInactive: {
    marginLeft: theme.spacing(1),
  },
  fabFail: {
    marginBottom: '20px',
  },
  fabProgress: {
    color: '#B0BEC5',
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
    marginLeft: theme.spacing(1),
  },
  listItemAvatar: {
    marginRight: '5px',
  },
  listItemText: {
    paddingLeft: '25px',
    color: 'rgb(0,0,0,0.87)',
  },
});

const getJobStateColour = (state) => {
  // ----------------------------------
  switch (state) {
    case 'Proposal':
      return 'rgba(142, 50, 188, 0.5)';
    case 'In Progress':
      return 'rgb(121, 201, 154)';
    case 'On Hold - Proposal':
      return 'rgb(255, 222, 125)';
    case 'Completed':
      return 'rgb(227, 242, 253)';
    case 'Cancelled':
      return 'rgba(186, 136, 57, 0.5)';
    case 'Lost':
      return 'light-grey';
    case 'In Transition':
      return 'rgba(121, 201, 154, 0.5)';
    default:
      return 'light-grey';
  }
};

const JobListHeaderPanel = props => {
  const { classes, validatedJobSelection, obsList, publishableObs } = props;
  const { buildingConsentNumbers } = validatedJobSelection;
  const hasBuildingConsentNumbers = isDefinedAndInitialized(buildingConsentNumbers);

  // STATE
  const [ loadingState, setLoadingState ] = useState(null);

  // USE MSAL HOOK
  const { instance, accounts, inProgress } = useMsal();
  const loginHint = (accounts && accounts[0]?.username) ?? '';
  const request = {
    loginHint,
    scopes: ["User.Read"]
  }
  const isAuthenticated = useIsAuthenticated();
  const isAuthed = isAuthenticated && inProgress === "none" && isDefinedAndInitialized(accounts) && accounts.length > 0 && isDefinedAndInitialized(accounts[0]) && isDefinedAndInitialized(accounts[0].username);

  // USE REF HOOKS
  const _isMounted = useRef(true);

  // MUI HOOKS
  const theme = useTheme();
  const isSmallerThanXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('sm'));

  // REACT QUERY
  const queryClient = useQueryClient();

  // EFFECTS
  useEffect(async () => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      await instance.loginRedirect(request);
    }
  }, [isAuthenticated, inProgress, instance]);
  
  useEffect(() => {
    _isMounted.current = true;

    return () => { // ComponentWillUnmount in Class Component
        _isMounted.current = false;
    }
  }, []);

  // CUSTOM HOOKS
  const { enqueueSnackbar } = useSnackbar();

  const publishableObsCount = publishableObs.length;
  const canPublishAllForJob = isDefinedAndInitialized(obsList) && obsList.length > 0 && publishableObsCount === obsList.length;
  let helperMessage = '';

  if ( isDefinedAndInitialized(obsList) && !canPublishAllForJob && obsList.length === 0 ) {
    helperMessage = 'No observations to download';
  }
  else if ( isDefinedAndInitialized(obsList) && !canPublishAllForJob && obsList.length > 0 ) { helperMessage = 'Approvals Needed' }

  const onBulkDownloadHandler = async () => {
    // ------------------------------------------
    if (isAuthed) {
      // ----------------------------------------
      const { jobId } = validatedJobSelection;

      let loadingStateUpdate = await createBulkDownload({}, jobId, { instance, accounts, inProgress });

      if (!_isMounted.current) { return; }
      setLoadingState(loadingStateUpdate);

      let loadingStateUpdateCallback = (result) => {

        if (!_isMounted.current) { return; }
        setLoadingState(result);
        if (result.percentageComplete === 100) { enqueueSnackbar('Bulk download complete, check your email', { variant: 'success', autoHideDuration: snackBarAutoHide }); }
      }

      await pollForLoadingStateCompletionResult(loadingStateUpdate.id, { instance, accounts, inProgress }, loadingStateUpdateCallback);


      if (!_isMounted.current) { return; }
      setLoadingState(null);
    }
    else {
      // -------------------------------
      console.info('Not authenticated for this API action');
    }
  }

  const onResequenceHandler = async () => {
    // ------------------------------------------
    if (isAuthed) {
      // --------------------------------------
      const { jobId } = validatedJobSelection;

      try {
        await postObservationBulkUpdate('resequence', { jobId }, { instance, accounts, inProgress });
        // Invalidate obs list to refresh on resequence success
        queryClient.invalidateQueries(['jobObsList', jobId]);
        enqueueSnackbar('Observation resequencing completed', { variant: 'success', autoHideDuration: snackBarAutoHide });
      }
      catch (error) {
        enqueueSnackbar(`Error during observation resequencing: ${error}`, { variant: 'error', autoHideDuration: snackBarAutoHide });
      }
    }
    else {
      // -------------------------------
      console.info('Not authenticated for this API action');
    }
  }
  
  return (
      <Paper
        key={ validatedJobSelection.jobUUID }
      >
      <Link
        href={`${validatedJobSelection.webUrl}`}
        style={{ textDecoration: 'none' }}
        target="_blank"
      >
        <Box
          display="flex"
          flexDirection={isSmallerThanSm ? "column-reverse" : "row" }
          px="20px"
          pt="10px"
          mr={5}
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="flex-start"
          >
            <Avatar className={ classes.listItemAvatar } style={{ marginRight: '12px' }}>
              <WorkOutlineIcon />
            </Avatar>
            <Avatar
              className={ classes.listItemAvatar }
              style={{ zIndex: 2 }}
              // eslint-disable-next-line max-len
              src={`https://initia-general.s3-ap-southeast-2.amazonaws.com/staff-media/${validatedJobSelection.partnerId}.jpg`}
            />
            <Avatar
              className={ classes.listItemAvatar }
              style={{ zIndex: 2 }}
              // eslint-disable-next-line max-len
              src={`https://initia-general.s3-ap-southeast-2.amazonaws.com/staff-media/${validatedJobSelection.managerId}.jpg`}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            color="black"
            paddingBottom="5px"
          >
            <Box fontSize="1rem"><strong>{validatedJobSelection.jobId}</strong></Box>
            <Box fontSize="0.75rem"><strong>{validatedJobSelection.jobName}</strong></Box>            
            {
              hasBuildingConsentNumbers &&
              <Box textOverflow="wrap">
                <Typography style={{ fontSize: '0.75rem' }}>
                  <em>Building Consents: {buildingConsentNumbers.split(',').join(', ')}</em>
                </Typography>
              </Box>
            }
            {
              !isSmallerThanXs &&
              <Box fontSize={12}>{validatedJobSelection.description}</Box>
            }
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            style={{ marginLeft: isSmallerThanSm ? '0px' : 'auto' }}
          >
            {
              !isSmallerThanXs &&
              <Box
                fontSize="default"
                display="flex"
                flexDirection="row"
                justifyContent={ isSmallerThanSm ? "flex-start" : "flex-end" }
              >
                <Chip
                  size="small"
                  label={validatedJobSelection.jobStatus}
                  style={{ backgroundColor: getJobStateColour(validatedJobSelection.jobStatus), fontSize: isSmallerThanSm ? '0.7rem' : '1rem' }}
                />
              </Box>
            }
            <Box
              fontSize={12}
              display="flex"
              flexDirection="row"
              justifyContent={ isSmallerThanSm ? "flex-start" : "flex-end" }
              color="black"
            >{validatedJobSelection.client}
            </Box>
          </Box>
        </Box>
        </Link>
        <Box display="flex" flexDirection={isSmallerThanSm ? "column" : "row" } flexWrap="wrap" alignItems="flex-start" paddingX="20px" paddingY="10px">
          <Box>
              <Chip
                variant="outlined"
                size="small"
                style={{ marginRight: '10px', marginTop: '5px', marginBottom: '5px' }}
                icon={<GetAppIcon />}
                label={ (canPublishAllForJob) ? 'Download all': helperMessage }
                clickable={true}                
                disabled={!canPublishAllForJob}
                onClick={onBulkDownloadHandler}
              />
          </Box>
          {
            isDefinedAndInitialized(obsList) 
            && obsList.length > 1 &&
            <Box>
              <Chip
                variant="outlined"
                size="small"
                style={{ marginRight: '10px', marginTop: '5px', marginBottom: '5px' }}
                icon={<LoopIcon />}
                label={ 'Resequence numbering for job' }
                clickable={true}                
                disabled={!(isDefinedAndInitialized(obsList) && obsList.length > 1)}
                onClick={onResequenceHandler}
              />
            </Box>
          }
          <Box>
            <Chip 
              variant="outlined"
              size="small"
              style={{ marginRight: '10px', marginTop: '5px', marginBottom: '5px', textDecoration: 'none' }}
              icon={<LaunchIcon />}
              label="NCR Summary" 
              component={Link}
              href={`/job/${validatedJobSelection.jobId}/ncr`}
              clickable={true}
              target="_blank"
            />
          </Box>
        </Box>
        {
          (isDefinedAndInitialized(loadingState)) &&
          <Box display="flex" flexDirection="row" alignItems="center" mt="15px" pl="40px" pb="1rem" width="100%">
            <LinearProgressWithLabel value={loadingState.percentageComplete} />
          </Box>
        }
      </Paper>
  )
};

JobListHeaderPanel.propTypes = {
  classes: PropTypes.object,
  validatedJobSelection: PropTypes.object,
  publishableObs: PropTypes.array,
  obsList: PropTypes.array
}

function SalesforceSearchObservationByJobComponent(props) {
  // -----------------------------
  // PROPS
  const { classes, handlePDFObservation, handleApproveObservation,
    handleDeleteObservation, ...nonClassProps } = props;
  const queryStringParameters = new URLSearchParams(useLocation().search);
  const currentTabName = config.getTabNameFromUrlQueryString(queryStringParameters);
  const jobId = config.getJobIdFromUrlQueryString(queryStringParameters);

  // MSAL
  const { instance, accounts, inProgress } = useMsal();
  const loginHint = (accounts && accounts[0]?.username) ?? '';
  const request = {
    loginHint,
    scopes: ["User.Read"]
  }

  const isAuthenticated = useIsAuthenticated();
  const isAuthed = isAuthenticated && inProgress === "none" && isDefinedAndInitialized(accounts) && accounts.length > 0 && isDefinedAndInitialized(accounts[0]) && isDefinedAndInitialized(accounts[0].username);

  // REACT QUERY
  // JOB DETAIL
  const { isLoading: isJobDetailDataLoading, isError: isJobDetailDataError, data: jobDetailData, error: jobDetailDataError,
          isFetching: isJobDetailDataFetching } = useQuery(
    ['jobDetail', jobId],
    () => getJobDetail(jobId, { instance, accounts, inProgress }),
    {
      enabled: isAuthed && isDefinedAndInitialized(jobId) && jobId !== '',
      refetchOnWindowFocus: false
    }
  );
  // OBSERVATIONS LIST (JOB)
  const { isLoading: isJobObsDataLoading, isError: isJobObsDataError, data: jobObsData, error: jobObsDataError } = useQuery(
    ['jobObsList', jobId],
    async () => {
      const obsResultSet = await searchOLAP('JOB', jobId, { instance, accounts, inProgress });
      return obsResultSet.data;    
    },
    {
      // AUTH ENABLEMENT
      enabled: isAuthed
      // TAB ENABLEMENT
      && currentTabName === 'JOB' && isDefinedAndInitialized(jobId) && jobId !== ''
    }
  );

  // EFFECTS
  useEffect(async () => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      await instance.loginRedirect(request);
    }
  }, [isAuthenticated, inProgress, instance]);

  const handleJobAutosuggestSelection = (newJobId) => {
    // -----------------------------------------
    // Handle an update of the observation list when a suggestion is selected that is not null
    if (isDefinedAndInitialized(newJobId) && newJobId !== '') {
      props.history.push(`/home?tabName=JOB&lastJobId=${encodeURIComponent(newJobId)}`);
    }
  };

  // HANDLE LOADING STATE
  // MSAL
  if (
    !isAuthed
  )
  {
    return (
      <Box width="100%" display="flex" flexDirection="row" justifyContent="center" >
        <CircularProgress />
      </Box>
    )
  }

  // QUERY DATA
  if (isJobDetailDataLoading) {
    return (
      <Box width="100%" display="flex" flexDirection="row" justifyContent="center" >
        <CircularProgress />
      </Box>
    )
  }

  // HANDLE ERROR STATE
  if (isJobDetailDataError) {
    if (jobDetailDataError.message === 'Not found') {
      return (
        <ErrorComponent
          statusCode={404}
          message={`Job ${jobId} not found in Salesforce - please check it has been copied across from WorkFlow Max or try Julian/Karen`}
          isExternalLink={true}
          linkAddress={"https://initianz.lightning.force.com/lightning/o/CGCB__Job__c/list"}
          linkLabel={"Salesforce Job List"}
        />
      )
    }
    return <span>An unexpected error has occurred with the job data {JSON.stringify(jobDetailDataError)}</span>
  }
  if (isJobObsDataError) {
      return <span>An unexpected error has occurred with the job observation data {JSON.stringify(jobObsDataError)}</span>
  }


  // DERIVED STATE
  const publishableObs = (
      isDefinedAndInitialized(jobObsData) 
      && !('initia' in jobObsData) 
      && !('user' in jobObsData) 
  ) ? jobObsData.filter(obs => obs.header.status === 'approved' || obs.header.status === 'downloaded') : [];


  return (
    <Box display="flex" flexDirection="column" flexGrow="1">
      <Box display="flex" flexDirection="row" my="20px">
        <SalesforceJobAutosuggest
          label={'List observations for an Initia job'}
          className={classes.autosuggest}
          handleChange={handleJobAutosuggestSelection}
        />
      </Box>
      <Box flexGrow="1" display="flex" flexDirection="column">
        {
          isJobDetailDataLoading ||
          isJobObsDataLoading &&
          <Box width="100%" display="flex" flexDirection="row" justifyContent="center" >
            <CircularProgress />
          </Box>
        }
        {
          (
            !isJobDetailDataLoading
            && !isJobObsDataLoading
            && jobId !== '')           
            && 
            <Box flexGrow="1" display="flex" flexDirection="column">
              { 
                isJobDetailDataFetching &&
                <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">
                  <Box mr="10px" height="100%" display="flex" flexDirection="row" alignItems="center">
                    <CircularProgress size={15} />
                  </Box>
                  <Typography>Refreshing job data from Salesforce</Typography>
                </Box>
              }
              <JobListHeaderPanel
                  classes={classes}
                  {...nonClassProps}
                  validatedJobSelection={jobDetailData}
                  publishableObs={ publishableObs }
                  obsList={ jobObsData }
              />
              <Divider variant="middle" />
              <ObservationSummaryList
                {...nonClassProps}
                handlePDFObservation={handlePDFObservation}
                handleApproveObservation={handleApproveObservation}
                handleDeleteObservation={handleDeleteObservation}
              />
            </Box>
        }
        {
          !isJobObsDataLoading
          && jobId === '' &&
          <Box display="flex" flexDirection="row" justifyContent="center">
            <Typography>Start searching for observations by job number above</Typography>
          </Box>
        }
      </Box>
    </Box>
  );
}

SalesforceSearchObservationByJobComponent.propTypes = {
  classes: PropTypes.object,
  handlePDFObservation: PropTypes.func,
  handleApproveObservation: PropTypes.func,
  handleDeleteObservation: PropTypes.func,
  history: PropTypes.object.isRequired
}

export default (withStyles)(styles)(SalesforceSearchObservationByJobComponent);
