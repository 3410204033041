import React, { useContext } from 'react';
import PropTypes from 'prop-types';

// MSAL
import { useIsAuthenticated } from '@azure/msal-react';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Box, Toolbar, Avatar,
          IconButton, Tooltip, Chip, useTheme, useMediaQuery } from '@material-ui/core';
import MapIcon from '@material-ui/icons/Public'

// Custom Components
import SwipeableMenu from './swipeableMenu';

// Custom CONFIG
import { currentVersion } from '../config/generalConfig';

// Context
import { InitiaContext } from '../context/initia-context';

const styles = () => ({
  appBar: {
    flexGrow: 1,
    backgroundColor: '#eceff1',
    borderBottom: '16px solid #eceff1',
  },
  toolbarRoot: {
    justifyContent: 'space-between',
  },
  appBarLogoImage: {
    height: '50px',
  },
  logoAvatar: {
    backgroundColor: '#0277bd',
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
  },
  button: {
    backgroundColor: '#0277bd',
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgb(1, 83, 132)',
      color: 'white',
    },
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  versionMenu: {
    marginRight: '-5px',
  },
  versionPrefix: {
    fontSize: '10px',
  },
  versionSuffix: {
    fontSize: '12px',
  },
  versionTiny: {
    fontSize: '8px',
  },
  versionHistoryTitle: {
    fontSize: '12px',
  },
  versionHistoryLink: {
    fontSize: '10px',
    textDecoration: 'none',
  },
  mapViewerIcon: {
    height: '40px',
  },
  mapViewerText: {
    top: '-2px',
    fontSize: '10px',
  },
  wfmAvatar: {
    backgroundColor: '#46b248',
  },
  wfmText: {
    fontSize: '17px',
    fontWeight: '800',
  },
  hsAvatar: {
    backgroundColor: '#eb345e',
  },
  hsText: {
    fontSize: '17px',
    fontWeight: '800',
  },
});

const InitiaAppBar = (props) => {
  const { classes } = props;
  const { state } = useContext(InitiaContext);

  const isAuthenticated = useIsAuthenticated();

  // MUI HOOKS
  const theme = useTheme();
  const isSmallerThanXs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box className={classes.appBar}>
      <AppBar position="static">
        <Toolbar className={classes.toolbarRoot}>
          <SwipeableMenu logo={state.nameLogo} />
          {isAuthenticated && (
            <Box className={classes.linkContainer}>
              <Box>
              <Tooltip title="Health and Safety">
                <IconButton
                  target="_blank"
                  href={`https://${(window.config.ENV === 'prod') ? '' : 'sandpit.'}work-and-safety.initia-digital.co.nz`}
                  size="small"
                >
                  <Avatar className={classes.hsAvatar}>
                    <span className={classes.hsText}>H+S</span>
                  </Avatar>
                </IconButton>
              </Tooltip>
              </Box>
              <Box>
              <Tooltip title={ "Salesforce" }>
                <IconButton
                  target="_blank"
                  href={ "https://initianz.lightning.force.com/lightning/o/CGCB__Job__c/list" }
                  size="small"
                >
                  <Avatar className={classes.wfmAvatar}>
                    <span className={classes.wfmText}>{ `JOB` }</span>
                  </Avatar>
                </IconButton>
              </Tooltip>
              </Box>
              <Box>
              <Tooltip title="Map Viewer">
                <IconButton
                  target="_blank"
                  href="https://initia.maps.arcgis.com/apps/webappviewer/index.html?id=f6175a6d472d4daf9bd95e0daf55af32"
                  size="small"
                >
                  <Avatar className={classes.logoAvatar}>
                    <MapIcon />
                  </Avatar>
                </IconButton>
              </Tooltip>
              </Box>
              {
                !isSmallerThanXs &&
                  <Chip color="secondary" label={(window.config.ENV === 'prod') ? `${currentVersion}` : `${window.config.ENV} ${currentVersion}`}/>
              }
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

InitiaAppBar.propTypes = {
  classes: PropTypes.object
}

export default withStyles(styles)(InitiaAppBar);
