import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";

import { fabric } from "fabric";

// Material UI
import {
  Box,
  Typography,
  IconButton,
  Tooltip,
  TextField,
  MenuItem,
  Grid,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  RadioGroup,
  Radio,
  Switch,
  Divider
} from "@material-ui/core";
import {
  AddCircle,
  Cancel,
  Details,
  Opacity,
  Layers,
  LayersClear,
  IndeterminateCheckBox
} from "@material-ui/icons";
import { withStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { DataGrid } from "@material-ui/data-grid";

// Custom Components

// Custom Services

// Custom Helpers
import { isDefinedAndInitialized, capitalizeFirstLetters } from "../helpers/helpers";
import {
  getMaxHeightInMetresForPileCanvas, calculatePileToeLevel,
  canCalculateRLs, getDatum, shouldDrawDesignToeLevel,
  checkPileToeElevationValid, checkPileGeoEmbedmentValid, getPileShaftOffsetFromSurface,
  getGeoLayerTopDepth, getGeoLayerBottomDepth, calculatedPileGeoEmbedmentThickness
} from '../helpers/pile-obs-helpers';

// Custom Config
import { supportedSteelSections } from '../config/businessConfig';

// CONSTANTS
const CANVAS_ASPECT_RATIO = 1.35;

// Canvas Height/Width Ratios for drawing (i.e. max extents)
const X_START_EDGE_OF_CANVAS_DRAWING_RATIO = 0.2 / 10;
const X_END_EDGE_OF_CANVAS_DRAWING_RATIO = 9.8 / 10;

// Pile Shaft Canvas Height/Width Ratios
const X_START_PILE_SHAFT_CANVAS_RATIO = 2.7 / 10;
const X_END_PILE_SHAFT_CANVAS_RATIO = 4 / 10;
const Y_START_PILE_SHAFT_CANVAS_RATIO = 0.35 / 5;
const Y_END_PILE_SHAFT_CANVAS_RATIO = 4 / 5;

// Left-hand elevation/depth label Canvas Height/Width Ratios
const X_ELEVATION_LABEL_TEXT = 0.25 / 10;
const X_DEPTH_LABEL_TEXT = 9.6 / 10;

const styles = (theme) => ({
  root: {
    '& .embedmentMaterial': {
      backgroundColor: '#e3f2fd'
    }
  },
  uploadButton: {
    position: "absolute",
    top: "200px",
    left: "360px",
  },
  button: {
    margin: theme.spacing(1),
  },
  textField: {
    fontSize: "0.8rem",
    '&& .MuiTypography-body1': {
      fontSize: "0.8rem"
    }
  },
  criteriaPending: {
    backgroundColor: 'rgba(0,0,0,0)'
  },
  criteriaUnacceptable: {
    backgroundColor: '#ffbab5'
  },
  criteriaAcceptable: {
    backgroundColor: '#e3fcd2'
  }
});

// CONFIGURATION

const PILE_METADATA = [
  {
    name: "Pile Details",
    switchField: null,
    content: [
      {
        type: "textField",
        name: "pileId",
        visibility: () => true,
        stylingFunction: () => { return null },
        readonly: false,
        calculateField: null,
        validationFunction: () => true,
        label: "Pile ID",
        valueFormatter: (value) => value,
        transformForSave: (value) => value,
        props: {
          multiline: false
        },
        helperText: () => ''
      },
      {
        type: "select",
        name: "pileCategory",
        visibility: () => true,
        stylingFunction: () => { return null },
        validationFunction: (value) =>
          ["bored pile"].includes(value.toLowerCase()),
        label: "Pile category",
        options: [
          { label: "Bored Pile", value: "Bored Pile" },
          // { 'label' : 'Driven Pile', 'value': 'Driven Pile'}
        ],
        defaultValue: "Bored Pile",
        valueFormatter: (value) => capitalizeFirstLetters(value),
        transformForSave: (value) => value,
        props: {},
        helperText: () => ''
      },
      {
        type: "select",
        name: "pileType",
        visibility: () => true,
        stylingFunction: () => { return null },
        validationFunction: (value) =>
          ["reinforced concrete", "timber pole", "concrete encased steel section", "other pile type"].includes(value.toLowerCase()),
        label: "Pile type",
        options: [
          { label: "Reinforced Concrete", value: "Reinforced Concrete" },
          { label: 'Concrete Encased Steel Section', value: 'Concrete Encased Steel Section' },
          { label: 'Timber Pole', value: 'Timber Pole' },
          { label: 'Other Pile Type', value: 'Other Pile Type' }
        ],
        defaultValue: "Reinforced Concrete",
        valueFormatter: (value) => capitalizeFirstLetters(value),
        transformForSave: (value) => value.toLowerCase(),
        props: {},
        helperText: (value) => {
          if (value.toLowerCase() === 'other pile type') {
            return `Ensure you enter pile details in the notes section below`
          }
          else if (value.toLowerCase() === 'concrete encased steel section')
            return `Choose a steel section from below (if 'other', enter details in pile notes)`
        }
      },
      {
        type: "checkbox",
        name: "pileCriteria",
        visibility: () => true,
        stylingFunction: () => { return null },
        validationFunction: (componentData) =>
          componentData.pileCriteria.length > 0 && componentData.pileCriteria.filter(criteria => !["minimum toe level", "pile socket criteria", "geological embedment depth"].includes(criteria)).length === 0,
        label: "Design criteria",
        options: [
          { label: "Minimum Toe Level", value: "Minimum Toe Level" },
          { label: "Pile Socket Criteria", value: "Pile Socket Criteria" },
        ],
        defaultValue: ["minimum toe level"],
        valueFormatter: (value) => capitalizeFirstLetters(value),
        transformForSave: (value, list) => {
          let transformedValue = value.toLowerCase();
          if (list.includes(transformedValue)) {
            // If the value is present filter it out
            list = list.filter(entry => entry !== transformedValue);
          }
          else {
            // Otherwise add the value to the list
            list = [...list, transformedValue]
          }
          return [...list];
        },
        props: {},
        errorText: 'At least one pile criteria must be selected',
        helperText: () => ''
      },
      {
        type: "select",
        name: "pilingRig",
        visibility: () => true,
        stylingFunction: () => { return null },
        validationFunction: (value) =>
          ["continuous flight auger", "excavator with auger", "piling rig", "other"].includes(value.toLowerCase()),
        label: "Piling rig",
        options: [
          { label: "Continuous Flight Auger", value: "Continuous Flight Auger" },
          { label: "Excavator With Auger", value: "Excavator With Auger" },
          { label: "Piling Rig", value: "Piling Rig" },
          { label: "Other", value: "Other" },
        ],
        defaultValue: '',
        valueFormatter: (value) => capitalizeFirstLetters(value),
        transformForSave: (value) => value.toLowerCase(),
        props: {},
        helperText: () => ''
      },
      {
        type: "select",
        name: "drillingFluid",
        visibility: () => true,
        stylingFunction: () => { return null },
        validationFunction: (value) =>
          ["none", "water", "bentonite"].includes(value.toLowerCase()),
        label: "Drilling fluid",
        options: [
          { label: "None", value: "None" },
          { label: "Water", value: "Water" },
          { label: "Bentonite", value: "Bentonite" },
        ],
        defaultValue: 'None',
        valueFormatter: (value) => capitalizeFirstLetters(value),
        transformForSave: (value) => value.toLowerCase(),
        props: {},
        helperText: () => ''
      },
    ],
  },
  {
    name: "Geometry",
    switchField: null,
    content: [
      {
        type: "integer",
        name: "externalDiameter",
        visibility: () => true,
        stylingFunction: () => { return null },
        validationFunction: () => true,
        label: "Hole diameter (mm)",
        valueFormatter: (value) => Number(value).toFixed(0),
        transformForSave: (value) => (isDefinedAndInitialized(value)) ? Number(value) : "",
        props: {},
        helperText: () => ''
      },
      {
        type: "integer",
        name: "poleDiameter",
        visibility: (componentData) => {
          return componentData.pileType === 'timber pole';
        },
        stylingFunction: () => { return null },
        validationFunction: () => true,
        label: "Pole diameter (mm)",
        valueFormatter: (value) => Number(value).toFixed(0),
        transformForSave: (value) => (isDefinedAndInitialized(value)) ? Number(value) : "",
        props: {},
        helperText: () => ''
      },
      {
        type: "select",
        name: "steelSection",
        visibility: (componentData) => {
          return componentData.pileType === 'concrete encased steel section';
        },
        stylingFunction: () => { return null },
        validationFunction: (value) => supportedSteelSections.map(section => section.label.toLowerCase()).includes(value.toLowerCase()),
        label: "Steel section",
        options: supportedSteelSections,
        defaultValue: '',
        valueFormatter: (value) => value.toUpperCase(),
        transformForSave: (value) => value.toLowerCase(),
        props: {},
        helperText: () => ''
      }
    ]
  },
  {
    name: "Casing",
    switchField: "hasCasing",
    content: [
      {
        type: "select",
        name: "casingType",
        visibility: () => true,
        stylingFunction: () => { return null },
        validationFunction: (value) =>
          ["none", "temporary", "permanent"].includes(value.toLowerCase()),
        label: "Casing Type",
        options: [
          { label: "None", value: "None" },
          { label: "Temporary", value: "Temporary" },
          { label: "Permanent", value: "Permanent" },
        ],
        defaultValue: 'None',
        valueFormatter: (value) => capitalizeFirstLetters(value),
        transformForSave: (value) => value.toLowerCase(),
        props: {},
        helperText: () => ''
      },
      {
        type: "integer",
        name: "casingDiameter",
        visibility: () => true,
        stylingFunction: () => { return null },
        validationFunction: () => true,
        label: "Casing diameter (mm)",
        valueFormatter: (value) => value,
        transformForSave: (value) => (isDefinedAndInitialized(value)) ? value : "",
        props: {},
        helperText: () => ''
      },
      {
        type: "number",
        name: "topOfCasingLevel",
        visibility: () => true,
        stylingFunction: () => { return null },
        validationFunction: () => true,
        label: "Top of casing level (m RL)",
        valueFormatter: (value) => value,
        transformForSave: (value) => (isDefinedAndInitialized(value)) ? value : "",
        props: {},
        helperText: () => ''
      },
      {
        type: "number",
        name: "casingLength",
        visibility: () => true,
        stylingFunction: () => { return null },
        validationFunction: () => true,
        label: "Casing length (m)",
        valueFormatter: (value) => value,
        transformForSave: (value) => (isDefinedAndInitialized(value)) ? value : "",
        props: {},
        helperText: () => ''
      },
      {
        type: "textField",
        name: "asBuiltCasingToeLevel",
        visibility: () => true,
        stylingFunction: () => { return null },
        readonly: true,
        calculateField: componentData => {
          if (componentData.topOfCasingLevel && componentData.casingLength) {
            return (componentData.topOfCasingLevel - componentData.casingLength).toFixed(1);
          }
          else {
            return '';
          }
        },
        validationFunction: () => true,
        label: "Calculated as-built casing toe elevation (m RL)",
        valueFormatter: (value) => value,
        transformForSave: (value) => value,
        props: {
          multiline: false
        },
        helperText: () => ''
      }
    ],
  },
  {
    name: "Depths and Levels",
    content: [
      {
        type: "radio",
        name: "referenceLevel",
        visibility: () => true,
        stylingFunction: () => { return null },
        validationFunction: (value) =>
          ["platform ground level", "reference level"].includes(value.toLowerCase()),
        label: "Reference Level Datum (used for measurement)",
        options: [
          { label: "Platform Ground Level", value: "Platform Ground Level" },
          { label: "Reference Level", value: "Reference Level" },
        ],
        defaultValue: 'Platform Ground Level',
        valueFormatter: (value) => capitalizeFirstLetters(value),
        transformForSave: (value) => value.toLowerCase(),
        props: {},
        helperText: () => ''
      },
      {
        type: "number",
        name: "pilingPlatformLevel",
        visibility: () => true,
        stylingFunction: () => { return null },
        validationFunction: () => true,
        label: "Piling platform ground level (m RL)",
        valueFormatter: (value) => value,
        transformForSave: (value) => (isDefinedAndInitialized(value)) ? value : "",
        props: {},
        helperText: () => ''
      },
      {
        type: "number",
        name: "otherReferenceLevel",
        visibility: (componentData) => {
          return componentData.referenceLevel === 'reference level';
        },
        stylingFunction: () => { return null },
        validationFunction: () => true,
        label: "Reference level for measurement (m RL)",
        valueFormatter: (value) => value,
        transformForSave: (value) => (isDefinedAndInitialized(value)) ? value : "",
        props: {},
        helperText: () => ''
      },
      {
        type: "textField",
        name: "otherReferenceLevelNote",
        visibility: (componentData) => {
          return componentData.referenceLevel === 'reference level';
        },
        stylingFunction: () => { return null },
        validationFunction: () => true,
        label: "Description of reference level for measurement",
        valueFormatter: (value) => value,
        transformForSave: (value) => (isDefinedAndInitialized(value)) ? value : "",
        props: {},
        helperText: () => ''
      }
    ],
  },
  {
    name: "Design and As-Built",
    switchField: null,
    content: [
      {
        type: "number",
        name: "designToeLevel",
        visibility: (componentData) => {
          return componentData.pileCriteria.includes('minimum toe level');
        },
        stylingFunction: () => { return null },
        validationFunction: () => true,
        label: "Design toe level (m RL)",
        valueFormatter: (value) => value,
        transformForSave: (value) => (isDefinedAndInitialized(value)) ? value : "",
        props: {},
        helperText: () => ''
      },
      {
        type: "textField",
        name: "asBuiltToeLevel",
        visibility: (componentData) => {
          return componentData.pileCriteria.includes('minimum toe level');
        },
        stylingFunction: (componentData) => {
          let shouldExecutePlatformGroundLevelToeElevCheck = componentData.referenceLevel === 'platform ground level' && isDefinedAndInitialized(componentData.pilingPlatformLevel) && isDefinedAndInitialized(componentData.depthToBase) && isDefinedAndInitialized(componentData.designToeLevel);
          let shouldExecuteReferenceGroundLevelToeElevCheck = componentData.referenceLevel === 'reference level' && isDefinedAndInitialized(componentData.otherReferenceLevel) && isDefinedAndInitialized(componentData.depthToBase) && isDefinedAndInitialized(componentData.designToeLevel);
          // Check design toe level is ABOVE as-built toe-level, i.e. pile toe is deeper than design requirement (using platform ground level OR reference level)
          let isPlatformBasedToeLevelAcceptable = (shouldExecutePlatformGroundLevelToeElevCheck) ? ((Number(componentData.pilingPlatformLevel) - Number(componentData.depthToBase)) <= Number(componentData.designToeLevel)) : null;
          let isReferenceBasedToeLevelAcceptable = (shouldExecuteReferenceGroundLevelToeElevCheck) ? ((Number(componentData.otherReferenceLevel) - Number(componentData.depthToBase)) <= Number(componentData.designToeLevel)) : null;
          // Styling function returns a classname associated with the associated styling
          // If either of design toe level or as-built toe level missing
          // -> Return null styling
          if (!(shouldExecutePlatformGroundLevelToeElevCheck) && !(shouldExecuteReferenceGroundLevelToeElevCheck)) { return 'criteriaNull'; }
          // Calculate if platform-based as-built toe elevation is acceptable/not
          else if (shouldExecutePlatformGroundLevelToeElevCheck && isPlatformBasedToeLevelAcceptable) { return 'criteriaAcceptable'; }
          // Calculate if other reference-level-based as-built toe elevation is acceptable/not
          else if (shouldExecuteReferenceGroundLevelToeElevCheck && isReferenceBasedToeLevelAcceptable) { return 'criteriaAcceptable'; }
          else { return 'criteriaUnacceptable'; }
        },
        readonly: true,
        calculateField: componentData => calculatePileToeLevel(componentData),
        validationFunction: () => true,
        label: "Calculated as-built toe level (m RL)",
        valueFormatter: (value) => value,
        transformForSave: (value) => value,
        props: {
          multiline: false
        },
        helperText: () => ''
      },
      {
        type: "number",
        name: "designGeoEmbedment",
        visibility: (componentData) => {
          return componentData.pileCriteria.includes('geological embedment depth') || componentData.pileCriteria.includes('pile socket criteria');
        },
        stylingFunction: () => { return null },
        validationFunction: () => true,
        label: "Pile socket criteria (m)",
        valueFormatter: (value) => value,
        transformForSave: (value) => (isDefinedAndInitialized(value)) ? value : "",
        props: {},
        helperText: () => ''
      },
      {
        type: "textField",
        name: "asBuiltGeologicalEmbedment",
        visibility: (componentData) => {
          return componentData.pileCriteria.includes('geological embedment depth') || componentData.pileCriteria.includes('pile socket criteria');
        },
        stylingFunction: (componentData) => {
          // Styling function returns a classname associated with the associated styling
          // Assume embedment is provided by default (i.e. if missing sum of embedment = 0 and therefore not acceptable unless no embedment is specified)
          // -> Return null styling
          // Calculate if the embedment depth is acceptable
          if ((componentData.pileCriteria.includes('geological embedment depth') || componentData.pileCriteria.includes('pile socket criteria')) && componentData.observedGeology.length > 0 && isDefinedAndInitialized(componentData.designGeoEmbedment)) {
            // -----------------------------------------------
            const pileGeoValid = checkPileGeoEmbedmentValid(componentData);
            if (pileGeoValid) { return 'criteriaAcceptable'; }
            else { return 'criteriaUnacceptable'; }
          }
          else { return 'criteriaNull'; }
        },
        readonly: true,
        calculateField: componentData => {
          if ((componentData.pileCriteria.includes('geological embedment depth') || componentData.pileCriteria.includes('pile socket criteria')) && componentData.observedGeology.length > 0) {
            return calculatedPileGeoEmbedmentThickness(componentData).toFixed(1);
          }
          else {
            return '';
          }
        },
        validationFunction: () => true,
        label: "Calculated as-built pile socket (m)",
        valueFormatter: (value) => value,
        transformForSave: (value) => value,
        props: {
          multiline: false
        },
        helperText: () => ''
      }
    ]
  },
  {
    name: 'Measurements and Observations',
    content: [
      {
        type: "number",
        name: "depthToBase",
        visibility: () => true,
        stylingFunction: () => { return null },
        validationFunction: () => true,
        label: "Depth to base of hole (m)",
        valueFormatter: (value) => value,
        transformForSave: (value) => (isDefinedAndInitialized(value)) ? value : "",
        props: {},
        helperText: () => ''
      },
      {
        type: "textField",
        name: "otherNotes",
        visibility: () => true,
        stylingFunction: () => { return null },
        readonly: false,
        calculateField: null,
        validationFunction: () => true,
        label: "Notes",
        valueFormatter: (value) => value,
        transformForSave: (value) => value,
        props: {
          multiline: true
        },
        helperText: () => ''
      },
    ],
  },
];

const GEO_COLUMNS = [
  {
    field: "depth",
    headerName: "Depth to bottom (m)",
    description: "Top depth (m) of the observed geology",
    editable: true,
    sortable: false,
    flex: 1,
  },
  {
    field: "geologicalObservation",
    headerName: "Observed Geology",
    description: "Geological unit inferred during piling",
    editable: true,
    sortable: false,
    flex: 3,
    // eslint-disable-next-line
    renderCell: (params) => {
      return (
        <>
          <Box display="inline" mr="10px" >{params.value}</Box>
          {/* Add Icon labelling embedment material if selected */}
          {(params.row.embedmentMaterial) &&
            <Layers
              color="secondary"
              className="addButtonColour"
              fontSize="large"
            />
          }
        </>
      )
    }
  },
];

const GROUNDWATER_COLUMNS = [
  {
    field: "depth",
    headerName: "Depth (m)",
    description: "Depth (m) of the groundwater observation",
    editable: true,
    sortable: false,
    flex: 1,
  },
  {
    field: "gwObsType",
    headerName: "Type",
    description: "Groundwater observation type (inflow/standing/dry)",
    editable: false,
    sortable: false,
    flex: 1,
  },
  {
    field: "groundwaterObservation",
    headerName: "Notes",
    description: "Notes to associate with groundwater observation",
    editable: true,
    sortable: false,
    flex: 2.5,
  },
];

const TextFieldContent = (props) => {
  // -------------------------------------
  const { classes, config, componentData, handleClientObservationStateUpdate } = props;
  let value = "";

  const theme = useTheme();

  // If a calculated field, return the calculated value as defined in the configuration
  if (config.calculateField) {
    value = config.calculateField(componentData);
  }
  // Otherwise return the formatted value
  else if (componentData[config.name]) {
    value = config.valueFormatter(componentData[config.name]);
  }

  let className = config.stylingFunction(componentData);

  return (
    <Grid item xs={12} md={6}>
      <Box mx="15px" my="5px" fontSize="0.75rem">
        <TextField
          className={(className) ? classes[className] : null}
          readOnly={config.readonly}
          disabled={config.readonly}
          fullWidth
          variant="outlined"
          label={config.label}
          InputProps={{
            classes: { input: classes.textField },
          }}
          InputLabelProps={{
            style: { fontSize: "0.8rem" },
          }}
          value={value}
          onChange={(e) => {
            componentData[config.name] = config.transformForSave(e.target.value);
            handleClientObservationStateUpdate(
              e,
              componentData.componentType,
              componentData.componentId,
              componentData
            );
          }}
          multiline={config.props.multiline}
        />
      </Box>
      {
        isDefinedAndInitialized(config.helperText) &&
        isDefinedAndInitialized(config.helperText(value)) &&
        <Box mx="15px" my="5px">
          <FormHelperText style={{ color: theme.palette.secondary.main }}>{config.helperText(value)}</FormHelperText>
        </Box>
      }
    </Grid>
  );
};

TextFieldContent.propTypes = {
  classes: PropTypes.object,
  config: PropTypes.object,
  componentData: PropTypes.object,
  handleClientObservationStateUpdate: PropTypes.func
};

const SelectContent = (props) => {
  // -------------------------------------
  const { classes, config, componentData, handleClientObservationStateUpdate } = props;

  const value = (componentData[config.name]) ? config.valueFormatter(componentData[config.name]) : config.defaultValue;

  const theme = useTheme();


  return (
    <Grid item xs={12} md={6}>
      <Box mx="15px" my="5px">
        <TextField
          fullWidth
          id="standard-select-pile-type"
          select
          variant="outlined"
          label={config.label}
          value={value}
          InputProps={{
            classes: { input: classes.textField },
          }}
          InputLabelProps={{
            style: { fontSize: "0.8rem" },
          }}
          onChange={(e) => {
            componentData[config.name] = config.transformForSave(e.target.value);
            // Validate the enumeration matches the configuration
            if (!config.validationFunction(componentData[config.name])) {
              componentData[config.name] = config.defaultValue;
              return
            }
            handleClientObservationStateUpdate(
              e,
              componentData.componentType,
              componentData.componentId,
              componentData
            );
          }}
        >
          {config.options.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              style={{ fontSize: "0.8rem" }}
            >
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      {
        isDefinedAndInitialized(config.helperText) &&
        isDefinedAndInitialized(config.helperText(value)) &&
        <Box mx="15px" my="5px">
          <FormHelperText style={{ color: theme.palette.secondary.main }}>{config.helperText(value)}</FormHelperText>
        </Box>
      }
    </Grid>
  );
};

SelectContent.propTypes = {
  classes: PropTypes.object,
  config: PropTypes.object,
  componentData: PropTypes.object,
  handleClientObservationStateUpdate: PropTypes.func
};

const NumberContent = (props) => {
  // -------------------------------------
  const { classes, config, componentData, handleClientObservationStateUpdate } = props;

  let className = config.stylingFunction(componentData);

  const value = (isDefinedAndInitialized(componentData[config.name])) ? String(config.valueFormatter(componentData[config.name])) : "";

  const theme = useTheme();


  return (
    <Grid item xs={12} md={6}>
      <Box mx="15px" my="5px" fontSize="0.75rem">
        <TextField
          className={(className) ? classes[className] : null}
          type="number"
          inputProps={{ min: "0", step: (config.type === "integer") ? 1 : null }}
          fullWidth
          variant="outlined"
          label={config.label}
          InputProps={{
            classes: { input: classes.textField },
          }}
          InputLabelProps={{
            style: { fontSize: "0.8rem" },
          }}
          value={value}
          onChange={(e) => {
            componentData[config.name] = config.transformForSave(e.target.value);
            handleClientObservationStateUpdate(
              e,
              componentData.componentType,
              componentData.componentId,
              componentData
            );
          }}
        />
      </Box>
      {
        isDefinedAndInitialized(config.helperText) &&
        isDefinedAndInitialized(config.helperText(value)) &&
        <Box mx="15px" my="5px">
          <FormHelperText style={{ color: theme.palette.secondary.main }}>{config.helperText(value)}</FormHelperText>
        </Box>
      }
    </Grid>
  );
};

NumberContent.propTypes = {
  classes: PropTypes.object,
  config: PropTypes.object,
  componentData: PropTypes.object,
  handleClientObservationStateUpdate: PropTypes.func
};

const CheckboxContent = (props) => {
  // -------------------------------------
  const { classes, config, componentData, handleClientObservationStateUpdate } = props;

  const theme = useTheme();

  const handleChange = (e) => {
    // ----------------------------------------
    componentData[config.name] = config.transformForSave(e.target.name, componentData[config.name]);
    handleClientObservationStateUpdate(
      e,
      componentData.componentType,
      componentData.componentId,
      componentData
    );
  }

  const error = !config.validationFunction(componentData);

  return (
    <Grid item xs={12} md={6}>
      <Box mx="15px" my="5px" fontSize="0.75rem">
        <FormControl error={error} component="fieldset" className={classes.formControl}>
          <FormLabel className={classes.textField} component="legend">{config.label}</FormLabel>
          <FormGroup row>
            {
              config.options.map(option => {
                return (
                  <FormControlLabel
                    key={`${componentData.componentId}-checkbox-${option.label}`}
                    control={<Checkbox checked={componentData[config.name].includes(option.value.toLowerCase())} onChange={handleChange} name={option.value} />}
                    label={option.label}
                    className={classes.textField}
                  />
                )
              })
            }
          </FormGroup>
          <Box mb="10px">
            <FormHelperText>{(error) ? config.errorText : ''}</FormHelperText>
          </Box>
        </FormControl>
      </Box>
      {
        isDefinedAndInitialized(config.helperText) &&
        isDefinedAndInitialized(config.helperText(componentData[config.name])) &&
        <Box mx="15px" my="5px">
          <FormHelperText style={{ color: theme.palette.secondary.main }}>{config.helperText(componentData[config.name])}</FormHelperText>
        </Box>
      }
    </Grid>
  );
};

CheckboxContent.propTypes = {
  classes: PropTypes.object,
  config: PropTypes.object,
  componentData: PropTypes.object,
  handleClientObservationStateUpdate: PropTypes.func
};


const RadioContent = (props) => {
  // -------------------------------------
  const { classes, config, componentData, handleClientObservationStateUpdate } = props;

  const theme = useTheme();

  const handleChange = (e) => {
    // ----------------------------------------
    componentData[config.name] = config.transformForSave(e.target.value);
    handleClientObservationStateUpdate(
      e,
      componentData.componentType,
      componentData.componentId,
      componentData
    );
  }

  const value = config.valueFormatter(componentData[config.name]);

  return (
    <Grid item xs={12} md={6}>
      <Box mx="15px" my="5px" fontSize="0.75rem">
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel className={classes.textField} component="legend">{config.label}</FormLabel>
          <RadioGroup className={classes.textField} aria-label={`${config.name}`} name={`${config.name}`} value={value} onChange={handleChange} row>
            {config.options.map(option => {
              return (
                <FormControlLabel key={`${componentData.componentId}-${config.name}-${option.value}-radio`} value={option.value} control={<Radio />} label={option.label} />
              )
            })
            }
          </RadioGroup>
        </FormControl>
      </Box>
      {
        isDefinedAndInitialized(config.helperText) &&
        isDefinedAndInitialized(config.helperText(value)) &&
        <Box mx="15px" my="5px">
          <FormHelperText style={{ color: theme.palette.secondary.main }}>{config.helperText(value)}</FormHelperText>
        </Box>
      }
    </Grid>
  );
};

RadioContent.propTypes = {
  classes: PropTypes.object,
  config: PropTypes.object,
  componentData: PropTypes.object,
  handleClientObservationStateUpdate: PropTypes.func
};

// Draw the pile platform level to the canvas and label it's elevation
const drawSurfaceLevels = (canvas, componentData, strokeWidthScaleFactor) => {
  // ----------------------------------------
  const { otherReferenceLevel, depthToBase, pilingPlatformLevel, otherReferenceLevelNote } = componentData;
  // Canvas drawing dimensions
  const pileShaftCanvasStart = Y_START_PILE_SHAFT_CANVAS_RATIO * canvas.height;
  const pileShaftCanvasEnd = Y_END_PILE_SHAFT_CANVAS_RATIO * canvas.height;
  const pileShaftCanvasDiff = pileShaftCanvasEnd - pileShaftCanvasStart;

  const datum = getDatum(componentData);
  const maxHeightInMetresForPileCanvas = getMaxHeightInMetresForPileCanvas(componentData);
  const pileShaftOffsetFromSurface = getPileShaftOffsetFromSurface(componentData);
  const pileShaftStartHeight = pileShaftCanvasStart + pileShaftCanvasDiff * (pileShaftOffsetFromSurface) / maxHeightInMetresForPileCanvas;
  const pileToeCanvasHeight = pileShaftCanvasStart + pileShaftCanvasDiff * (depthToBase) / maxHeightInMetresForPileCanvas;

  const pileShaftLeftX = X_START_PILE_SHAFT_CANVAS_RATIO * canvas.width;
  const pileShaftRightX = X_END_PILE_SHAFT_CANVAS_RATIO * canvas.width;

  // Datum tells what our 'zero' reference is which equates to our 1/5 of the canvas height starting point
  // Note: the bottom of the pile shaft (either pile toe OR design pile toe is always at 4/5 of the canvas height)
  const rlPrimTextLabelX = X_ELEVATION_LABEL_TEXT * canvas.width;
  const rlTertiaryLabelX = X_DEPTH_LABEL_TEXT * canvas.width;

  let toDraw = [];

  const lineProps = {
    strokeWidth: 2 * strokeWidthScaleFactor,
    fill: "black",
    stroke: "black",
    originX: "center",
    originY: "center",
    selectable: false,
    hasBorders: false,
    hasControls: false,
    evented: false,
    objectCaching: false,
  };
  let hatchingProps = {
    strokeWidth: 2 * strokeWidthScaleFactor,
    fill: "black",
    stroke: "black",
    originX: "center",
    originY: "center",
    selectable: false,
    hasBorders: false,
    hasControls: false,
    evented: false,
    objectCaching: false,
  };

  const groundLevel = new fabric.Line(
    [
      X_START_EDGE_OF_CANVAS_DRAWING_RATIO * canvas.width,
      pileShaftStartHeight,
      X_END_EDGE_OF_CANVAS_DRAWING_RATIO * canvas.width,
      pileShaftStartHeight
    ],
    hatchingProps
  );
  toDraw.push(groundLevel);

  let groundLevelValue = 0;
  if (datum.name === 'reference level' && isDefinedAndInitialized(pilingPlatformLevel)) { groundLevelValue = (otherReferenceLevel - pilingPlatformLevel).toFixed(1) }
  else if (!isDefinedAndInitialized(pilingPlatformLevel)) { groundLevelValue = null; }
  const groundLevelDepthFromReferenceLabel = new fabric.Text(isDefinedAndInitialized(groundLevelValue) ? `${groundLevelValue} m depth` : 'unknown', {
    fontFamily: "Noto Sans SC",
    left: rlTertiaryLabelX,
    top: pileShaftStartHeight,
    fontSize: 14 * (canvas.width / 1000),
    textAlign: "right",
    fill: (isDefinedAndInitialized(pilingPlatformLevel)) ? "#000000" : "red",
    originX: "right",
    originY: "bottom",
    selectable: false,
    hasBorders: false,
    hasControls: false,
    evented: false,
    objectCaching: false,
  });

  toDraw.push(groundLevelDepthFromReferenceLabel);

  const groundHatching1 = new fabric.Line(
    [
      8.3 / 10 * canvas.width,
      pileShaftStartHeight,
      8.6 / 10 * canvas.width,
      pileShaftStartHeight + 0.1 / 5 * canvas.height
    ],
    hatchingProps
  );
  const groundHatching2 = new fabric.Line(
    [
      8.5 / 10 * canvas.width,
      pileShaftStartHeight,
      8.8 / 10 * canvas.width,
      pileShaftStartHeight + 0.1 / 5 * canvas.height
    ],
    hatchingProps
  );
  const groundHatching3 = new fabric.Line(
    [
      8.7 / 10 * canvas.width,
      pileShaftStartHeight,
      9 / 10 * canvas.width,
      pileShaftStartHeight + 0.1 / 5 * canvas.height
    ],
    hatchingProps
  );
  const groundHatching4 = new fabric.Line(
    [
      8 / 10 * canvas.width,
      pileShaftStartHeight + 0.1 / 5 * canvas.height,
      8.3 / 10 * canvas.width,
      pileShaftStartHeight,
    ],
    hatchingProps
  );
  const groundHatching5 = new fabric.Line(
    [
      7.95 / 10 * canvas.width,
      pileShaftStartHeight + 0.05 / 5 * canvas.height,
      8.1 / 10 * canvas.width,
      pileShaftStartHeight,
    ],
    hatchingProps
  );
  const groundHatching6 = new fabric.Line(
    [
      8.2 / 10 * canvas.width,
      pileShaftStartHeight + 0.1 / 5 * canvas.height,
      8.4 / 10 * canvas.width,
      pileShaftStartHeight + 0.03 / 5 * canvas.height,
    ],
    hatchingProps
  );
  const groundHatching7 = new fabric.Line(
    [
      8.825 / 10 * canvas.width,
      pileShaftStartHeight + 0.05 / 5 * canvas.height,
      9 / 10 * canvas.width,
      pileShaftStartHeight,
    ],
    hatchingProps
  );
  const groundHatching8 = new fabric.Line(
    [
      8.95 / 10 * canvas.width,
      pileShaftStartHeight + 0.08 / 5 * canvas.height,
      9.2 / 10 * canvas.width,
      pileShaftStartHeight,
    ],
    hatchingProps
  );
  toDraw = [...toDraw, groundHatching1, groundHatching2, groundHatching3, groundHatching4,
    groundHatching5, groundHatching6, groundHatching7, groundHatching8]

  const pilingPlatformLevelLabel = new fabric.Text(isDefinedAndInitialized(componentData.pilingPlatformLevel) ? `${componentData.pilingPlatformLevel} m RL` : 'unknown', {
    fontFamily: "Noto Sans SC",
    left: rlPrimTextLabelX,
    top: pileShaftStartHeight,
    fontSize: 20 * (canvas.width / 1000),
    textAlign: "left",
    fill: (isDefinedAndInitialized(componentData.pilingPlatformLevel)) ? "#000000" : "red",
    originX: "left",
    originY: "bottom",
    selectable: false,
    hasBorders: false,
    hasControls: false,
    evented: false,
    objectCaching: false,
  });
  toDraw.push(pilingPlatformLevelLabel);

  let shouldDrawOtherReferenceLevel = pileShaftOffsetFromSurface > 0;
  if (shouldDrawOtherReferenceLevel) {
    const polygonPoints = [
      {
        x: X_END_PILE_SHAFT_CANVAS_RATIO * canvas.width + 12 * canvas.width / 1000,
        y: Y_START_PILE_SHAFT_CANVAS_RATIO * canvas.height
      },
      {
        x: X_END_PILE_SHAFT_CANVAS_RATIO * canvas.width + 12 * canvas.width / 1000,
        y: pileShaftStartHeight
      },
      {
        x: 5.3 / 10 * canvas.width,
        y: pileShaftStartHeight
      },
      {
        x: 5.3 / 10 * canvas.width,
        y: Y_START_PILE_SHAFT_CANVAS_RATIO * canvas.height
      },
    ]
    const referenceLevelUpstandPolygon = new fabric.Polygon(polygonPoints, {
      stroke: '#1976d2',
      strokeWidth: 2 * strokeWidthScaleFactor,
      fill: '#1976d2',
      opacity: 0.75,
      selectable: false,
      hasBorders: false,
      hasControls: false,
      evented: false,
      objectCaching: false,
    });
    toDraw.push(referenceLevelUpstandPolygon);

    // Bottom of the pile shaft
    const referenceLineExtension = new fabric.Line(
      [
        (pileShaftLeftX + pileShaftRightX) / 2,
        pileShaftCanvasStart,
        X_END_EDGE_OF_CANVAS_DRAWING_RATIO * canvas.width,
        pileShaftCanvasStart
      ],
      {
        ...lineProps,
        strokeDashArray: [5, 5],
        fill: '#1976d2',
        stroke: '#1976d2'
      }
    );
    toDraw.push(referenceLineExtension);

    const rlReferenceText = new fabric.Text(`${otherReferenceLevel} m RL`, {
      fontFamily: "Noto Sans SC",
      left: 4.47 / 10 * canvas.width,
      top: pileShaftCanvasStart,
      fontSize: 20 * (canvas.width / 1000),
      textAlign: "left",
      fill: '#1976d2',
      originX: "left",
      originY: "bottom",
      selectable: false,
      hasBorders: false,
      hasControls: false,
      evented: false,
      objectCaching: false,
    });
    toDraw.push(rlReferenceText);

    if (isDefinedAndInitialized(otherReferenceLevelNote)) {
      // ----------------------------------------
      const rlReferenceLevelNoteText = new fabric.Text(`${otherReferenceLevelNote}`, {
        fontFamily: "Noto Sans SC",
        left: 5.4 / 10 * canvas.width,
        top: pileShaftCanvasStart + 5 * canvas.width / 1000,
        fontSize: 15 * (canvas.width / 1000),
        textAlign: "left",
        fill: '#1976d2',
        originX: "left",
        originY: "top",
        objectCaching: false,
      });
      toDraw.push(rlReferenceLevelNoteText);
    }

    const otherReferenceDepthLabel = new fabric.Text(`0 m depth`, {
      fontFamily: "Noto Sans SC",
      left: rlTertiaryLabelX,
      top: pileShaftCanvasStart,
      fontSize: 14 * (canvas.width / 1000),
      textAlign: "right",
      fill: (isDefinedAndInitialized(pilingPlatformLevel)) ? "#000000" : "red",
      originX: "right",
      originY: "bottom",
      selectable: false,
      hasBorders: false,
      hasControls: false,
      evented: false,
      objectCaching: false,
    });
    toDraw.push(otherReferenceDepthLabel);

    const rlReferenceLabelText = new fabric.Text(`Reference`, {
      fontFamily: "Noto Sans SC",
      left: 4.47 / 10 * canvas.width,
      top: pileShaftCanvasStart + 5 * canvas.width / 1000,
      fontSize: 15 * (canvas.width / 1000),
      textAlign: "left",
      fill: 'white',
      originX: "left",
      originY: "top",
      selectable: false,
      hasBorders: false,
      hasControls: false,
      evented: false,
      objectCaching: false,
    });
    toDraw.push(rlReferenceLabelText);
  }

  const pileCentreLine = new fabric.Line(
    [
      (pileShaftLeftX + pileShaftRightX) / 2,
      pileShaftCanvasStart,
      (pileShaftLeftX + pileShaftRightX) / 2,
      pileToeCanvasHeight
    ],
    {
      strokeWidth: 2 * strokeWidthScaleFactor,
      fill: "black",
      stroke: "black",
      originX: "center",
      originY: "center",
      selectable: false,
      hasBorders: false,
      hasControls: false,
      evented: false,
      objectCaching: false,
    }
  );
  const pileCentreLineArrowTop = new fabric.Triangle({
    left: pileCentreLine.get('x1'),
    top: pileCentreLine.get('y1'),
    originX: 'center',
    originY: 'top',
    hasBorders: false,
    hasControls: false,
    lockScalingX: true,
    lockScalingY: true,
    lockRotation: true,
    selectable: false,
    evented: false,
    objectCaching: false,
    pointType: 'arrow_start',
    angle: 0,
    width: 10 * canvas.width / 1000,
    height: 20 * canvas.width / 1000,
    fill: '#000'
  });
  const pileCentreLineArrowBottom = new fabric.Triangle({
    left: pileCentreLine.get('x2'),
    top: pileCentreLine.get('y2'),
    originX: 'center',
    originY: 'top',
    hasBorders: false,
    hasControls: false,
    lockScalingX: true,
    lockScalingY: true,
    lockRotation: true,
    selectable: false,
    evented: false,
    objectCaching: false,
    pointType: 'arrow_start',
    angle: 180,
    width: 10 * canvas.width / 1000,
    height: 20 * canvas.width / 1000,
    fill: '#000'
  });
  const measuredDepthLabel = new fabric.Text(isDefinedAndInitialized(depthToBase) ? `${depthToBase} m` : 'unknown', {
    fontFamily: "Noto Sans SC",
    left: (pileShaftLeftX + pileShaftRightX) / 2,
    top: pileShaftCanvasStart + pileShaftCanvasDiff / 2,
    fontSize: 20 * (canvas.width / 1000),
    textAlign: "left",
    fill: "black",
    originX: "center",
    originY: "bottom",
    selectable: false,
    hasBorders: false,
    hasControls: false,
    evented: false,
    objectCaching: false,
  });

  const measuredDepthLabelBackground = new fabric.Polygon([
    {
      x: measuredDepthLabel.left - (measuredDepthLabel.width / 2) - 5 * canvas.width / 1000,
      y: measuredDepthLabel.top + 5 * canvas.width / 1000
    },
    {
      x: measuredDepthLabel.left - (measuredDepthLabel.width / 2) - 5 * canvas.width / 1000,
      y: measuredDepthLabel.top - measuredDepthLabel.height - 5 * canvas.width / 1000
    },
    {
      x: measuredDepthLabel.left + (measuredDepthLabel.width / 2) + 8 * canvas.width / 1000,
      y: measuredDepthLabel.top - measuredDepthLabel.height - 5 * canvas.width / 1000
    },
    {
      x: measuredDepthLabel.left + (measuredDepthLabel.width / 2) + 8 * canvas.width / 1000,
      y: measuredDepthLabel.top + 5 * canvas.width / 1000
    },
  ],
    {
      stroke: 'white',
      strokeWidth: 2 * strokeWidthScaleFactor,
      fill: 'white',
      opacity: 1,
      selectable: false,
      hasBorders: false,
      hasControls: false,
      evented: false,
      objectCaching: false
    })
  toDraw.push(pileCentreLine);
  toDraw.push(pileCentreLineArrowTop);
  toDraw.push(pileCentreLineArrowBottom);
  toDraw.push(measuredDepthLabelBackground);
  toDraw.push(measuredDepthLabel);

  return toDraw;
}

// Draw the pile platform level and the pile shaft to toe level on the canvas
const drawPileShaft = (canvas, componentData, strokeWidthScaleFactor) => {
  // ----------------------------------------
  const { designToeLevel, depthToBase, externalDiameter, poleDiameter, steelSection } = componentData;
  // Canvas drawing dimensions
  const pileShaftCanvasStart = Y_START_PILE_SHAFT_CANVAS_RATIO * canvas.height;
  const pileShaftCanvasEnd = Y_END_PILE_SHAFT_CANVAS_RATIO * canvas.height;
  const pileShaftCanvasDiff = pileShaftCanvasEnd - pileShaftCanvasStart;

  // canDrawRLs tells us whether we have sufficient information to draw the observation in terms of levels rather than depths
  const canDrawRLs = canCalculateRLs(componentData);
  const maxHeightInMetresForPileCanvas = getMaxHeightInMetresForPileCanvas(componentData);
  const pileShaftOffsetFromSurface = getPileShaftOffsetFromSurface(componentData);
  const pileShaftStartHeight = pileShaftCanvasStart + pileShaftCanvasDiff * (pileShaftOffsetFromSurface) / maxHeightInMetresForPileCanvas;
  const pileToeCanvasHeight = pileShaftCanvasStart + pileShaftCanvasDiff * (depthToBase) / maxHeightInMetresForPileCanvas;

  const pileShaftLeftX = X_START_PILE_SHAFT_CANVAS_RATIO * canvas.width;
  const pileShaftRightX = X_END_PILE_SHAFT_CANVAS_RATIO * canvas.width;

  // Datum tells what our 'zero' reference is which equates to our 1/5 of the canvas height starting point
  // Note: the bottom of the pile shaft (either pile toe OR design pile toe is always at 4/5 of the canvas height)
  const datum = getDatum(componentData);
  const lineProps = {
    strokeWidth: 2 * strokeWidthScaleFactor,
    fill: "black",
    stroke: "black",
    originX: "center",
    originY: "center",
    selectable: false,
    hasBorders: false,
    hasControls: false,
    evented: false,
    objectCaching: false,
  };

  const rlPrimLineLabelX1 = X_START_EDGE_OF_CANVAS_DRAWING_RATIO * canvas.width;
  const rlPrimLineLabelX2 = (canDrawRLs) ? 2.3 / 10 * canvas.width : 2.62 / 10 * canvas.width;
  const rlPrimTextLabelX = X_ELEVATION_LABEL_TEXT * canvas.width;
  const rlSecLineLabelX1 = 4.08 / 10 * canvas.width;
  const rlSecLineLabelX2 = (canDrawRLs) ? 5.38 / 10 * canvas.width : 5.7 / 10 * canvas.width;
  const rlSecTextLabelX = 4.45 / 10 * canvas.width;

  let toDraw = [];

  // Design Pile Toe
  // Only draw where a absolute (i.e. non-depth) datum is defined
  const drawDesignPileToe = shouldDrawDesignToeLevel(componentData);
  if (drawDesignPileToe) {
    const designPileToeCanvasHeight = pileShaftCanvasStart + pileShaftCanvasDiff * (datum.value - designToeLevel) / maxHeightInMetresForPileCanvas;
    const isPileToeElevationValid = checkPileToeElevationValid(componentData);
    const designPileToeLine = new fabric.Line(
      [
        rlSecLineLabelX1,
        designPileToeCanvasHeight,
        rlSecLineLabelX2,
        designPileToeCanvasHeight
      ],
      {
        // Switch up the line properties colour depending on the state of the pile record
        ...lineProps,
        stroke: (isPileToeElevationValid) ? 'green' : 'red',
        fill: (isPileToeElevationValid) ? 'green' : 'red',
      }
    );
    toDraw.push(designPileToeLine);

    const rlToeLevelText = new fabric.Text(`${designToeLevel} m RL`, {
      fontFamily: "Noto Sans SC",
      left: rlSecTextLabelX,
      top: designPileToeCanvasHeight,
      fontSize: 20 * (canvas.width / 1000),
      textAlign: "left",
      fill: (isPileToeElevationValid) ? "green" : "red",
      originX: "left",
      originY: "bottom",
      selectable: false,
      hasBorders: false,
      hasControls: false,
      evented: false,
      objectCaching: false,
    });
    toDraw.push(rlToeLevelText);

    const rlToeLevelDesignText = new fabric.Text(`${(isPileToeElevationValid) ? 'Design' : 'Design not achieved'}`, {
      fontFamily: "Noto Sans SC",
      left: rlSecTextLabelX,
      top: designPileToeCanvasHeight + 5 * canvas.width / 1000,
      fontSize: 15 * (canvas.width / 1000),
      textAlign: "left",
      fill: (isPileToeElevationValid) ? "green" : "red",
      originX: "left",
      originY: "top",
      selectable: false,
      hasBorders: false,
      hasControls: false,
      evented: false,
      objectCaching: false,
    });
    toDraw.push(rlToeLevelDesignText);
  }

  // Bottom of the pile shaft
  const pileShaftBottom = new fabric.Line(
    [
      pileShaftLeftX,
      pileToeCanvasHeight,
      pileShaftRightX,
      pileToeCanvasHeight
    ],
    lineProps
  );
  toDraw.push(pileShaftBottom);

  // Toe level Label
  const toeLevel = new fabric.Line(
    [
      rlPrimLineLabelX1,
      pileToeCanvasHeight,
      rlPrimLineLabelX2,
      pileToeCanvasHeight
    ],
    lineProps
  );
  toDraw.push(toeLevel);
  const pileShaftLeft = new fabric.Line(
    [
      pileShaftLeftX,
      pileShaftStartHeight,
      pileShaftLeftX,
      pileToeCanvasHeight
    ],
    lineProps
  );
  const pileShaftRight = new fabric.Line(
    [
      pileShaftRightX,
      pileShaftStartHeight,
      pileShaftRightX,
      pileToeCanvasHeight
    ],
    lineProps
  );
  const polygonPoints = [
    {
      x: pileShaftLeftX,
      y: pileShaftCanvasStart
    },
    {
      x: pileShaftLeftX,
      y: pileToeCanvasHeight,
    },
    {
      x: pileShaftRightX,
      y: pileToeCanvasHeight,
    },
    {
      x: pileShaftRightX,
      y: pileShaftCanvasStart
    },
  ]
  const pileShaftPolygon = new fabric.Polygon(polygonPoints, {
    stroke: 'white',
    strokeWidth: 0,
    fill: 'white',
    opacity: 1,
    selectable: false,
    hasBorders: false,
    hasControls: false,
    evented: false,
    objectCaching: false,
  });
  toDraw.push(pileShaftPolygon);
  toDraw.push(pileShaftLeft);
  toDraw.push(pileShaftRight);

  let label = (canDrawRLs) ? 'm RL' : 'm depth';
  const rlToeLevelText = new fabric.Text(calculatePileToeLevel(componentData) !== '' ? `${calculatePileToeLevel(componentData)} ${label}` : 'unknown', {
    fontFamily: "Noto Sans SC",
    left: rlPrimTextLabelX,
    top: pileToeCanvasHeight,
    fontSize: 20 * (canvas.width / 1000),
    textAlign: "left",
    fill: (calculatePileToeLevel(componentData) !== '') ? "#000000" : "red",
    originX: "left",
    originY: "bottom",
    selectable: false,
    hasBorders: false,
    hasControls: false,
    evented: false,
    objectCaching: false,
  });
  toDraw.push(rlToeLevelText);

  const pileDiameterLineLeft = new fabric.Line(
    [
      pileShaftLeftX - 50 * (canvas.width / 1000),
      pileShaftCanvasStart - 0.2 / 5 * canvas.height,
      pileShaftLeftX,
      pileShaftCanvasStart - 0.2 / 5 * canvas.height,
    ],
    {
      strokeWidth: 2 * strokeWidthScaleFactor,
      fill: "black",
      stroke: "black",
      originX: "center",
      originY: "center",
      selectable: false,
      hasBorders: false,
      hasControls: false,
      evented: false,
      objectCaching: false,
    }
  );
  const pileDiameterLineRight = new fabric.Line(
    [
      pileShaftRightX,
      pileShaftCanvasStart - 0.2 / 5 * canvas.height,
      pileShaftRightX + 50 * (canvas.width / 1000),
      pileShaftCanvasStart - 0.2 / 5 * canvas.height,
    ],
    {
      strokeWidth: 2 * strokeWidthScaleFactor,
      fill: "black",
      stroke: "black",
      originX: "center",
      originY: "center",
      selectable: false,
      hasBorders: false,
      hasControls: false,
      evented: false,
      objectCaching: false,
    }
  );
  const pileDiameterLineUnderlay = new fabric.Line(
    [
      pileShaftLeftX,
      pileShaftCanvasStart - 0.2 / 5 * canvas.height,
      pileShaftRightX,
      pileShaftCanvasStart - 0.2 / 5 * canvas.height,
    ],
    {
      strokeWidth: 2 * strokeWidthScaleFactor,
      opacity: 0.5,
      strokeDashArray: [5, 5],
      fill: "grey",
      stroke: "grey",
      originX: "center",
      originY: "center",
      selectable: false,
      hasBorders: false,
      hasControls: false,
      evented: false,
      objectCaching: false,
    }
  );
  const pileDiameterLineArrowLeft = new fabric.Triangle({
    left: pileDiameterLineLeft.get('x2'),
    top: pileDiameterLineLeft.get('y2'),
    originX: 'center',
    originY: 'top',
    hasBorders: false,
    hasControls: false,
    lockScalingX: true,
    lockScalingY: true,
    lockRotation: true,
    selectable: false,
    evented: false,
    objectCaching: false,
    pointType: 'arrow_start',
    angle: 90,
    width: 10 * canvas.width / 1000,
    height: 20 * canvas.width / 1000,
    fill: '#000'
  });
  const pileDiameterLineArrowRight = new fabric.Triangle({
    left: pileDiameterLineRight.get('x1'),
    top: pileDiameterLineRight.get('y1'),
    originX: 'center',
    originY: 'top',
    hasBorders: false,
    hasControls: false,
    lockScalingX: true,
    lockScalingY: true,
    lockRotation: true,
    selectable: false,
    evented: false,
    objectCaching: false,
    pointType: 'arrow_start',
    angle: 270,
    width: 10 * canvas.width / 1000,
    height: 20 * canvas.width / 1000,
    fill: '#000'
  });

  const timberPoleAddendumText = (componentData.pileType === 'timber pole' && isDefinedAndInitialized(poleDiameter)) ? `(${poleDiameter} mm pole)` : ``;
  const steelSectionAddendumText = (componentData.pileType === 'concrete encased steel section' && isDefinedAndInitialized(steelSection)) ? `(Steel ${steelSection.toUpperCase()})` : ``;

  const pileDiameterLabel = new fabric.Text(isDefinedAndInitialized(externalDiameter) ? `${externalDiameter} mm ${timberPoleAddendumText}${steelSectionAddendumText}` : 'unknown', {
    fontFamily: "Noto Sans SC",
    left: (pileShaftLeftX + pileShaftRightX) / 2,
    top: pileShaftCanvasStart - 0.2 / 5 * canvas.height - 10 * (canvas.width / 1000),
    fontSize: 20 * (canvas.width / 1000),
    textAlign: "left",
    fill: isDefinedAndInitialized(externalDiameter) ? "black" : "red",
    originX: "center",
    originY: "bottom",
    selectable: false,
    hasBorders: false,
    hasControls: false,
    evented: false,
    objectCaching: false,
  });
  toDraw.push(pileDiameterLineLeft);
  toDraw.push(pileDiameterLineRight);
  toDraw.push(pileDiameterLineUnderlay);
  toDraw.push(pileDiameterLineArrowLeft);
  toDraw.push(pileDiameterLineArrowRight);
  toDraw.push(pileDiameterLabel);

  return toDraw;
}

// Draw casing levels as thickened pile shaft lines with labels and notes
const drawPileCasing = (canvas, componentData, strokeWidthScaleFactor) => {
  // ----------------------------------------
  const { casingType, casingDiameter, topOfCasingLevel, casingLength } = componentData;
  let toDraw = [];

  // Canvas drawing dimensions
  const pileShaftCanvasStart = Y_START_PILE_SHAFT_CANVAS_RATIO * canvas.height;
  const pileShaftCanvasEnd = Y_END_PILE_SHAFT_CANVAS_RATIO * canvas.height;
  const pileShaftCanvasDiff = pileShaftCanvasEnd - pileShaftCanvasStart;

  // canDrawRLs tells us whether we have sufficient information to draw the observation in terms of levels rather than depths
  const canDrawRLs = canCalculateRLs(componentData);
  const maxHeightInMetresForPileCanvas = getMaxHeightInMetresForPileCanvas(componentData);

  const pileShaftLeftX = X_START_PILE_SHAFT_CANVAS_RATIO * canvas.width;
  const pileShaftRightX = X_END_PILE_SHAFT_CANVAS_RATIO * canvas.width;

  // Datum tells what our 'zero' reference is which equates to our 1/5 of the canvas height starting point
  // Note: the bottom of the pile shaft (either pile toe OR design pile toe is always at 4/5 of the canvas height)
  const datum = getDatum(componentData);
  const lineProps = {
    strokeWidth: 3 * strokeWidthScaleFactor,
    fill: "#ffc936",
    stroke: "#ffc936",
    originX: "center",
    originY: "center",
    selectable: false,
    hasBorders: false,
    hasControls: false,
    evented: false,
    objectCaching: false,
  };

  // 1) If topOfCasingLevel has been provided along with casing length
  //    draw a thickened pile shaft wall in this zone
  if (canDrawRLs && isDefinedAndInitialized(topOfCasingLevel) && isDefinedAndInitialized(casingLength)) {
    // ---------------------------------------------------------
    const startOfCasingCanvasHeight = pileShaftCanvasStart + (datum.value - topOfCasingLevel) / maxHeightInMetresForPileCanvas * pileShaftCanvasDiff;
    const endOfCasingCanvasHeight = startOfCasingCanvasHeight + (casingLength) / maxHeightInMetresForPileCanvas * pileShaftCanvasDiff;

    const pileCasingLeft = new fabric.Line(
      [
        pileShaftLeftX - 5 * canvas.width / 1000,
        startOfCasingCanvasHeight,
        pileShaftLeftX - 5 * canvas.width / 1000,
        endOfCasingCanvasHeight
      ],
      lineProps
    );
    const pileCasingRight = new fabric.Line(
      [
        pileShaftRightX + 5 * canvas.width / 1000,
        startOfCasingCanvasHeight,
        pileShaftRightX + 5 * canvas.width / 1000,
        endOfCasingCanvasHeight
      ],
      lineProps
    );
    toDraw.push(pileCasingLeft);
    toDraw.push(pileCasingRight);

    let casingTopElevationLabel = new fabric.Text(`Top of casing: ${topOfCasingLevel} m RL`, {
      fontFamily: "Noto Sans SC",
      left: pileShaftRightX + 12 * canvas.width / 1000,
      top: (startOfCasingCanvasHeight + endOfCasingCanvasHeight) / 2,
      fontSize: 15 * (canvas.width / 1000),
      textAlign: "left",
      fill: "#80641b",
      originX: "left",
      originY: "bottom",
      hasBorders: false,
      objectCaching: false,
    });
    let casingBottomElevationLabel = new fabric.Text(`Toe of casing: ${(topOfCasingLevel - casingLength).toFixed(1)} m RL`, {
      fontFamily: "Noto Sans SC",
      left: pileShaftRightX + 12 * canvas.width / 1000,
      top: (startOfCasingCanvasHeight + endOfCasingCanvasHeight) / 2,
      fontSize: 15 * (canvas.width / 1000),
      textAlign: "left",
      fill: "#80641b",
      originX: "left",
      originY: "top",
      hasBorders: false,
      objectCaching: false,
    });
    toDraw.push(casingTopElevationLabel);
    toDraw.push(casingBottomElevationLabel);

    // 2) Add casing notes including diameter and type of casing used
    // (NOTE: casing notes was initially thought of but have instead gone for pile notes with multi-line input to cover various note taking requirements)
    if ((isDefinedAndInitialized(casingType) || isDefinedAndInitialized(casingDiameter)) && casingType !== 'None') {
      // ----------------------------------------
      let casingNotesSections = [];
      if (isDefinedAndInitialized(casingDiameter)) { casingNotesSections.push(`Diameter: ${casingDiameter} mm`); }
      if (isDefinedAndInitialized(casingType)) { casingNotesSections.push(`Type: ${capitalizeFirstLetters(casingType)}`); }

      let casingNotes = new fabric.Text(`${casingNotesSections.join('\n')}`, {
        fontFamily: "Noto Sans SC",
        left: pileShaftRightX + 12 * canvas.width / 1000,
        top: casingBottomElevationLabel.top + 20 * (canvas.width / 1000),
        fontSize: 15 * (canvas.width / 1000),
        textAlign: "left",
        fill: "#80641b",
        originX: "left",
        originY: "top",
        objectCaching: false,
      });
      toDraw.push(casingNotes);
    }
  }

  return toDraw;
}

// Draw pile notes
const addPileNotes = (canvas, componentData, strokeWidthScaleFactor) => {
  // ----------------------------------------
  const { otherNotes, observedGroundwater } = componentData;
  let toDraw = [];

  const bottomOfOtherPileNotes = 4.15 / 5 * canvas.height;

  // Groundwater notes
  // Add a label for each observation plus an associated note
  let startOfGroundwaterNotes = bottomOfOtherPileNotes;
  let cursorForGroundwaterNotes = 0;
  let groundwaterNotesGroup = [];

  let canvasGroundwaterNotesGroup = null;
  const groundwaterHeader = new fabric.Text('Groundwater notes:', {
    fontFamily: "Noto Sans SC",
    fontSize: 18 * (canvas.width / 1000),
    textAlign: 'right',
    originX: 'left',
    originY: 'center',
    fill: "blue",
    objectCaching: false,
    top: cursorForGroundwaterNotes,
    selectable: false,
    hasBorders: false,
    hasControls: false,
    evented: false,
  });
  groundwaterNotesGroup.push(groundwaterHeader);
  cursorForGroundwaterNotes = groundwaterHeader.top + groundwaterHeader.height + 7 * (canvas.width / 1000);

  for (const [index, observation] of observedGroundwater.entries()) {
    // ------------------------------------
    const circle = new fabric.Circle({
      radius: 14 * (canvas.width / 1000),
      fill: 'white',
      stroke: observation.gwObsType === 'dry' ? 'black' : 'blue',
      strokeWidth: 1 * strokeWidthScaleFactor,
      originX: 'left',
      originY: 'center',
      objectCaching: false,
    });


    const indexLabel = new fabric.Text((index + 1).toString(), {
      fontFamily: "Noto Sans SC",
      fontSize: 14 * (canvas.width / 1000),
      textAlign: 'right',
      originX: 'left',
      originY: 'center',
      fill: observation.gwObsType === 'dry' ? 'black' : 'blue',
      width: 2.75 / 10 * canvas.width,
      objectCaching: false,
      left: 10 * (canvas.width / 1000)
    });

    const indexNotes = new fabric.Textbox((isDefinedAndInitialized(observation.groundwaterObservation) && observation.groundwaterObservation !== '')
      ? observation.groundwaterObservation : 'No further notes', {
      fontFamily: "Noto Sans SC",
      fontSize: 14 * (canvas.width / 1000),
      textAlign: 'left',
      originX: 'left',
      originY: 'center',
      width: 2.75 / 10 * canvas.width,
      fill: observation.gwObsType === 'dry' ? 'black' : 'blue',
      objectCaching: false,
      left: 32 * (canvas.width / 1000)
    });

    const labelGroup = new fabric.Group([circle, indexLabel, indexNotes], {
      top: cursorForGroundwaterNotes,
      selectable: false,
      hasBorders: false,
      hasControls: false,
      evented: false,
    });

    groundwaterNotesGroup.push(labelGroup);
    cursorForGroundwaterNotes = labelGroup.top + labelGroup.height + 7 * (canvas.width / 1000);
  }

  canvasGroundwaterNotesGroup = new fabric.Group(groundwaterNotesGroup, {
    top: startOfGroundwaterNotes + 6 * (canvas.width / 1000),
    left: X_ELEVATION_LABEL_TEXT * canvas.width,
  });
  toDraw = [...toDraw, canvasGroundwaterNotesGroup]

  // Other pile notes
  let otherPileNotes = new fabric.Textbox(`Pile notes:\n${(isDefinedAndInitialized(otherNotes) && otherNotes !== '') ? otherNotes : '-'}`, {
    fontFamily: "Noto Sans SC",
    left: 4.35 / 10 * canvas.width,
    top: bottomOfOtherPileNotes + 6 * (canvas.width / 1000),
    width: 4.65 / 10 * canvas.width,
    fontSize: 18 * (canvas.width / 1000),
    textAlign: "left",
    fill: "black",
    originX: "left",
    originY: "top",
  });
  toDraw.push(otherPileNotes);

  return toDraw;
}

// Draw geological observations
const addGeologicalObservations = (canvas, componentData, strokeWidthScaleFactor) => {
  // ----------------------------------------
  const { observedGeology, pileCriteria, designGeoEmbedment, depthToBase } = componentData;
  let toDraw = [];

  // Canvas drawing dimensions
  const pileShaftCanvasStart = Y_START_PILE_SHAFT_CANVAS_RATIO * canvas.height;
  const pileShaftCanvasEnd = Y_END_PILE_SHAFT_CANVAS_RATIO * canvas.height;
  const pileShaftCanvasDiff = pileShaftCanvasEnd - pileShaftCanvasStart;

  // canDrawRLs tells us whether we have sufficient information to draw the observation in terms of levels rather than depths
  const canDrawRLs = canCalculateRLs(componentData);
  const datum = getDatum(componentData);
  const maxHeightInMetresForPileCanvas = getMaxHeightInMetresForPileCanvas(componentData);

  const pileShaftRightX = X_END_PILE_SHAFT_CANVAS_RATIO * canvas.width;

  const geoObsBoundaryLabelStart = 6.3 / 10 * canvas.width;
  const geoObsBoundaryLabelEnd = X_END_EDGE_OF_CANVAS_DRAWING_RATIO * canvas.width;
  const rlPrimLineLabelX1 = X_START_EDGE_OF_CANVAS_DRAWING_RATIO * canvas.width;
  const rlPrimLineLabelX2 = (canDrawRLs) ? 2.3 / 10 * canvas.width : 2.62 / 10 * canvas.width;
  const rlPrimTextLabelX = X_ELEVATION_LABEL_TEXT * canvas.width;
  const rlTertiaryLabelX = X_DEPTH_LABEL_TEXT * canvas.width;
  const geoLabelX = 6.2 / 10 * canvas.width;
  const socketLabelX = 0.8 / 10 * canvas.width;

  const lineProps = {
    strokeWidth: 1 * strokeWidthScaleFactor,
    fill: "black",
    stroke: "black",
    originX: "center",
    originY: "center",
    selectable: false,
    hasBorders: false,
    hasControls: false,
    evented: false,
    objectCaching: false,
  };

  // Draw pile geo embedment result if relevant criteria
  if ((pileCriteria.includes('geological embedment depth') || pileCriteria.includes('pile socket criteria')) && observedGeology.length > 0 && isDefinedAndInitialized(designGeoEmbedment)) {
    // -----------------------------------------
    const isPileGeoEmbedmentValid = checkPileGeoEmbedmentValid(componentData);

    const canvasHeightToDesignLabelText = pileShaftCanvasStart + depthToBase / maxHeightInMetresForPileCanvas * pileShaftCanvasDiff;
    let geologicalObservationText = new fabric.Textbox(`${(isPileGeoEmbedmentValid) ? `${designGeoEmbedment} m design socket achieved` : `${designGeoEmbedment} m design embedment not achieved`}`, {
      fontFamily: "Noto Sans SC",
      left: (X_START_PILE_SHAFT_CANVAS_RATIO + X_END_PILE_SHAFT_CANVAS_RATIO) / 2 * canvas.width,
      top: canvasHeightToDesignLabelText + 10 * canvas.width / 1000,
      width: 2.5 / 10 * canvas.width,
      fontSize: 15 * (canvas.width / 1000),
      textAlign: "center",
      fill: (isPileGeoEmbedmentValid) ? "green" : "red",
      originX: "center",
      originY: "top",
      objectCaching: false,
    });
    toDraw.push(geologicalObservationText);
  }

  for (const [index, observation] of observedGeology.entries()) {
    // -----------------------------------------
    // 1) Add a depth line and dashed extension if a bottom depth is detailed for the unit
    if (isDefinedAndInitialized(observation.depth)) {
      // ---------------------------
      let canvasHeightForDepth = pileShaftCanvasStart + (observation.depth) / maxHeightInMetresForPileCanvas * pileShaftCanvasDiff;
      const geoDashedDepthLevel = new fabric.Line(
        [
          pileShaftRightX,
          canvasHeightForDepth,
          X_END_EDGE_OF_CANVAS_DRAWING_RATIO * canvas.width,
          canvasHeightForDepth
        ],
        {
          ...lineProps,
          stroke: 'grey',
          fill: 'grey',
          opacity: 0.5,
          strokeDashArray: [5, 5]
        }
      );
      toDraw.push(geoDashedDepthLevel);
      const geoSolidDepthLevel = new fabric.Line(
        [
          geoObsBoundaryLabelStart,
          canvasHeightForDepth,
          geoObsBoundaryLabelEnd,
          canvasHeightForDepth
        ],
        lineProps
      );
      toDraw.push(geoSolidDepthLevel);

      const geoObsElevationLine = new fabric.Line(
        [
          rlPrimLineLabelX1,
          canvasHeightForDepth,
          rlPrimLineLabelX2,
          canvasHeightForDepth,
        ],
        lineProps
      );
      toDraw.push(geoObsElevationLine);

      let label = (canDrawRLs) ? 'm RL' : 'm depth';
      const layerElevation = (canDrawRLs) ? (datum.value - observation.depth).toFixed(1) : Math.abs(datum.value - observation.depth).toFixed(1);
      const rlGeoObsLabelText = new fabric.Text(`${(calculatePileToeLevel(componentData) !== layerElevation) ? `${layerElevation} ${label}` : ''}`, {
        fontFamily: "Noto Sans SC",
        left: rlPrimTextLabelX,
        top: canvasHeightForDepth,
        fontSize: 20 * (canvas.width / 1000),
        textAlign: "left",
        fill: "black",
        originX: "left",
        originY: "bottom",
        objectCaching: false,
      });
      toDraw.push(rlGeoObsLabelText);

      let depthLabel = (Number(observation.depth) === Number(depthToBase)) ? `${Number(observation.depth).toFixed(1)} m depth EOH` : `${Number(observation.depth).toFixed(1)} m depth`;
      const depthFromReferenceLabel = new fabric.Text(depthLabel, {
        fontFamily: "Noto Sans SC",
        left: rlTertiaryLabelX,
        top: canvasHeightForDepth,
        fontSize: 14 * (canvas.width / 1000),
        textAlign: "right",
        fill: "black",
        originX: "right",
        originY: "bottom",
        selectable: false,
        hasBorders: false,
        hasControls: false,
        evented: false,
        objectCaching: false,
      });
      toDraw.push(depthFromReferenceLabel);
    }

    // If an embedment material, underly the unit with a light grey polygon
    const layerTopDepth = getGeoLayerTopDepth(componentData, index);
    const layerBottomDepth = getGeoLayerBottomDepth(componentData, observation, index);
    // If an embedment material has its top and bottom depths defined render these as grey shading on the geological observations
    if (observation.embedmentMaterial && isDefinedAndInitialized(layerTopDepth) && isDefinedAndInitialized(layerBottomDepth)) {
      // -----------------------------------------
      const shadingPolygonPoints = [
        {
          x: X_START_EDGE_OF_CANVAS_DRAWING_RATIO * canvas.width,
          y: pileShaftCanvasStart + layerTopDepth / maxHeightInMetresForPileCanvas * pileShaftCanvasDiff
        },
        {
          x: X_START_EDGE_OF_CANVAS_DRAWING_RATIO * canvas.width,
          y: pileShaftCanvasStart + layerBottomDepth / maxHeightInMetresForPileCanvas * pileShaftCanvasDiff
        },
        {
          x: X_END_EDGE_OF_CANVAS_DRAWING_RATIO * canvas.width,
          y: pileShaftCanvasStart + layerBottomDepth / maxHeightInMetresForPileCanvas * pileShaftCanvasDiff
        },
        {
          x: X_END_EDGE_OF_CANVAS_DRAWING_RATIO * canvas.width,
          y: pileShaftCanvasStart + layerTopDepth / maxHeightInMetresForPileCanvas * pileShaftCanvasDiff
        },
      ]
      const embedmentMaterialShadingPolygon = new fabric.Polygon(shadingPolygonPoints, {
        stroke: '#e3f2fd',
        strokeWidth: 0,
        fill: '#e3f2fd',
        opacity: 0.5,
        selectable: false,
        hasBorders: false,
        hasControls: false,
        evented: false,
        objectCaching: false,
      });
      toDraw.push(embedmentMaterialShadingPolygon);

      const emphasisPolygonPoints = [
        {
          x: X_START_PILE_SHAFT_CANVAS_RATIO * canvas.width - 5 * strokeWidthScaleFactor,
          y: pileShaftCanvasStart + layerTopDepth / maxHeightInMetresForPileCanvas * pileShaftCanvasDiff
        },
        {
          x: X_START_PILE_SHAFT_CANVAS_RATIO * canvas.width - 5 * strokeWidthScaleFactor,
          y: pileShaftCanvasStart + layerBottomDepth / maxHeightInMetresForPileCanvas * pileShaftCanvasDiff + 5 * strokeWidthScaleFactor
        },
        {
          x: X_END_PILE_SHAFT_CANVAS_RATIO * canvas.width + 5 * strokeWidthScaleFactor,
          y: pileShaftCanvasStart + layerBottomDepth / maxHeightInMetresForPileCanvas * pileShaftCanvasDiff + 5 * strokeWidthScaleFactor
        },
        {
          x: X_END_PILE_SHAFT_CANVAS_RATIO * canvas.width + 5 * strokeWidthScaleFactor,
          y: pileShaftCanvasStart + layerTopDepth / maxHeightInMetresForPileCanvas * pileShaftCanvasDiff
        },
      ]
      const embedmentMaterialEmphasisPolygon = new fabric.Polygon(emphasisPolygonPoints, {
        stroke: '#e3f2fd',
        strokeWidth: 0,
        fill: 'black',
        opacity: 1,
        selectable: false,
        hasBorders: false,
        hasControls: false,
        evented: false,
        objectCaching: false,
      });
      toDraw.push(embedmentMaterialEmphasisPolygon);

      const embedmentLabelLinePoints = [
        X_START_PILE_SHAFT_CANVAS_RATIO * canvas.width - 10,
        pileShaftCanvasStart + layerTopDepth / maxHeightInMetresForPileCanvas * pileShaftCanvasDiff,
        X_START_PILE_SHAFT_CANVAS_RATIO * canvas.width - 10,
        pileShaftCanvasStart + layerBottomDepth / maxHeightInMetresForPileCanvas * pileShaftCanvasDiff + 5
      ]
      const embedmentLabelLine = new fabric.Line(
        embedmentLabelLinePoints,
        lineProps
      );
      const embedmentLabelLineArrowTop = new fabric.Triangle({
        left: embedmentLabelLine.get('x1'),
        top: embedmentLabelLine.get('y1'),
        originX: 'center',
        originY: 'top',
        hasBorders: false,
        hasControls: false,
        lockScalingX: true,
        lockScalingY: true,
        lockRotation: true,
        selectable: false,
        evented: false,
        objectCaching: false,
        pointType: 'arrow_start',
        angle: 0,
        width: 10 * canvas.width / 1000,
        height: 20 * canvas.width / 1000,
        fill: '#000'
      });
      const embedmentLabelLineArrowBottom = new fabric.Triangle({
        left: embedmentLabelLine.get('x2'),
        top: embedmentLabelLine.get('y2'),
        originX: 'center',
        originY: 'top',
        hasBorders: false,
        hasControls: false,
        lockScalingX: true,
        lockScalingY: true,
        lockRotation: true,
        selectable: false,
        evented: false,
        objectCaching: false,
        pointType: 'arrow_start',
        angle: 180,
        width: 10 * canvas.width / 1000,
        height: 20 * canvas.width / 1000,
        fill: '#000'
      });
      toDraw.push(embedmentLabelLine);
      toDraw.push(embedmentLabelLineArrowTop);
      toDraw.push(embedmentLabelLineArrowBottom);

      if (isDefinedAndInitialized(layerTopDepth) && isDefinedAndInitialized(layerBottomDepth)) {
        // -----------------------------------------
        const labelDepth = (layerTopDepth + layerBottomDepth) / 2;
        const canvasHeightToObservationText = pileShaftCanvasStart + labelDepth / maxHeightInMetresForPileCanvas * pileShaftCanvasDiff;
        let socketObservationText = new fabric.Textbox(`Socket material`, {
          fontFamily: "Noto Sans SC",
          left: socketLabelX,
          top: canvasHeightToObservationText,
          width: 2.5 / 10 * canvas.width,
          fontSize: 18 * (canvas.width / 1000),
          textAlign: "left",
          fill: "black",
          originX: "left",
          originY: "center",
          objectCaching: false,
        });
        toDraw.push(socketObservationText);
      }
    }

    if (isDefinedAndInitialized(layerTopDepth) && isDefinedAndInitialized(layerBottomDepth)) {
      // -----------------------------------------
      const labelDepth = (layerTopDepth + layerBottomDepth) / 2;
      const canvasHeightToObservationText = pileShaftCanvasStart + labelDepth / maxHeightInMetresForPileCanvas * pileShaftCanvasDiff;
      let geologicalObservationText = new fabric.Textbox(`${observation.geologicalObservation}`, {
        fontFamily: "Noto Sans SC",
        left: geoLabelX,
        top: canvasHeightToObservationText,
        width: 2.5 / 10 * canvas.width,
        fontSize: 18 * (canvas.width / 1000),
        textAlign: "left",
        fill: "black",
        originX: "left",
        originY: "center",
        objectCaching: false,
      });
      toDraw.push(geologicalObservationText);
    }
  }
  return toDraw;
}

// Draw groundwater observations
const addGroundwaterObservations = (canvas, componentData, strokeWidthScaleFactor) => {
  // ----------------------------------------
  const { observedGroundwater } = componentData;
  let toDraw = [];

  // Canvas drawing dimensions
  const pileShaftCanvasStart = Y_START_PILE_SHAFT_CANVAS_RATIO * canvas.height;
  const pileShaftCanvasEnd = Y_END_PILE_SHAFT_CANVAS_RATIO * canvas.height;
  const pileShaftCanvasDiff = pileShaftCanvasEnd - pileShaftCanvasStart;

  // canDrawRLs tells us whether we have sufficient information to draw the observation in terms of levels rather than depths
  const datum = getDatum(componentData);
  const maxHeightInMetresForPileCanvas = getMaxHeightInMetresForPileCanvas(componentData);

  const pileShaftLeftX = X_START_PILE_SHAFT_CANVAS_RATIO * canvas.width;
  const pileShaftRightX = X_END_PILE_SHAFT_CANVAS_RATIO * canvas.width;

  const gwInflowArrowStart = pileShaftRightX - 0.15 / 10 * canvas.width;
  const gwInflowArrowEnd = pileShaftRightX + 0.15 / 10 * canvas.width;
  const gwInflowTextDepthLabelStartX = pileShaftRightX + 0.15 / 10 * canvas.width;
  const gwInflowTextElevationLabelStartX = pileShaftLeftX - 0.1 / 10 * canvas.width;

  const rlLineTriangleWidth = 20 * canvas.width / 1000;
  const rlLineTriangleHeight = 20 * canvas.width / 1000;

  const lineProps = {
    strokeWidth: 1 * strokeWidthScaleFactor,
    fill: "blue",
    stroke: "blue",
    originX: "center",
    originY: "center",
    selectable: false,
    hasBorders: false,
    hasControls: false,
    evented: false,
    objectCaching: false,
  };


  for (const [index, observation] of observedGroundwater.entries()) {
    // -----------------------------------------
    let canvasHeightForDepth = pileShaftCanvasStart + (observation.depth) / maxHeightInMetresForPileCanvas * pileShaftCanvasDiff;

    // 1) Add standing water observations
    if (isDefinedAndInitialized(observation.depth) && observation.gwObsType === 'inflow') {
      // ---------------------------
      const inflowSolidDepth1Level = new fabric.Line(
        [
          gwInflowArrowStart,
          canvasHeightForDepth,
          gwInflowArrowEnd,
          canvasHeightForDepth
        ],
        lineProps
      );
      const groundwaterArrow1 = new fabric.Triangle({
        left: inflowSolidDepth1Level.get('x1'),
        top: inflowSolidDepth1Level.get('y1'),
        originX: 'center',
        originY: 'top',
        hasBorders: false,
        hasControls: false,
        lockScalingX: true,
        lockScalingY: true,
        lockRotation: true,
        selectable: false,
        evented: false,
        objectCaching: false,
        pointType: 'arrow_start',
        angle: 270,
        width: 8 * canvas.width / 1000,
        height: 8 * canvas.width / 1000,
        fill: 'blue'
      });
      const depthFromReferenceLabel = new fabric.Text(`${Number(observation.depth).toFixed(1)} m\ndepth`, {
        fontFamily: "Noto Sans SC",
        left: gwInflowTextDepthLabelStartX,
        top: canvasHeightForDepth + 12 * canvas.width / 1000,
        fontSize: 14 * (canvas.width / 1000),
        textAlign: "right",
        fill: "blue",
        originX: "left",
        originY: "center",
        objectCaching: false,
      });
      const rlFromReferenceLabel = new fabric.Text(`${(Number(datum.value) - Number(observation.depth)).toFixed(1)}\nm RL`, {
        fontFamily: "Noto Sans SC",
        left: gwInflowTextElevationLabelStartX,
        top: canvasHeightForDepth + 12 * canvas.width / 1000,
        fontSize: 14 * (canvas.width / 1000),
        textAlign: "left",
        fill: "blue",
        originX: "right",
        originY: "center",
        objectCaching: false,
      });
      toDraw.push(rlFromReferenceLabel);
      toDraw.push(depthFromReferenceLabel);
      toDraw.push(inflowSolidDepth1Level);
      toDraw.push(groundwaterArrow1);

      const inflowSolidDepth2Level = new fabric.Line(
        [
          gwInflowArrowStart,
          canvasHeightForDepth + 12 * canvas.width / 1000,
          gwInflowArrowEnd,
          canvasHeightForDepth + 12 * canvas.width / 1000,
        ],
        lineProps
      );
      const groundwaterArrow2 = new fabric.Triangle({
        left: inflowSolidDepth2Level.get('x1'),
        top: inflowSolidDepth2Level.get('y1'),
        originX: 'center',
        originY: 'top',
        hasBorders: false,
        hasControls: false,
        lockScalingX: true,
        lockScalingY: true,
        lockRotation: true,
        selectable: false,
        evented: false,
        objectCaching: false,
        pointType: 'arrow_start',
        angle: 270,
        width: 8 * canvas.width / 1000,
        height: 8 * canvas.width / 1000,
        fill: 'blue'
      });
      toDraw.push(inflowSolidDepth2Level);
      toDraw.push(groundwaterArrow2);

      const inflowSolidDepth3Level = new fabric.Line(
        [
          gwInflowArrowStart,
          canvasHeightForDepth + 24 * canvas.width / 1000,
          gwInflowArrowEnd,
          canvasHeightForDepth + 24 * canvas.width / 1000
        ],
        lineProps
      );
      const groundwaterArrow3 = new fabric.Triangle({
        left: inflowSolidDepth3Level.get('x1'),
        top: inflowSolidDepth3Level.get('y1'),
        originX: 'center',
        originY: 'top',
        hasBorders: false,
        hasControls: false,
        lockScalingX: true,
        lockScalingY: true,
        lockRotation: true,
        selectable: false,
        evented: false,
        objectCaching: false,
        pointType: 'arrow_start',
        angle: 270,
        width: 8 * canvas.width / 1000,
        height: 8 * canvas.width / 1000,
        fill: 'blue'
      });
      // 4) Add a label for each observation to reference an associated note
      const circle = new fabric.Circle({

        radius: 14 * (canvas.width / 1000),
        fill: 'white',
        stroke: observation.gwObsType === 'dry' ? 'black' : 'blue',
        strokeWidth: 1 * strokeWidthScaleFactor,
        originX: 'center',
        originY: 'center',
        objectCaching: false,
      });


      const indexLabel = new fabric.Text((index + 1).toString(), {
        fontFamily: "Noto Sans SC",
        fontSize: 14 * (canvas.width / 1000),
        textAlign: 'right',
        originX: 'center',
        originY: 'center',
        fill: observation.gwObsType === 'dry' ? 'black' : 'blue',
        objectCaching: false,
      });

      const labelGroup = new fabric.Group([circle, indexLabel], {
        top: canvasHeightForDepth - 32 * (canvas.width / 1000),
        left: gwInflowArrowStart - 20 * (canvas.width / 1000),
      })

      toDraw.push(labelGroup);
      toDraw.push(inflowSolidDepth3Level);
      toDraw.push(groundwaterArrow3);
    }
    // 2) Add inflow observations
    if (isDefinedAndInitialized(observation.depth) && observation.gwObsType === 'standing') {
      // ---------------------------
      const inflowSolidDepth1Level = new fabric.Line(
        [
          pileShaftLeftX,
          canvasHeightForDepth,
          pileShaftRightX,
          canvasHeightForDepth
        ],
        lineProps
      );
      const depthFromReferenceLabel = new fabric.Text(`${Number(observation.depth).toFixed(1)} m\ndepth`, {
        fontFamily: "Noto Sans SC",
        left: gwInflowTextDepthLabelStartX - 0.05 / 10 * canvas.width,
        top: canvasHeightForDepth,
        fontSize: 14 * (canvas.width / 1000),
        textAlign: "right",
        fill: "blue",
        originX: "left",
        originY: "center",
        objectCaching: false,
      });
      const rlFromReferenceLabel = new fabric.Text(`${(Number(datum.value) - Number(observation.depth)).toFixed(1)}\nm RL`, {
        fontFamily: "Noto Sans SC",
        left: gwInflowTextElevationLabelStartX,
        top: canvasHeightForDepth,
        fontSize: 14 * (canvas.width / 1000),
        textAlign: "left",
        fill: "blue",
        originX: "right",
        originY: "center",
        objectCaching: false,
      });
      const standingLevelArrow = new fabric.Triangle({
        left: pileShaftLeftX + 0.21 / 10 * canvas.width,
        top: canvasHeightForDepth,
        originX: 'center',
        originY: 'top',
        hasBorders: false,
        hasControls: false,
        lockScalingX: true,
        lockScalingY: true,
        lockRotation: true,
        selectable: false,
        evented: false,
        objectCaching: false,
        pointType: 'arrow_start',
        angle: 180,
        width: rlLineTriangleWidth,
        height: rlLineTriangleHeight,
        fill: "rgba(0,0,0,0)",
        strokeWidth: 2 * strokeWidthScaleFactor,
        stroke: 'blue',
      });
      // 4) Add a label for each observation to reference an associated note
      const circle = new fabric.Circle({

        radius: 14 * (canvas.width / 1000),
        fill: 'white',
        stroke: observation.gwObsType === 'dry' ? 'black' : 'blue',
        strokeWidth: 1 * strokeWidthScaleFactor,
        originX: 'center',
        originY: 'center',
        objectCaching: false,
      });


      const indexLabel = new fabric.Text((index + 1).toString(), {
        fontFamily: "Noto Sans SC",
        fontSize: 14 * (canvas.width / 1000),
        textAlign: 'right',
        originX: 'center',
        originY: 'center',
        fill: observation.gwObsType === 'dry' ? 'black' : 'blue',
        objectCaching: false,
      });

      const labelGroup = new fabric.Group([circle, indexLabel], {
        top: canvasHeightForDepth - 32 * (canvas.width / 1000),
        left: gwInflowArrowStart - 20 * (canvas.width / 1000),
      })

      toDraw.push(labelGroup);
      toDraw.push(standingLevelArrow);
      toDraw.push(rlFromReferenceLabel);
      toDraw.push(depthFromReferenceLabel);
      toDraw.push(inflowSolidDepth1Level);
    }
    // 3) Add dry hole observations
    if (isDefinedAndInitialized(observation.depth) && observation.gwObsType === 'dry') {
      // ---------------------------
      const depthFromReferenceLabel = new fabric.Text(`${Number(observation.depth).toFixed(1)} m\ndepth`, {
        fontFamily: "Noto Sans SC",
        left: gwInflowTextDepthLabelStartX - 0.05 / 10 * canvas.width,
        top: canvasHeightForDepth,
        fontSize: 14 * (canvas.width / 1000),
        textAlign: "right",
        fill: "black",
        originX: "left",
        originY: "center",
        objectCaching: false,
      });
      const rlFromReferenceLabel = new fabric.Text(`${(Number(datum.value) - Number(observation.depth)).toFixed(1)}\nm RL`, {
        fontFamily: "Noto Sans SC",
        left: gwInflowTextElevationLabelStartX,
        top: canvasHeightForDepth,
        fontSize: 14 * (canvas.width / 1000),
        textAlign: "left",
        fill: "black",
        originX: "right",
        originY: "center",
        objectCaching: false,
      });

      // 4) Add a label for each observation to reference an associated note
      const circle = new fabric.Circle({

        radius: 14 * (canvas.width / 1000),
        fill: 'white',
        stroke: observation.gwObsType === 'dry' ? 'black' : 'blue',
        strokeWidth: 1 * strokeWidthScaleFactor,
        originX: 'center',
        originY: 'center',
        objectCaching: false,
      });


      const indexLabel = new fabric.Text((index + 1).toString(), {
        fontFamily: "Noto Sans SC",
        fontSize: 14 * (canvas.width / 1000),
        textAlign: 'right',
        originX: 'center',
        originY: 'center',
        fill: observation.gwObsType === 'dry' ? 'black' : 'blue',
        objectCaching: false,
      });

      const labelGroup = new fabric.Group([circle, indexLabel], {
        top: canvasHeightForDepth - 32 * (canvas.width / 1000),
        left: gwInflowArrowStart - 20 * (canvas.width / 1000),
      })

      toDraw.push(labelGroup);
      toDraw.push(rlFromReferenceLabel);
      toDraw.push(depthFromReferenceLabel);
    }
  }
  return toDraw;
}


// Helper message for when there is insufficient information
const drawInsufficentDataMessageAndHelper = (canvas) => {
  // ----------------------------------------
  let toDraw = [];

  let insufficientDataMessage = new fabric.Text(`Not enough information to prepare a pile observation preview`, {
    fontFamily: "Noto Sans SC",
    left: 1 / 2 * canvas.width,
    top: Y_START_PILE_SHAFT_CANVAS_RATIO * canvas.width,
    fontSize: 30 * (canvas.width / 1000),
    textAlign: "left",
    fill: "red",
    originX: "center",
    originY: "top",
    selectable: false,
    hasBorders: false,
    hasControls: false,
    evented: false,
    objectCaching: false,
  });
  let helperMessage = new fabric.Text(
    `Please enter some geological units or groundwater observations`, {
    fontFamily: "Noto Sans SC",
    left: 1 / 2 * canvas.width,
    top: 1 / 5 * canvas.width + 40 * (canvas.width / 1000),
    fontSize: 25 * (canvas.width / 1000),
    textAlign: "left",
    fill: "red",
    originX: "center",
    originY: "top",
    selectable: false,
    hasBorders: false,
    hasControls: false,
    evented: false,
    objectCaching: false,
  });
  toDraw.push(insufficientDataMessage);
  toDraw.push(helperMessage);

  return toDraw;
}

// Draw all levels and depths on the canvas
const drawPileObservation = (canvas, componentData, strokeWidthScaleFactor) => {
  // ----------------------------------------
  const { pilingPlatformLevel, otherReferenceLevel, hasCasing, casingType,
    observedGeology, observedGroundwater } = componentData;
  let toDraw = [];

  canvas.backgroundColor = 'white';

  // Determine whether to draw anything (i.e. can define the pile shaft)
  // -> Other render message that data is required to prepare preview
  let maxHeightForPileCanvas = getMaxHeightInMetresForPileCanvas(componentData);
  let shouldDrawPileCanvas = maxHeightForPileCanvas > 0;
  if (!shouldDrawPileCanvas) {
    // ----------------------------------------------
    let message = drawInsufficentDataMessageAndHelper(canvas);
    toDraw = [...toDraw, ...message]
  }

  // Conditionally draw geological observations
  if (shouldDrawPileCanvas && observedGeology.length > 0) {
    // -------------------------------------------------------
    let geologicalObservations = addGeologicalObservations(canvas, componentData, strokeWidthScaleFactor);
    toDraw = [...toDraw, ...geologicalObservations];
  }

  // Draw toe elevation
  if (shouldDrawPileCanvas && calculatePileToeLevel(componentData) !== '') {
    // -------------------------------------------------------
    let pileToe = drawPileShaft(canvas, componentData, strokeWidthScaleFactor);
    toDraw = [...toDraw, ...pileToe];
  }

  // Conditionally draw pile platform
  if (shouldDrawPileCanvas && (isDefinedAndInitialized(pilingPlatformLevel) || isDefinedAndInitialized(otherReferenceLevel))) {
    // -------------------------------------------------------
    let pilePlatform = drawSurfaceLevels(canvas, componentData, strokeWidthScaleFactor);
    toDraw = [...toDraw, ...pilePlatform];
  }

  // Conditionally draw pile casing
  if (shouldDrawPileCanvas && (isDefinedAndInitialized(hasCasing)) && hasCasing && isDefinedAndInitialized(casingType) && casingType !== 'none') {
    // -------------------------------------------------------
    let casing = drawPileCasing(canvas, componentData, strokeWidthScaleFactor);
    toDraw = [...toDraw, ...casing];
  }

  // Conditionally draw pile notes
  if (shouldDrawPileCanvas) {
    // -------------------------------------------------------
    let pileNotes = addPileNotes(canvas, componentData, strokeWidthScaleFactor);
    toDraw = [...toDraw, ...pileNotes];
  }

  // Conditionally draw groundwater observations
  if (shouldDrawPileCanvas && observedGroundwater.length > 0) {
    // -------------------------------------------------------
    let groundwaterObservations = addGroundwaterObservations(canvas, componentData, strokeWidthScaleFactor);
    toDraw = [...toDraw, ...groundwaterObservations];
  }

  toDraw.map(object => {
    canvas.add(object);
  });
  canvas.renderAll();
}


const PileObservationCanvas = (props) => {
  const { classes, componentData, handleClientObservationStateUpdate, updatePileObsCanvasStore, sketchDim } = props;
  const { pileId, pileType, observedGeology, observedGroundwater, externalDiameter, poleDiameter, steelSection, referenceLevel, pilingPlatformLevel,
    otherReferenceLevel, otherReferenceLevelNote, depthToBase, designToeLevel, designGeoEmbedment, pileCriteria, hasCasing,
    topOfCasingLevel, casingLength, casingDiameter, casingType, otherNotes } = componentData;
  const [geoSelectionModel, setGeoSelectionModel] = useState([]);
  const [groundwaterSelectionModel, setGroundwaterSelectionModel] = useState([]);

  const canvas = useRef(null);
  const fabricCanvas = useRef(null);

  const theme = useTheme();
  const isXsOrSmaller = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmOrSmaller = useMediaQuery(theme.breakpoints.down('sm'));

  let strokeWidthScaleFactor = 1;
  if (isXsOrSmaller) { strokeWidthScaleFactor = 600 / (1280 * 2) }
  else if (isSmOrSmaller) { strokeWidthScaleFactor = 960 / (1280) }

  useEffect(() => {
    // -------------------------------
    fabricCanvas.current = new fabric.Canvas(canvas.current, {
      selection: false,
    });

    fabricCanvas.current.setDimensions({
      width: sketchDim.width,
      height: sketchDim.width * CANVAS_ASPECT_RATIO,
    });
  }, []);

  useEffect(() => {
    // -------------------------------
    fabricCanvas.current.clear();
    drawPileObservation(fabricCanvas.current, componentData, strokeWidthScaleFactor);
    updatePileObsCanvasStore(componentData.componentId, fabricCanvas.current);
  }, [
    canvas.current, sketchDim.width, sketchDim.height,
    pileId, pileType, JSON.stringify(observedGeology), JSON.stringify(observedGroundwater), externalDiameter,
    poleDiameter, steelSection, referenceLevel, pilingPlatformLevel, otherReferenceLevel, otherReferenceLevelNote,
    depthToBase, designToeLevel, designGeoEmbedment, pileCriteria, hasCasing,
    topOfCasingLevel, casingLength, casingDiameter, casingType,
    otherNotes
  ]);

  const handleEditCellChangeCommitted = useCallback((type, e) => {
    // ------------------------------------------------
    switch (type) {
      case 'GEO': {
        const { id, field, props } = e;
        const data = props;
        let observedGeologyUpdate = [...componentData.observedGeology]
        observedGeologyUpdate[id][field] = data.value;
        e.target = { value: data.value }
        handleClientObservationStateUpdate(
          e,
          componentData.componentType,
          componentData.componentId,
          componentData
        )
        break;
      }
      case 'GROUNDWATER': {
        const { id, field, props } = e;
        const data = props;
        let observedGroundwaterUpdate = [...componentData.observedGroundwater]
        observedGroundwaterUpdate[id][field] = data.value;
        e.target = { value: data.value }
        handleClientObservationStateUpdate(
          e,
          componentData.componentType,
          componentData.componentId,
          componentData
        )
        break;
      }
    }

  }, [observedGeology, observedGroundwater]);

  const onGeoAdd = (e) => {
    //----------------------------
    let numUnits = componentData.observedGeology.length;

    let newUnit = {
      id: numUnits,
      depth: '',
      geologicalObservation: '',
      embedmentMaterial: false
    }

    componentData.observedGeology = [...componentData.observedGeology, newUnit];

    handleClientObservationStateUpdate(
      e,
      componentData.componentType,
      componentData.componentId,
      componentData
    )
  };

  const onGroundwaterAdd = (type, e) => {
    //----------------------------
    let numUnits = componentData.observedGroundwater.length;
    let maxDepth = (componentData.observedGroundwater.length > 0) ? Number(componentData.observedGroundwater[(numUnits - 1)].depth) : 0;

    let newUnit = {
      id: numUnits,
      depth: type === 'dry' ? null : maxDepth + 0.5,
      gwObsType: type,
      groundwaterObservation: type === 'dry' ? 'Hole dry on completion' : '',
    }

    componentData.observedGroundwater = [...componentData.observedGroundwater, newUnit];

    handleClientObservationStateUpdate(
      e,
      componentData.componentType,
      componentData.componentId,
      componentData
    )
  };

  const onGeoDelete = (e) => {
    //----------------------------
    e.stopPropagation();
    if (geoSelectionModel.length > 0) {
      // -------------------------------------------
      let indicesToRemove = geoSelectionModel.map(index => Number(index));

      let observedGeologyUpdate = [...componentData.observedGeology];
      observedGeologyUpdate = observedGeologyUpdate
        .filter(row => !indicesToRemove.includes(row.id))
        .map((row, index) => {
          return {
            ...row,
            id: index
          }
        });

      // Update the observed geology table state
      componentData.observedGeology = observedGeologyUpdate;
      handleClientObservationStateUpdate(
        e,
        componentData.componentType,
        componentData.componentId,
        componentData
      )
    }
    setGeoSelectionModel([]);
  };

  const onGroundwaterDelete = (e) => {
    //----------------------------
    e.stopPropagation();
    if (groundwaterSelectionModel.length > 0) {
      // -------------------------------------------
      let indicesToRemove = groundwaterSelectionModel.map(index => Number(index));

      let observedGroundwaterUpdate = [...componentData.observedGroundwater];
      observedGroundwaterUpdate = observedGroundwaterUpdate
        .filter(row => !indicesToRemove.includes(row.id))
        .map((row, index) => {
          return {
            ...row,
            id: index
          }
        });

      // Update the observed groundwater table state
      componentData.observedGroundwater = observedGroundwaterUpdate;
      handleClientObservationStateUpdate(
        e,
        componentData.componentType,
        componentData.componentId,
        componentData
      )
    }
    setGroundwaterSelectionModel([]);
  };

  const onEmbedmentMaterialSelected = (e, type) => {
    //----------------------------
    if (geoSelectionModel.length > 0) {
      // -------------------------------------------
      let indicesOfEmbedment = geoSelectionModel.map(index => Number(index));

      let observedGeologyUpdate = [...componentData.observedGeology];
      observedGeologyUpdate = observedGeologyUpdate
        .map(row => {
          if (indicesOfEmbedment.includes(row.id)) {
            // -------------------------------
            return {
              ...row,
              embedmentMaterial: (type === 'ADD')
            }
          }
          return row;
        });

      // Update the observed geology table state
      componentData.observedGeology = observedGeologyUpdate;
      handleClientObservationStateUpdate(
        e,
        componentData.componentType,
        componentData.componentId,
        componentData
      )
    }
    setGeoSelectionModel([]);
  }


  return (
    <Box display="flex" flexDirection="column" width={sketchDim.width}>
      <Box display="flex" flexDirection="column" width={sketchDim.width}>
        <Box display="flex" flexDirection="column" width={sketchDim.width}>
          {PILE_METADATA.map((section) => {
            return (
              <Box key={section.name} width={sketchDim.width}>
                <Box my="5px" mx="15px" display="flex" flexDirection="row" alignItems="center">
                  <Typography variant="caption">{section.name}</Typography>
                  {section.switchField ?
                    (
                      <Box ml="15px">
                        <FormControlLabel
                          className={classes.textField}
                          control={
                            <Switch
                              checked={componentData[section.switchField]}
                              onChange={(e) => {
                                componentData[section.switchField] = !componentData[section.switchField];
                                handleClientObservationStateUpdate(
                                  e,
                                  componentData.componentType,
                                  componentData.componentId,
                                  componentData
                                );
                              }}
                              name={`${section.name}`}
                            />}
                          label={componentData[section.switchField] ? 'hide' : 'show'}
                        />
                      </Box>
                    )
                    :
                    ""}
                </Box>
                {section.switchField && !componentData[section.switchField] ?
                  <Divider />
                  :
                  ""
                }
                {!section.switchField || componentData[section.switchField] ?
                  (
                    <Grid container>
                      {section.content.map((field) => {
                        if (field.visibility(componentData)) {
                          {/* If the section is visible based on the component data, render according to the field type */ }
                          switch (field.type) {
                            case "textField": {
                              return (
                                <TextFieldContent
                                  key={`${field.type}-${field.name}`}
                                  classes={classes}
                                  config={field}
                                  componentData={componentData}
                                  handleClientObservationStateUpdate={handleClientObservationStateUpdate}
                                />
                              );
                            }
                            case "select": {
                              return (
                                <SelectContent
                                  key={`${field.type}-${field.name}`}
                                  classes={classes}
                                  config={field}
                                  componentData={componentData}
                                  handleClientObservationStateUpdate={handleClientObservationStateUpdate}
                                />
                              );
                            }
                            case "number":
                            case "integer": {
                              return (
                                <NumberContent
                                  key={`${field.type}-${field.name}`}
                                  classes={classes}
                                  config={field}
                                  componentData={componentData}
                                  handleClientObservationStateUpdate={handleClientObservationStateUpdate}
                                />
                              );
                            }
                            case "checkbox": {
                              return (
                                <CheckboxContent
                                  key={`${field.type}-${field.name}`}
                                  classes={classes}
                                  config={field}
                                  componentData={componentData}
                                  handleClientObservationStateUpdate={handleClientObservationStateUpdate}
                                />
                              );
                            }
                            case "radio": {
                              return (
                                <RadioContent
                                  key={`${field.type}-${field.name}`}
                                  classes={classes}
                                  config={field}
                                  componentData={componentData}
                                  handleClientObservationStateUpdate={handleClientObservationStateUpdate}
                                />
                              );
                            }
                          }
                        }
                      })}
                    </Grid>)
                  :
                  ""}
              </Box>
            );
          })}
        </Box>
        <Box my="10px">
          <Typography variant="button">Observed Geology</Typography>
        </Box>
        <Box width={sketchDim.width}>
          <DataGrid
            className={classes.root}
            columnBuffer={1}
            autoHeight={true}
            autoPageSize
            rows={observedGeology}
            columns={GEO_COLUMNS}
            onEditCellChangeCommitted={(e) => handleEditCellChangeCommitted('GEO', e)}
            hideFooter
            disableColumnMenu
            sortingMode="server"
            checkboxSelection
            disableSelectionOnClick
            disableExtendRowFullWidth
            onSelectionModelChange={(newSelection) => {
              setGeoSelectionModel(newSelection.selectionModel);
            }}
            selectionModel={geoSelectionModel}
            getRowClassName={(params) => { if (isDefinedAndInitialized(params.row.embedmentMaterial) && params.row.embedmentMaterial) { return 'embedmentMaterial' } return 'nonEmbedmentMaterial' }}
          />
        </Box>
        <Box display="flex" width={sketchDim.width}>
          <Grid container>
            <Grid item xs={3} sm={3}>
              <Tooltip title="Add row/unit">
                <IconButton
                  className={classes.button}
                  aria-label="Add"
                  onClick={onGeoAdd}
                  color="primary"
                >
                  <AddCircle
                    color="secondary"
                    className="addButtonColour"
                    fontSize="large"
                  />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={3} sm={3}>
              <Tooltip title="Delete row">
                <IconButton
                  className={classes.button}
                  aria-label="Delete"
                  onClick={onGeoDelete}
                  color="primary"
                >
                  <Cancel
                    color="secondary"
                    className="addButtonColour"
                    fontSize="large"
                  />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={6} sm={false} />
            <Grid item xs={3} sm={3}>
              <Tooltip title="Add Design Embedment Material">
                <IconButton
                  className={classes.button}
                  aria-label="Add embedment material"
                  onClick={(e) => { onEmbedmentMaterialSelected(e, 'ADD') }}
                  color="primary"
                >
                  <Layers
                    color="secondary"
                    className="addButtonColour"
                    fontSize="large"
                  />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={3} sm={3}>
              <Tooltip title="Remove Design Embedment Material">
                <IconButton
                  className={classes.button}
                  aria-label="Remove embedment material"
                  onClick={(e) => { onEmbedmentMaterialSelected(e, 'REMOVE') }}
                  color="primary"
                >
                  <LayersClear
                    color="secondary"
                    className="addButtonColour"
                    fontSize="large"
                  />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={false} />
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" width="100%">
        <Box my="10px">
          <Typography variant="button">Groundwater</Typography>
        </Box>
        <Box width={sketchDim.width}>
          <DataGrid
            className={classes.root}
            columnBuffer={1}
            autoHeight={true}
            autoPageSize
            rows={observedGroundwater}
            columns={GROUNDWATER_COLUMNS}
            onEditCellChangeCommitted={(e) => handleEditCellChangeCommitted('GROUNDWATER', e)}
            hideFooter
            disableColumnMenu
            sortingMode="server"
            checkboxSelection
            disableSelectionOnClick
            onSelectionModelChange={(newSelection) => {
              setGroundwaterSelectionModel(newSelection.selectionModel);
            }}
            selectionModel={groundwaterSelectionModel}
          />
        </Box>
        <Box display="flex" width={sketchDim.width}>
          <Tooltip title="Delete row">
            <IconButton
              className={classes.button}
              aria-label="Delete"
              onClick={onGroundwaterDelete}
              color="primary"
            >
              <Cancel
                color="secondary"
                className="deleteButtonColour"
                fontSize="large"
              />
            </IconButton>
          </Tooltip>
          <Box>
            <Tooltip title="Add standing water level">
              <IconButton
                className={classes.button}
                aria-label="Standing water level"
                onClick={(e) => { onGroundwaterAdd('standing', e) }}
                color="primary"
              >
                <Details
                  color="secondary"
                  className="addButtonColour"
                  fontSize="large"
                />
              </IconButton>
            </Tooltip>
          </Box>
          <Box>
            <Tooltip title="Add groundwater inflow">
              <IconButton
                className={classes.button}
                aria-label="Water inflow"
                onClick={(e) => { onGroundwaterAdd('inflow', e) }}
                color="primary"
              >
                <Opacity
                  color="secondary"
                  className="addButtonColour"
                  fontSize="large"
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Add dry hole observation">
              <IconButton
                className={classes.button}
                aria-label="Dry hole"
                onClick={(e) => { onGroundwaterAdd('dry', e) }}
                color="primary"
              >
                <IndeterminateCheckBox
                  color="secondary"
                  className="addButtonColour"
                  fontSize="large"
                />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Box display="flex" height={sketchDim.width * CANVAS_ASPECT_RATIO}>
        {(sketchDim.height !== 0) & (sketchDim.width !== 0) && (
          <canvas
            ref={canvas}
            style={{
              width: sketchDim.width,
              height: sketchDim.width * CANVAS_ASPECT_RATIO,
            }}
          />
        )}
      </Box>
    </Box>
  );
};

PileObservationCanvas.propTypes = {
  classes: PropTypes.object,
  componentData: PropTypes.object,
  sketchDim: PropTypes.object,
  handleClientObservationStateUpdate: PropTypes.func,
  updatePileObsCanvasStore: PropTypes.func
};

export default withStyles(styles)(PileObservationCanvas);
