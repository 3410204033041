import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// MASL React
import { InteractionStatus } from "@azure/msal-browser";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";

// MUI
import { Box, Card, CardContent, Tooltip, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import ImageFileIcon from '@material-ui/icons/Image';
import PdfFileIcon from '@material-ui/icons/PictureAsPdf';
import DocumentIcon from '@material-ui/icons/Description';
import ExcelIcon from '@material-ui/icons/GridOn';
import UnknownIcon from '@material-ui/icons/Help';
import JSONIcon from '@material-ui/icons/Code';

// CUSTOM COMPONENTS
import { BasicIconButton } from './Buttons';
import { LabelledTextField } from './Inputs';
import BasicLoader from '../basicLoader';

// CUSTOM SERVICES AND HELPERS
import { getPresignedUrlS3GetObject } from '../../services/api';
import { dataURIToBlob, isDefinedAndInitialized, openInNewTab } from '../../helpers/helpers';


const IFileUploadOrSelectionCardProps = {
    namePrefix: PropTypes.string,
    file: PropTypes.object,
    handleFileItemStateChange: PropTypes.func,
    handleFileListItemAddOrRemove: PropTypes.func
}

export const getFileTypeExtension = (name) => {
    // --------------------------------------
    const nameSplit = name.split('.');
    const splitLength = nameSplit.length;
    const extension = splitLength > 1 ? nameSplit[splitLength - 1] : '';

    return extension;
}


export const readImageFileAsBlob = (file) => new Promise((resolve, reject) => {
    // -------------------------------------------------
    const reader = new FileReader();
    reader.onload = (e) => {
        if (isDefinedAndInitialized(e.target) && isDefinedAndInitialized(e.target.result)) {
            const img = new Image();
            img.src = e.target.result;
            img.onload = () => {
                return resolve(img);
            };
        }
    }
    reader.onerror = (e) => {
        console.error(`Failed to read file!\n\n${reader.error}`);
        reader.abort();
        reject(e);
    };
    return reader.readAsDataURL(file);
});

export const getResizedImageAsBlob = (img) => {
    // -------------------------------------------------
    const canvas = document.createElement('canvas');

    let MAX_WIDTH = 800;
    let MAX_HEIGHT = 600;
    let width = img.width;
    let height = img.height;

    if (width > height) {
        if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
        }
    } else if (height > MAX_HEIGHT) {
        width *= MAX_HEIGHT / height;
        height = MAX_HEIGHT;
    }

    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0, width, height);

    const imageUrl = canvas.toDataURL('image/jpeg');
    const imageBlob = dataURIToBlob(imageUrl);

    return imageBlob;
}


export const readFileAsBlob = (file) => new Promise((resolve, reject) => {
    // -------------------------------------------------
    const reader = new FileReader();
    reader.onload = (e) => { if (e.target) { resolve(dataURIToBlob(e.target.result)) } else { throw new Error('Failed to read file'); } };
    reader.onerror = (e) => {
      console.error(`Failed to read file!\n\n${reader.error}`);
      reader.abort();
      reject(e);
    };
    return reader.readAsDataURL(file);
  }); 


export const FileTypeIcon = (props) => {
    // --------------------------------------
    const { file } = props;
    const { name } = file;

    const extension = getFileTypeExtension(name);

    switch (extension) {
        case 'jpg':
        case 'png':
        case 'bmp':
            return <Tooltip title="Image" placement="top"><ImageFileIcon /></Tooltip>
        case 'pdf':
            return <Tooltip title="PDF" placement="top"><PdfFileIcon /></Tooltip>
        case 'docx':
        case 'doc':
            return <Tooltip title="Word document" placement="top"><DocumentIcon /></Tooltip>
        case 'xlsx':
        case 'xls':
        case 'csv':
            return <Tooltip title="Spreadsheet" placement="top"><ExcelIcon /></Tooltip>
        case 'json':
            return <Tooltip title="JSON" placement="top"><JSONIcon /></Tooltip>
        case '':
        default:
            return <Tooltip title="Unknown" placement="top"><UnknownIcon /></Tooltip>
    }
}

FileTypeIcon.propTypes = {
    namePrefix: PropTypes.string,
    file: PropTypes.object,
    handleFileItemStateChange: PropTypes.func,
    handleFileListItemAddOrRemove: PropTypes.func
}


export const FileSelectionCardImagePreview = (props) => {
    // --------------------------------------
    // PROPS
    const { file: fileProp } = props;
    const { file } = fileProp;

    // DERIVED STATE
    const fileUrl = isDefinedAndInitialized(file) ? URL.createObjectURL(file) : null;

    return (
        <>
            {
                !isDefinedAndInitialized(fileUrl) &&
                <Typography>Image not found</Typography>
            }
            {
                isDefinedAndInitialized(fileUrl) &&
                <Box mt="10px" width="100%" position="relative">
                    <img src={fileUrl} alt="" style={{ objectFit: 'contain', maxWidth: '100%', maxHeight: '100%' }} />
                </Box>
            }
        </>
    )
}

FileSelectionCardImagePreview.propTypes = IFileUploadOrSelectionCardProps;


export const FileUploadCardImagePreview = (props) => {
    // --------------------------------------
    // PROPS
    const { file } = props;

    // STATE
    const [presignedFileUrl, setPresignedFileUrl] = useState(null);

    // MSAL
    let { instance, accounts, inProgress } = useMsal();
    const loginHint = (accounts[0]?.username) ?? '';
    const request = {
        loginHint,
        scopes: ["User.Read"]
    }
    const isAuthenticated = useIsAuthenticated();

    // EFFECTS
    useEffect(async () => {
        if (!isAuthenticated && inProgress === InteractionStatus.None) {
            await instance.loginRedirect(request);
        }
    }, [isAuthenticated, inProgress, instance]);

    const updateFileContent = async () => {
        // ------------------------------------------
        if (inProgress === "none") {
            // -------------------------------------
            let presignedUrl = await getPresignedUrlS3GetObject(file.path, 's3Path', { instance, accounts, inProgress });
            setPresignedFileUrl(presignedUrl.presigned);
        }
    }

    // Get PDF files for preview
    useEffect(() => {
        // --------------------------------------
        updateFileContent();
    }, [file.name, file.lastUpdateTimestamp]);


    return (
        <>
            {
                isDefinedAndInitialized(presignedFileUrl) &&
                <Box mt="10px" width="100%" position="relative">
                    <img src={presignedFileUrl} alt="" style={{ objectFit: 'contain', maxWidth: '100%', maxHeight: '100%' }} />
                </Box>
            }
        </>
    )
}

FileUploadCardImagePreview.propTypes = IFileUploadOrSelectionCardProps;


export const GenericPresignedImg = (props) => {
    // --------------------------------------
    // PROPS
    const { file } = props;
    const { name, lastUpdateTimestamp } = file;

    // STATE
    const [presignedFileUrl, setPresignedFileUrl] = useState(null);

    // MSAL
    let { instance, accounts, inProgress } = useMsal();
    const loginHint = (accounts[0]?.username) ?? '';
    const request = {
        loginHint,
        scopes: ["User.Read"]
    }
    const isAuthenticated = useIsAuthenticated();

    // EFFECTS
    useEffect(async () => {
        if (!isAuthenticated && inProgress === InteractionStatus.None) {
            await instance.loginRedirect(request);
        }
    }, [isAuthenticated, inProgress, instance]);

    const updateFileContent = async () => {
        // ------------------------------------------
        if (inProgress === "none") {
            // -------------------------------------
            let presignedUrl = await getPresignedUrlS3GetObject(file.path, 's3Path', { instance, accounts, inProgress });
            setPresignedFileUrl(presignedUrl.presigned);
        }
    }

    // Get PDF files for preview
    useEffect(() => {
        // --------------------------------------
        updateFileContent();
    }, [name, lastUpdateTimestamp]);

    if (presignedFileUrl) {
        return (
            <img src={presignedFileUrl} style={{ objectFit: 'cover', width: "100%", height: "100%" }} />
        )
    }
    else {
        return (
            <BasicLoader />
        )
    }
}

GenericPresignedImg.propTypes = {
    file: PropTypes.object
}


export const FileSelectionCard = (props) => {
    // --------------------------------------
    // PROPS
    const { namePrefix, file, handleFileItemStateChange, handleFileListItemAddOrRemove } = props;
    const { name } = file;


    let setNamePrefix = 'fileUploads';
    if (isDefinedAndInitialized(namePrefix)) {
        setNamePrefix = namePrefix;
    }

    const extension = getFileTypeExtension(name);

    return (
        <Box height="100%">
            <Card variant="outlined" style={{ width: '100%', height: '100%' }}>
                <CardContent style={{ width: '100%', height: '100%' }}>
                    <Box display="flex" flexDirection="column">
                        <Box display="flex" flexDirection="row" alignItems="center" marginY="5px">
                            <FileTypeIcon {...props} />&nbsp;
                            <Typography variant="subtitle2" style={{ fontSize: '0.7rem', marginBottom: '0px' }} color="secondary" gutterBottom>
                                {file.name}
                            </Typography>
                        </Box>
                        <Box display="flex" flexDirection={"row"} justifyContent="space-between">
                            {
                                isDefinedAndInitialized(file.size) &&
                                <Typography variant="subtitle2" style={{ fontSize: '0.8rem' }} color="secondary" gutterBottom>
                                    {`Upload: ${file.uploadStatus} [ ${Math.round(file.size / 1000)} KB ]`}
                                </Typography>
                            }
                            <Typography variant="subtitle2" style={{ fontSize: '0.8rem' }} color="secondary" gutterBottom>
                                { }
                            </Typography>
                            <Box display="flex" flexDirection="row">
                                <BasicIconButton
                                    ariaLabel='delete-attachment'
                                    color="secondary.main" backgroundColor="secondary.contrastText"
                                    hoverColor='primary.contrastText'
                                    hoverBackgroundColor='primary.main'
                                    tooltipLabel={`Delete file`}
                                    icon={<DeleteIcon />}
                                    onClick={() => handleFileListItemAddOrRemove({ target: { name: `${setNamePrefix}_${file.id}`, value: null } })}
                                />
                            </Box>
                        </Box>
                        <LabelledTextField
                            value={file.name}
                            name={`${setNamePrefix}_${file.id}_name`}
                            label={'Filename'}
                            minRows={1}
                            placeholder={`Name the file (note: include the extension if you want a pdf/image preview)`}
                            handleChange={handleFileItemStateChange}
                        />
                        <LabelledTextField
                            value={file.notes ? file.notes : ''}
                            name={`${setNamePrefix}_${file.id}_notes`}
                            label={'Notes'}
                            minRows={2}
                            placeholder={`Any supporting comments you want to include with this file`}
                            handleChange={handleFileItemStateChange}
                        />
                    </Box>
                    {/* REMOVED PDF PREVIEW FROM BACKPORTED WORK AND SAFETY CODE - NOT REQUIRED AT THE MOMENT AND WORTH A REFACTOR */}
                    {
                        ['jpg', 'jpeg', 'png', 'gif'].includes(extension || '') &&
                        <Box display="flex" flexDirection="row" justifyContent="center" mt="10px">
                            <FileSelectionCardImagePreview
                                {...props}
                            />
                        </Box>
                    }
                </CardContent>
            </Card>
        </Box>
    )
}

FileSelectionCard.propTypes = IFileUploadOrSelectionCardProps;


export const FileUploadCard = (props) => {
    // --------------------------------------
    // PROPS
    const { namePrefix, file, handleFileItemStateChange, handleFileListItemAddOrRemove } = props;
    const { name } = file;
    const extension = getFileTypeExtension(name);

    let setNamePrefix = 'fileUploads';
    if (isDefinedAndInitialized(namePrefix)) {
        setNamePrefix = namePrefix;
    }

    // USE MSAL HOOK
    const { instance, accounts, inProgress } = useMsal();
    const loginHint = (accounts && accounts[0]?.username) ?? '';
    const request = {
        loginHint,
        scopes: ["User.Read"]
    }
    const isAuthenticated = useIsAuthenticated();

    // MUI HOOKS
    const theme = useTheme();
    const smallerThanSm = useMediaQuery(theme.breakpoints.down('sm'));

    // EFFECTS
    useEffect(async () => {
        if (!isAuthenticated && inProgress === InteractionStatus.None) {
            await instance.loginRedirect(request);
        }
    }, [isAuthenticated, inProgress, instance]);

    // HANDLE MSAL LOADING
    if (inProgress !== "none") {
        return <BasicLoader />
    }

    // EVENT HANDLERS
    const openFileInNewTab = async () => {
        // --------------------------------
        let url = await getPresignedUrlS3GetObject(file.path, 's3Path', { instance, accounts, inProgress });
        openInNewTab(url.presigned);
    }

    return (
        <Box height="100%">
            <Card variant="outlined" style={{ width: '100%', height: '100%' }}>
                <CardContent style={{ width: '100%', height: '100%' }}>
                    <Box display="flex" flexDirection="column">
                        <Box display="flex" flexDirection="row" alignItems="center" marginY="5px">
                            <FileTypeIcon {...props} />&nbsp;
                            <Typography variant="subtitle2" style={{ fontSize: '0.7rem', marginBottom: '0px' }} color="secondary" gutterBottom>
                                {file.name}
                            </Typography>
                        </Box>
                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                            {
                                isDefinedAndInitialized(file.size) &&
                                <Typography variant="subtitle2" style={{ fontSize: smallerThanSm ? '0.65rem' : '0.8rem' }} color="secondary" gutterBottom>
                                    {`Upload: ${file.uploadStatus} [ ${Math.round(file.size / 1000)} KB ]`}
                                </Typography>
                            }
                            <Box display="flex" flexDirection="row">
                                <BasicIconButton
                                    ariaLabel='download-attachment'
                                    color="secondary.main" backgroundColor="secondary.contrastText"
                                    hoverColor='primary.contrastText'
                                    hoverBackgroundColor='primary.main'
                                    tooltipLabel={`Download file`}
                                    icon={<CloudDownloadIcon />}
                                    onClick={openFileInNewTab}
                                />
                                <BasicIconButton
                                    ariaLabel='delete-attachment'
                                    color="secondary.main" backgroundColor="secondary.contrastText"
                                    hoverColor='primary.contrastText'
                                    hoverBackgroundColor='primary.main'
                                    tooltipLabel={`Delete file`}
                                    icon={<DeleteIcon />}
                                    onClick={() => handleFileListItemAddOrRemove({ target: { name: `${setNamePrefix}_${file.id}`, value: null } })}
                                />
                            </Box>
                        </Box>
                        <LabelledTextField
                            value={file.name}
                            name={`${setNamePrefix}_${file.id}_name`}
                            label={'Filename'}
                            minRows={1}
                            placeholder={`Name the file (note: include the extension if you want a pdf/image preview)`}
                            handleChange={handleFileItemStateChange}
                        />
                        <LabelledTextField
                            value={file.notes ? file.notes : ''}
                            name={`${setNamePrefix}_${file.id}_notes`}
                            label={'Notes'}
                            minRows={2}
                            placeholder={`Any supporting comments you want to include with this file`}
                            handleChange={handleFileItemStateChange}
                        />
                    </Box>
                    {
                        ['jpg', 'jpeg', 'png', 'gif'].includes(extension || '') &&
                        <Box display="flex" flexDirection="row" justifyContent="center" mt="10px">
                            <FileUploadCardImagePreview
                                {...props}
                            />
                        </Box>
                    }
                </CardContent>
            </Card>
        </Box>
    )
}

FileUploadCard.propTypes = IFileUploadOrSelectionCardProps;