import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';

// Context
// import { InitiaContext } from '../context/initia-context';

// Custom Components
import DeletablePaperLarge from './deletablePaperLarge';
import PileObservationCanvas from './PileObservationCanvas';

// CUSTOM UTILITIES
import { isDefinedAndInitialized } from "../helpers/helpers";

const styles = theme => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
});


function PileObservation(props) {
  const { deleteHandler, componentData, componentUpHandler, handleClientObservationStateUpdate, updatePileObsCanvasStore, dynamicObsParentRef } = props;
  const [ sketchDim, setSketchDim ] = useState({ height: 0, width: 0 });

  const stageCanvasRef = useRef(null);

  // useEffect will run on stageCanvasRef value assignment
  useEffect( () => {

      // The 'current' property contains info of the reference:
      // align, title, ... , width, height, etc.
      if(stageCanvasRef.current){

          let height = stageCanvasRef.current.offsetHeight;
          let width  = stageCanvasRef.current.offsetWidth;

          setSketchDim({
            height: height * 0.8,
            width: width * 0.8
          })
      }

  }, [stageCanvasRef]);



  useEffect(() => {
    if (dynamicObsParentRef.current && sketchDim.height > 0) {
      const resizeObserver = new ResizeObserver((event) => {
        // Depending on the layout, you may need to swap inlineSize with blockSize
        // https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserverEntry/contentBoxSize
        setSketchDim({
          height: sketchDim.height,
          width: event[0].contentBoxSize[0].inlineSize * 0.8
        })
      });

      if (isDefinedAndInitialized(dynamicObsParentRef.current)) {
        resizeObserver.observe(dynamicObsParentRef.current);
      }

      return () => {
        if (isDefinedAndInitialized(dynamicObsParentRef.current)) {
          // ------------------------------------
          resizeObserver.unobserve(dynamicObsParentRef.current);
        } else {
          resizeObserver.disconnect();
        }
      }
    }
    else {
      return;
    }
  }, [dynamicObsParentRef, sketchDim.height]);


  return (
    <>
      <DeletablePaperLarge
        title="PILE OBSERVATION"
        onClick={deleteHandler}
        componentId={componentData.componentId}
        componentUpHandler={componentUpHandler}
        ref = {stageCanvasRef}
      >
      {(sketchDim.height !== 0 & sketchDim.width !== 0) &&
        <PileObservationCanvas
          componentData={componentData}
          handleClientObservationStateUpdate={handleClientObservationStateUpdate}
          sketchDim={sketchDim}
          updatePileObsCanvasStore={updatePileObsCanvasStore}
        />
      }
      </DeletablePaperLarge>
    </>
  );
}

PileObservation.propTypes = {
  classes: PropTypes.object,
  deleteHandler: PropTypes.func,
  componentData: PropTypes.object,
  handleClientObservationStateUpdate: PropTypes.func,
  componentUpHandler: PropTypes.func,
  canvasStore: PropTypes.object,
  canvasStoreMetadata: PropTypes.object,
  updateCanvasStore: PropTypes.func,
  updateCanvasStoreMetadata: PropTypes.func,
  updatePileObsCanvasStore: PropTypes.func,
  dynamicObsParentRef: PropTypes.object
};

export default withStyles(styles)(PileObservation);
