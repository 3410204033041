import React, { useState } from 'react';
import PropTypes from 'prop-types';

import uuidv4 from 'uuid/v4';

// Custom Config
import { config } from '../../config/generalConfig';

// Custom Components
// eslint-disable-next-line
import Table from './table';


function EditableTable(props) {
  const [localState] = useState({ filterText: '', rows: [] });


  const handleRowDel = (e, rowId) => {
    // Take the inital componentData and mutate before bubbling back up the component tree
    const componentDataUpdate = props.componentData;
    componentDataUpdate.rows = props.componentData.rows;
    componentDataUpdate.rows = componentDataUpdate.rows.filter(rowSearch => rowSearch.id !== rowId);
    props.handleClientObservationStateUpdate(
      e, props.componentData.componentType, props.componentData.componentId, componentDataUpdate,
    );
  };

  const handleAddEvent = (e) => {
    const rowToAdd = {
      id: uuidv4(),
      values: config.dataTableTypes.find(tableFormat => tableFormat.tableType === props.componentData.componentType)
        .columnValues.map(value => ({ value: value.default })),
    };

    // Take the inital componentData and mutate before bubbling back up the component tree
    const componentDataUpdate = props.componentData;
    componentDataUpdate.rows = props.componentData.rows;
    componentDataUpdate.rows.push(rowToAdd);
    props.handleClientObservationStateUpdate(
      e, props.componentData.componentType, props.componentData.componentId, componentDataUpdate,
    );
  };

  const handleDataTableUpdate = (e) => {
    const updateItem = {
      id: e.target.id,
      name: e.target.name,
      value: e.target.value,
    };

    if (updateItem.id.includes('header')) {
      // ------------------------------------------------------
      // UPDATE HEADER
      // ------------------------------------------------------
      const headerUpdate = Object.assign(props.header);
      // Get the index from the end of the header row-name and update the corresponding value
      headerUpdate[updateItem.name.slice(-1)].value = updateItem.value || null;

      const componentDataUpdate = props.componentData;
      componentDataUpdate.header = headerUpdate;
      props.handleClientObservationStateUpdate(
        e, props.componentData.componentType, props.componentData.componentId, componentDataUpdate,
      );
    } else {
      // ------------------------------------------------------
      // UPDATE ROW
      // ------------------------------------------------------
      // Shallow copy rows
      const rows = props.rows.slice();
      const updateRows = rows.map((row) => {
        if ((`${row.id}-${updateItem.name.slice(-1)}`) === updateItem.id) {
          const rowUpdate = row;
          rowUpdate.values[updateItem.name.slice(-1)].value = updateItem.value || null;
          return rowUpdate;
        }
        return row;
      });
      // Take the inital componentData and mutate before bubbling back up the component tree
      const componentDataUpdate = props.componentData;
      componentDataUpdate.rows = updateRows;
      props.handleClientObservationStateUpdate(
        e, props.componentData.componentType, props.componentData.componentId, componentDataUpdate,
      );
    }
  };

  return (
    <div>
      <Table
        id={props.componentId}
        onTableUpdate={handleDataTableUpdate}
        onRowAdd={handleAddEvent}
        onRowDel={handleRowDel}
        header={props.componentData.header}
        rows={props.componentData.rows}
        tableType={props.componentData.componentType}
        filterText={localState.filterText}
      />
    </div>
  );
}

EditableTable.propTypes = {
  componentData: PropTypes.object,
  componentId: PropTypes.string,
  header: PropTypes.array,
  rows: PropTypes.array,
  handleClientObservationStateUpdate: PropTypes.func
}

export default EditableTable;
