import React from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import { Box, Button, useTheme, useMediaQuery } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import HelpIcon from '@material-ui/icons/Help';

import { BasicIconTextButton } from './genericComponents/Buttons';
import { isDefinedAndInitialized } from '../helpers/helpers';


export const ErrorPage = ({ statusCode, message, linkAddress, linkLabel, isExternalLink }) => {
    // ----------------------------

    const history = useHistory();    
    
    const theme = useTheme();
    const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('md'));
  
    let setLinkAddress = `/`;
    if (isDefinedAndInitialized(linkAddress)) {
      // ----------------------------------------
      setLinkAddress = linkAddress;
    }
    let setLinkLabel = `Home`;
    if (isDefinedAndInitialized(linkLabel)) {
      // ----------------------------------------
      setLinkLabel = linkLabel;
    }
    let setIsExternalLink = false;
    if (isDefinedAndInitialized(isExternalLink)) {
        // ----------------------------------------
        setIsExternalLink = isExternalLink;
    }
  
    let setMessage = ``;
    if (isDefinedAndInitialized(message)) {
      // ----------------------------------------
      setMessage = message;
    }
    else if (statusCode === 401) {
      // ----------------------------------------
      setMessage = `You are not signed in`;
    }
    else if (statusCode === 403) {
      // ----------------------------------------
      setMessage = `Forbidden`;
    }
    else if (statusCode === 404) {
      // ----------------------------------------
      setMessage = `Not found`;
    }
    else if (statusCode === 500) {
      // ----------------------------------------
      setMessage = `Server error - contact support`;
    }
    else {
      setMessage = `Unexpected error code - contact support`;
    }
  
    const styles = `  
      body { margin: 0; color: #000; background: #fff; }
      .error-h1 {
        border-right: 1px solid rgba(0, 0, 0, .3);
      }
  
      @media (prefers-color-scheme: dark) {
        body { color: #fff; background: #000; }
        .error-h1 {
          border-right: 1px solid rgba(255, 255, 255, .3);
        }
      }
    `
  
    return (
      <>
        <style>
          {styles}
        </style>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh" marginX={ isSmallerThanMd ? "50px" : "70px" }>
          <Box justifySelf="flex-end" marginY="15px">
            {
                !setIsExternalLink &&
                <BasicIconTextButton
                    backgroundColor={theme.palette.secondary.main}
                    color={theme.palette.secondary.contrastText}
                    startIcon={<ArrowBack />}
                    onClick={() => history.push(`${setLinkAddress}`)}
                    >
                    {setLinkLabel}
                </BasicIconTextButton>
            }
            {
                setIsExternalLink &&
                <Button
                    size="small"
                    variant="contained"
                    style={{
                        width: '100%', backgroundColor: theme.palette.secondary.main, 
                        color: theme.palette.secondary.contrastText, 
                        '&:hover': { backgroundColor: 'primary.contrastText', color: "primary.main" }, 
                        fontSize: '0.85rem',
                        textDecoration: 'none', paddingY: '10px'
                    }}
                    endIcon={<HelpIcon />}
                    target="_blank"
                    href={setLinkAddress}
                >
                    {setLinkLabel}            
                </Button>
            }
          </Box>
          <Box display="flex" flexDirection="row">
            <Box display="flex" alignItems="center" justifyContent="center">
              <h1 className="error-h1" style={{
                margin: '0px 20px 0px 0px', padding: '0px 23px 0px 0px',
                fontSize: '24px', fontWeight: 500, verticalAlign: 'top', lineHeight: '49px'
              }}>
                {statusCode}
              </h1>
            </Box>
            <Box textAlign="left" flexGrow={1} display="flex" alignItems="center" justifyContent="center" >
              <h2 style={{
                fontSize: '14px', fontWeight: 'normal',
                margin: '0px', padding: '0px'
              }}>
                {setMessage}
              </h2>
            </Box>
          </Box>
        </Box>
      </>
    )
  }


  ErrorPage.propTypes = {
    statusCode: PropTypes.number, 
    message: PropTypes.string, 
    linkAddress: PropTypes.string, 
    linkLabel: PropTypes.string,
    isExternalLink: PropTypes.bool
  }


  export const ErrorComponent = ({ statusCode, message, linkAddress, linkLabel, isExternalLink }) => {
    // ----------------------------

    const history = useHistory();    
    
    const theme = useTheme();
    const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('md'));
  
    let setLinkAddress = `/`;
    if (isDefinedAndInitialized(linkAddress)) {
      // ----------------------------------------
      setLinkAddress = linkAddress;
    }
    let setLinkLabel = `Home`;
    if (isDefinedAndInitialized(linkLabel)) {
      // ----------------------------------------
      setLinkLabel = linkLabel;
    }
    let setIsExternalLink = false;
    if (isDefinedAndInitialized(isExternalLink)) {
        // ----------------------------------------
        setIsExternalLink = isExternalLink;
    }
  
    let setMessage = ``;
    if (isDefinedAndInitialized(message)) {
      // ----------------------------------------
      setMessage = message;
    }
    else if (statusCode === 401) {
      // ----------------------------------------
      setMessage = `You are not signed in`;
    }
    else if (statusCode === 403) {
      // ----------------------------------------
      setMessage = `Forbidden`;
    }
    else if (statusCode === 404) {
      // ----------------------------------------
      setMessage = `Not found`;
    }
    else if (statusCode === 500) {
      // ----------------------------------------
      setMessage = `Server error - contact support`;
    }
    else {
      setMessage = `Unexpected error code - contact support`;
    }
  
    const styles = `  
      body { margin: 0; color: #000; background: #fff; }
      .error-h1 {
        border-right: 1px solid rgba(0, 0, 0, .3);
      }
  
      @media (prefers-color-scheme: dark) {
        body { color: #fff; background: #000; }
        .error-h1 {
          border-right: 1px solid rgba(255, 255, 255, .3);
        }
      }
    `
  
    return (
      <>
        <style>
          {styles}
        </style>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" marginX={ isSmallerThanMd ? "50px" : "70px" }>
          <Box justifySelf="flex-end" marginY="15px">
          {
                !setIsExternalLink &&
                <BasicIconTextButton
                    backgroundColor={theme.palette.secondary.main}
                    color={theme.palette.secondary.contrastText}
                    startIcon={<ArrowBack />}
                    onClick={() => history.push(`${setLinkAddress}`)}
                    >
                    {setLinkLabel}
                </BasicIconTextButton>
            }
            {
                setIsExternalLink &&
                <Button
                    size="small"
                    variant="contained"
                    style={{
                        width: '100%', backgroundColor: theme.palette.secondary.main, 
                        color: theme.palette.secondary.contrastText, 
                        '&:hover': { backgroundColor: 'primary.contrastText', color: "primary.main" }, 
                        fontSize: '0.85rem',
                        textDecoration: 'none', paddingY: '10px'
                    }}
                    endIcon={<HelpIcon />}
                    target="_blank"
                    href={setLinkAddress}
                >
                    {setLinkLabel}            
                </Button>
            }
          </Box>
          <Box display="flex" flexDirection="row">
            <Box display="flex" alignItems="center" justifyContent="center">
              <h1 className="error-h1" style={{
                margin: '0px 20px 0px 0px', padding: '0px 23px 0px 0px',
                fontSize: '24px', fontWeight: 500, verticalAlign: 'top', lineHeight: '49px'
              }}>
                {statusCode}
              </h1>
            </Box>
            <Box textAlign="left" flexGrow={1} display="flex" alignItems="center" justifyContent="center" >
              <h2 style={{
                fontSize: '14px', fontWeight: 'normal',
                margin: '0px', padding: '0px'
              }}>
                {setMessage}
              </h2>
            </Box>
          </Box>
        </Box>
      </>
    )
  }


  ErrorComponent.propTypes = {
    statusCode: PropTypes.number, 
    message: PropTypes.string, 
    linkAddress: PropTypes.string, 
    linkLabel: PropTypes.string,
    isExternalLink: PropTypes.bool
  }