import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    position: 'relative',

  },

  button: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
});

const DeletablePaperSmall = (props) => {
  const { classes, children, onClick } = props;

  return (
    <Box my="5px">
      <Paper className={`${classes.root} box paper`}>
        <IconButton className={classes.button} aria-label="Delete" onClick={onClick}>
          <DeleteIcon />
        </IconButton>
        <div>
          {children[0]}
        </div>
        <div>
          {children[1]}
        </div>
      </Paper>
    </Box>
  );
};


DeletablePaperSmall.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.array,
  onClick: PropTypes.func
};

export default withStyles(styles)(DeletablePaperSmall);
