import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// MSAL imports
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from '@azure/msal-browser';

// Material UI
import { Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';


function ReviewNotificationDialog(props) {
  // PROPS
  const { observationId, reviewNotifyPromptOpen, handleReviewDialog, 
          updateObservationStatusHandler, updateTimestamp } = props;

  // USE MSAL HOOK
  const { instance, accounts, inProgress } = useMsal();
  const loginHint = (accounts && accounts[0]?.username) ?? '';
  const request = {
    loginHint,
    scopes: ["User.Read"]
  }
  const isAuthenticated = useIsAuthenticated();
  
  // EFFECTS
  useEffect(async () => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      await instance.loginRedirect(request);
    }
  }, [isAuthenticated, inProgress, instance]);

  return (
    <Dialog
      open={reviewNotifyPromptOpen}
      onClose={ handleReviewDialog }
      aria-labelledby="review-request-dialog-title"
      aria-describedby="review-request-dialog-description"
    >
      <DialogTitle id="review-request-dialog-title">Notify the job manager and partner</DialogTitle>
      <DialogContent>
        <DialogContentText id="review-request-dialog-description-1">
          Except when making minor changes to an observation, we recommend sending a notification for a review request.
        </DialogContentText>
        <DialogContentText id="review-request-dialog-description-2">
          This will notify the observation creator as well as the project manager and partner for this job
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box display="flex" flexDirection="column">
          <Button onClick={async () => {
              handleReviewDialog();
              await updateObservationStatusHandler(observationId, 'review', true, updateTimestamp)
            }} 
            style={{ color: '#1976d2', width: '100%', justifyContent: 'flex-start' }} startIcon={<NotificationsActiveIcon />}>
            Notify Team
          </Button>
          <Button onClick={async () => {
              handleReviewDialog();
              await updateObservationStatusHandler(observationId, 'review', false, updateTimestamp)
            }}
            style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '0.75rem', width: '100%', justifyContent: 'flex-start' }} >
            Status Change Only
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

ReviewNotificationDialog.propTypes = {
  observationId: PropTypes.string,
  updateTimestamp: PropTypes.string,
  approvalStatusModifier: PropTypes.oneOf(['draft', 'approved']),
  reviewNotifyPromptOpen: PropTypes.bool,
  handleReviewDialog: PropTypes.func,
  updateObservationStatusHandler: PropTypes.func,
  tabName: PropTypes.string,
  refreshSummaryList: PropTypes.func
}

export default ReviewNotificationDialog;
