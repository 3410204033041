import React, { useContext, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// MSAL imports
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from '@azure/msal-browser';

// Material UI
import { Paper, Box, Grid, FormControlLabel, Switch, Tooltip, Button,
          List, ListItem, ListItemIcon, ListItemText, Typography,
          TextField, 
          useTheme,
          useMediaQuery} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import RoomIcon from '@material-ui/icons/Room';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import { useSnackbar } from 'notistack';
const snackBarAutoHide = 2000;

// Leaflet/React-Leaflet
import { MapContainer, TileLayer, Marker, Popup, LayersControl, useMapEvents, useMap } from 'react-leaflet';
const { BaseLayer } = LayersControl;
import L from 'leaflet';

// Custom Components
import { JobSummaryRowExport as JobSummaryRow } from './jobSummaryTable';
import { ConditionalWrapper } from './ConditionalWrapper';

// Custom Styles
import '../leaflet.css';

// Custom Helpers
import { isDefinedAndInitialized } from '../helpers/helpers';

// Custom Config
import { config } from '../config/generalConfig';

// Context
import { InitiaContext } from '../context/initia-context';
import { getGeocodedAddress } from '../services/api';

const LINZ_API_KEY = window.config.LINZ_API_KEY;
const SATELLITE_TILE_ENDPOINT = `https://basemaps.linz.govt.nz/v1/tiles/aerial/3857/{z}/{x}/{y}.png?api=${LINZ_API_KEY}`;
const STREET_MAP_TILE_ENDPOINT = 'https://api.mapbox.com/styles/v1/initia-spatial/ckpvq9qou39y517nx2yrkz2tw/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiaW5pdGlhLXNwYXRpYWwiLCJhIjoiY2twdnE0MmdrMHF5dzJwbnZlNnNxYWltNSJ9.beyyExVnKIW-gKbUGmDX0A';

const styles = (theme) => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderTop: "1px solid rgba(224, 224, 224, 1)",
    marginTop: "0px",
  },
  informationTitle: {
    paddingBottom: "18px",
  },
});

const getCoordinateTupleFromObsStandard = (data) => {
  // -------------------------------------------

  return [data.jobSummaryTableData.latitude, data.jobSummaryTableData.longitude]
}

const shouldInitializeLocation = (data) => {
  // -------------------------------------------
  return isDefinedAndInitialized(data.jobSummaryTableData.latitude) && isDefinedAndInitialized(data.jobSummaryTableData.longitude);
}

const canUseJobAddressForLocation = (data) => {
  // -------------------------------------------
  return isDefinedAndInitialized(data.jobData.siteAddress.value) && data.jobData.siteAddress.value !== '';
}

function LocationMarker(props) {
  const { coordinateTuple, handleClientObservationStateUpdate } = props;
  const map = useMapEvents({
    click(e) {
      handleClientObservationStateUpdate(
        { target: { value: { latitude: e.latlng.lat, longitude: e.latlng.lng } } },
        'location',
        null,
        null
      );
      map.flyTo(e.latlng, 18);
    },
  })

  return (
    <Marker position={coordinateTuple}>
      <Popup>Observation Location</Popup>
    </Marker>
  )
}

LocationMarker.propTypes = {
  coordinateTuple: PropTypes.array,
  handleClientObservationStateUpdate: PropTypes.func
}

function ChangeView({ center, zoom }) {
  const map = useMap();
  map.setView(center, zoom);
  return null;
}

ChangeView.propTypes = {
  center: PropTypes.array,
  zoom: PropTypes.number
}

const POSITION_CLASSES = {
  bottomleft: 'leaflet-bottom leaflet-left',
  bottomright: 'leaflet-bottom leaflet-right',
  topleft: 'leaflet-top leaflet-left',
  topright: 'leaflet-top leaflet-right',
}

const ReturnToLocationControl = (props) => {
  // ------------------------------------------
  const { coordinateTuple, position } = props;

  const controlRef = useRef(null);

  const map = useMap();

  useEffect(() => {
    // ----------------------------------
    if (controlRef.current) {
      // ---------------------------
      L.DomEvent.disableClickPropagation(controlRef.current);
    }
  })

  const positionClass =
    (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright;

  const handleControlClick = () => {
    // ----------------------------------------
    map.flyTo(coordinateTuple, 18)
  }


  return (
    <div ref={controlRef} className={positionClass} onClick={handleControlClick} style={{ cursor: 'pointer' }}>
      <div className="leaflet-control leaflet-bar">
        <a 
          title={`Back to coordinate`} role="button" 
          aria-label={`Back to coordinate`} aria-disabled="false"><span aria-hidden="true"
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', cursor: 'pointer' }}
        >
          { <KeyboardReturnIcon /> }
        </span></a>
      </div>
    </div>
  )
}

ReturnToLocationControl.propTypes = {
  coordinateTuple: PropTypes.array,
  position: PropTypes.oneOf(['bottomleft', 'bottomright', 'topleft', 'topright'])
}

const InteractionActiveControl = (props) => {
  // ------------------------------------------
  const { interactionActive, setInteractionActive, position } = props;

  const controlRef = useRef(null);

  useEffect(() => {
    // ----------------------------------
    if (controlRef.current) {
      // ---------------------------
      L.DomEvent.disableClickPropagation(controlRef.current);
    }
  })

  const positionClass =
    (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright;

  const handleControlClick = () => {
    // ----------------------------------------
    setInteractionActive(!interactionActive) 
  }


  return (
    <div ref={controlRef} className={positionClass} onClick={handleControlClick} style={{ cursor: 'pointer' }}>
      <div className="leaflet-control leaflet-bar">
        <a 
          title={`Toggle interaction ${interactionActive ? 'off' : 'on'}`} role="button" 
          aria-label={`Toggle interaction ${interactionActive ? 'off' : 'on'}`} aria-disabled="false"><span aria-hidden="true"
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', cursor: 'pointer' }}
        >
          { interactionActive ? <LockOpenIcon /> : <LockIcon /> }
        </span></a>
      </div>
    </div>
  )
}

InteractionActiveControl.propTypes = {
  interactionActive: PropTypes.bool,
  setInteractionActive: PropTypes.func,
  position: PropTypes.oneOf(['bottomleft', 'bottomright', 'topleft', 'topright'])
}


const GeospatialCoordinates = (props) => {
  // -------------------------------------------
  // PROPS
  const { classes, handleClientObservationStateUpdate } = props;

  // CONTEXT
  const { state } = useContext(InitiaContext);

  // STATE
  const [mapInfo, setMapInfo] = useState({
    active: shouldInitializeLocation(state.observationPage)
  });
  const [ siteAddressOptions, setSiteAddressOptions ] = useState({
    searchStatus: 'PENDING',
    data: []
  });
  const [ customAddress, setCustomAddress ] = useState('');
  const [ interactionActive, setInteractionActive ] = useState(false);

  // DERIVED STATE
  const data = state.observationPage;
  const coordinateTuple = getCoordinateTupleFromObsStandard(data);
  const canUseJobAddress = canUseJobAddressForLocation(state.observationPage);

  // NOTISTACK
  const { enqueueSnackbar } = useSnackbar();

  // USE MSAL HOOK
  const { instance, accounts, inProgress } = useMsal();
  const loginHint = (accounts && accounts[0]?.username) ?? '';
  const request = {
    loginHint,
    scopes: ["User.Read"]
  }
  const isAuthenticated = useIsAuthenticated();
  const isAuthed = isAuthenticated && inProgress === "none" && isDefinedAndInitialized(accounts) && accounts.length > 0 && isDefinedAndInitialized(accounts[0]) && isDefinedAndInitialized(accounts[0].username);

  // MUI Hooks
  const theme = useTheme();
  const isSmallerThanXs = useMediaQuery(theme.breakpoints.down('xs'));


  // EFFECTS
  useEffect(async () => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      await instance.loginRedirect(request);
    }
  }, [isAuthenticated, inProgress, instance]);

  useEffect(() => {
    // -------------------------------
    setMapInfo({
      ...mapInfo,
      active: shouldInitializeLocation(state.observationPage)
    });
  }, [
    JSON.stringify(data)
  ]);

  if (!state.observationPage.observationEdit) {
    return null;
  }

  const handleDeviceLocationRequest = () => {
    // ---------------------------------------------------
    // Check whether the device supports geolocation and respond accordingly
    setSiteAddressOptions({
      searchStatus: 'PENDING',
      data: []
    });

    if (!navigator.geolocation) {
      enqueueSnackbar('Geolocation is not supported by your browser', { variant: 'error', autoHideDuration: snackBarAutoHide + 2000 });
    }
    else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // OnSuccess
          // ------------------
          handleClientObservationStateUpdate(
            { target: { value: { latitude: position.coords.latitude, longitude: position.coords.longitude } } },
            'location',
            null,
            null
          );
          enqueueSnackbar('Successfully updated device location', { variant: 'success', autoHideDuration: snackBarAutoHide });
        },
        () => {
          // OnError
          // ------------------
          enqueueSnackbar('Unable to retrieve device location', { variant: 'error', autoHideDuration: snackBarAutoHide + 2000 });
        });
    }
  }

  const handleGeocodingRequest = async (address) => {
    // ---------------------------------------------------
    if (isAuthed) {
      // -------------------------------------
      // Hit Initia geocoding API using input address
      console.info(`Checking site address: ${address}`);

      setSiteAddressOptions({
        searchStatus: 'PENDING',
        data: []
      });

      let result = await getGeocodedAddress(address, { instance, accounts, inProgress });

      if (result.results.length === 1) {
        // -----------------------------
        handleClientObservationStateUpdate(
          { target: { value: { latitude: result.results[0].geometry.location.lat, longitude: result.results[0].geometry.location.lng } } },
          'location',
          null,
          null
        );
        setSiteAddressOptions({
          searchStatus: 'COMPLETE',
          data: result.results
        });
        enqueueSnackbar('Successfully updated observation location', { variant: 'success', autoHideDuration: snackBarAutoHide });
      }
      else {
        // -----------------------------
        setSiteAddressOptions({
          searchStatus: 'COMPLETE',
          data: result.results
        });
      }
    }
    else {
      // -------------------------------
      console.info('Not authenticated for this API action');
    }
  }

  const handleGeocodedAddressSelection = ({ lat, lng }) => {
    // ---------------------------------------------------
    handleClientObservationStateUpdate(
      { target: { value: { latitude: lat, longitude: lng } } },
      'location',
      null,
      null
    );
    enqueueSnackbar('Successfully updated observation location', { variant: 'info', autoHideDuration: snackBarAutoHide });
  }

  const handleToggleMapInteraction = () => {
    // -------------------------------------------------
    setInteractionActive(!interactionActive);
  }


  return (
    <Box my="5px">
      <Paper className={classes.root}>
        <Box className={classes.informationTitle} display="flex" flexDirection={ isSmallerThanXs ? "column" :"row" } justifyContent="space-between" alignItems="center">
          SPATIAL INFORMATION (refresh device location or click a point on the map)

          <Box ml="15px" paddingTop={ isSmallerThanXs ? "10px" : "0px" }>
            <Tooltip title="Initial location based on device">
              <FormControlLabel
                className={classes.textField}
                control={
                  <Switch
                    checked={mapInfo.active}
                    onChange={() => {
                      const locationActivityStateChange = !mapInfo.active
                      setMapInfo({
                        ...mapInfo,
                        active: locationActivityStateChange
                      });

                      // If updating to inactive, reset the coordinates to 0
                      if (!locationActivityStateChange) {
                        handleClientObservationStateUpdate(
                          { target: { value: { latitude: null, longitude: null } } },
                          'location',
                          null,
                          null
                        );
                      }
                      else {
                        handleDeviceLocationRequest();
                      }
                    }}
                    name={"geospatial-toggle"}
                  />}
                  label={<Typography color="textSecondary" style={{ fontSize: isSmallerThanXs ? '0.9rem' : '1rem' }}>Use location?</Typography>}
              />
            </Tooltip>
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" mb="20px">
          <Grid container direction="row" alignItems="center">
            <Grid item xs={12} md={6}>
              <Box display="flex" flexDirection={ isSmallerThanXs ? "column" : "row" } alignItems="center">
                <ConditionalWrapper
                  condition={mapInfo.active && canUseJobAddress}
                  wrapper={children => <Tooltip title="Use device location (check you have allowed location)">{children}</Tooltip>}
                >
                  <Box mx={ isSmallerThanXs ? "0px" : "10px" } my={ isSmallerThanXs ? "10px" : "0px" }>
                    <Button
                      color="primary"
                      hidden={!mapInfo.active && canUseJobAddress}
                      disabled={!mapInfo.active && canUseJobAddress}
                      aria-label="device-location"
                      variant="contained"
                      className={classes.button}
                      startIcon={<GpsFixedIcon />}
                      onClick={handleDeviceLocationRequest}
                    >
                      Device Location
                    </Button>
                  </Box>
                </ConditionalWrapper>
                <ConditionalWrapper
                  condition={mapInfo.active}
                  wrapper={children => <Tooltip title="Use geocoded job address">{children}</Tooltip>}
                >
                  <Box mx="10px">
                    <Button
                      color="primary"
                      hidden={!mapInfo.active}
                      disabled={!mapInfo.active}
                      aria-label="device-location"
                      variant="contained"
                      className={classes.button}
                      startIcon={<RoomIcon />}
                      onClick={() => handleGeocodingRequest(state.observationPage.jobData.siteAddress.value)}
                    >
                      Use site address
                    </Button>
                  </Box>
                </ConditionalWrapper>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} >
              <ConditionalWrapper
                condition={mapInfo.active}
                wrapper={children => <Tooltip title="Enter a different address">{children}</Tooltip>}
              >
                <Box mx="10px" mt={ isSmallerThanXs ? "20px" : "0px" } display="flex" flexDirection={ isSmallerThanXs ? "column" : "row" } alignItems="center">
                  <Box mr="5px">
                    <TextField
                      name="custom-address"
                      margin="normal"
                      variant="outlined"
                      onChange={(e) => setCustomAddress(e.target.value)}
                      value={mapInfo.active ? customAddress : ''}
                      placeholder={ mapInfo.active ? "Enter a different address" : "Use location" }
                      disabled={!mapInfo.active}
                    />
                  </Box>
                  <Button
                    color="primary"
                    hidden={!mapInfo.active}
                    disabled={!mapInfo.active}
                    aria-label="device-location"
                    variant="contained"
                    className={classes.button}
                    startIcon={<EditLocationIcon />}
                    onClick={() => handleGeocodingRequest(customAddress)}
                  > 
                    Search
                  </Button>
                </Box>
              </ConditionalWrapper>
            </Grid>
          </Grid>
        </Box>
        {
            mapInfo.active 
            && siteAddressOptions.searchStatus === 'COMPLETE'
            && isDefinedAndInitialized(siteAddressOptions.data) 
            && siteAddressOptions.data.length > 0
            && 
            <Box mx="10px">
              <Typography variant="h6">Search results:</Typography>
              <List>
                {
                  siteAddressOptions.data.map((addressOption, idx) => {
                    return (
                      <ListItem key={`address-option-${idx}`} button onClick={ () => handleGeocodedAddressSelection(addressOption.geometry.location) }>
                        <ListItemIcon>
                          <RoomIcon />
                        </ListItemIcon>
                        <ListItemText primary={addressOption.formatted_address} />
                      </ListItem>
                    )
                  })
                }
              </List>
            </Box>
          }
          {
            mapInfo.active 
            && siteAddressOptions.searchStatus === 'COMPLETE'
            && isDefinedAndInitialized(siteAddressOptions.data) 
            && siteAddressOptions.data.length === 0
            && 
            <Box mx="10px" mb="15px" display="flex" flexDirection="row" justifyContent="center">
              <Typography style={{ color: '#d45f17' }}>No results found for your address, sometimes a more specific search can help</Typography>
            </Box>
          }
        {(mapInfo.active && shouldInitializeLocation(data)) ?
          <>
            <Grid container>
              {config.geoSpatialConfig.rows.map(row => {
                return (
                  <JobSummaryRow
                    key={`row-${row[0].id}-${row[1].id}`}
                    rowConfig={row}
                    tableData={data}
                  />
                )
              })}
            </Grid>
            <Grid container>
              <Box position="relative" height="100%" width="100%">
                <MapContainer
                  center={coordinateTuple}
                  zoom={18}
                >
                  {/* <ChangeView center={coordinateTuple} zoom={18} /> */}
                  <LayersControl>
                    <BaseLayer checked name="Satellite" >
                      <TileLayer
                          attribution={`© <a href="//www.linz.govt.nz/linz-copyright">LINZ CC BY 4.0</a> © <a href="//www.linz.govt.nz/data/linz-data/linz-basemaps/data-attribution">Imagery Basemap contributors</a>`}
                          url={SATELLITE_TILE_ENDPOINT}
                          maxNativeZoom={22}
                          maxZoom={21}
                          minZoom={14}
                      />
                    </BaseLayer>
                    <BaseLayer name="Street Map" >
                      <TileLayer
                        attribution={`© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>`}
                        url={`${STREET_MAP_TILE_ENDPOINT}`}
                      />
                    </BaseLayer>
                  </LayersControl>
                  <LocationMarker coordinateTuple={coordinateTuple} handleClientObservationStateUpdate={handleClientObservationStateUpdate} />
                  <InteractionActiveControl interactionActive={interactionActive} setInteractionActive={setInteractionActive} position="bottomleft" />
                  <ReturnToLocationControl coordinateTuple={coordinateTuple} position="bottomright" />
                </MapContainer>
                {
                  !interactionActive &&
                  <Box 
                      position="absolute" 
                      display="flex" justifyContent="center" alignItems="center"
                      bgcolor="rgba(184,184,184,0.6)" 
                      height="100%" width="100%" 
                      top={0} left={0} zIndex={400} 
                      color="white" fontSize="1.1rem"
                      px="20px"
                      style={{ cursor: 'pointer' }}
                      onClick={handleToggleMapInteraction}
                  >
                    Click on the map to activate click, scroll and drag
                  </Box>
                }
              </Box>
            </Grid>
          </>
          : ''}
      </Paper>
    </Box>
  )
}

GeospatialCoordinates.propTypes = {
  classes: PropTypes.object,
  handleClientObservationStateUpdate: PropTypes.func,
};

export default withStyles(styles)(GeospatialCoordinates);
