import React, { useState } from 'react';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';

// Material UI
import SaveIcon from '@material-ui/icons/Save';
import { Fab, Box } from '@material-ui/core';

// Custom Components
import EnhancedMenu from './enhancedMenu/enhancedMenu';
import ApprovalDialog from './approvalDialog';
import ReviewNotificationDialog from './reviewNotificationDialog';

// Custom Helpers
import { isDefinedAndInitialized } from '../helpers/helpers';


function SaveComponent(props) {
  const { classes, saveHandler, downloadPdfHandler, observationId, updateTimestamp,
    observationHeader, updateObservationStatusHandler } = props;

  // USE STATE HOOKS
  const [anchorElement, setAnchorElement] = useState(null);
  const [approveConfirmationOpen, setApproveConfirmationOpen] = useState(false);
  const [reviewNotifyPromptOpen, setReviewNotifyPromptOpen] = useState(false);

  const userGroups = JSON.parse(localStorage.getItem('siteObservationsUserGroups'));
  const authorizedForReview = isDefinedAndInitialized(userGroups) && userGroups.filter(group => group.displayName === 'Site Observations Reviewers').length > 0;

  const observationAuthorised = (observationHeader)
    ? ['downloaded', 'approved'].includes(observationHeader.status) : null;
  const approvalStatusModifier = observationAuthorised ? 'draft' : 'approved';

  const handleButtonClick = (event) => {
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorElement(null);
  };

  const handleApproveDialog = () => {
    setApproveConfirmationOpen(false);
  };

  const handleReviewDialog = () => {
    setReviewNotifyPromptOpen(false);
  };

  // Initialise menu items depending on observation status
  const menuItems = [
    {
      componentType: uuidv4(),
      caption: 'Save',
      clickHandler: (e) => {
        saveHandler(e, false)
      }
    },
    {
      componentType: uuidv4(),
      caption: 'Save and exit',
      clickHandler: (e) => {
        saveHandler(e, false)
          .then(() => {
            window.location.href = '/home';
          });
      },
    },
  ];

  // If in the 'draft' status, allow review to be requested
  if (observationHeader && observationHeader.status === 'draft') {
    menuItems.push({
      componentType: uuidv4(),
      caption: 'Save and request approval',
      clickHandler: () => {
        setReviewNotifyPromptOpen(true);
      }
    });
  }

  // If authorised - add the save and download option to the save submenu
  if (observationAuthorised) {
    menuItems.push({
      componentType: uuidv4(),
      caption: 'Save and download',
      clickHandler: async (e) => {
        await saveHandler(e, true);
        await downloadPdfHandler();
      },
    });
  } else if (authorizedForReview) {
    // Otherwise - provide the option to authorise the observation with subsequent confirmation dialogue
    menuItems.push({
      componentType: uuidv4(),
      caption: 'Save and approve for download',
      clickHandler: () => {
        if (!observationAuthorised) {
          setApproveConfirmationOpen(true);
        }
      },
    });
  }

  return (
    <Box position="fixed" bottom="2%" right="2%" zIndex={1000}>
      <Fab
        variant="extended"
        color="secondary"
        aria-label="Save"
        className={classes.fab}
        onClick={handleButtonClick}
      >
        <SaveIcon />
        Save
      </Fab>
      <EnhancedMenu
        open={Boolean(anchorElement)}
        menuItems={menuItems}
        anchorElement={anchorElement}
        onClose={handleMenuClose}
      />
      <ApprovalDialog
        tabName="INSPECTION_DETAIL"
        observationId={observationId}
        updateTimestamp={updateTimestamp}
        approvalStatusModifier={approvalStatusModifier}
        approveConfirmationOpen={approveConfirmationOpen}
        handleApproveDialog={handleApproveDialog}
        updateObservationStatusHandler={updateObservationStatusHandler}
        refreshSummaryList={null}
      />
      <ReviewNotificationDialog 
        tabName="INSPECTION_DETAIL"
        observationId={observationId}
        updateTimestamp={updateTimestamp}
        approvalStatusModifier={approvalStatusModifier}
        reviewNotifyPromptOpen={reviewNotifyPromptOpen}
        handleReviewDialog={handleReviewDialog}
        updateObservationStatusHandler={updateObservationStatusHandler}
      />
    </Box>
  );
}

SaveComponent.propTypes = {
  classes: PropTypes.object,
  saveHandler: PropTypes.func,
  downloadPdfHandler: PropTypes.func,
  observationId: PropTypes.string,
  updateTimestamp: PropTypes.string,
  observationHeader: PropTypes.object,
  updateObservationStatusHandler: PropTypes.func
}

export default SaveComponent;
