import React from 'react';
import PropTypes from 'prop-types';

import { TextField } from '@material-ui/core';

// CUSTOM UTILITIES
import { isDefinedAndInitialized } from '../../helpers/helpers';


function V2EditableCell(props) {
  const { cellData, onTableUpdate, colNum } = props;
  const { label, type, id, value, placeholder, isRowHeader } = cellData;

  let labelValue = `Column ${colNum}`;
  if (isDefinedAndInitialized(label)) {
    // ------------------------------
    labelValue = label;
  }
  let setIsRowHeader = false;
  if (isDefinedAndInitialized(isRowHeader)) {
    // ------------------------------
    setIsRowHeader = isRowHeader;
  }

  return (
      <TextField
        label={labelValue}
        variant="outlined"
        InputProps={{
          style: { paddingTop: '9.5px', paddingBottom: '9.5px', fontSize: '0.8rem', backgroundColor: setIsRowHeader ? 'lightgrey' : 'white', ".MuiInputBaseInput::placeholder": {
            fontSize: "0.8rem"
          } }
        }}
        InputLabelProps={{
          style: {
            fontSize: '0.8rem',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            maxWidth: '85%'
          }
        }}
        multiline
        minRows={ 4 }
        name={type}
        id={id}
        value={value || ''}
        onChange={onTableUpdate}
        placeholder={placeholder}
        style={{ paddingTop: '8px', paddingBottom: '8px', paddingLeft: '5px', paddingRight: '5px', width: '100%' }}
      />
  );
}

V2EditableCell.propTypes = {
  classes: PropTypes.object,
  cellData: PropTypes.object,
  onTableUpdate: PropTypes.func,
  colNum: PropTypes.number
}

export default V2EditableCell;
