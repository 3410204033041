
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, withRouter, Link } from 'react-router-dom';

// MASL React
import { InteractionStatus } from "@azure/msal-browser";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";

// Material UI
import { Box, Paper, Container, CircularProgress, Typography, Button, useMediaQuery } from '@material-ui/core';
import { withStyles, useTheme } from '@material-ui/core/styles';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

// REACT-QUERY
import {
  useQuery
} from 'react-query';

// REACT-PDF
import { BlobProvider } from '@react-pdf/renderer';

// Custom Components
import PDFViewer from '../components/pdfViewer';
import { NCRSummaryPdf } from '../components/reactPdfs/ncrSummary';

// Custom Services
import { getJobDetail, searchNcr } from '../services/api';
import { isDefinedAndInitialized } from '../helpers/helpers';

const styles = theme => ({
  titleBlock: {
    color: '#1976d2',
    fontSize: '32px',
    paddingLeft: '20px',
  },
  titleBlockContainer: {
    textAlign: 'center',
    paddingTop: '25px',
  },
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2) + 30,
  },
  tabNav: {
    backgroundColor: 'white',
    boxShadow: 'none',
  },
  tabPanel: {
    padding: '0px',
  },
  modal: {
    color: '#FFF',
    transform: 'translate(-50%, -50%)',
  }
});

export const BackToJobButton = (props) => {
  // -------------------------------
  const { jobDetailData } = props;

  const theme = useTheme();
  const isSmallerThanXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('sm'));

  const displayString = isSmallerThanSm ? `${jobDetailData.jobId}` : `${jobDetailData.jobId} | ${jobDetailData.jobName}`;

  if (isDefinedAndInitialized(jobDetailData)) {
    return (
      <Link
        to={`/home?tabName=JOB&lastJobId=${jobDetailData.jobId}`}
        style={{ textDecoration: 'none' }}
      >
        <Button variant="contained" color="primary">
          <KeyboardBackspaceIcon style={{ fontSize: isSmallerThanXs ? '0.65rem' : '1rem' }} />
          <Typography style={{ fontSize: isSmallerThanXs ? '0.65rem' : '1rem' }}>&nbsp;&nbsp;{ displayString }</Typography>          
        </Button>
      </Link>
    );
  }
  return <></>;
};
BackToJobButton.propTypes = {
  jobDetailData: PropTypes.object
}


function NcrSummaryPage(props) {
  // PROPS
  const { classes } = props;

  // REACT ROUTER
  let { jobId } = useParams();

  // USE MSAL HOOK
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const loginHint = (accounts && accounts[0]?.username) ?? '';
  const request = {
    loginHint,
    scopes: ["User.Read"]
  }
  const isAuthed = isAuthenticated && inProgress === "none" && isDefinedAndInitialized(accounts) && accounts.length > 0 && isDefinedAndInitialized(accounts[0]) && isDefinedAndInitialized(accounts[0].username);
  
  // REACT-QUERY
  // 1) JOB DETAIL
  const { isLoading: isJobDetailDataLoading, isError: isJobDetailDataError, data: jobDetailData, error: jobDetailDataError } = useQuery(
    ['jobDetail', jobId],
    () => getJobDetail(jobId, { instance, accounts, inProgress }),
    {
      enabled: isAuthed && isDefinedAndInitialized(jobId),
      refetchOnWindowFocus: false
    }
  );

  const { isLoading: isJobNcrListLoading, isError: isJobNcrListError, data: jobNcrListData, error: jobNcrListError } = useQuery(
    ['jobNcrList', jobId],
    () => searchNcr( { type: 'jobSearch', jobNumber: jobId }, { instance, accounts, inProgress }),
    {
      enabled: isAuthed && isDefinedAndInitialized(jobId),
      refetchOnWindowFocus: false
    }
  );
  
  useEffect(async () => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      await instance.loginRedirect(request);
    }
  }, [isAuthenticated, inProgress, instance]);

  // HANDLE ERROR STATE
  if (isJobDetailDataError) {
    return <span>An unexpected error has occurred with the job detail data {jobDetailDataError}</span>
  }
  if (isJobNcrListError) {
    return <span>An unexpected error has occurred with the job NCR list data {jobNcrListError}</span>
  }


  return (
    <Container maxWidth="md">

      {/* MAIN CONTENT */}
      <Box className={classes.contentWrapper}>
        <Paper className={classes.root}>

          {/* TITLE BLOCK */}
          <Box>
            {
              (!isAuthed ||
              isJobDetailDataLoading) &&
              <Box width="100%" display="flex" flexDirection="row" justifyContent="center" >
                <CircularProgress />
              </Box>
            }
            {
              isAuthed && !isJobDetailDataLoading && isDefinedAndInitialized(jobDetailData) &&
              <BackToJobButton jobDetailData={jobDetailData} />
            }
          </Box>

          <Box className={classes.titleBlockContainer}>
            <Box className={classes.titleBlock}>
              <Typography variant="h4">{`NCR Summary for ${jobId}`}</Typography>
              {(window.config.ENV === 'prod') ? '' : <Typography variant="h4" color="secondary"><br /><strong>{`${window.config.ENV.toUpperCase()}`}</strong></Typography>}
            </Box>
          </Box>

          <Box>
            {
              (!isAuthed ||
              (isJobDetailDataLoading ||
                isJobNcrListLoading)) &&
              <Box width="100%" display="flex" flexDirection="row" justifyContent="center" >
                <CircularProgress />
              </Box>
            }
            {
              isAuthed && !isJobDetailDataLoading && isDefinedAndInitialized(jobDetailData) &&
              !isJobNcrListLoading && isDefinedAndInitialized(jobNcrListData) &&
              <BlobProvider document={<NCRSummaryPdf jobDetail={jobDetailData} jobNcrListData={jobNcrListData} />}>
                {({ blob, url }) => {
                  // --------------------------------

                  if (blob && url) {
                    return (
                      <Box display="flex" flexDirection="row" justifyContent="center" my="15px" width="100%">
                        <PDFViewer url={url} pdfType='ncrSummary' />
                      </Box>
                    )
                  }
                  else {
                    return (
                      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt="15px">
                        <Typography>Generating PDF...</Typography>
                        <CircularProgress />
                      </Box>
                    )
                  }
                }}
              </BlobProvider>
            }
          </Box>

        </Paper>
      </Box>

    </Container>
  );
}

NcrSummaryPage.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
}

export default withRouter(withStyles(styles)(NcrSummaryPage));
