import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

// MSAL imports
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from '@azure/msal-browser';

// Nivo Charts
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveCalendar } from '@nivo/calendar';
import { ResponsiveAreaBump } from '@nivo/bump';

// Material UI
import { Typography, TextField, Button, useTheme, useMediaQuery } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import GetAppIcon from '@material-ui/icons/GetApp';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import {
  useQuery,
  useQueryClient
} from 'react-query';

// UUID
import uuidv4 from 'uuid/v4';

// CUSTOM SERVICES AND HELPERS
// import Divider from '@material-ui/core/Divider';
import { searchOLAP } from '../services/api';
import { isDefinedAndInitialized } from '../helpers/helpers';

// Custom Components
import AppLogo from './appLogo';
import { renderObservationSummaryListItem } from './v2observationSummaryListItem';

// Custom Helpers
import { capitalizeFirstLetters } from '../helpers/helpers';

// Custom Config
import { config } from '../config/generalConfig';

// Context
import { InitiaContext } from '../context/initia-context';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';

const styles = theme => ({
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    width: '100%',
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '16px',
    paddingRight: '16px',
    boxSizing: 'border-box',
  },
  progress: {
    margin: theme.spacing(2),
    color: '#1976d2',
  },
  summaryFullWidthColumn: {
    width: '100%',
  },
  summaryHalfColumn: {
    width: '50%'
  },
  summaryWideColumn: {
    width: '65%'
  },
  summaryNarrowColumn: {
    width: '35%',
  },
  majorDigitValueText: {
    fontSize: '50px',
    marginRight: '5px',
    color: '#0277bd',
    textAlign: 'center',
  },
  majorDigitDescriptionText: {
    fontSize: '14px'
  },
  minorDigitValueText: {
    fontSize: '20px',
    marginRight: '5px',
    color: '#0277bd',
  },
  minorDigitDescriptionText: {
    fontSize: '10px',
  },
  nameLabel: {
    lineHeight: '30px',
  },
});

const EmptyObservationsListMessaging = () => {
  // ----------------------------------------
  return (
    <Box style={{ 
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row',
      width: '100%',
      height: '225px',
      alignItems: 'center',
      paddingLeft: '16px',
      paddingRight: '16px',
      boxSizing: 'border-box',
      border: '1px solid lightgrey',
      borderRadius: '5px'
      }}>
      Click a status on the graph to review the corresponding observations
    </Box>
  );
};

const StatTwoDigitSummary = (props) => {
  const { classes, data, statType } = props;
  const { state } = useContext(InitiaContext);

  // USE MSAL HOOK
  const { instance, accounts, inProgress } = useMsal();
  const loginHint = (accounts && accounts[0]?.username) ?? '';
  const request = {
    loginHint,
    scopes: ["User.Read"]
  }
  const isAuthenticated = useIsAuthenticated();

  useEffect(async () => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      await instance.loginRedirect(request);
    }
  }, [isAuthenticated, inProgress, instance]);

  const personName = accounts[0]?.idTokenClaims?.name ?? '';

  const onClick = (clickStatType) => {
    const redirect = (clickStatType === 'initia') ? 'LAST_MONTH' : 'MY_LAST_3_MONTHS';

    props.history.push(`/home?tabName=${redirect}`);
  };

  return (
    <Paper variant="outlined" onClick={() => onClick(statType)}
      style={{
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '&:hover': {
          backgroundColor: '#f5faff',
          cursor: 'pointer',
        }
      }}>
      <Box style={{ alignSelf: 'flex-end', lineHeight: '30px' }}>
        {(statType === 'initia')
          ? (
            <AppLogo height="30px" logo={state.simplifiedLogo} />
          )
          : <strong className={classes.nameLabel}>{`${personName}`}</strong>
        }
      </Box>
      {data.map(entry => (
        <Box key={uuidv4()}>
          <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <span className={classes.majorDigitValueText}>{entry.majorValue}</span>
            <span style={{ width: '50%' }}>{entry.majorSupportingText}</span>
          </Box>
          <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
            <span className={classes.minorDigitValueText}>{entry.minorValue}</span>
            <span className={classes.minorDigitDescriptionText}>{entry.minorSupportingText}</span>
          </Box>
        </Box>
      ))}
    </Paper>
  );
};

StatTwoDigitSummary.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.array,
  statType: PropTypes.string,
  loading: PropTypes.bool,
  history: PropTypes.object,
}

const ObservationSummaryChartInteractive = (props) => {
  // --------------------------------------
  const { obsList, nonClassProps, tabName,
          handlePDFObservation, handleApproveObservation, handleDeleteObservation,
          searchType, refreshSummaryList
        } = props;

  const [ mobileItemIdxExpanded, setMobileItemIdxExpanded ] = useState(null);
  const [ listHeight, setListHeight ] = useState(0);

  const listRef = useRef();
  
  // MUI HOOKS
  const theme = useTheme();
  const isSmallerThanXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  const getItemSize = useCallback((idx) => {
    // -----------------------------------------
    const width = window.innerWidth;
    // Smaller than SM (i.e. xs)
    // OR, arger than MD (i.e. lg, xl)
    if (width < theme.breakpoints.values.sm || width > theme.breakpoints.values.md) {
      return mobileItemIdxExpanded === idx ? 237 : 63;
    }
    // Smaller than MD (i.e. sm)
    else if (width < theme.breakpoints.values.md) {
      return 174;
    } 
    // Larger than MD (i.e. md, lg, xl)
    else {
      return 104;
    }
  }, [theme.breakpoints, mobileItemIdxExpanded]);

  // EFFECTS
  useEffect(() => {
    // ----------------------------------------
    let resizeTimeout;
    const handleTimedOutOrientationChangeOrResize = () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(handleOrientationOrResize, 100); // adjust delay as needed
    }

    // Use the ScreenOrientation API if available
    if (window.screen && window.screen.orientation) {
      // ----------------------------------------
      window.screen.orientation.addEventListener('change', handleTimedOutOrientationChangeOrResize);
    }
    // Otherwise try the deprecated orientationchange event
    else {
      // ----------------------------------------
      console.info(`ScreenOrientation API not available. Using fallback orientationchange event.`)
      window.addEventListener('orientationchange', handleTimedOutOrientationChangeOrResize);
    }
    window.addEventListener('resize', handleTimedOutOrientationChangeOrResize);
    handleTimedOutOrientationChangeOrResize();
    return () => {
      clearTimeout(resizeTimeout);
      window.removeEventListener('resize', handleTimedOutOrientationChangeOrResize);
      
      if (window.screen && window.screen.orientation) {
        window.screen.orientation.removeEventListener('change', handleTimedOutOrientationChangeOrResize);
      }
      else {
        window.removeEventListener('orientationchange', handleTimedOutOrientationChangeOrResize);
      }
    }
  }, []);

  useEffect(() => {
    // ----------------------------------------
    // Set the list height initially based on the observation list length and the item size
    setListHeight(obsList.reduce((acc, obs, idx) => {
      return acc + getItemSize(idx);
    }, 0));
  }, [obsList]);
  
  // EVENT HANDLERS
  const handleOrientationOrResize = useCallback(() => {
    if (isDefinedAndInitialized(listRef.current)) {
      // --------------------------
      setMobileItemIdxExpanded(null);
      setListHeight(obsList.reduce((acc, obs, idx) => {
        return acc + getItemSize(idx);
      }, 0));
      listRef.current.resetAfterIndex(0, true);
    }
  }, [listRef.current]);

  return (
    <Box minHeight="225px" height={listHeight} maxHeight={ isSmallerThanXs ? "225px" : "520px" } style={{ 
      border: '1px solid lightgrey',
      borderRadius: '5px' 
    }}>
      <AutoSizer>
        {
          ({ height, width }) => {

            return (
              <VariableSizeList
                ref={listRef}
                height={height}
                width={width}
                itemCount={obsList.length}
                itemSize={getItemSize}
                itemData={
                  obsList
                    .sort((a, b) => {
                      if (DateTime.fromISO(a.header.inspectionTimestamp) > (DateTime.fromISO(b.header.inspectionTimestamp))) {
                        return -1;
                      }
                      if (DateTime.fromISO(b.header.inspectionTimestamp) > (DateTime.fromISO(a.header.inspectionTimestamp))) {
                        return 1;
                      }
                      // a must be equal to b
                      return 0;
                    }).map((observationSummary, idx) => {
                      return {
                        ...nonClassProps,
                        tabName,
                        ObservationSummaryData: observationSummary,
                        handlePDFObservation,
                        handleApproveObservation,
                        handleDeleteObservation,
                        searchType,
                        refreshSummaryList,
                        idx,
                        showActions: ( !isSmallerThanXs && !isLargerThanMd ) || ( ( isSmallerThanXs || isLargerThanMd) && idx === mobileItemIdxExpanded),
                        handleShowActions: (newIdx) => {
                          // ----------------------------
                          let resetIdx = mobileItemIdxExpanded !== null ? Math.min(mobileItemIdxExpanded, newIdx) : newIdx;
                          if (listRef.current) {
                            listRef.current.scrollToItem(newIdx);
                            listRef.current.resetAfterIndex(resetIdx, true);
                          }
                          setMobileItemIdxExpanded(newIdx === mobileItemIdxExpanded ? null : newIdx);
                        },
                        useMobileLayout: isLargerThanMd
                      }
                    })
                }
              >
                { renderObservationSummaryListItem }
              </VariableSizeList>
            )
          }
        }
      </AutoSizer>
    </Box>
  );
};

ObservationSummaryChartInteractive.propTypes = {
  nonClassProps: PropTypes.object,
  obsList: PropTypes.array,
  tabName: PropTypes.string,
  handlePDFObservation: PropTypes.func,
  handleApproveObservation: PropTypes.func,
  handleDeleteObservation: PropTypes.func,
  searchType: PropTypes.string,
  refreshSummaryList: PropTypes.func
}

const PieChart = (props) => {
  const { classes, title, obsState, searchType, obsList, obsListLoading, onSliceClick, refreshSummaryList } = props;

  const pieRef = useRef(null);

  // MUI HOOKS
  const theme = useTheme();
  const isSmallerThanXs = useMediaQuery(theme.breakpoints.down('xs'));

  const lookupKey = (searchType === 'MONTH_STATUS_SEARCH') ? 'by_status' : 'by_type';

  const colors = {
    MONTH_STATUS_SEARCH: config.statusTypes.reduce((obj, statusType) => { obj[statusType.name] = statusType.monthSummaryColour; return obj }, {}),
    MONTH_TYPE_SEARCH: config.observationTypes.reduce((obj, observationType) => {
      obj[observationType.name.toLowerCase()] = observationType.colour;
      return obj;
    }, {}),
  };

  const total = obsState.org.month.last[lookupKey].reduce((sum, valueSummary) =>
    // ---------------------------
    sum + valueSummary.value,
    0);

  const getColor = (slice, typeInput) => colors[typeInput][slice.data.label.toLowerCase()];
  const getLabel = slice =>
    `${capitalizeFirstLetters(slice.id)} ${slice.value} (${Math.round(slice.value / total * 100)}%)`;

  const data = obsState.org.month.last[lookupKey].map(valueSummary => ({
    ...valueSummary,
    id: getLabel(valueSummary),
    value: valueSummary.value,
    percentage: `${Math.round(valueSummary.value / total * 100)}%`,
  }));

  useEffect(() => {
    // --------------------------
    // setup touch events to prevent scrolling
    const target = pieRef.current;
    const handleTouchAsClick = e => { 
      e.preventDefault();
      let clickEvent = new Event('click', { bubbles: true, cancelable: true });
      e.target.dispatchEvent(clickEvent);
    }
    target.addEventListener('touchend', handleTouchAsClick);


    return () => {
      // --------------------------
      target.removeEventListener('touchend', handleTouchAsClick);
    }
  }, [pieRef]);


 
  return (
    <Box >
      <Paper variant="outlined" height="500px">
        <Box px="16px" py="8px" >
          <Typography style={{
            fontSize: isSmallerThanXs ? '1rem' : '1.2rem', marginTop: '10px',
            textAlign: "center", paddingLeft: '25px',
            paddingRight: '25px'
          }} variant="h5" color="secondary" gutterBottom>
            {title}
          </Typography>
          <Box ref={pieRef} display="flex" justifyContent="center" height={isSmallerThanXs ? 200 : 250} mx="10px">
            <ResponsivePie
              data={data}
              padAngle={6}
              margin={{ top: isSmallerThanXs ? 30 : 10, bottom: isSmallerThanXs ? 30 : 10, right: isSmallerThanXs ? -105 : -140 }}
              innerRadius={0.5}
              startAngle={360}
              endAngle={0}
              cornerRadius={5}
              colors={input => getColor(input, searchType)}
              borderWidth={2}
              borderColor={{ from: 'color', modifiers: [['darker', '0.9']] }}
              enableRadialLabels={false}
              theme={{
                legends: {
                  text: {
                    fontSize: isSmallerThanXs ? 7.5 : 10
                  }
                }
              }}
              legends={[
                {
                  onClick: (node) => onSliceClick(node, searchType),
                  anchor: 'left',
                  direction: 'column',
                  itemWidth: 100,
                  itemHeight: 18,
                  itemTextColor: '#999',
                  itemDirection: 'left-to-right',
                  itemsSpacing: 0,
                  symbolSize: 12,
                  symbolShape: 'circle',
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemTextColor: '#000',
                      },
                    },
                  ],
                },
              ]}
              sliceLabel=""
              slicesLabelsSkipAngle={10}
              slicesLabelsTextColor="#333333"
              isInteractive
              onMouseEnter={ isSmallerThanXs ? undefined : (_, event) => {
                const target = event.target;
                target.style.cursor = 'pointer';
              }}
              onClick={ (node, event) => { event.preventDefault(); onSliceClick(node, searchType) }}
              enableArcLinkLabels={false}
            />
          </Box>
          {
            obsListLoading &&
            <Box height="225px" display="flex" flexDirection="row" justifyContent="center">
              <CircularProgress className={classes.progress} />
            </Box>
          }
          {
            !obsListLoading && obsList.length > 0 &&
            <ObservationSummaryChartInteractive
              {...props}
              obsList={obsList}
              refreshSummaryList={refreshSummaryList}
            />
          }
          {
            !obsListLoading && obsList.length === 0 &&
            <EmptyObservationsListMessaging />
          }
        </Box>
      </Paper>
    </Box>
  );
};

PieChart.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  obsState: PropTypes.object,
  searchType: PropTypes.string,
  obsList: PropTypes.array,
  obsListLoading: PropTypes.bool,
  onSliceClick: PropTypes.func,
  refreshSummaryList: PropTypes.func,
  handlePDFObservation: PropTypes.func,
  handleApproveObservation: PropTypes.func,
  handleDeleteObservation: PropTypes.func
}


const CalendarChart = (props) => {
  // ------------------------------------
  const { obsState } = props;

  // MUI HOOKS
  const theme = useTheme();
  const isSmallerThanXs = useMediaQuery(theme.breakpoints.down('xs'));

  const queryStringParameters = new URLSearchParams(useLocation().search);
  const monthAndYearSelection = config.getMonthAndYearSelectionFromUrlQueryString(queryStringParameters);
  let last12MonthsDateTime = DateTime.fromISO(monthAndYearSelection).setZone("Pacific/Auckland").startOf("month").minus({ months: 12 });
  let monthEndDateTime = DateTime.fromISO(monthAndYearSelection).setZone("Pacific/Auckland").endOf("month");

  const data = obsState.calendar.map(row => ({ day: row.day, value: row.value }))

  return (
    <Paper variant="outlined" style={{ height: '100%' }}>
      <Box display="flex" justifyContent="center" py="10px" px="25px">
        <Typography
          style={{ fontSize: isSmallerThanXs ? '1.2rem' : '1.5rem', textAlign: 'center' }}
          color="secondary"
        >
          {`Initia observations per day (last 12 months)`}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" height={350} px="10px">
        <ResponsiveCalendar
          data={data}
          from={last12MonthsDateTime.toFormat('yyyy-MM-dd')}
          to={monthEndDateTime.toFormat('yyyy-MM-dd')}
          emptyColor="#eeeeee"
          colors={[ '#d2ebfa', '#aedffc', '#59bcf7', '#0277bd' ]}
          margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
          yearSpacing={40}
          monthBorderColor="#ffffff"
          dayBorderWidth={2}
          dayBorderColor="#ffffff"
          legends={[
              {
                  anchor: 'bottom-right',
                  direction: 'row',
                  translateY: 36,
                  itemCount: 4,
                  itemWidth: 42,
                  itemHeight: 36,
                  itemsSpacing: 14,
                  itemDirection: 'right-to-left'
              }
          ]}
          direction={isSmallerThanXs ? 'vertical' : 'horizontal'}
      />
      </Box>
    </Paper>
  );
}
CalendarChart.propTypes = {
  classes: PropTypes.object,
  obsState: PropTypes.object,
  history: PropTypes.object,
}

const StatusBumpChart = (props) => {
  const { obsState, history } = props;

  const theme = useTheme();
  const isSmallerThanXs = useMediaQuery(theme.breakpoints.down('xs'));


  return (
    <Paper variant="outlined" style={{ height: '100%' }}>
      <Box display="flex" justifyContent="center" py="10px" px="25px">
        <Typography
          style={{ fontSize: isSmallerThanXs ? '1.2rem' : '1.5rem', textAlign: 'center' }}
          color="secondary"
        >
          {`Observation Status Pipeline (last 3 months)`}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" height={350} px="10px">
      <ResponsiveAreaBump
        data={obsState.bump}
        margin={{ top: 40, right: 100, bottom: 40, left: 30 }}
        spacing={20}
        colors={obsState.bump.map((d) => d.color)}
        blendMode="multiply"
        defs={[
            {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: '#38bcb2',
                size: 4,
                padding: 1,
                stagger: true
            },
            {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: '#eed312',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
            }
        ]}
        endLabel="label"
        axisTop={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendPosition: 'middle',
            legendOffset: -36
        }}
        onClick={(event) => {
          // --------------------------
          history.push(`/home?tabName=STATUS&status=${event.id.toLowerCase()}`);
        }}
        tooltip={(event) => {
          // --------------------------
          const { serie } = event;

          return (
            <Box style={{ border: '0.5px solid lightgrey', borderRadius: '3px', backgroundColor: 'white', padding: '3px' }} display="flex" flexDirection="column" alignItems="center">
              <Box display="flex" flexDirection="row" alignItems="center">
                <Box style={{ marginLeft: '5px', marginRight: '5px', marginTop: '2px', marginBottom: '2px', backgroundColor: serie.color, width: '8px', height: '8px' }}>
                </Box>
                <Typography style={{ fontSize: '0.8rem' }}>{serie.id}</Typography>
              </Box>
              {
                serie.data.data.map((data, index) => {
                  // --------------------------
                  return (
                    <Typography style={{ fontSize: '0.75rem' }} key={index}>{data.x}: <strong>{data.y}</strong></Typography>
                  )
                })
              }
            </Box>
          )
        }}
    />
      </Box>
    </Paper>
  );
};

StatusBumpChart.propTypes = {
  classes: PropTypes.object,
  obsState: PropTypes.object,
  history: PropTypes.object,
}


const getQueryAndParamsForSearchType = () => {
  // --------------------------
  const queryStringParameters = new URLSearchParams(window.location.search);
  const searchType = config.getMonthSummarySearchTypeFromUrlQueryString(queryStringParameters);

  switch (searchType) {
    case 'MONTH_STATUS_SEARCH':
      return {
        query: 'MONTH_STATUS_SEARCH',
        supportingParam: [
          {
            'name': 'status',
            'value': config.getMonthSummarySearchParamFromUrlQueryString(queryStringParameters)
          },
          {
            'name': 'date',
            'value': config.getMonthAndYearSelectionFromUrlQueryString(queryStringParameters)
          }
        ]
      }
    case 'MONTH_TYPE_SEARCH':
      return {
        query: 'MONTH_TYPE_SEARCH',
        supportingParam: [
          {
            'name': 'type',
            'value': config.getMonthSummarySearchParamFromUrlQueryString(queryStringParameters)
          },
          {
            'name': 'date',
            'value': config.getMonthAndYearSelectionFromUrlQueryString(queryStringParameters)
          }
        ]
      }
    default:
      return {
        query: null,
        supportingParam: null
      }
  }
}

const TopSummaryPanel = (props) => {
  const { classes, obsState } = props;

  const queryStringParameters = new URLSearchParams(useLocation().search);
  const monthAndYearSelection = config.getMonthAndYearSelectionFromUrlQueryString(queryStringParameters);

  const [personalDownloadLink, setPersonalDownloadLink] = useState('');
  const [orgDownloadLink, setOrgDownloadLink] = useState('');
  const [obsListState, setObsListState] = useState({
    statusList: [],
    statusListLoading: false,
    typeList: [],
    typeListLoading: false,
    searchType: null
  });

  // USE MSAL HOOK
  const { instance, accounts, inProgress } = useMsal();
  const loginHint = (accounts && accounts[0]?.username) ?? '';
  const request = {
    loginHint,
    scopes: ["User.Read"]
  }
  const isAuthenticated = useIsAuthenticated();
  const isAuthed = isAuthenticated && inProgress === "none" && isDefinedAndInitialized(accounts) && accounts.length > 0 && isDefinedAndInitialized(accounts[0]) && isDefinedAndInitialized(accounts[0].username);

  useEffect(async () => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      await instance.loginRedirect(request);
    }
  }, [isAuthenticated, inProgress, instance]);

  // Call the function if list changes
  useEffect(async () => {
    // ---------------------------------
    if (isAuthed) {
      await makeInitiaAndPersonalMonthSummaryCsv(accounts[0].name);
    }
    else {
      console.info('Not authenticated for this API action');
    }
  }, [monthAndYearSelection, isAuthed]);

  // Update the query if any present in the query strings
  useEffect(async () => {
    // ---------------------------------
    const searchType = config.getMonthSummarySearchTypeFromUrlQueryString(queryStringParameters);
    const { query, supportingParam } = getQueryAndParamsForSearchType();
    if (isAuthed) {
      // ---------------------------------
      // SET LOADING STATE
      setObsListState({
        statusList: [],
        statusListLoading: searchType === 'MONTH_STATUS_SEARCH',
        typeList: [],
        typeListLoading: searchType === 'MONTH_TYPE_SEARCH'
      });

      const res = await searchOLAP(query, supportingParam, { instance, accounts, inProgress });

      setObsListState({
        statusList: searchType === 'MONTH_STATUS_SEARCH' ? res.data : [],
        statusListLoading: false,
        typeList: searchType === 'MONTH_TYPE_SEARCH' ? res.data : [],
        typeListLoading: false,
        searchType
      });
    }
    else {
      console.info('Not authenticated for this API action');
    }
  }, [JSON.stringify(queryStringParameters)]);

  const onSliceClick = async (event, searchType) => {
    // ---------------------------------
    if (isAuthed) {
      // ---------------------------------
      // SET LOADING STATE
      setObsListState({
        statusList: [],
        statusListLoading: searchType === 'MONTH_STATUS_SEARCH',
        typeList: [],
        typeListLoading: searchType === 'MONTH_TYPE_SEARCH'
      });

      // UPDATE THE TYPE INTO THE QUERY STRING
      const type = ('label' in event) ? event.label.toLowerCase() : event.data.label.toLowerCase();
      let pathToPush = [
        `/home?tabName=MONTH_SUMMARY`, 
        `date=${config.getMonthAndYearSelectionFromUrlQueryString(queryStringParameters)}`, 
        `searchType=${searchType}`, 
        `searchParam=${type}`
      ].join('&');
      props.history.push(pathToPush);

      const { query, supportingParam } = getQueryAndParamsForSearchType();
      const res = await searchOLAP(query, supportingParam, { instance, accounts, inProgress });

      setObsListState({
        statusList: searchType === 'MONTH_STATUS_SEARCH' ? res.data : [],
        statusListLoading: false,
        typeList: searchType === 'MONTH_TYPE_SEARCH' ? res.data : [],
        typeListLoading: false,
        searchType
      });
    }
    else {
      console.info('Not authenticated for this API action');
    }
  };

  const obsSortFunction = (firstObs, secondObs) => {
    // ---------------------------------------------
    let diff = firstObs.jobId.localeCompare(secondObs.jobId);

    // If on the same date sort by
    if (diff === 0) {
      // ----------------------
      diff = Number(firstObs.inspectionTimestamp) - Number(secondObs.inspectionTimestamp);
    }
    return diff;
  }

  const getMonthObservations = async (userName) => {
    // ---------------------------------------------
    let res = await searchOLAP('MONTH_OBSERVATIONS', monthAndYearSelection, { instance, accounts, inProgress });

    return {
      personal: res.data.filter(observation => observation.header.createdBy.name === userName).map(observation => {
        return {
          jobId: observation.header.jobId,
          jobName: observation.header.jobName,
          observationNumber: observation.header.inspectionNumber,
          inspectionTimestamp: observation.header.inspectionTimestamp,
          createdBy: observation.header.createdBy.name,
          inspectionType: capitalizeFirstLetters(observation.header.inspectionType),
          observationDuration: isDefinedAndInitialized(observation?.header?.observationDuration) ? observation.header.observationDuration : '',
          client: observation.header.client,
          status: capitalizeFirstLetters(observation.header.status),
        }
      }).sort(obsSortFunction),
      initia: res.data.map(observation => {
        return {
          jobId: observation.header.jobId,
          jobName: observation.header.jobName,
          observationNumber: observation.header.inspectionNumber,
          inspectionTimestamp: observation.header.inspectionTimestamp,
          createdBy: observation.header.createdBy.name,
          inspectionType: capitalizeFirstLetters(observation.header.inspectionType),
          observationDuration: isDefinedAndInitialized(observation?.header?.observationDuration) ? observation.header.observationDuration : '',
          client: observation.header.client,
          status: capitalizeFirstLetters(observation.header.status),
        }
      }).sort(obsSortFunction)
    }
  }

  const makeInitiaAndPersonalMonthSummaryCsv = async (userName) => {
    // ----------------------------------------
    let calendarMonthObs = await getMonthObservations(userName);
    let personalCsvString = [
      `Job Number,Job Name,ObservationNumber,Observation Timestamp,Created By,Observation Type,Observation Duration,Client,Observation Status`,
      ...calendarMonthObs.personal.map(entry =>
        `${entry.jobId},"${entry.jobName}","${entry.observationNumber}","${DateTime.fromISO(entry.inspectionTimestamp).toFormat('dd/MM/yyyy hh:mm a')}","${entry.createdBy}","${entry.inspectionType}","${entry.observationDuration}","${entry.client}","${entry.status}"`)
    ].join('\n')
    let orgCsvString = [
      `Job Number,Job Name,ObservationNumber,Observation Timestamp,Created By,Observation Type,Observation Duration,Client,Observation Status`,
      ...calendarMonthObs.initia.map(entry =>
        `${entry.jobId},"${entry.jobName}","${entry.observationNumber}","${DateTime.fromISO(entry.inspectionTimestamp).toFormat('dd/MM/yyyy hh:mm a')}","${entry.createdBy}","${entry.inspectionType}","${entry.observationDuration}","${entry.client}","${entry.status}"`)
    ].join('\n')

    let personalCsv = new Blob([personalCsvString], { type: 'text/csv;charset=utf-8;' });
    let orgCsv = new Blob([orgCsvString], { type: 'text/csv;charset=utf-8;' });

    // this part avoids memory leaks
    if (personalDownloadLink !== '') window.URL.revokeObjectURL(personalDownloadLink);
    if (orgDownloadLink !== '') window.URL.revokeObjectURL(orgDownloadLink)

    // update the download link state
    setPersonalDownloadLink(window.URL.createObjectURL(personalCsv));
    setOrgDownloadLink(window.URL.createObjectURL(orgCsv));
  }

  const refreshSummaryList = async () => {
    // --------------------------------------
    if (isAuthed) {
      // ----------------------------------------
      const { query, supportingParam } = getQueryAndParamsForSearchType();
      const res = await searchOLAP(query, supportingParam, { instance, accounts, inProgress });
      setObsListState({
        statusList: obsListState.searchType === 'MONTH_STATUS_SEARCH' ? res.data : [],
        statusListLoading: false,
        typeList: obsListState.searchType === 'MONTH_TYPE_SEARCH' ? res.data : [],
        typeListLoading: false
      });
    }
    else {
      console.info('Not authenticated for this API action');
    }
  };


  const initiaValues = [
    {
      majorValue: obsState.org.total.count,
      majorSupportingText: 'observations to-date',
      minorValue: `${Math.round(obsState.org.total.avg)}`,
      minorSupportingText: 'per month on average',
    },
    {
      majorValue: obsState.org.month.last.count,
      majorSupportingText: 'this month',
      minorValue: '',
      minorSupportingText: '',

    },
  ];

  const userValues = [
    {
      majorValue: obsState.user.total.count,
      majorSupportingText: 'observations to-date',
      minorValue: `${Math.round(obsState.user.total.avg)}`,
      minorSupportingText: 'per month on average',
    },
    {
      majorValue: obsState.user.month.last.count,
      majorSupportingText: 'this month',
      minorValue: '',
      minorSupportingText: '',

    },
  ];

  const handleMonthChange = (e) => {
    // --------------------------------------------
    setObsListState({
      statusList: [],
      statusListLoading: false,
      typeList: [],
      typeListLoading: false,
      searchType: null
    });
    let pathToPush = [`/home?tabName=MONTH_SUMMARY`, `date=${DateTime.fromISO(e.target.value).startOf('month').toFormat('yyyy-MM')}`].join('&');
    props.history.push(pathToPush);
  };


  return (
    <>
      <Grid container>
        <Grid item xs={12} md={3}>
          <Box
            display="flex"
            flexDirection="column"
          >
            <Box
              mb="16px"
              ml="16px" >
              <Typography variant='subtitle1' color='secondary'>Select a month/year</Typography>
            </Box>
            <Box
              pb="8px"
              ml="12px" >
              <TextField
                id="month-summary-date-range-picker"
                label={"Month and Year"}
                type="month"
                defaultValue={monthAndYearSelection}
                variant="outlined"
                onChange={(e) => {
                  handleMonthChange(e);
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            display="flex"
            flexDirection="column"
          >
            <Box
              mb="16px"
              ml="40px" >
              <Typography variant='subtitle1' color='secondary'>Reports</Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              pb="8px"
              ml="32px" >
              <Box
                mr="10px" >
                <Button
                  variant="contained"
                  color="default"
                  className={classes.button}
                  startIcon={<GetAppIcon />}
                  download={`${monthAndYearSelection}-org-obs-summary.csv`}
                  href={orgDownloadLink}
                >
                  Initia Summary
                </Button>
              </Box>
              <Box
                mr="10px" >
                <Button
                  variant="contained"
                  color="default"
                  className={classes.button}
                  startIcon={<GetAppIcon />}
                  download={`${monthAndYearSelection}-personal-obs-summary.csv`}
                  href={personalDownloadLink}
                >
                  My Summary
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="center" alignItems="stretch">
        <Grid item xs={12} md={4} style={{ paddingTop: '3px', display: "flex", flexDirection: "column", justifyContent: 'space-between' }}>
          <Box display="flex" flexDirection="column" marginTop="3px" marginBottom="3px" mx="3px" paddingTop="3px">
            <Box flex={1} flexGrow={1} height="100%" marginBottom="1.5px">
              <StatTwoDigitSummary classes={classes} data={userValues} statType="personal" {...props} />
            </Box>
            <Box flex={1} flexGrow={1} height="100%" marginTop="1.5px">
              <StatTwoDigitSummary classes={classes} data={initiaValues} statType="initia" {...props} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box flex={1} paddingTop="9px" paddingBottom="3px" paddingRight="3px" height="100%">
            <StatusBumpChart {...props} />
          </Box>
        </Grid>
      </Grid>

      <Grid container direction="row" justifyContent="center" alignItems="stretch">
        <Grid item xs={12}>
          <Box>
            <CalendarChart {...props} />
          </Box>
        </Grid>
      </Grid>

      <Grid container direction="row" justifyContent="center" alignItems="stretch">
        <Grid item xs={12} md={6} >
          <Box paddingY="3px" paddingRight="3px">
            <PieChart
              {...props}
              handleApproveObservation={async (observationId, approvalStatus, notify = false, updateTimestamp, preRefreshFn) => {
                // ------------------------------------
                await props.handleApproveObservation(observationId, approvalStatus, notify, updateTimestamp, preRefreshFn);
                await refreshSummaryList();
              }}
              handleDeleteObservation={async (observationId, updateTimestamp, preRefreshFn) => {
                await props.handleDeleteObservation(observationId, updateTimestamp, preRefreshFn);
                await refreshSummaryList();
              }}
              handlePDFObservation={async (observationId, updateTimestamp, preRefreshFn) => {
                await props.handlePDFObservation(observationId, updateTimestamp, preRefreshFn);
                await refreshSummaryList();
              }}
              title="Observation status"
              searchType="MONTH_STATUS_SEARCH"
              obsList={obsListState.statusList}
              obsListLoading={obsListState.statusListLoading}
              onSliceClick={onSliceClick}
              refreshSummaryList={refreshSummaryList}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} >
          <Box paddingY="3px" paddingRight="3px">
            <PieChart
              {...props}
              handleApproveObservation={async (observationId, approvalStatus, notify = false, updateTimestamp, preRefreshFn) => {
                // ------------------------------------
                await props.handleApproveObservation(observationId, approvalStatus, notify, updateTimestamp, preRefreshFn);
                await refreshSummaryList();
              }}
              handleDeleteObservation={async (observationId, updateTimestamp, preRefreshFn) => {
                await props.handleDeleteObservation(observationId, updateTimestamp, preRefreshFn);
                await refreshSummaryList();
              }}
              handlePDFObservation={async (observationId, updateTimestamp, preRefreshFn) => {
                await props.handlePDFObservation(observationId, updateTimestamp, preRefreshFn);
                await refreshSummaryList();
              }}
              title="Observation types"
              searchType="MONTH_TYPE_SEARCH"
              obsList={obsListState.typeList}
              obsListLoading={obsListState.typeListLoading}
              onSliceClick={onSliceClick}
              refreshSummaryList={refreshSummaryList}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

TopSummaryPanel.propTypes = {
  classes: PropTypes.object,
  obsState: PropTypes.object,
  history: PropTypes.object.isRequired,
  handlePDFObservation: PropTypes.func,
  handleApproveObservation: PropTypes.func,
  handleDeleteObservation: PropTypes.func
}


const MonthSummary = (props) => {
  // -------------------------------------
  const queryStringParameters = new URLSearchParams(useLocation().search);
  const currentTabName = config.getTabNameFromUrlQueryString(queryStringParameters);
  const monthAndYearSelection = config.getMonthAndYearSelectionFromUrlQueryString(queryStringParameters);

  // MSAL
  const { instance, accounts, inProgress } = useMsal();
  const loginHint = (accounts && accounts[0]?.username) ?? '';
  const request = {
    loginHint,
    scopes: ["User.Read"]
  }
  const isAuthenticated = useIsAuthenticated();
  const isAuthed = isAuthenticated && inProgress === "none" && isDefinedAndInitialized(accounts) && accounts.length > 0 && isDefinedAndInitialized(accounts[0]) && isDefinedAndInitialized(accounts[0].username);

  // REACT QUERY
  // - Query client used for invalidation of queries
  const queryClient = useQueryClient();
  // 1) MONTH SUMMARY DATA
  const { isLoading: isOtherObsDataLoading, isError: isOtherObsDataError, data: otherObsData, error: otherObsDataError } = useQuery(
    ['otherObsList', 'MONTH_SUMMARY', monthAndYearSelection],
    async () => {
      const obsResultSet = await searchOLAP(currentTabName, monthAndYearSelection, { instance, accounts, inProgress });
      return obsResultSet.data;
    },
    {
      // AUTH ENABLEMENT
      enabled: isAuthed
        // TAB ENABLEMENT
        && isDefinedAndInitialized(currentTabName),
      refetchOnWindowFocus: false
    }
  );

  // EFFECTS
  useEffect(async () => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      await instance.loginRedirect(request);
    }
  }, [isAuthenticated, inProgress, instance]);

  useEffect(() => {
    // ---------------------------------------------------------------
    queryClient.invalidateQueries(['otherObsList', 'MONTH_SUMMARY', monthAndYearSelection]);
  }, [monthAndYearSelection, isAuthed]);

  // HANDLE LOADING STATE
  // MSAL
  if (
    inProgress !== "none"
    || isOtherObsDataLoading
  ) {
    return (
      <Box width="100%" display="flex" flexDirection="row" justifyContent="center" >
        <CircularProgress />
      </Box>
    )
  }

  // HANDLE ERROR STATE
  if (isOtherObsDataError) {
    return <span>An unexpected error has occurred with the job observation data {otherObsDataError}</span>
  }


  return (
    <Box>
      <TopSummaryPanel {...props} obsState={otherObsData} />
    </Box>
  );
};

MonthSummary.propTypes = {
  classes: PropTypes.object,
  obsState: PropTypes.object,
  handlePDFObservation: PropTypes.func,
  handleApproveObservation: PropTypes.func,
  handleDeleteObservation: PropTypes.func
}

export default (withStyles)(styles)(MonthSummary);
