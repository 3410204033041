import SiteSketch from '../components/siteSketch';
import DataTable from '../components/dataTable';
import Caption from '../components/caption';
import GeneralText from '../components/generalText';
import PileObservation from '../components/pileObservation';
import NonConformanceRegister from '../components/nonConformanceRegister';
import { WSTake5Comonent } from '../components/workAndSafety';
import FixedHeaderDataTable from '../components/fixedHeaderDataTable';

export const components = {
  // Observation React Components
  // --------------------------
  components: {
    siteSketch: SiteSketch,
    generalTable: DataTable,
    shearVaneTable: DataTable,
    cleggTable: DataTable,
    nonConformanceRegister: DataTable,
    healthAndSafetyTakeFive: DataTable,
    stockpileAssessmentTable: FixedHeaderDataTable,
    aialStockpileAssessmentTable: FixedHeaderDataTable,
    caption: Caption,
    photo: Caption,
    generalText: GeneralText,
    recommendation: GeneralText,
    heading: GeneralText,
    pileObservation: PileObservation,
    nonConformanceRegisterV2: NonConformanceRegister,
    workAndSafetyV1: WSTake5Comonent
  },
};
