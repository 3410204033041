import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { 
  Box, IconButton 
} from '@material-ui/core';
import AddCircle from '@material-ui/icons/AddCircle';

// Custom Components
// eslint-disable-next-line
import Row from './row';

const styles = () => ({
  button: {
    marginTop: '12px',
  },
});

function Table(props) {
  // ----------------------------------------------
  const { classes, header, onTableUpdate, onRowAdd, onRowDel, rows, tableType } = props;

  return (
    <Box>
      {
        rows.map((row, idx) =>
          (
            <Row
              key={row.id}
              idx={idx}
              tableType={tableType}
              onTableUpdate={onTableUpdate}
              row={row}
              onDelEvent={onRowDel}
              isHeader={false}
              header={header}
            />
          ))
      }
      <IconButton
        className={classes.button}
        aria-label="Add"
        onClick={onRowAdd}
        color="primary"
      >
        <AddCircle color="secondary" className="addButtonColour" fontSize="large" />
      </IconButton>
    </Box>
  );
}

Table.propTypes = {
  classes: PropTypes.object,
  id: PropTypes.string,
  onTableUpdate: PropTypes.func,
  onRowAdd: PropTypes.func,
  onRowDel: PropTypes.func,
  rows: PropTypes.array,
  header: PropTypes.array,
  tableType: PropTypes.string
}

export default withStyles(styles)(Table);
