import React, { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
import { useParams, withRouter } from 'react-router-dom';

// MUI
import { Grid, Box, Typography, Fab, CircularProgress, 
            Button, useTheme, useMediaQuery } from '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ArrowBack from '@material-ui/icons/ArrowBack';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

// REACT-PDF
// @ts-ignore
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

// LUXON
import { DateTime } from "luxon";

// CUSTOM COMPONENTS

// CUSTOM UTILITIES
import { isDefinedAndInitialized } from '../helpers/helpers';


const getTitleFromType = (type) => {
    // -------------------------------------
    switch (type) {
        case 'ncrSummary':
        default:
            return 'Job NCR Summary'
    }
}


const PlanPDFViewer = (props) => {
    // props
    const { url, pdfType } = props;
      
    // REACT ROUTER
    let { jobId } = useParams();

    // STATE
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [isPdfLoading, setIsPdfLoading] = useState(true);
    const [width, setWidth] = useState(100);

    // REF
    const pdfViewerRef = useRef();

    // DERIVED PROPS
    const title = getTitleFromType(pdfType);

    let downloadFileName = `${DateTime.local().toFormat('yyyyMMdd')} - ${jobId}${isDefinedAndInitialized(title) ? ` - ${title}` : ''}.pdf`;

    // MUI HOOKS
    const theme = useTheme();
    const isSmOrSmaller = useMediaQuery(theme.breakpoints.down('sm'));
  

    useEffect(() => {
        const resizeObserver = new ResizeObserver((event) => {
            // Depending on the layout, you may need to swap inlineSize with blockSize
            // https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserverEntry/contentBoxSize
            setWidth(event[0].contentBoxSize[0].inlineSize);
        });

        if (isDefinedAndInitialized(pdfViewerRef.current)) {
            resizeObserver.observe(pdfViewerRef.current);
        }

        return () => {
            if (isDefinedAndInitialized(pdfViewerRef.current)) {
                // ------------------------------------
                resizeObserver.unobserve(pdfViewerRef.current);
            } else {
                resizeObserver.disconnect();
            }
        }
    }, [pdfViewerRef, isPdfLoading]);

    // EVENT HANDLERS
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setPageNumber(1);
        setIsPdfLoading(false);
    }

    const changePage = (offset) => {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    const previousPage = () => {
        changePage(-1);
    }

    const nextPage = () => {
        changePage(1);
    }


    return (
        <Box ref={pdfViewerRef} width="100%" id="pdf-viewer-container">
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="100%" mb="15px">
                <Grid container>
                    <Grid item xs={false} sm={4} />
                    <Grid item xs={12} sm={4}>
                        <Box display="flex" flexDirection="column" style={{ width: '100%' }} my="10px" >
                            <a href={url} target="_blank" rel="noopener noreferrer" download={downloadFileName} style={{ textDecoration: 'none' }}>
                                <Button
                                    color="secondary"
                                    aria-label="download-ncr-summary"
                                    variant="contained"
                                    endIcon={ <CloudDownloadIcon /> }
                                    style={{ fontSize: isSmOrSmaller ? '0.8rem' : '1rem', width: '100%' }}
                                    >
                                    { 'Download' }
                                </Button>
                            </a>
                        </Box>
                    </Grid>
                    <Grid item xs={false} md={4} />
                </Grid>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="center" width="100%">
                <Document
                    file={url}
                    onLoadSuccess={onDocumentLoadSuccess}
                    loading={
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt="15px">
                            <Typography>Starting PDF viewer...</Typography>
                            <CircularProgress />
                        </Box>
                    }
                >
                    <Page
                        width={width * 0.9}
                        pageNumber={pageNumber}
                        loading={
                            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt="15px">
                                <Typography>Loading pages into PDF viewer...</Typography>
                                <CircularProgress />
                            </Box>
                        }
                    />
                </Document>
            </Box>
            <Box display="flex" flexDirection="row"
                justifyContent="center" alignItems="center"
                width="100%"
                style={{
                    position: "sticky",
                    bottom: (theme) => theme.spacing(10)
                }}
            >
                <Box display="flex" flexDirection="column"
                    justifyContent="center" alignItems="center"
                    p="10px"
                    style={{ backgroundColor: 'rgba(255,255,255,0.7)', borderRadius: "15px" }}>
                    <Box display="flex" flexDirection="row"
                        justifyContent="center" alignItems="center">
                        <Box mx="10px">
                            <Fab
                                color="primary"
                                aria-label="pdf-back"
                                onClick={() => previousPage()}
                                disabled={pageNumber === 1}
                            >
                                <ArrowBack />
                            </Fab>
                        </Box>
                        <Box>
                            <Typography>
                                Page {pageNumber} of {numPages}
                            </Typography>
                        </Box>
                        <Box mx="10px">
                            <Fab
                                color="secondary"
                                aria-label="pdf-forward"
                                onClick={() => nextPage()}
                                disabled={pageNumber === numPages}
                            >
                                <ArrowForward />
                            </Fab>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

PlanPDFViewer.propTypes = {
    url: PropTypes.string,
    pdfType: PropTypes.oneOf(['ncrSummary']),
}

export default withRouter(PlanPDFViewer);