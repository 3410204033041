import React, { useContext } from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";

// Material UI
import { withStyles } from "@material-ui/core/styles";
import { 
  Paper, Box, Grid, Divider, TextField, OutlinedInput, 
  Select, MenuItem, Typography, Stepper, Step, StepLabel,
  useTheme, Tooltip, IconButton,
  useMediaQuery
} from "@material-ui/core";

// Material Icons
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import CreateIcon from "@material-ui/icons/Create";
import HourglassFullIcon from "@material-ui/icons/HourglassFull";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import RefreshIcon from "@material-ui/icons/Refresh";


// Custom Config
import { config } from "../config/generalConfig";

// Context
import { InitiaContext } from "../context/initia-context";

// Custom Helpers
import { capitalizeFirstLetters, isDefinedAndInitialized } from "../helpers/helpers";

const styles = (theme) => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderTop: "1px solid rgba(224, 224, 224, 1)",
    marginTop: "0px",
  },
  secondTable: {
    marginTop: "10px",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  rowStyle: {
    paddingTop: "9.5px",
    paddingBottom: "9.5px",
    fontSize: "0.8rem",
    textAlign: "right",
  },
  select: {
    paddingTop: "9.5px",
    paddingBottom: "9.5px",
    fontSize: "13px",
    textAlign: "right",
  },
  stepper: {
    padding: 0,
  },
  stepLabelClass: {
    fontSize: "10px",
  },
  completedIcon: {
    color: "green",
  },
  pastIcon: {
    color: "grey",
  },
  onScheduleIcon: {
    color: "blue",
  },
  behindScheduleIcon: {
    color: "orange",
  },
  overdueIcon: {
    color: "red",
  }
});

const ImmutableCell = (props) => {
  const { classes, cellConfig, tableData } = props;
  const { parentStyle } = cellConfig;

  let setParentStyle = null;
  if (isDefinedAndInitialized(parentStyle)) {
    // ---------------------------------
    setParentStyle = parentStyle;
  }

  return (
    <Grid item key={`grid-${cellConfig.id}`} xs={12} md={6} style={ isDefinedAndInitialized(setParentStyle) ? setParentStyle : undefined }>
      <Box
        display="flex"
        height="100%"
        justifyContent="space-between"
        alignItems="center"
        borderTop={1}
        borderColor="rgb(224, 224, 224)"
        p="4px"
        fontSize="0.75rem"
      >
        <Box ml="20px">
          <Typography inline="true" variant="button" className={classes.rowStyle}>
            {cellConfig.name}
          </Typography>
        </Box>
        <Box my="5px" mr="20px">
          <Typography
            inline="true"
            className={classes.rowStyle}
            style={{
              color: cellConfig.color(tableData),
              fontWeight: cellConfig.fontWeight,
            }}
          >
            {cellConfig.value(tableData)}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

ImmutableCell.propTypes = {
  classes: PropTypes.object,
  cellConfig: PropTypes.object,
  tableData: PropTypes.object,
};

const TextFieldCell = (props) => {
  const {
    classes,
    cellConfig,
    tableData,
    handleClientObservationStateUpdate,
  } = props;

  return (
    <Grid item key={`grid-${cellConfig.id}`} xs={12} md={6}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        borderTop={1}
        borderColor="rgb(224, 224, 224)"
        p="4px"
        fontSize="0.75rem"
        height="100%"
      >
        <Box ml="20px">
          <Typography inline="true" variant="button" className={classes.rowStyle}>
            {cellConfig.name}
          </Typography>
        </Box>
        <Box
          width="50%"
          inline="true"
          style={{
            color: cellConfig.color(tableData),
            fontWeight: cellConfig.fontWeight,
          }}
          my="5px" mr="20px"
        >
          <TextField
            InputProps={{
              classes: { input: classes.rowStyle },
            }}
            fullWidth
            variant="outlined"
            value={cellConfig.value(tableData) || ""}
            onChange={(e) => {
              handleClientObservationStateUpdate(
                e,
                cellConfig.field,
                null,
                null
              );
            }}
            readOnly={cellConfig.readonly}
            disabled={cellConfig.readonly}
          />
        </Box>
      </Box>
    </Grid>
  );
};

TextFieldCell.propTypes = {
  classes: PropTypes.object,
  cellConfig: PropTypes.object,
  tableData: PropTypes.object,
  handleClientObservationStateUpdate: PropTypes.func,
};

const DropdownSelectFieldCell = (props) => {
  const {
    classes,
    cellConfig,
    tableData,
    handleClientObservationStateUpdate,
  } = props;

  return (
    <Grid item key={`grid-${cellConfig.id}`} xs={12} md={6}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        borderTop={1}
        borderColor="rgb(224, 224, 224)"
        height="100%"
        p="4px"
        fontSize="0.75rem"
      >
        <Box ml="20px">
          <Typography inline="true" variant="button" className={classes.rowStyle}>
            {cellConfig.name}
          </Typography>
        </Box>
        <Box
          width="50%"
          inline="true"
          style={{
            color: cellConfig.color(tableData),
            fontWeight: cellConfig.fontWeight,
          }}
          my="5px" mr="20px"
        >
          <Select
            classes={{
              select: classes.rowStyle,
            }}
            input={
              <OutlinedInput
                name={cellConfig.field}
                id={`outlined-inspection-type-${cellConfig.field}`}
              />
            }
            variant="outlined"
            value={cellConfig.value(tableData) || ""}
            onChange={(e) => {
              handleClientObservationStateUpdate(
                e,
                cellConfig.field,
                null,
                null
              );
            }}
          >
            {config[cellConfig.configRef]
              .filter((option) => option.status === "active")
              .map((option) => (
                <MenuItem
                  key={option.name}
                  classes={{ root: classes.rowStyle }}
                  value={option.name}
                >
                  {option.name}
                </MenuItem>
              ))}
          </Select>
        </Box>
      </Box>
    </Grid>
  );
};

DropdownSelectFieldCell.propTypes = {
  classes: PropTypes.object,
  cellConfig: PropTypes.object,
  tableData: PropTypes.object,
  handleClientObservationStateUpdate: PropTypes.func,
};

const DateTimePickerCell = (props) => {
  const {
    classes, cellConfig,
    tableData,
    handleClientObservationStateUpdate,
  } = props;

  return (
    <Grid item key={`grid-${cellConfig.id}`} xs={12} md={6}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        borderTop={1}
        borderColor="rgb(224, 224, 224)"
        p="4px"
        height="100%"
        fontSize="0.75rem"
      >
        <Box ml="20px">
        <Typography inline="true" variant="button" className={classes.rowStyle}>
          {cellConfig.name}
          </Typography>
        </Box>
        <Box
          inline="true"
          style={{
            color: cellConfig.color(tableData),
            fontWeight: cellConfig.fontWeight,
          }}
          my="5px" mr="20px"
        >
          <TextField
            id="datetime-local"
            label={cellConfig.name}
            type="datetime-local"
            value={cellConfig.value(tableData) || ""}
            InputProps={{
              classes: { input: classes.rowStyle },
            }}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            onChange={(e) => {
              if (e.target.value !== "") {
                handleClientObservationStateUpdate(
                  e,
                  cellConfig.field,
                  null,
                  null
                );
              }
            }}
            inputProps={{
              step: 900,
            }}
          />
        </Box>
      </Box>
    </Grid>
  );
};

DateTimePickerCell.propTypes = {
  classes: PropTypes.object,
  cellConfig: PropTypes.object,
  tableData: PropTypes.object,
  handleClientObservationStateUpdate: PropTypes.func,
};

const JobSummaryCell = (props) => {
  const { cellConfig } = props;

  switch (cellConfig.type) {
    case "TEXTFIELD":
      return <TextFieldCell {...props} />;
    case "MULTISELECT":
      return <DropdownSelectFieldCell {...props} />;
    case "DATETIME_PICKER":
      return <DateTimePickerCell {...props} />;
    case "BLANK":
      return "";
    case "IMMUTABLE":
    default:
      return <ImmutableCell {...props} />;
  }
};

JobSummaryCell.propTypes = {
  cellConfig: PropTypes.object,
};

const JobSummaryRow = (props) => {
  const {
    classes,
    rowConfig,
    tableData,
    handleClientObservationStateUpdate,
  } = props;

  return rowConfig.map((cell) => (
    <JobSummaryCell
      classes={classes}
      handleClientObservationStateUpdate={handleClientObservationStateUpdate}
      key={cell.id}
      cellConfig={cell}
      tableData={tableData}
    />
  ));
};

JobSummaryRow.propTypes = {
  classes: PropTypes.object,
  rowConfig: PropTypes.array,
  tableData: PropTypes.object,
  handleClientObservationStateUpdate: PropTypes.func,
};

function getSteps() {
  return config.statusTypes.map((statusType) =>
    capitalizeFirstLetters(statusType.name)
  );
}

const OnScheduleIcon = (props) => {
  // --------------------------------------
  return (
    <Box classes={props.classes}>
      <CreateIcon />
    </Box>
  );
};

OnScheduleIcon.propTypes = {
  classes: PropTypes.object,
  index: PropTypes.number,
};

const BehindScheduleIcon = (props) => {
  // --------------------------------------
  return (
    <Box classes={props.classes}>
      <HourglassEmptyIcon />
    </Box>
  );
};

BehindScheduleIcon.propTypes = {
  classes: PropTypes.object,
  index: PropTypes.number,
};

const OverdueIcon = (props) => {
  // --------------------------------------
  return (
    <Box classes={props.classes}>
      <AssignmentLateIcon />
    </Box>
  );
};

OverdueIcon.propTypes = {
  classes: PropTypes.object,
  index: PropTypes.number,
};

const CompletedIcon = (props) => {
  // --------------------------------------
  return (
    <Box classes={props.classes}>
      <CheckCircleIcon />
    </Box>
  );
};

CompletedIcon.propTypes = {
  classes: PropTypes.object,
  index: PropTypes.number,
};

const PendingIcon = (props) => {
  // --------------------------------------
  return (
    <Box classes={props.classes}>
      <HourglassFullIcon />
    </Box>
  );
};

PendingIcon.propTypes = {
  classes: PropTypes.object,
  index: PropTypes.number,
};

const getActiveStep = (observationPage) => {
  // ----------------------------------------
  return config.statusTypes.findIndex(
    (statusType) =>
      observationPage.observationEdit.header.status === statusType.name
  );
};

const assessObservationProgress = (observationPage) => {
  // ----------------------------------------
  // Initial Business Rules:
  // 1) Observation is behindSchedule if in 'draft' for over 24 hours (since the observation was carried out)
  // 2) Observation is behindSchedule if in 'review OR approved' for over 48 hours (since the observation was carried out)
  // 3) Observation is overdue if more than 72 hours (since the observation was carried out)

  let status = observationPage.observationEdit.header.status;

  // 'completed'
  if (status === "downloaded") {
    return "completed";
  }

  // 'behindSchedule'
  if (
    (status === "draft" &&
      DateTime.local().diff(
        DateTime.fromISO(
          observationPage.observationEdit.header.inspectionTimestamp
        ),
        ["hours"]
      ).hours > 24 &&
      DateTime.local().diff(
        DateTime.fromISO(
          observationPage.observationEdit.header.inspectionTimestamp
        ),
        ["hours"]
      ).hours < 72) ||
    (( status === "approved" || status === "review" ) &&
      DateTime.local().diff(
        DateTime.fromISO(
          observationPage.observationEdit.header.inspectionTimestamp
        ),
        ["hours"]
      ).hours > 48 &&
      DateTime.local().diff(
        DateTime.fromISO(
          observationPage.observationEdit.header.inspectionTimestamp
        ),
        ["hours"]
      ).hours < 72)
  ) {
    return "behindSchedule";
  }

  // 'overdue'
  if (
    DateTime.local().diff(
      DateTime.fromISO(
        observationPage.observationEdit.header.inspectionTimestamp
      ),
      ["hours"]
    ).hours > 72
  ) {
    return "overdue";
  }

  return "onSchedule";
};

const getStepperLabelDetail = (idx, activeIdx) => {
  // ----------------------------------------
  // Handle all past statuses
  if (idx < activeIdx + 1) {
    return capitalizeFirstLetters(config.statusTypes[idx].stepperPastDisplay);
  }
  return capitalizeFirstLetters(config.statusTypes[idx].stepperPendingDisplay);
};

const getStepperIconDetail = (
  idx,
  activeIdx,
  observationProgressAssessment
) => {
  // ----------------------------------------
  // Handle all completed observations
  if (observationProgressAssessment === "completed") {
    return {
      class: "completedIcon",
      icon: CompletedIcon,
      caption: "",
    };
  }

  // Handle all past statuses
  if (idx < activeIdx + 1) {
    // --------------------
    return {
      class: "pastIcon",
      icon: CompletedIcon,
      caption: "",
    };
  }

  // Handle OnSchedule observations
  if (observationProgressAssessment === "onSchedule" && idx === activeIdx + 1) {
    // --------------------
    return {
      class: "onScheduleIcon",
      icon: OnScheduleIcon,
      caption: "- In progress",
    };
  }
  if (observationProgressAssessment === "onSchedule" && idx > activeIdx + 1) {
    // --------------------
    return {
      class: "onScheduleIcon",
      icon: PendingIcon,
      caption: "",
    };
  }

  // Handle BehindSchedule observations
  if (
    observationProgressAssessment === "behindSchedule" &&
    idx === activeIdx + 1
  ) {
    // --------------------
    return {
      class: "behindScheduleIcon",
      icon: BehindScheduleIcon,
      caption: "- Behind Schedule",
    };
  }
  if (
    observationProgressAssessment === "behindSchedule" &&
    idx > activeIdx + 1
  ) {
    // --------------------
    return {
      class: "behindScheduleIcon",
      icon: PendingIcon,
      caption: "",
    };
  }

  // Handle Overdue observations
  if (observationProgressAssessment === "overdue" && idx === activeIdx + 1) {
    // --------------------
    return {
      class: "overdueIcon",
      icon: OverdueIcon,
      caption: "- Overdue!",
    };
  }
  if (observationProgressAssessment === "overdue" && idx > activeIdx + 1) {
    // --------------------
    return {
      class: "overdueIcon",
      icon: PendingIcon,
      caption: "",
    };
  }

  return {
    class: "onScheduleIcon",
    icon: OnScheduleIcon,
    caption: "",
  };
};

const getStatusChangeDate = (statusLabel, observationPage) => {
  // ---------------------------------------
  if (statusLabel.toLowerCase() === "draft") {
    return DateTime.fromISO(
      observationPage.observationEdit.header.inspectionTimestamp
    ).toFormat("dd/MM/yyyy hh:mm a");
  }
  if (
    statusLabel.toLowerCase() === "review" &&
    (observationPage.observationEdit.header?.lastReviewTimestamp ?? null)
  ) {
    return DateTime.fromISO(
      observationPage.observationEdit.header.lastReviewTimestamp
    ).toFormat("dd/MM/yyyy hh:mm a");
  }
  if (
    statusLabel.toLowerCase() === "review" &&
    !(observationPage.observationEdit.header?.lastReviewTimestamp ?? null)
  ) {
    return "-";
  }
  if (
    statusLabel.toLowerCase() === "approved" &&
    (observationPage.observationEdit.header?.lastApprovalTimestamp ?? null)
  ) {
    return DateTime.fromISO(
      observationPage.observationEdit.header.lastApprovalTimestamp
    ).toFormat("dd/MM/yyyy hh:mm a");
  }
  if (
    statusLabel.toLowerCase() === "approved" &&
    !(observationPage.observationEdit.header?.lastApprovalTimestamp ?? null)
  ) {
    return "-";
  }
  if (
    statusLabel.toLowerCase() === "downloaded" &&
    (observationPage.observationEdit.header?.lastPublishTimestamp ?? null)
  ) {
    return DateTime.fromISO(
      observationPage.observationEdit.header.lastPublishTimestamp
    ).toFormat("dd/MM/yyyy hh:mm a");
  }
  if (
    statusLabel.toLowerCase() === "downloaded" &&
    !(observationPage.observationEdit.header?.lastPublishTimestamp ?? null)
  ) {
    return "-";
  } else {
    return "-";
  }
};

const JobStatusStepper = (props) => {
  const { classes, observationPage } = props;

  const steps = getSteps();
  const activeStep = getActiveStep(observationPage);
  const obsProgressAssessment = assessObservationProgress(observationPage);

  // MUI HOOKS
  const theme = useTheme();
  const isSmallerThanXs = useMediaQuery(theme.breakpoints.down('xs'));


  return (
    <Stepper
      className={classes.stepper}
      activeStep={activeStep}
      alternativeLabel={ !isSmallerThanXs }
      orientation={ isSmallerThanXs ? "vertical" : "horizontal" }
    >
      {steps.map((label, idx) => {
        return (
          <Step key={label}>
            <StepLabel
              StepIconProps={{
                classes: {
                  root:
                    classes[
                      getStepperIconDetail(
                        idx,
                        activeStep,
                        obsProgressAssessment
                      ).class
                    ],
                },
                index: idx + 1
              }}
              StepIconComponent={
                getStepperIconDetail(idx, activeStep, obsProgressAssessment)
                  .icon
              }
              optional={
                <Box mb="12px">
                  <Typography variant="caption">{`${getStatusChangeDate(
                    label,
                    observationPage
                  )}`}</Typography>
                </Box>
              }
            >
              {`${getStepperLabelDetail(idx, activeStep)} ${
                getStepperIconDetail(idx, activeStep, obsProgressAssessment)
                  .caption
              }`}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

JobStatusStepper.propTypes = {
  classes: PropTypes.object,
  observationPage: PropTypes.object,
};

const JobSummaryTable = (props) => {
  const { classes, handleClientObservationStateUpdate, apiRefreshObservationJobData } = props;
  const { state } = useContext(InitiaContext);

  if (!state.observationPage.observationEdit) {
    return null;
  }

  const { inspectionId }  = state.observationPage.observationEdit;
  const data = state.observationPage;

  // Refactor 26/02/2021 -> want to add granular cell-wise config to make individual cells configurable
  // Mainly to permit a datetime picker for the inspection timestamp

  return (
    <Paper className={classes.root}>
      <Box textAlign="center" mb="20px">
        <Box
          className="title"
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
          alignItems="center"
        >
          <Box width="100%">
            <JobStatusStepper
              classes={classes}
              observationPage={state.observationPage}
            />
          </Box>
        </Box>
        <Divider variant="middle" />
        {window.config.ENV === "prod" ? (
          ""
        ) : (
          <Typography variant="h4" color="secondary">
            <strong>{`${window.config.ENV.toUpperCase()}`}</strong>
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="center">
        <Typography><strong>JOB INFORMATION</strong></Typography>
        <Tooltip title="Refresh job data from Salesforce">
          <IconButton
              onClick={() => apiRefreshObservationJobData(inspectionId)}
              size="small"
          >
              <RefreshIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Grid container>
        {config.jobSummaryTableCells.map((row) => (
          <JobSummaryRow
            classes={classes}
            handleClientObservationStateUpdate={
              handleClientObservationStateUpdate
            }
            key={`row-${row[0].id}-${row[1].id}`}
            rowConfig={row}
            tableData={data}
          />
        ))}
      </Grid>
    </Paper>
  );
};

JobSummaryTable.propTypes = {
  classes: PropTypes.object,
  handleClientObservationStateUpdate: PropTypes.func,
  apiRefreshObservationJobData: PropTypes.func
};

const JobSummaryTableExport = withStyles(styles)(JobSummaryTable);
const JobSummaryRowExport = withStyles(styles)(JobSummaryRow);

export {
  JobSummaryTableExport,
  JobSummaryRowExport
}
