import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

// Context
// eslint-disable-next-line
import { InitiaContext } from '../../context/initia-context';

// Custom Config
import { config } from '../../config/generalConfig';

// Custom Components
import EditableCell from './cell';


function Row(props) {
  const { tableType } = props;

  return (
    <tr className="eachRow">
      { props.row.values.map((cell, i) => {
        const tableFormat = config.dataTableTypes.find(tableTypeSearch => tableTypeSearch.tableType === tableType);
        const cellKey = `${props.row.id}-${i}`;

        return (
          <EditableCell
            key={cellKey}
            onTableUpdate={props.onTableUpdate}
            cellData={{
              type: tableFormat.columnValues[i].name,
              value: cell.value,
              id: `${props.row.id}-${i}`,
              placeholder: tableFormat.columnValues[i].placeholder,
            }}
          />
        );
      }) }
      <td className="del-cell">
        <IconButton
          size="medium"
          type="button"
          aria-label="Delete"
          onClick={(e) => { props.onDelEvent(e, props.row.id); }}
        >
          <DeleteIcon />
        </IconButton>
      </td>
    </tr>
  );
}

Row.propTypes = {
  row: PropTypes.object,
  tableType: PropTypes.string,
  onDelEvent: PropTypes.func,
  onTableUpdate: PropTypes.func
}

export default Row;
