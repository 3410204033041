import { isDefinedAndInitialized } from './helpers';

// Can calculate RL's (i.e. elevations) or should pile observation be presented in terms of depths
export const canCalculateRLs = (componentData) => {
  // ----------------------------------------------------------
  let { referenceLevel, pilingPlatformLevel, otherReferenceLevel } = componentData;

  if (referenceLevel === 'platform ground level' && isDefinedAndInitialized(pilingPlatformLevel)) {
    return true;
  }
  else if (referenceLevel === 'reference level' && isDefinedAndInitialized(otherReferenceLevel)) {
    return true;
  }
  return false;
}

// Calculate pile toe level
export const calculatePileToeLevel = (componentData) => {
  // ----------------------------------------------------------
  let { referenceLevel, pilingPlatformLevel, depthToBase, otherReferenceLevel } = componentData;

  if (referenceLevel === 'platform ground level' && isDefinedAndInitialized(pilingPlatformLevel) && isDefinedAndInitialized(depthToBase)) {
    return (Number(pilingPlatformLevel) - Number(depthToBase)).toFixed(1);
  }
  else if (referenceLevel === 'reference level' && isDefinedAndInitialized(otherReferenceLevel) && isDefinedAndInitialized(depthToBase)) {
    return (Number(otherReferenceLevel) - Number(depthToBase)).toFixed(1);
  }
  // If depth to base is defined, present template in terms of depths ONLY
  else if (isDefinedAndInitialized(depthToBase)) {
    return Number(depthToBase).toFixed(1);
  }
  else {
    return '';
  }
}

export const getMaxHeightInMetresForPileCanvas = (componentData) => {
  // ----------------------------------------------------------
  let { depthToBase, referenceLevel, pilingPlatformLevel, otherReferenceLevel, designToeLevel, observedGeology, observedGroundwater, pileCriteria } = componentData;
  let maxHeight = 0;

  // ----------------------------------------------------------------------------------------------------------------
  // (1) Start based on the pile shaft i.e. the difference between the reference/measurement level and the toe level (i.e. depth to base)
  // ----------------------------------------------------------------------------------------------------------------
  // If depthToBase is defined and greater than zero, set the depth to base as the maxHeight
  if (isDefinedAndInitialized(depthToBase) && depthToBase > 0) {
    maxHeight = Number(depthToBase);
  }

  // ----------------------------------------------------------------------------------------------------------------
  // (2) Adjust for when the design toe level is below the calculated toeLevel (based on the correct reference level)
  //        BUGFIX (21/11/2022) -> note: only applied where the pileCriteria includes the design toe level specification
  // ----------------------------------------------------------------------------------------------------------------
  let usePilingPlatformForToeLevel = (isDefinedAndInitialized(depthToBase) && depthToBase > 0
                                        && isDefinedAndInitialized(referenceLevel) && referenceLevel === 'platform ground level' && isDefinedAndInitialized(pilingPlatformLevel));
  let useOtherReferenceLevelForToeLevel = (isDefinedAndInitialized(depthToBase) && depthToBase > 0
                                        && isDefinedAndInitialized(referenceLevel) && referenceLevel === 'reference level' && isDefinedAndInitialized(otherReferenceLevel));
  if ( (usePilingPlatformForToeLevel || useOtherReferenceLevelForToeLevel)  && isDefinedAndInitialized(designToeLevel) && pileCriteria.includes('minimum toe level') ) {
    // --------------------------------------------
    if (usePilingPlatformForToeLevel) {
      // -----------------------------------------
      let asBuiltToeLevel = Number(pilingPlatformLevel) - Number(depthToBase);
      maxHeight = (Number(asBuiltToeLevel) > Number(designToeLevel)) ? maxHeight + ( Number(asBuiltToeLevel) - Number(designToeLevel) ) : maxHeight;
    }
    else if (useOtherReferenceLevelForToeLevel) {
      let asBuiltToeLevel = Number(otherReferenceLevel) - Number(depthToBase);
      maxHeight = (Number(asBuiltToeLevel) > Number(designToeLevel)) ? maxHeight + ( Number(asBuiltToeLevel) - Number(designToeLevel) ) : maxHeight;
    }
  }

  // ----------------------------------------------------------------------------------------------------------------
  // (3) If the maxHeight is still zero, take the max of the deepest geological observation/deepest groundwater observation
  // ----------------------------------------------------------------------------------------------------------------
  if ( maxHeight === 0 ) {
    // --------------------------------------------
    let maxGeoObsDepth = observedGeology.reduce((accumulator, current) => {
      if ( current.depth > accumulator ) { return current.depth }
      return accumulator;
    }, 0);

    let maxGroundwaterObsDepth = observedGroundwater.reduce((accumulator, current) => {
      if ( current.depth > accumulator ) { return current.depth }
      return accumulator;
    }, 0);

    maxHeight = Math.max(maxGeoObsDepth, maxGroundwaterObsDepth);
  }

  return maxHeight;
}

export const getDatum = (componentData) => {
  // ----------------------------------------------------------
  let { referenceLevel, pilingPlatformLevel, otherReferenceLevel } = componentData;

  if (referenceLevel === 'platform ground level' && isDefinedAndInitialized(pilingPlatformLevel)) {
    return {
      name: 'piling platform level',
      value: Number(pilingPlatformLevel)
    };
  }
  else if (referenceLevel === 'reference level' && isDefinedAndInitialized(otherReferenceLevel)) {
    return {
      name: 'reference level',
      value: Number(otherReferenceLevel)
    };
  }
  return {
    name: 'depth',
    value: 0
  };
}

export const shouldDrawDesignToeLevel = (componentData) => {
  // ----------------------------------------------------------
  let { designToeLevel, pileCriteria } = componentData;
  const datum = getDatum(componentData);

  return (isDefinedAndInitialized(designToeLevel) && datum.name !== 'depth' && pileCriteria.includes('minimum toe level'))
}

export const checkPileToeElevationValid = (componentData) => {
  // ----------------------------------------------------------
  let { designToeLevel, depthToBase } = componentData;
  const datum = getDatum(componentData);

  return (datum.value - Number(depthToBase)) <= Number(designToeLevel);
}

export const getPileShaftOffsetFromSurface = (componentData) => {
  // ----------------------------------------------------------
  let { referenceLevel, pilingPlatformLevel, otherReferenceLevel } = componentData;

  if (referenceLevel === 'reference level' && isDefinedAndInitialized(otherReferenceLevel) && isDefinedAndInitialized(pilingPlatformLevel)) {
    // -------------------------------------------------------
    return Number(otherReferenceLevel) - Number(pilingPlatformLevel);
  }
  else if (referenceLevel === 'reference level' && isDefinedAndInitialized(otherReferenceLevel)) {
    // -------------------------------------------------------
    // If the piling platform level is undefined, show on the preview a default of 0.5m below the ground surface
    return 0.5;
  }
  return 0;
}

export const getGeoLayerTopDepth = (componentData, index) => {
  // ----------------------------------------------------------
  let { observedGeology  } = componentData;

  const isFirstObservation = (index === 0);
  const hasCompanionDepth = (!isFirstObservation && isDefinedAndInitialized(observedGeology[(index - 1)].depth));

  // Handle the top layer conditionally on whether the reference level is used or the piling platform level
  if (isFirstObservation) {
    // ----------------------------------
    return getPileShaftOffsetFromSurface(componentData);
  }
  // Handle intermediate layers and last layer (that have an upper layer interface depth specified)
  else if (hasCompanionDepth) {
    // ----------------------------------
    return Number(observedGeology[(index - 1)].depth);
  }
  // Otherwise we don't have enough information so return 0
  else {
    // ----------------------------------
    return null;
  }
}

export const getGeoLayerBottomDepth = (componentData, observation, index) => {
  // ----------------------------------------------------------
  let { observedGeology, depthToBase } = componentData;

  const isLastObservation = (index === (observedGeology.length - 1));
  const hasOwnDepth = (isDefinedAndInitialized(observation.depth) && observation.depth !== '');

  // Handle the top layer conditionally on whether the reference level is used or the piling platform level
  if (hasOwnDepth) {
    // ----------------------------------
    return Number(observation.depth);
  }
  // Handle intermediate layers and last layer (that have an upper layer interface depth specified)
  else if (isLastObservation && isDefinedAndInitialized(depthToBase)) {
    // ----------------------------------
    return Number(depthToBase);
  }
  // Otherwise we don't have enough information so return 0
  else {
    // ----------------------------------
    return null;
  }
}

/**
 * Calculates the thickness of embedment material within the geological layers of a pile component.
 * It iteratively traverses the observedGeology array of the input data, summing the thickness of each layer that is comprised of embedment material.
 *
 * @export
 * @param {Object} componentData - The data describing the pile component.
 * @param {Object[]} componentData.observedGeology - An array of objects each representing a geological layer within the pile.
 * @param {string} componentData.observedGeology[].embedmentMaterial - Represents whether the geological layer is comprised of embedment material.
 * @param {string} componentData.observedGeology[].depth - Represents the depth to the base of the geological layer from the pile top (needs to be parsed from string).
 * @param {number} componentData.depthToBase - Represents the total pile length below the ground level.
 * 
 * The function handles the following cases:
 * - Case 1: Only one layer and its depth to the base is defined.
 * - Case 2: Only one layer and its depth to the base is not defined (depth to the base of the pile is used instead).
 * - Case 3: The first layer and its depth to the base is defined.
 * - Case 4: Not the first layer, but the depth to the base is defined and the depth to the base of the previous layer is defined.
 * - Case 5: The last layer, depth to the base of the previous layer is defined but the depth to the base of the current layer is not (depth to the base of the pile is used instead).
 *
 * @returns {number} - Returns the total thickness of embedment material within the pile.
 */
export const calculatedPileGeoEmbedmentThickness = (componentData) => {
  // ----------------------------------------------------------
  return componentData.observedGeology.reduce((accumulator, current, index) => {
    // ----------------------------------
    const { embedmentMaterial, depth } = current;
    const { depthToBase } = componentData;

    const isPileDepthDefined = isDefinedAndInitialized(depthToBase) && depthToBase !== '';
    const isFirstLayer = index === 0;
    const isLastLastLayer = index === (componentData.observedGeology.length - 1);
    const isLayerEmbedmentMaterial = embedmentMaterial;
    const isLayerDepthDefined = isDefinedAndInitialized(depth) && depth !== '';
    const isPrevLayerDepthDefined = index !== 0 && isDefinedAndInitialized(componentData.observedGeology[index - 1].depth) && componentData.observedGeology[index - 1].depth !== '';

    // Increase the embedment thickness if an embedment material is encountered
    let embedmentLayerThickness = accumulator;
    if (isLayerEmbedmentMaterial) {
      // ----------------------------------
      // Case 1: only 1 layer and it's depth to bottom is defined
      if (isFirstLayer && isLastLastLayer && isLayerDepthDefined) {
        // ----------------------------------
        return Number(depth);
      }
      // Case 2: only 1 layer and it's depth to bottom is not defined (depth to base of hole used instead)
      else if (isFirstLayer && isLastLastLayer && !isLayerDepthDefined) {
        // ----------------------------------
        return Number(depthToBase);
      }
      // Case 3: first layer and it's depth to bottom is defined
      else if (isFirstLayer && isLayerDepthDefined) {
        // ----------------------------------
        embedmentLayerThickness = accumulator + Number(depth);
      }
      // Case 4: (standard case) not the first layer BUT depth to bottom is defined AND depth to bottom of previous layer is defined
      else if (!isFirstLayer && isLayerDepthDefined && isPrevLayerDepthDefined) {
        // ----------------------------------
        embedmentLayerThickness = accumulator + (Number(depth) - Number(componentData.observedGeology[index - 1].depth));
      }
      // Case 5: last layer, depth to bottom of previous layer is defined BUT depth to bottom of subject layer is not (depth to base of hole used instead)
      else if (isLastLastLayer && isPrevLayerDepthDefined && !isLayerDepthDefined && isPileDepthDefined) {
        // ----------------------------------
        embedmentLayerThickness = accumulator + (Number(depthToBase) - Number(componentData.observedGeology[index - 1].depth));
      }
    }
    return embedmentLayerThickness;
  }, 0);
}

export const checkPileGeoEmbedmentValid = (componentData) => {
  // ----------------------------------------------------------
  const epsilon = 1e-10; // tolerance level for comparison
  let embedmentThickness = calculatedPileGeoEmbedmentThickness(componentData);

  return (embedmentThickness >= Number(componentData.designGeoEmbedment)) || (Math.abs(embedmentThickness - Number(componentData.designGeoEmbedment)) < epsilon);
}
