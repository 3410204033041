import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

// Custom Components
import NameLogo from '../images/official_logo.png';
import MapViewLogo from '../images/borehole_icon.png';
import { isDefinedAndInitialized } from '../helpers/helpers';

// Reference Tutorial
// https://dev.to/oieduardorabelo/react-hooks-how-to-create-and-update-contextprovider-1f68

// Make sure the shape of the default value passed to
// createContext matches the shape that the consumers expect!
const InitiaContext = createContext();

const observationInit = {
  observationEdit: null,
  ncrActive: false,
  sections: [],
  jobData: {
    siteAddress: {
      isShown: false,
      value: '',
    },
    weather: {
      isShown: false,
      value: '',
    },
    siteContacts: {
      isShown: false,
      value: '',
    },
  },
  jobSummaryTableData: {
    latitude: '',
    longitude: '',
    createdBy: '',
    observationType: '',
    observationDuration: ''
  },
  workAndSafety: null
};

const initialState = {
  // Logos
  // --------------------------
  splashLogo: NameLogo,
  simplifiedLogo: NameLogo,
  nameLogo: NameLogo,
  mapViewerLogo: MapViewLogo,
  // WelcomePage
  // --------------------------
  tabName: 'LAST_MONTH',
  jobList: {
    error: null,
    loading: true,
    data: [],
  },
  observationList: {
    error: null,
    loading: true,
    full: [],
    subset: [],
    limit: 100,
    exclusiveStartKeyId: null,
    exclusiveStartKeyComposite: null,
    scrolling: false,
  },

  // ObservationPage
  observationPage: observationInit,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'reset':
      // --------------------------
      return initialState;
    case 'updateJobAndObservationList':
      // --------------------------
      return {
        ...state,
        jobList: action.payload.jobList,
        observationList: action.payload.observationList,
        observationPage: observationInit,
      };
    case 'clearJobAndObservationList':
      // --------------------------
      return {
        ...state,
        jobList: { error: null, loading: true, data: [] },
        observationList: {
          error: null,
          loading: true,
          full: [],
          subset: [],
          limit: 100,
          exclusiveStartKeyId: null,
          exclusiveStartKeyComposite: null,
          scrolling: false },
        observationPage: observationInit,
      };
    case 'handleObservationListScroll':
      return {
        ...state,
        observationList: {
          ...state.observationList,
          scrolling: true,
        },
      };
    case 'appendToObservationList':
      return {
        ...state,
        observationList: {
          ...state.observationList,
          full: state.observationList.full.concat(action.payload.observations),
          subset: state.observationList.subset.concat(action.payload.observations),
          limit: action.payload.limit,
          exclusiveStartKeyId: action.payload.exclusiveStartKeyId,
          exclusiveStartKeyComposite: action.payload.exclusiveStartKeyComposite,
          scrolling: action.payload.scrolling,
        },
      };
    case 'updateObservationPage':
      return {
        ...state,
        observationPage: {          
          ...action.payload.observationUpdateState,
          workAndSafety: action.payload.workAndSafetyUpdateState
        },
      };
    case 'updateJobDataVisibility':
      return {
        ...state,
        observationPage: {
          ...state.observationPage,
          jobData: {
            ...state.observationPage.jobData,
            [action.payload.componentType]: {
              ...state.observationPage.jobData[action.payload.componentType],
              isShown: !state.observationPage.jobData[action.payload.componentType].isShown,
            },
          },
        },
      };
    case 'updateJobDataValue':
      return {
        ...state,
        observationPage: {
          ...state.observationPage,
          jobData: {
            ...state.observationPage.jobData,
            [action.payload.componentType]: {
              ...state.observationPage.jobData[action.payload.componentType],
              value: action.payload.value,
            },
          },
        },
      };
    case 'updateJobSummaryDataValue':
      return {
        ...state,
        observationPage: {
          ...state.observationPage,
          jobSummaryTableData: {
            ...state.observationPage.jobSummaryTableData,
            [action.payload.componentType]: action.payload.value,
          },
        },
      };
    case 'updateLocation':
      return {
        ...state,
        observationPage: {
          ...state.observationPage,
          jobSummaryTableData: {
            ...state.observationPage.jobSummaryTableData,
            latitude: action.payload.value.latitude,
            longitude: action.payload.value.longitude,
          },
        },
      };
    case 'updateInspectionTimestamp':
      return {
        ...state,
        observationPage: {
          ...state.observationPage,
          observationEdit: {
            ...state.observationPage.observationEdit,
            header: {
              ...state.observationPage.observationEdit.header,
              inspectionTimestamp: DateTime.fromISO(action.payload.value),
            },
          },
        },
      };
    case 'updateNcrVisibility':
      return {
        ...state,
        observationPage: {
          ...state.observationPage,
          observationEdit: {
            ...state.observationPage.observationEdit,
            header: {
              ...state.observationPage.observationEdit.header,
              ncrActive: action.payload.value,
            },
          },
        }
      }
    case 'addNewComponent':
      return {
        ...state,
        observationPage: {
          ...state.observationPage,
          sections: [...state.observationPage.sections, action.payload],
        },
      };
    case 'addNewComponentAtIdx':
      // INSERT THE NEW COMPONENT AT A PARTICULAR IDX
      return {
        ...state,
        observationPage: {
          ...state.observationPage,
          sections: [
            ...state.observationPage.sections.slice(0, action.payload.idx),
            action.payload.newComponent,
            ...state.observationPage.sections.slice(action.payload.idx)
          ]
        }
      };
    case 'updateSectionState':
      return {
        ...state,
        observationPage: {
          ...state.observationPage,
          sections: action.payload,
        },
      };
    case 'deleteComponent':
      return {
        ...state,
        observationPage: {
          ...state.observationPage,
          sections: action.payload,
        },
      };
    case 'clearObservation':
      return {
        ...state,
        observationPage: observationInit,
      };
    case 'changeTabAndUpdateJobAndObservationList':
      // --------------------------
      return {
        ...state,
        tabName: action.payload.tabName,
        jobList: action.payload.jobList,
        observationList: action.payload.observationList,
        observationPage: observationInit,
      };
    case 'updateWsData': {
      // --------------------------
      const isPayloadDefined = isDefinedAndInitialized(action.payload);
      const workAndSafetyUpdate = isPayloadDefined ? action.payload : null;
      return {
        ...state,
        observationPage: {
          ...state.observationPage,
          observationEdit: {
            ...state.observationPage.observationEdit,
            header: {
              ...state.observationPage.observationEdit.header,
              wsPlanId: isPayloadDefined ? action.payload.planId : null,
              wsPrestartId: isPayloadDefined ? action.payload.id : null
            },
          },
          workAndSafety: workAndSafetyUpdate
        },
      };
    }
    case 'updateWsHazardPresentation': {
      // --------------------------
      return {
        ...state,
        observationPage: {
          ...state.observationPage,
          observationEdit: {
            ...state.observationPage.observationEdit,
            header: {
              ...state.observationPage.observationEdit.header,
              hazardObservationDisplayIds: action.payload 
            }
          }
        }
      };
    }
    default:
      // --------------------------
      return state;
  }
};

const InitiaContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };

  return (
    <InitiaContext.Provider value={value}>{props.children}</InitiaContext.Provider>
  );
};

InitiaContextProvider.propTypes = {
  children: PropTypes.object
}

const InitiaContextConsumer = InitiaContext.Consumer;

export { InitiaContext, InitiaContextProvider, InitiaContextConsumer };
