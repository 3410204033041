// React
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// MASL React
import { InteractionStatus } from "@azure/msal-browser";
import { useMsal, useIsAuthenticated, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

// Material UI
import { Container, Paper, Box, Typography } from '@material-ui/core';

// CUSTOM UTILITIES
import { isDefinedAndInitialized } from '../helpers/helpers';

const DefaultUnauthenticatedContent = () => {
  const environmentLabel = (window.config.ENV !== 'prod') ? window.config.ENV.toUpperCase() : '';

  return (
    <Container maxWidth="md">

      {/* MAIN CONTENT */}
      <Box>
        <Paper>
          <Box p={2}>
            {
              isDefinedAndInitialized(window?.config?.ENV) &&
              window.config.ENV === 'sandpit' &&
              <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Typography color="secondary">{environmentLabel}<br/></Typography>
              </Box>
            }
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mb="50px">
              <Typography variant="h4" color="secondary"><strong>{window.config.SYS_NAME_DISPLAY}</strong></Typography>
            </Box>
            <Box p={2} display="flex" flexDirection="column" justifyContent="center" alignItems="center" mb="50px">
              <Typography variant="h5" color="secondary">Signing you in</Typography>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Container>
  )
}

const ProtectedContent = ({ children, unauthenticatedContent }) => {
  // -------------------------------------------------
  
  const unauthenticatedView = ( typeof unauthenticatedContent !== 'undefined' ) ? unauthenticatedContent : <DefaultUnauthenticatedContent />;
  
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const loginHint = (accounts[0]?.idTokenClaims)?.preferred_username ?? '';

  // EFFECTS
  useEffect(async () => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      await instance.loginRedirect({
        loginHint,
        scopes: ["User.Read"]
      });
    }
  }, [isAuthenticated, inProgress, instance]);

  return (
    <>
      <AuthenticatedTemplate>
        { children }
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        { unauthenticatedView }
      </UnauthenticatedTemplate>
    </>
  )
}

ProtectedContent.propTypes = {
  children: PropTypes.object,
  unauthenticatedContent: PropTypes.object
}



export default ProtectedContent;
