import { DateTime } from "luxon";

export const isDefinedAndInitialized = (value) => {
  return (value === false || (value ?? false) || value === 0 || value === '') ? true : false;
}


// This function searches an object's top-level keys for any
// containing a particular substring
// Parameters:
//  - searchObject [the object to be searched]
//  - searchSubstring [the substring for which to search for]
// Returns:
//  - a list of matching object keys
export const getMatchingKeys = (searchRegex, searchObject) => {
  const keyList = Object.keys(searchObject)
    .filter(key => searchRegex.test(key));

  return keyList;
};

export const hasOwnProperty = (obj, prop) => {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

export const capitalizeFirstLetters = text => text.toLowerCase()
  .split(' ')
  .map(s => s.charAt(0).toUpperCase() + s.substring(1))
  .join(' ');

export const standardiseString = (value) => {
  return value.trim().toLowerCase();
}

export const prepNullableFieldToString = (value) => {
  return isDefinedAndInitialized(value) ? value : "";
}

export const convertCamelToSpaced = (text) => {
  const words = text.match(/[A-Za-z0-9][a-z0-9]*/g) || [];
  return words.join(' ');
};

export const dataURIToBlob = (dataurl) => {
  var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], {type:mime});
}

// Timeout function useful for mocking API calls/asynchronous code
export const timeout = ms => new Promise(resolve => setTimeout(resolve, ms));

export const removeTimezoneFromDatetimeString = (datetimeString) => {
  // ---------------------------------
  // Assume this takes in a ISO datetime string. Want to remove the timezone indicator
  if (datetimeString.includes('+')) { return datetimeString.split('+')[0]; }
  return datetimeString;
};

export const formatValueAsDefinedStringOrNull = (string) => {
  return (string && string !== '') ? string : null
}


export const shouldUseUserGroupCache = () => {
  // ---------------------------------
  const userGroupsInLocalStorage = localStorage.getItem('siteObservationsUserGroups');
  const userGroupsRefreshTimestampInLocalStorage = localStorage.getItem('siteObservationsUserGroupsRefreshTimestamp');

  return isDefinedAndInitialized(userGroupsInLocalStorage)
          && isDefinedAndInitialized(userGroupsRefreshTimestampInLocalStorage)
          && DateTime.local().diff(DateTime.fromSeconds(Number(userGroupsRefreshTimestampInLocalStorage)), 'days').toObject().days < 1
}

export const shouldUseCognitoCache = () => {
  // ---------------------------------
  const tempCognitoInLocalStorage = localStorage.getItem('tempCognito');
  const tempCognitoExpiryInLocalStorage = localStorage.getItem('tempCognitoExpiry');

  // Use Cognito cache up until within 1 minute of expiry (OR LESS)
  return isDefinedAndInitialized(tempCognitoInLocalStorage)
          && isDefinedAndInitialized(tempCognitoExpiryInLocalStorage)
          && DateTime.fromISO(tempCognitoExpiryInLocalStorage).diff(DateTime.local(), 'minutes').toObject().minutes > 1
}

export const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null
}


/**
 * Function to replace unsupported characters commonly mistakenly included in S3 path strings
 * @param {string} text - the text which potentially includes unsupported characters
 * @returns {string} the text with the unsupported characters replaced by a replacement character (default '_')
 * @example
 *     s3ReplaceUnsupportedChars('text', '_')
 */
 export const s3ReplaceUnsupportedChars = (text, replacementChar= '_') => {
  // -------------------------------------
  // Replaces all S3 sensitive characters from a provided string with underscores (or another input character)
  return text.replace(/&/g, replacementChar)
  .replace(/’/g, replacementChar)
  .replace(/–/g, replacementChar)
  .replace(/%/g, replacementChar)
  .replace(/\$/g, replacementChar)
  .replace(/@/g, replacementChar)
  .replace(/=/g, replacementChar)
  .replace(/;/g, replacementChar)
  .replace(/:/g, replacementChar)
  .replace(/\+/g, replacementChar)
  .replace(/ /g, replacementChar)
  .replace(/,/g, replacementChar)
  .replace(/\?/g, replacementChar)
  .replace(/\(/g, replacementChar)
  .replace(/\)/g, replacementChar);
}