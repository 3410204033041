import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, withRouter } from 'react-router-dom';

// MSAL imports
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from '@azure/msal-browser';

// Material UI
import { Box, Paper, Container, Modal, CircularProgress, useTheme, useMediaQuery } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

import {
  useQueryClient
} from 'react-query';

// Custom Components
import SearchObservationByStatusComponent from '../components/searchObservationByStatusComponent';
import ObservationSummaryList from '../components/observationSummaryList';
import MonthSummary from '../components/monthSummary';
import BasicLoader from '../components/basicLoader';

// CUSTOM COMPONENTS - SALESFORCE INTEGRATIONS
import SalesforceCreateObservationBar from '../components/salesforceCreateObservationBar';
import SalesforceSearchObservationByJobComponent from '../components/salesforceSearchObservationByJobComponent';

// CUSTOM SERVICES AND HELPERS
import { putObservation, deleteObservation,
  pollForObservationUpdateTimestampChange, downloadPdf } from '../services/api';
import { isDefinedAndInitialized } from '../helpers/helpers';

// Custom Config
import { config } from '../config/generalConfig';

const snackBarAutoHide = 2000;

const styles = theme => ({
  root: {
    flexGrow: '1',
    display: 'flex', 
    flexDirection: 'column',
    width: '100%',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  tabNav: {
    backgroundColor: 'white',
    boxShadow: 'none'
  },
  tabPanel: {
    padding: '0px',
    flexGrow: 1,
    display: "flex",
    flexDirection: "column"
  },
  modal: {
    color: '#FFF',
    transform: 'translate(-50%, -50%)',
  }
});


function TabPanel(props) {
  const { children, value, index, classes, ...other } = props;

  return value === index ?
    (
      <Box
        sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        <Box className={classes.tabPanel}>
          {children}
        </Box>
      </Box>
    )
    :
    <></>
}

TabPanel.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.oneOfType([ PropTypes.object, PropTypes.array, PropTypes.bool ]),
  value: PropTypes.number,
  index: PropTypes.number,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const StaticQueryObsList = (props) => {
  // ----------------------------------------
  let { classes, index, queryStringParameters,
        nonClassProps, handlePDFObservation,
        handleApproveObservation, handleDeleteObservation } = props;

  return (
    <TabPanel classes={classes} value={config.getTabValueFromUrlQueryString(queryStringParameters)} index={index}>
      <ObservationSummaryList
        {...nonClassProps}
        handlePDFObservation={handlePDFObservation}
        handleApproveObservation={handleApproveObservation}
        handleDeleteObservation={handleDeleteObservation}
      />
    </TabPanel>
  )
}


StaticQueryObsList.propTypes = {
  classes: PropTypes.object,
  index: PropTypes.number,
  queryStringParameters: PropTypes.object,
  nonClassProps: PropTypes.object,
  handlePDFObservation: PropTypes.func,
  handleApproveObservation: PropTypes.func,
  handleDeleteObservation: PropTypes.func,
}

const SingleSearchFieldList = (props) => {
  // ----------------------------------------
  let { classes, index, queryStringParameters,
    nonClassProps, handlePDFObservation,
    handleApproveObservation, handleDeleteObservation } = props;

    return (
      <TabPanel classes={classes} value={config.getTabValueFromUrlQueryString(queryStringParameters)} index={index}>
        <SalesforceSearchObservationByJobComponent 
          {...nonClassProps}
          handlePDFObservation={handlePDFObservation}
          handleApproveObservation={handleApproveObservation}
          handleDeleteObservation={handleDeleteObservation} 
        />
      </TabPanel>
    )
}


SingleSearchFieldList.propTypes = {
  classes: PropTypes.object,
  index: PropTypes.number,
  queryStringParameters: PropTypes.object,
  nonClassProps: PropTypes.object,
  handlePDFObservation: PropTypes.func,
  handleApproveObservation: PropTypes.func,
  handleDeleteObservation: PropTypes.func,
}


const MultiSelectList = (props) => {
  // ----------------------------------------
  let { classes, index, queryStringParameters,
    nonClassProps,
    handlePDFObservation,
    handleApproveObservation, handleDeleteObservation } = props;

    return (
      <TabPanel classes={classes} value={config.getTabValueFromUrlQueryString(queryStringParameters)} index={index}>
        <SearchObservationByStatusComponent
          {...nonClassProps}
          currentStatuses={config.getStatusesFromUrlQueryString(queryStringParameters)}
          handlePDFObservation={handlePDFObservation}
          handleApproveObservation={handleApproveObservation}
          handleDeleteObservation={handleDeleteObservation}
        />
      </TabPanel>
    )
}


MultiSelectList.propTypes = {
  classes: PropTypes.object,
  index: PropTypes.number,
  queryStringParameters: PropTypes.object,
  nonClassProps: PropTypes.object,
  handlePDFObservation: PropTypes.func,
  handleApproveObservation: PropTypes.func,
  handleDeleteObservation: PropTypes.func,
}

const MonthSummaryTabPanel = (props) => {
  // ----------------------------------------
  let { classes, index, queryStringParameters,
    nonClassProps,
    handlePDFObservation,
    handleApproveObservation, handleDeleteObservation } = props;

    return (
      <TabPanel classes={classes} value={config.getTabValueFromUrlQueryString(queryStringParameters)} index={index}>
        <MonthSummary
          {...nonClassProps}
          handlePDFObservation={handlePDFObservation}
          handleApproveObservation={handleApproveObservation}
          handleDeleteObservation={handleDeleteObservation}
        />
      </TabPanel>
    )
}


MonthSummaryTabPanel.propTypes = {
  classes: PropTypes.object,
  index: PropTypes.number,
  queryStringParameters: PropTypes.object,
  nonClassProps: PropTypes.object,
  handlePDFObservation: PropTypes.func,
  handleApproveObservation: PropTypes.func,
  handleDeleteObservation: PropTypes.func,
}


function WelcomePage(props) {
  // PROPS
  const { classes, ...nonClassProps } = props;

  // STATE
  const [pdfModalOpen, setPdfModalOpen] = useState(false);

  // MUI HOOKS
  const theme = useTheme();
  const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('sm'));

  // USE MSAL HOOK
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const loginHint = (accounts && accounts[0]?.username) ?? '';
  const request = {
    loginHint,
    scopes: ["User.Read"]
  }

  const personName = accounts[0]?.idTokenClaims?.name ?? '';
  const isAuthed = isAuthenticated && inProgress === "none" && isDefinedAndInitialized(accounts) && accounts.length > 0 && isDefinedAndInitialized(accounts[0]) && isDefinedAndInitialized(accounts[0].username);

  // REACT QUERY
  // - Query client used for invalidation of queries
  const queryClient = useQueryClient();

  // NOTISTACK
  const { enqueueSnackbar } = useSnackbar();

  // QUERY STRING PARAMETERS
  let queryStringParameters = new URLSearchParams(useLocation().search);
  let currentTabName = config.getTabNameFromUrlQueryString(queryStringParameters);
  const jobId = config.getJobIdFromUrlQueryString(queryStringParameters);
  const statuses = config.getStatusesFromUrlQueryString(queryStringParameters);

  // EFFECTS
  useEffect(async () => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      await instance.loginRedirect(request);
    }
  }, [isAuthenticated, inProgress, instance]);

  const refreshObservations = () => {
    // ---------------------------------------------------------------
    if (currentTabName === 'JOB') {
      if (isDefinedAndInitialized(jobId)) {
        queryClient.invalidateQueries(['jobObsList']);
      }
    }
    else if (currentTabName === 'STATUS') {
      if (isDefinedAndInitialized(statuses)) {
        queryClient.invalidateQueries(['statusObsList']);
      }
    }
    else {
      queryClient.invalidateQueries(['otherObsList']);
      queryClient.invalidateQueries(['jobObsList']);
      queryClient.invalidateQueries(['statusObsList']);
    }
  };


  // EVENT HANDLERS
  const handlePDFObservation = (observationId, updateTimestamp, preRefreshFn) => {
    if (isAuthed) {
      // ------------------------------------
      handlePdfModalOpen();
      enqueueSnackbar('Downloading observation', { variant: 'info', autoHideDuration: snackBarAutoHide });
      return downloadPdf(observationId, { instance, accounts, inProgress }, handlePdfModalClose)
        .then(() => {
          pollForObservationUpdateTimestampChange(observationId, updateTimestamp, { instance, accounts, inProgress }, true);
        })
        .then(() => {
          if (preRefreshFn) { return preRefreshFn(); }
          return '';
        })
        .then(() => refreshObservations())
        .catch(() => {
          enqueueSnackbar('PDF download failed', { variant: 'error', autoHideDuration: snackBarAutoHide + 2000 });
          handlePdfModalClose();
        });
    }
    else {
      console.info('Not authenticated for this API action');
    }
  };

  const handlePdfModalOpen = () => {
    setPdfModalOpen(true);
  };

  const handlePdfModalClose = () => {
    setPdfModalOpen(false);
  };

  const handleApproveObservation = async (observationId, approvalStatus, notify=false, updateTimestamp, preRefreshFn = null) => {
    if (isAuthed) {
      // ----------------------------------------------
      enqueueSnackbar(`Updating observation status to ${approvalStatus}`,
        { variant: 'info', autoHideDuration: snackBarAutoHide });

      try {
        await putObservation({ inspectionId: observationId }, approvalStatus, false, false, notify, { instance, accounts, inProgress });
        await pollForObservationUpdateTimestampChange(observationId, updateTimestamp, { instance, accounts, inProgress }, true);
        if (preRefreshFn) { await preRefreshFn(); }
        await refreshObservations()
        enqueueSnackbar(`Observation updated to ${approvalStatus}`, { variant: 'success', autoHideDuration: snackBarAutoHide });
      }
      catch (err) {
        console.error(err);
        enqueueSnackbar('Observation approval failed', { variant: 'error', autoHideDuration: snackBarAutoHide + 2000 });
      }
    }
    else {
      console.info('Not authenticated for this API action');
    }
  };

  const handleDeleteObservation = (observationId, updateTimestamp, preRefreshFn) => {
    // -------------------------------------------------
    if (isAuthed) {
      // -----------------------------
      enqueueSnackbar('Deleting observation', { variant: 'info', autoHideDuration: snackBarAutoHide });
      return deleteObservation(observationId, { instance, accounts, inProgress })
        .then(() => {
          enqueueSnackbar('Observation deleted successfully', { variant: 'success', autoHideDuration: snackBarAutoHide });
        })
        .then(() => pollForObservationUpdateTimestampChange(observationId, updateTimestamp, { instance, accounts, inProgress }, true))
        .then(() => {
          if (preRefreshFn) { return preRefreshFn(); }
          return '';
        })
        .then(() => refreshObservations())
        .catch((err) => {
          // ---------------------------------
          // 404 expected from detail call once observation deleted
          if (err.message !== 'Error in request: 404, {}') {
            console.error(err.message);
            enqueueSnackbar('Observation deletion failed', { variant: 'error', autoHideDuration: snackBarAutoHide + 2000 });
          }
          else {
            refreshObservations();
          }
        });
    }
    else {
      console.info('Not authenticated for this API action');
    }
  };

  const handleTabChange = async (event, newValue) => {
    // ----------------------------
    props.history.push(`/home?tabName=${Object.keys(config.welcomePageTabs)[newValue]}${config.welcomePageTabs[Object.keys(config.welcomePageTabs)[newValue]].defaultQuery}`);
  };

 
  return (
    <Container maxWidth="md" style={{ flexGrow: '1', display: 'flex', flexDirection: 'column' }}>

      {/* DOWNLOADING OBSERVATION OVERLAY MODAL */}
      <Modal
        open={pdfModalOpen}
        onClose={handlePdfModalClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box className={classes.modal} top='50%' left='50%' position='absolute' display="flex" alignItems="center" flexDirection="column">
          <CircularProgress />
          <Typography>Downloading your observation...</Typography>
        </Box>
      </Modal>

      {/* MAIN CONTENT */}
      <Box flexGrow="1" display="flex" flexDirection="column">
        <Paper className={classes.root}>

          {/* TITLE BLOCK */}
          <Box>
            <Box style={{ color: '#1976d2', paddingLeft: '20px' }}>
              <Typography style={{ fontSize: isSmallerThanSm ? '1.5rem' : '1.6rem', textAlign: 'center' }}><strong>Site Observations</strong></Typography>
              <Typography style={{ fontSize: isSmallerThanSm ? '1.1rem' : '1.2rem', textAlign: 'center' }}>{personName}</Typography>
              {(window.config.ENV === 'prod') ? '' : <Typography color="secondary" style={{ textAlign: 'center' }}><br/><strong>{`${window.config.ENV.toUpperCase()}`}</strong></Typography>}
            </Box>
          </Box>

          {/* CREATE OBSERVATION INPUT/AUTOSUGGEST */}
          {/* NOTE: updated to integrate react-query 06/2022 */}
          {
            !isAuthed &&
            <BasicLoader />
          }
          {
            isAuthed &&
            <SalesforceCreateObservationBar {...nonClassProps} />
          }

          {/* WELCOME PAGE TAB NAVIGATION */}
          {/* NOTE: no data dependencies - static config only */}
          <AppBar className={classes.tabNav} position="static">
            <Tabs
              value={config.getTabValueFromUrlQueryString(queryStringParameters)}
              onChange={handleTabChange}
              aria-label="scrollable welcome page tabs"
              scrollButtons="auto"
              variant="scrollable"
            >
              {Object.keys(config.welcomePageTabs).filter(tabName => tabName !== 'DEFAULT').map((tabName, idx) => {
                return (
                  <Tab key={`${tabName}-tab`} label={config.welcomePageTabs[tabName].display} {...a11yProps(idx)}  />
                )
              })}
            </Tabs>
          </AppBar>

          {/* WELCOME PAGE TAB PANELS */}
          {/* For all configured tabs (excluding the default which is a duplicate of the index tab) render the tab panel */}
          {
            !isAuthed &&
            <BasicLoader />
          }
          {
            isAuthed &&
            Object.keys(config.welcomePageTabs).filter(tabName => tabName !== 'DEFAULT').map((tabName, idx) => {

              switch(config.welcomePageTabs[tabName].type) {
                // STATIC QUERY, i.e. no dynamic changes to the networked query on this tab 
                // (e.g. recent observations)
                case 'STATIC_QUERY':
                  return (
                    <StaticQueryObsList
                      key={`${tabName}-tab-panel`}
                      classes={classes}
                      index={idx}
                      queryStringParameters={queryStringParameters}
                      nonClassProps={nonClassProps}
                      handlePDFObservation={handlePDFObservation}
                      handleApproveObservation={handleApproveObservation}
                      handleDeleteObservation={handleDeleteObservation}
                      refreshObservations={refreshObservations}
                    />
                  )
                case 'SINGLE_FIELD_SEARCH':
                  // SINGLE FIELD QUERY, i.e. a single field changes the networked query on this tab 
                  // (e.g. job search)
                  return (
                    <SingleSearchFieldList
                      key={`${tabName}-tab-panel`}
                      classes={classes}
                      index={idx}
                      queryStringParameters={queryStringParameters}
                      nonClassProps={nonClassProps}
                      handlePDFObservation={handlePDFObservation}
                      handleApproveObservation={handleApproveObservation}
                      handleDeleteObservation={handleDeleteObservation}
                      refreshObservations={refreshObservations}
                    />
                  )
                case 'MULTI-SELECT':
                  // MULTI-SELECT, i.e. multi-select options determine the networked query on this tab
                  // (e.g. observation status search)
                  return (
                    <MultiSelectList
                      key={`${tabName}-tab-panel`}
                      classes={classes}
                      index={idx}
                      queryStringParameters={queryStringParameters}
                      nonClassProps={nonClassProps}
                      handlePDFObservation={handlePDFObservation}
                      handleApproveObservation={handleApproveObservation}
                      handleDeleteObservation={handleDeleteObservation}
                      refreshObservations={refreshObservations}
                    />
                  )
                case 'STATIC_AGGREGATION_QUERY':
                  // STATIC-AGGERGATION QUERY, i.e. a static networked query with aggregated data
                  // (e.g. month summary)
                  return (
                      <MonthSummaryTabPanel
                        key={`${tabName}-tab-panel`}
                        classes={classes}
                        index={idx}
                        queryStringParameters={queryStringParameters}
                        nonClassProps={nonClassProps}
                        handlePDFObservation={handlePDFObservation}
                        handleApproveObservation={handleApproveObservation}
                        handleDeleteObservation={handleDeleteObservation}
                      />
                  )
              }
            })
          }
        </Paper>
      </Box>
    </Container>
  );
}

WelcomePage.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
}

export default withRouter(withStyles(styles)(WelcomePage));
