import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

// MSAL imports
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from '@azure/msal-browser';

// REACT-ROUTER
import { useLocation } from 'react-router-dom';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Box, Typography, CircularProgress, ButtonGroup, Button, useTheme, useMediaQuery } from '@material-ui/core';

// REACT-QUERY
import {
  useQuery,
  useQueryClient
} from 'react-query';

// REACT-VIRTUALIZED
import { WindowScroller, List, AutoSizer } from 'react-virtualized';

// CUSTOM SERVICES AND HELPERS
import { searchOLAP } from '../services/api';
import { isDefinedAndInitialized } from '../helpers/helpers';

// Custom Components
import ObservationSummaryListItem from './observationSummaryListItem';

// Custom Config
import { config } from '../config/generalConfig';
import { sortObservationByCreator, sortObservationByInspectionTimestamp, sortObservationByStatus } from '../helpers/observation-helpers';

const styles = theme => ({
  observationListItem: {
    display: 'flex',
    justifyContent: 'left',
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  progress: {
    margin: theme.spacing(2),
    color: '#1976d2',
  },
  list: {
    flexGrow: '1',
    display: 'flex',
    flexDirection: 'column'
  },
});

const EmptyObservationsListMessaging = (props) => {
  // ----------------------------------------
  const { classes, tabName, queryStringParameters } = props;
  const PrevJobId = config.getJobIdFromUrlQueryString(queryStringParameters);

  if (['JOB'].includes(tabName)) {
    return (
      <Box className={classes.loaderContainer} mt="20px">
        <Typography style={{ fontSize: "1.2rem" }}>
          {
            (PrevJobId) ? 'No observations found for this job' : 'Start searching for observations by job number above'
          }
        </Typography>
      </Box>
    );
  }
  if (['MY_LAST_3_MONTHS'].includes(tabName)) {
    return (
      <Box className={classes.loaderContainer} mt="20px">
        <Typography style={{ fontSize: "1.2rem" }}>
          You are not involved in any observations yet. Create an observation above to get started
        </Typography>
      </Box>
    );
  }

  if (['MY_LAST_3_MONTHS'].includes(tabName)) {
    return (
      <Box className={classes.loaderContainer} mt="20px">
        <Typography style={{ fontSize: "1.2rem" }}>
          You are not involved in any observations yet. Create an observation above to get started
        </Typography>
      </Box>
    );
  }

  if (['STATUS'].includes(tabName)) {
    return (
      <Box className={classes.loaderContainer} mt="20px">
        <Typography style={{ fontSize: "1.2rem" }}>
          No observations with the selected group of statuses
        </Typography>
      </Box>
    );
  }
  return '';
};

EmptyObservationsListMessaging.propTypes = {
  classes: PropTypes.object,
  tabName: PropTypes.string,
  queryStringParameters: PropTypes.object
}

const sortingConfig = [
  {
    label: 'Date',
    function: sortObservationByInspectionTimestamp,
    defaultOrder: 'desc'
  },
  {
    label: 'Status',
    function: sortObservationByStatus,
    defaultOrder: 'asc'
  },
  {
    label: 'Creator',
    function: sortObservationByCreator,
    defaultOrder: 'asc'
  }
];


const ObservationSummaryList = (props) => {
  // ----------------------------------------------------------
  // PROPS
  const { classes, nonClassProps,
    handlePDFObservation, handleApproveObservation,
    handleDeleteObservation } = props;

  // STATE
  const [sortingState, setSortingState] = useState({
    label: 'Date',
    function: sortObservationByInspectionTimestamp,
    order: 'desc'
  });
  const [itemIdxExpanded, setItemIdxExpanded] = useState(null);

  // REFS
  const listRef = useRef();
  const windowScrollerRef = useRef();

  // QUERY STRING PARAMETERS
  const queryStringParameters = new URLSearchParams(useLocation().search);
  const currentTabName = config.getTabNameFromUrlQueryString(queryStringParameters);
  const jobId = config.getJobIdFromUrlQueryString(queryStringParameters);
  const statuses = config.getStatusesFromUrlQueryString(queryStringParameters);

  // USE MSAL HOOK
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const loginHint = (accounts && accounts[0]?.username) ?? '';
  const request = {
    loginHint,
    scopes: ["User.Read"]
  }
  const isAuthed = inProgress === "none" && isDefinedAndInitialized(accounts) && accounts.length > 0 && isDefinedAndInitialized(accounts[0]) && isDefinedAndInitialized(accounts[0].username);

  // MUI HOOKS
  const theme = useTheme();
  const isSmallerThanXs = useMediaQuery(theme.breakpoints.down('xs'));


  const getItemSize = useCallback(({ index }) => {
    // -----------------------------------------
    const width = window.innerWidth;
    const isSelected = itemIdxExpanded === index;
    // Smaller than SM (i.e. xs)
    if (width < theme.breakpoints.values.sm) {
      return isSelected ? 218 : 63;
    }
    // Smaller than MD (i.e. sm)
    else if (width < theme.breakpoints.values.lg) {
      return isSelected ? 255 : 70;
    }
    // Larger than MD (i.e. md, lg, xl)
    else {
      return isSelected ? 314 : 74;
    }
  }, [theme.breakpoints, itemIdxExpanded]);

  // REACT-QUERY
  // - Query client used for invalidation of queries
  const queryClient = useQueryClient();
  // 1) OBSERVATIONS LIST (JOB)
  const { isLoading: isJobObsDataLoading, isError: isJobObsDataError, data: jobObsData, error: jobObsDataError } = useQuery(
    ['jobObsList', jobId],
    async () => {
      const obsResultSet = await searchOLAP('JOB', jobId, { instance, accounts, inProgress });
      return obsResultSet.data;
    },
    {
      // AUTH ENABLEMENT
      enabled: isAuthed
        // TAB ENABLEMENT
        && currentTabName === 'JOB' && isDefinedAndInitialized(jobId) && jobId !== ''
    }
  );
  // 2) OBSERVATIONS LIST (STATUS)
  const { isLoading: isStatusObsDataLoading, isError: isStatusObsDataError, data: statusObsData, error: statusObsDataError } = useQuery(
    ['statusObsList', ...statuses],
    async () => {
      const obsResultSet = await searchOLAP('STATUS', statuses, { instance, accounts, inProgress });
      return obsResultSet.data;
    },
    {
      // AUTH ENABLEMENT
      enabled: isAuthed
        // TAB ENABLEMENT
        && currentTabName === 'STATUS' && isDefinedAndInitialized(statuses)
    }
  );
  // 3) OBSERVATION DATA (OTHER TAB)
  const { isLoading: isOtherObsDataLoading, isError: isOtherObsDataError, data: otherObsData, error: otherObsDataError } = useQuery(
    ['otherObsList', currentTabName],
    async () => {
      const obsResultSet = await searchOLAP(currentTabName, null, { instance, accounts, inProgress });
      return obsResultSet.data;
    },
    {
      // AUTH ENABLEMENT
      enabled: isAuthed
        // TAB ENABLEMENT
        && !['JOB', 'STATUS'].includes(currentTabName) && isDefinedAndInitialized(currentTabName)
    }
  );

  let data = [];
  if (currentTabName === 'JOB') {
    if (isDefinedAndInitialized(jobId) && !isJobObsDataLoading && isDefinedAndInitialized(jobObsData)) {
      data = jobObsData;
    }
  }
  else if (currentTabName === 'STATUS') {
    if (isDefinedAndInitialized(statuses) && !isStatusObsDataLoading && isDefinedAndInitialized(statusObsData)) {
      data = statusObsData;
    }
  }
  else if (!isOtherObsDataLoading && isDefinedAndInitialized(otherObsData)) {
    data = otherObsData;
  }

  const sortedData = data.sort((a, b) => sortingState.function(sortingState.order, a, b));

  // Render row
  const rowRenderer = useCallback(({ index, key, style }) => {
    const item = sortedData[index];
    const itemData = {
      ...nonClassProps,
      type: "SUMMARY_LIST",
      ObservationSummaryData: item,
      handlePDFObservation,
      handleApproveObservation,
      handleDeleteObservation,
      refreshSummaryList: null,
      idx: index,
      showActions: index === itemIdxExpanded,
      handleShowActions: (newIdx) => {
        let resetIdx = itemIdxExpanded !== null ? Math.min(itemIdxExpanded, newIdx) : newIdx;
        setItemIdxExpanded(newIdx === itemIdxExpanded ? null : newIdx);
        if (listRef.current) {
          listRef.current.scrollToRow(newIdx);
          listRef.current.recomputeRowHeights(resetIdx);
        }
      }
    }

    return (
      <Box key={key} style={style} >
        <ObservationSummaryListItem {...itemData} />
      </Box>
    );
  }, [sortedData, itemIdxExpanded, listRef.current]);

  // EFFECTS
  // HANDLE AUTH REDIRECT
  useEffect(async () => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      await instance.loginRedirect(request);
    }
  }, [isAuthenticated, inProgress, instance]);

  useEffect(() => {
    // ---------------------------------------------------------------
    if (currentTabName === 'JOB') {
      if (isDefinedAndInitialized(jobId)) {
        queryClient.invalidateQueries(['jobObsList', jobId]);
      }
    }
    else if (currentTabName === 'STATUS') {
      if (isDefinedAndInitialized(statuses)) {
        queryClient.invalidateQueries(['statusObsList', ...statuses]);
      }
    }
    else {
      queryClient.invalidateQueries(['otherObsList', currentTabName]);
    }
  }, [currentTabName, jobId, JSON.stringify(statuses), isAuthed]);

  useEffect(() => {
    // ----------------------------------------
    let resizeTimeout;
    const handleTimedOutOrientationChangeOrResize = () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(handleOrientationOrResize, 100); // adjust delay as needed
    }

    // Use the ScreenOrientation API if available
    if (window.screen && window.screen.orientation) {
      // ----------------------------------------
      window.screen.orientation.addEventListener('change', handleTimedOutOrientationChangeOrResize);
    }
    // Otherwise try the deprecated orientationchange event
    else {
      // ----------------------------------------
      console.info(`ScreenOrientation API not available. Using fallback orientationchange event.`)
      window.addEventListener('orientationchange', handleTimedOutOrientationChangeOrResize);
    }
    window.addEventListener('resize', handleTimedOutOrientationChangeOrResize);
    handleTimedOutOrientationChangeOrResize();
    return () => {
      clearTimeout(resizeTimeout);
      window.removeEventListener('resize', handleTimedOutOrientationChangeOrResize);

      if (window.screen && window.screen.orientation) {
        window.screen.orientation.removeEventListener('change', handleTimedOutOrientationChangeOrResize);
      }
      else {
        window.removeEventListener('orientationchange', handleTimedOutOrientationChangeOrResize);
      }
    }
  }, []);

  // EVENT HANDLERS
  const handleOrientationOrResize = useCallback(() => {
    if (isDefinedAndInitialized(listRef.current)) {
      // --------------------------
      setItemIdxExpanded(null);
      listRef.current.recomputeRowHeights(0);
    }
  }, [listRef.current]);


  const handleSortTypeChange = (label) => {
    // ---------------------------------------------------------------
    const newSortingState = sortingConfig.find((sorting) => sorting.label === label);
    setSortingState({
      ...newSortingState,
      order: newSortingState.defaultOrder
    });
    listRef.current.recomputeRowHeights(0);
  }

  const handleSortOrderChange = (label) => {
    // ---------------------------------------------------------------
    setSortingState({
      ...sortingState,
      order: label
    });
    listRef.current.recomputeRowHeights(0);
  }

  // HANDLE LOADING STATE
  // MSAL
  if (!isAuthed) {
    return (
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt="15px">
        <Typography>Loading your profile..</Typography>
        <CircularProgress />
      </Box>
    )
  }
  // QUERY DATA
  if (
    (currentTabName === 'JOB' && isJobObsDataLoading)
    || (currentTabName === 'STATUS' && isStatusObsDataLoading)
    || (!['JOB', 'STATUS'].includes(currentTabName) && isOtherObsDataLoading)
  ) {
    return (
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt="15px">
        <Typography>Loading data for this tab..</Typography>
        <CircularProgress />
      </Box>
    )
  }

  // HANDLE ERROR STATE
  if (isJobObsDataError) {
    return <span>An unexpected error has occurred with the job observation data {jobObsDataError}</span>
  }
  if (isStatusObsDataError) {
    return <span>An unexpected error has occurred with the status observation data {statusObsDataError}</span>
  }
  if (isOtherObsDataError) {
    return <span>An unexpected error has occurred with the other observation data {otherObsDataError}</span>
  }

  return (
    <Box flex={1} display="flex" flexDirection="column">
      <Box className={classes.list}>
        <Box display="flex" flexDirection="column" alignItems="center">
          {
            currentTabName === 'LAST_MONTH' &&
            <Box mt={"10px"} ml={"35px"} display="flex" flexDirection="column" width="100%">
              <Typography variant="button" ><strong>From the last month</strong></Typography>
            </Box>
          }
          {
            currentTabName === 'MY_LAST_3_MONTHS' &&
            <Box mt={"10px"} ml={"35px"} display="flex" flexDirection="column" width="100%">
              <Typography variant="button" ><strong>From the last 3 months</strong></Typography>
            </Box>
          }
          {
            <Box display="flex" flexDirection="row" mb={"10px"} ml={"35px"} width="100%">
              <Box display="flex" flexDirection={isSmallerThanXs ? "column" : "row"}>
                <Box display="flex" flexDirection="column" mr="10px">
                  <Typography variant="caption" >Sort By</Typography>
                  <ButtonGroup variant="contained" color="primary" aria-label="change sort type" size="small" disableElevation fullWidth={false}>
                    {
                      sortingConfig
                        .filter(sorting => (
                          currentTabName === 'MY_LAST_3_MONTHS' ? sorting.label !== 'Creator' : true
                        ))
                        .map((sorting) => (
                          <Button
                            color={sortingState.label === sorting.label ? "secondary" : "primary"}
                            key={sorting.label}
                            value={sorting.label}
                            onClick={() => handleSortTypeChange(sorting.label)}
                            style={{ fontSize: isSmallerThanXs ? '0.65rem' : '1rem' }}
                          >
                            {sorting.label}
                          </Button>
                        ))
                    }
                  </ButtonGroup>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Typography variant="caption" >Order</Typography>
                  <ButtonGroup variant="contained" color="primary" aria-label="change sort order" disableElevation fullWidth={false} size="small">
                    {
                      [{ label: 'desc' }, { label: 'asc' }]
                        .map((sorting) => (
                          <Button
                            color={sortingState.order === sorting.label ? "secondary" : "primary"}
                            key={sorting.label}
                            value={sorting.label}
                            onClick={() => handleSortOrderChange(sorting.label)}
                            style={{ fontSize: isSmallerThanXs ? '0.65rem' : '1rem' }}
                          >
                            {sorting.label}
                          </Button>
                        ))
                    }
                  </ButtonGroup>
                </Box>
              </Box>
            </Box>
          }
          {
            isSmallerThanXs &&
            <Box>
              <Typography style={{ fontSize: '0.9rem' }}>Tap an observation for actions</Typography>
            </Box>
          }
        </Box>
        {/* OBS SUMMARY LIST */}
        {
          isDefinedAndInitialized(data) && data.length > 0 &&
          <WindowScroller
            ref={windowScrollerRef}
          >
            {
              ({ height, registerChild, onChildScroll, scrollTop }) => (
                <AutoSizer disableHeight>
                  {
                    ({ width }) => (
                      <Box
                        ref={registerChild}
                      >
                        <List
                          ref={listRef}
                          autoHeight
                          height={height}
                          width={width}
                          rowCount={sortedData.length}
                          rowHeight={getItemSize}
                          overscanRowCount={5}
                          rowRenderer={rowRenderer}
                          onScroll={onChildScroll}
                          scrollTop={scrollTop}
                        />
                      </Box>
                    )
                  }
                </AutoSizer>
              )
            }
          </WindowScroller>
        }        {
          isDefinedAndInitialized(data) && data.length === 0 &&
          <EmptyObservationsListMessaging
            classes={classes}
            tabName={currentTabName}
            queryStringParameters={queryStringParameters}
          />
        }
      </Box>
    </Box>
  );
};

ObservationSummaryList.propTypes = {
  classes: PropTypes.object,
  tabName: PropTypes.string,
  nonClassProps: PropTypes.object,
  handlePDFObservation: PropTypes.func,
  handleApproveObservation: PropTypes.func,
  handleDeleteObservation: PropTypes.func
}

export default (withStyles)(styles)(ObservationSummaryList);
