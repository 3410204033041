import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';

import uuidv4 from 'uuid/v4';
import { useSnackbar } from 'notistack';
import arrayMove from 'array-move';
import { DateTime } from "luxon";

// MSAL imports
import { InteractionStatus } from "@azure/msal-browser";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";

// REACT-QUERY
import {
  useQueryClient
} from 'react-query';

// Material UI
import { Modal, Box, Typography, CircularProgress } from '@material-ui/core';
import { withStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery';

// Context
import { InitiaContext } from '../context/initia-context';

// Custom Components
import ObservationContent from './observationContent';
import BasicLoader from '../components/basicLoader';

// Custom Config
import { config } from '../config/generalConfig';
import { components } from '../config/reactComponents';

// Custom Services
import { getObservationDetail, putObservation, uploadImageToS3, downloadPdf, createWorkAndSafetyTake5, updateWorkAndSafetyTake5, getWorkAndSafetyTake5Detail, deleteWorkAndSafetyTake5ForObservation, getJobDetail } from '../services/api';
import { dataURIToBlob, capitalizeFirstLetters, convertCamelToSpaced } from '../helpers/helpers';

// Custom Helpers
import { isDefinedAndInitialized } from '../helpers/helpers';


const snackBarAutoHide = 2000;

const styles = () => ({
  modal: {
    color: '#FFF',
    transform: 'translate(-50%, -50%)',
  }
});

// A function to load a specific observation from the backend
const loadObservationDetail = async (observationId, { instance, accounts, inProgress }) => {
  // --------------------------------------------------
  let observationDetailResultset = await getObservationDetail(observationId, { instance, accounts, inProgress });
  return observationDetailResultset?.data;
};

// CUSTOM HOOK
// Source: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

// A function to transform the API response data structure to the client data structure
const apiToClientStructure = (state, res) => {
  // --------------------------------------------------
  return {
    updatedState: {
      observationEdit: res,
      sections: res.body.components
        .map((component) => {
          // ---------------------
          if (component.componentType === 'siteSketch') {
            // ---------------------
            return ({
              component: components.components[component.componentType],
              data: {
                componentId: component.componentId,
                componentType: component.componentType,
                referenceData: component.referenceData,
                header: component.header,
              },
            });
          }
          else {
            // ---------------------
            return ({
              component: components.components[component.componentType],
              data: component,
            });
          }
        }),
      jobSummaryTableData: {
        latitude: res.header.inspectionLatitude,
        longitude: res.header.inspectionLongitude,
        createdBy: res.header.createdBy.name,
        createdById: res.header.createdBy.id,
        inspectionType: res.header.inspectionType,
        observationDuration: res.header.observationDuration
      },
      jobData: res.header.jobData
    },
    updatedCanvasStore: res.body.components
      .filter(component => component.componentType === 'siteSketch')
      .map(component => {
        // ---------------------
        return {
          componentId: component.componentId,
          canvas: component.canvas
        };
      }),
    updatedCanvasStoreMetadata: res.body.components
      .filter(component => component.componentType === 'siteSketch')
      .map(component => {
        // ---------------------
        return {
          componentId: component.componentId,
          canvasMetadata: component.canvasMetadata
        };
      })
  };
};

// Functional ObservationContentContainer Component
function ObservationContentContainer(props) {
  const { match, classes } = props;
  const { observationId } = match.params;
  const { enqueueSnackbar } = useSnackbar();

  // Local References (i.e. non-render inducing state)
  const canvas = useRef(null);
  const canvasStore = useRef({});
  const canvasStoreMetadata = useRef({});
  const firstLoadComplete = useRef(false);
  const pileObsCanvasStore = useRef({});

  // USE MSAL HOOK
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const loginHint = (accounts && accounts[0]?.username) ?? '';
  const request = {
    loginHint,
    scopes: ["User.Read"]
  }

  const isAuthed = isAuthenticated && inProgress === "none" && isDefinedAndInitialized(accounts) && accounts.length > 0 && isDefinedAndInitialized(accounts[0]) && isDefinedAndInitialized(accounts[0].username);

  // REACT QUERY
  // - Query client used for invalidation of queries
  const queryClient = useQueryClient();

  // EFFECTS
  useEffect(async () => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      await instance.loginRedirect(request);
    }
  }, [isAuthenticated, inProgress, instance]);

  // USE THEME
  const theme = useTheme();
  const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('sm'));
  // SET SNACKBAR POSITION ACCORDING TO MEDIA SIZE (TOP IF SMALLER THAN MD)
  const anchorSnackbar = {
    horizontal: 'left',
    vertical: isSmallerThanSm ? 'top' : 'bottom'
  }

  // -----------------------------------------------------------------------------------
  // LOCAL STATE -> LOOK TO MOVE TO CONTEXT
  // -----------------------------------------------------------------------------------
  // Force Update for backwards compatibility
  const [settings, setSettings] = useState({
    autoSave: (localStorage.getItem('siteObservationsSettings') && JSON.parse(localStorage.getItem('siteObservationsSettings')).autoSave) ? true : false,
    lastAutoSaves: (localStorage.getItem('lastAutoSaves')) ? JSON.parse(localStorage.getItem('lastAutoSaves')) : null
  });
  const [shouldAutosave, setShouldAutosave] = useState(false);
  const [pdfModalOpen, setPdfModalOpen] = useState(false);

  // -----------------------------------------------------------------------------------
  // CONTEXT
  // -----------------------------------------------------------------------------------
  const { state, dispatch } = useContext(InitiaContext);

  // -----------------------------------------------------------------------------------
  // ONLOAD SIDE-EFFECTS (i.e. lifecycle mounting/unmounting methods combined)
  // -----------------------------------------------------------------------------------
  useEffect(() => {
    // If no settings present, update to default to autosave
    if (!isDefinedAndInitialized(localStorage.getItem('siteObservationsSettings'))) {
      let settingsUpdate = { autoSave: true };
      localStorage.setItem('siteObservationsSettings', JSON.stringify(settingsUpdate));
      setSettings(settingsUpdate);
    }

    // Load the observation from the API
    if (isAuthed) {
      // ----------------------------------------------------
      const initialLoad = async (observationId, { instance, accounts, inProgress }) => {
        // ---------------------------------------------
        let loadObservationResultset = await loadObservationDetail(observationId, { instance, accounts, inProgress });

        // ------------------------------------------
        // If there is a linked Take-5 record to this observation - query this as well too
        const hasObservationDetailData = isDefinedAndInitialized(loadObservationResultset);
        const shouldEnableTake5Query = isDefinedAndInitialized(loadObservationResultset?.header?.wsPrestartId);

        let workAndSafetyUpdate = null;
        if (hasObservationDetailData && shouldEnableTake5Query) {
          // ---------------------------------------------
          workAndSafetyUpdate = await getWorkAndSafetyTake5Detail(loadObservationResultset?.header?.wsPrestartId, { instance, accounts, inProgress });
          if (!isDefinedAndInitialized(workAndSafetyUpdate)) { 
            // ----------------------------------------
            loadObservationResultset.header.wsPrestartId = null;
            loadObservationResultset.header.wsPlanId = null;
          }
        }


        // State update required for:
        // 1) observationEdit (used to render metadata and transfer inspectionId to photo upload components)
        // 2) sections/jobData (used to render individual inspection components and jobData)
        const transformState = apiToClientStructure(state, loadObservationResultset);
        // Update the canvas stores

        const canvasStoreUpdate = {};
        for (const updatedCanvas of transformState.updatedCanvasStore) {
          // ------------------------------------
          canvasStoreUpdate[updatedCanvas.componentId] = updatedCanvas.canvas;
        }
        canvasStore.current = canvasStoreUpdate;

        const canvasStoreMetadataUpdate = {};
        for (const updatedCSMetadata of transformState.updatedCanvasStoreMetadata) {
          // ------------------------------------
          canvasStoreMetadataUpdate[updatedCSMetadata.componentId] = updatedCSMetadata.canvasMetadata;
        }
        canvasStoreMetadata.current = canvasStoreMetadataUpdate;

        firstLoadComplete.current = true;

        dispatch({
          type: 'updateObservationPage',
          payload: {
            observationUpdateState: transformState.updatedState,
            workAndSafetyUpdateState: workAndSafetyUpdate
          }
        });
      }

      initialLoad(observationId, { instance, accounts, inProgress });
    }
    else {
      console.info('Not authenticated for this API action');
    }
  }, [observationId, isAuthed]);

  const pauseAutoSave = () => {

    enqueueSnackbar('Auto-save paused to complete action', { variant: 'info', autoHideDuration: snackBarAutoHide, anchorOrigin: anchorSnackbar });
    setShouldAutosave(false);
  }

  const restartAutoSave = () => {

    enqueueSnackbar('Auto-save resumed', { variant: 'info', autoHideDuration: snackBarAutoHide, anchorOrigin: anchorSnackbar });
    setShouldAutosave(true);
  }
  // -----------------------------------------------------------------------------------
  // SECOND EFFECT TO SETUP AUTO-SAVE EACH MINUTE
  // -----------------------------------------------------------------------------------
  let delay = (localStorage.getItem('siteObservationsSettings') && JSON.parse(localStorage.getItem('siteObservationsSettings')).autoSave && shouldAutosave) ? 60000 : null;
  useInterval(async () => {
    let lastAutoSaves = {};
    if (localStorage.getItem('lastAutoSaves')) {
      lastAutoSaves = JSON.parse(localStorage.getItem('lastAutoSaves'));
    }
    await saveObservationHandler(null, false, true);
    localStorage.setItem('lastAutoSaves', JSON.stringify({
      ...lastAutoSaves,
      [observationId]: DateTime.local().toISO()
    }));
    setSettings({
      ...settings,
      lastAutoSaves: {
        ...settings.lastAutoSaves,
        [observationId]: DateTime.local().toISO()
      }
    });
    // Disable autosave until the next change is made
    setShouldAutosave(false);

  }, delay);


  // -----------------------------------------------------------------------------------
  // CLIENT OBSERVATION STATE UPDATES
  // -----------------------------------------------------------------------------------
  const handleClientObservationStateUpdate = (e, componentType, componentId = null, componentData = null) => {
    // -----------------------------------------------------------
    const { value } = e.target;

    // A change has been made, switch auto-save back ON
    setShouldAutosave(true);


    // Generic handler implemented for JobData Values!
    switch (componentType) {
      case 'ncr':
        dispatch({
          type: 'updateNcrVisibility',
          payload: {
            value
          }
        })
        break;
      case 'siteAddress':
      case 'weather':
      case 'siteContacts': {
        if (e.type === 'click') {
          // JOBDATA VISIBILITY UPDATE
          // -----------------------------------
          dispatch({
            type: 'updateJobDataVisibility',
            payload: {
              componentType,
            },
          });
        } else {
          // JOBDATA VALUE UPDATE
          // -----------------------------------
          dispatch({
            type: 'updateJobDataValue',
            payload: {
              componentType,
              value: (value === '') ? null : value,
            },
          });
        }
        break;
      }
      case 'createdBy':
      case 'inspectionType':
      case 'observationDuration': {
        // JOBSUMMARYDATA
        // -----------------------------------
        // VALUE UPDATE
        dispatch({
          type: 'updateJobSummaryDataValue',
          payload: {
            componentType,
            value,
          },
        });
        break;
      }
      case 'location': {
        // LOCATION
        // -----------------------------------
        // VALUE UPDATE
        dispatch({
          type: 'updateLocation',
          payload: {
            componentType,
            value,
          },
        });
        break;
      }
      case 'inspectionTimestamp':
        // INSPECTION TIMESTAMP
        // -----------------------------------
        // VALUE UPDATE
        dispatch({
          type: 'updateInspectionTimestamp',
          payload: {
            componentType,
            value,
          },
        });
        break;
      case 'siteSketch':
      case 'generalTable':
      case 'shearVaneTable':
      case 'cleggTable':
      case 'stockpileAssessmentTable':
      case 'aialStockpileAssessmentTable':
      case 'nonConformanceRegister':
      case 'healthAndSafetyTakeFive':
      case 'photo':
      case 'generalText':
      case 'recommendation':
      case 'heading':
      case 'pileObservation': {
        // COMPONENT DATA UPDATE
        const componentIndex = state.observationPage.sections
          .findIndex(section => section.data.componentId === componentId);
        const sectionUpdate = [
          ...state.observationPage.sections.slice(0, componentIndex),
          {
            component: components.components[componentData.componentType],
            data: componentData,
          },
          ...state.observationPage.sections.slice(componentIndex + 1),
        ];
        dispatch({
          type: 'updateSectionState',
          payload: sectionUpdate,
        });
        break;
      }
      case 'workAndSafetyV1': {
        let sectionUpdate = isDefinedAndInitialized(componentData) ? componentData : null;

        dispatch({
          type: 'updateWsData',
          payload: sectionUpdate,
        });
        break;
      }
      case 'workAndSafetyV1Presentation': {
        dispatch({
          type: 'updateWsHazardPresentation',
          payload: componentData
        });
        break;        
      }
      default:
        break;
    }
  };

  // -----------------------------------------------------------------------------------
  // REFRESH OBSERVATION FROM API
  // -----------------------------------------------------------------------------------
  const apiRefreshObservation = async (observationId) => {
    if (isAuthed) {
      // ------------------------------
      let loadObservationResultset = await loadObservationDetail(observationId, { instance, accounts, inProgress });

      // ------------------------------------------
      // If there is a linked Take-5 record to this observation - query this as well too
      const hasObservationDetailData = isDefinedAndInitialized(loadObservationResultset);
      const shouldEnableTake5Query = isDefinedAndInitialized(loadObservationResultset?.header?.wsPrestartId);

      let workAndSafetyUpdate = null;
      if (hasObservationDetailData && shouldEnableTake5Query) {
        // ---------------------------------------------
        workAndSafetyUpdate = await getWorkAndSafetyTake5Detail(loadObservationResultset?.header?.wsPrestartId, { instance, accounts, inProgress });
        if (!isDefinedAndInitialized(workAndSafetyUpdate)) { 
          // ----------------------------------------
          loadObservationResultset.header.wsPrestartId = null;
          loadObservationResultset.header.wsPlanId = null;
        }
      }

      const transformState = apiToClientStructure(state, loadObservationResultset);
      // Update the canvas stores
      transformState.updatedCanvasStore.forEach((updatedCanvas) => {
        canvasStore.current[updatedCanvas.componentId] = updatedCanvas.canvas;
      });
      transformState.updatedCanvasStoreMetadata.forEach((updatedCSMetadata) => {
        canvasStoreMetadata.current[updatedCSMetadata.componentId] = updatedCSMetadata.canvasMetadata;
      });
      dispatch({
        type: 'updateObservationPage',
        payload: {
          observationUpdateState: transformState.updatedState,
          workAndSafetyUpdateState: workAndSafetyUpdate
        }
      });
    }
    else {
      enqueueSnackbar('Not authenticated for this action', { variant: 'error', autoHideDuration: snackBarAutoHide + 2000, anchorOrigin: anchorSnackbar });
    }
  };


  // -----------------------------------------------------------------------------------
  // REFRESH JOB DATA FROM API
  // -----------------------------------------------------------------------------------
  const apiRefreshObservationJobData = async (observationId) => {
    // ------------------------------
    if (isAuthed) {
      // ------------------------------
      const loadObservationResultset = await loadObservationDetail(observationId, { instance, accounts, inProgress });

      // ------------------------------------------
      // If there is a linked Take-5 record to this observation - query this as well too
      const hasObservationDetailData = isDefinedAndInitialized(loadObservationResultset);
      const shouldEnableTake5Query = isDefinedAndInitialized(loadObservationResultset?.header?.wsPrestartId);

      let workAndSafetyUpdate = null;
      if (hasObservationDetailData && shouldEnableTake5Query) {
        // ---------------------------------------------
        workAndSafetyUpdate = await getWorkAndSafetyTake5Detail(loadObservationResultset?.header?.wsPrestartId, { instance, accounts, inProgress });
        if (!isDefinedAndInitialized(workAndSafetyUpdate)) { 
          // ----------------------------------------
          loadObservationResultset.header.wsPrestartId = null;
          loadObservationResultset.header.wsPlanId = null;
        }
      }

      const jobData = await getJobDetail(loadObservationResultset?.header?.jobId, { instance, accounts, inProgress });
      // ------------------------------------------
      let updatedObservation = {
        ...loadObservationResultset,
        header: {
          ...loadObservationResultset.header,
          jobId: jobData.jobId,
          jobName: jobData.jobName,
          jobType: jobData.jobType,
          client: jobData.client,
          manager: jobData.manager,
          partner: jobData.partner,
          buildingConsentNumbers: jobData.buildingConsentNumbers,
          jobData: {
            ...loadObservationResultset.header.jobData,
            siteAddress: {
              value: isDefinedAndInitialized(jobData.address) ? jobData.address : null,
              isShown: loadObservationResultset.header.jobData.siteAddress.isShown
            }
          }
        }
      }

      const transformState = apiToClientStructure(state, updatedObservation);
      // Update the canvas stores
      transformState.updatedCanvasStore.forEach((updatedCanvas) => {
        canvasStore.current[updatedCanvas.componentId] = updatedCanvas.canvas;
      });
      transformState.updatedCanvasStoreMetadata.forEach((updatedCSMetadata) => {
        canvasStoreMetadata.current[updatedCSMetadata.componentId] = updatedCSMetadata.canvasMetadata;
      });
      setShouldAutosave(true)
      dispatch({
        type: 'updateObservationPage',
        payload: {
          observationUpdateState: transformState.updatedState,
          workAndSafetyUpdateState: workAndSafetyUpdate
        }
      });
      enqueueSnackbar('Successfully refreshed job data', { variant: 'success', autoHideDuration: snackBarAutoHide, anchorOrigin: anchorSnackbar });
    }
    else {
      enqueueSnackbar('Not authenticated for this action', { variant: 'error', autoHideDuration: snackBarAutoHide + 2000, anchorOrigin: anchorSnackbar });
    }
  };


  // -----------------------------------------------------------------------------------
  // REFRESH WORK AND SAFETY FROM API
  // -----------------------------------------------------------------------------------
  const apiRefreshWorkAndSafety = async (wsPrestartId) => {
    if (isAuthed) {
      // ------------------------------
      if (isDefinedAndInitialized(wsPrestartId)) {
        // -------------------------------------------
        // If there is a linked Take-5 record to this observation - query this
        const workAndSafetyUpdate = await getWorkAndSafetyTake5Detail(wsPrestartId, { instance, accounts, inProgress });
        // If the query returns a result - dispatch an update to the application state for the work and safety component ONLY
        if (isDefinedAndInitialized(workAndSafetyUpdate)) { 
          // ----------------------------------------
          dispatch({
            type: 'updateWsData',
            payload: workAndSafetyUpdate
          });
        }
      }
    }
    else {
      enqueueSnackbar('Not authenticated for this action', { variant: 'error', autoHideDuration: snackBarAutoHide + 2000, anchorOrigin: anchorSnackbar });
    }
  };


  const pdfHandler = async () => {
    if (isAuthed) {
      // --------------------------------
      const { observationId } = match.params;
      handlePdfModalOpen();
      enqueueSnackbar('Downloading observation', { variant: 'info', autoHideDuration: snackBarAutoHide, anchorOrigin: anchorSnackbar });
      try {
        await downloadPdf(observationId, { instance, accounts, inProgress }, handlePdfModalClose);
        enqueueSnackbar('Successfully downloaded observation', { variant: 'success', autoHideDuration: snackBarAutoHide, anchorOrigin: anchorSnackbar });
        await apiRefreshObservation(observationId);
      }
      catch (error) {
        enqueueSnackbar('PDF download failed', { variant: 'error', autoHideDuration: snackBarAutoHide + 2000, anchorOrigin: anchorSnackbar });
      }
    }
    else {
      // -------------------------------
      enqueueSnackbar('Not authenticated for this action', { variant: 'error', autoHideDuration: snackBarAutoHide + 2000, anchorOrigin: anchorSnackbar });
    }
  };

  const handlePdfModalOpen = () => {
    setPdfModalOpen(true);
  };

  const handlePdfModalClose = () => {
    setPdfModalOpen(false);
  };

  // -----------------------------------------------------------------------------------
  // PUSH OBSERVATION TO API
  // -----------------------------------------------------------------------------------
  const saveObservationHandler = async (e, prePublishChange = false, autoSave = false, ncrActiveUpdate = null, workAndSafetyUpdate = null) => {
    if (isAuthed) {
      // ----------------------------------------
      // CONSTRUCT THE OBSERVATION UPDATE OBJECT
      // Conditions
      setShouldAutosave(false); // turnoff auto-save while we save the observation manually

      const obsHasLocation = isDefinedAndInitialized(state.observationPage.jobSummaryTableData.latitude)
        && isDefinedAndInitialized(state.observationPage.jobSummaryTableData.longitude);
      const obsHasWorkAndSafety = isDefinedAndInitialized(state?.observationPage?.observationEdit?.header?.wsPlanId)
        && isDefinedAndInitialized(state?.observationPage?.observationEdit?.header?.wsPrestartId)
        && isDefinedAndInitialized(state?.observationPage?.workAndSafety) && Object.keys(state?.observationPage?.workAndSafety).length > 0;
      const take5HasBeenCreated = obsHasWorkAndSafety && isDefinedAndInitialized(state?.observationPage?.workAndSafety?.creationTimestamp);
      const hasOverloadTake5StateForUpdate = isDefinedAndInitialized(workAndSafetyUpdate);

      let observationUpdate = {
        ...state.observationPage.observationEdit,
        header: {
          ...state.observationPage.observationEdit.header, // Note: this includes Work and Safety references (wsPlanId and wsPrestartId)
          createdBy: {
            name: state.observationPage.jobSummaryTableData.createdBy,
            id: state.observationPage.jobSummaryTableData.createdById,
          },
          // LOCATION
          inspectionLatitude: state.observationPage.jobSummaryTableData.latitude,
          inspectionLongitude: state.observationPage.jobSummaryTableData.longitude,
          location: obsHasLocation ? {
            lat: state.observationPage.jobSummaryTableData.latitude,
            lon: state.observationPage.jobSummaryTableData.longitude
          } : null,
          inspectionType: state.observationPage.jobSummaryTableData.inspectionType,
          observationDuration: state.observationPage.jobSummaryTableData.observationDuration,
          jobData: state.observationPage.jobData
        },
        body: {
          components: []
        }
      };

      // HANDLE NCR ACTIVE UPDATE
      if (isDefinedAndInitialized(ncrActiveUpdate)) {
        // --------------------------------------------------
        observationUpdate.header.ncrActive = ncrActiveUpdate;
      }
      else if (isDefinedAndInitialized(state.observationPage.observationEdit.header.ncrActive)) {
        // --------------------------------------------------
        observationUpdate.header.ncrActive = state.observationPage.observationEdit.header.ncrActive;
      }
      else {
        // --------------------------------------------------
        observationUpdate.header.ncrActive = false;
      }

      // DATA TRANSFORMATIONS
      // Map over all sections stored in the state and commit these components to the inspection body
      const promises = state.observationPage.sections.map((section) => {
        const sectionUpdate = section;
        // SITE SKETCHES AND PILE OBSERVATIONS REQUIRE AN ADDITIONAL TRANSFORMATION PRIOR TO SUBMISSION TO THE API 
        if (section.data.componentType === 'siteSketch') {
          // --------------------------
          // If we're dealing with the siteSketch,
          // at this stage we want to add the stringified data to the API request body
          sectionUpdate.data.canvas = canvasStore.current[section.data.componentId];
          sectionUpdate.data.canvasMetadata = canvasStoreMetadata.current[section.data.componentId];
          // Upload images promise and resolve using promise.all below before updating inspection
          // sketches a final time and putting observation to dynamo!

          // For each sketch component, we need to upload the sketch as an image to S3 (for inclusion in PDF reports)
          // and add these to the inspection component bodies
          const fabricCanvas = canvas.current[section.data.componentId];
          const fabricCanvasAsDataUrl = fabricCanvas.toDataURL({ multiplier: 2 });
          const imageBlob = dataURIToBlob(fabricCanvasAsDataUrl);

          // Add the transformed component data to the section update object 
          observationUpdate.body.components.push(sectionUpdate.data);

          return uploadImageToS3(imageBlob, sectionUpdate.data.componentId, 'png', `inspection-media/inspection-${observationUpdate.inspectionId}/siteSketch`, { instance, accounts, inProgress });
        }
        else if (section.data.componentType === 'pileObservation') {
          // --------------------------
          // When dealing with the numeric values in the app we want to work with strings for easy input
          // When saving though we want to keep our numbers AS numbers
          sectionUpdate.data.externalDiameter = (isDefinedAndInitialized(section.data.externalDiameter)) ? Number(section.data.externalDiameter) : null;
          sectionUpdate.data.poleDiameter = (isDefinedAndInitialized(section.data.poleDiameter)) ? Number(section.data.poleDiameter) : null;
          sectionUpdate.data.casingDiameter = (isDefinedAndInitialized(section.data.casingDiameter)) ? Number(section.data.casingDiameter) : null;
          sectionUpdate.data.topOfCasingLevel = (isDefinedAndInitialized(section.data.topOfCasingLevel)) ? Number(section.data.topOfCasingLevel) : null;
          sectionUpdate.data.casingLength = (isDefinedAndInitialized(section.data.casingLength)) ? Number(section.data.casingLength) : null;
          sectionUpdate.data.designToeLevel = (isDefinedAndInitialized(section.data.designToeLevel)) ? Number(section.data.designToeLevel) : null;
          sectionUpdate.data.designGeoEmbedment = (isDefinedAndInitialized(section.data.designGeoEmbedment)) ? Number(section.data.designGeoEmbedment) : null;
          sectionUpdate.data.otherReferenceLevel = (isDefinedAndInitialized(section.data.otherReferenceLevel)) ? Number(section.data.otherReferenceLevel) : null;
          sectionUpdate.data.pilingPlatformLevel = (isDefinedAndInitialized(section.data.pilingPlatformLevel)) ? Number(section.data.pilingPlatformLevel) : null;
          sectionUpdate.data.depthToBase = (isDefinedAndInitialized(section.data.depthToBase)) ? Number(section.data.depthToBase) : null;

          // Convert current canvas to image blob then upload to S3 for usage during next publish
          const fabricCanvas = pileObsCanvasStore.current[section.data.componentId];
          const fabricCanvasAsDataUrl = fabricCanvas.toDataURL({ multiplier: 2 });

          const imageBlob = dataURIToBlob(fabricCanvasAsDataUrl);

          // Add the transformed component data to the section update object 
          observationUpdate.body.components.push(sectionUpdate.data);

          return uploadImageToS3(imageBlob, sectionUpdate.data.componentId, 'png', `inspection-media/inspection-${observationUpdate.inspectionId}/pile-observation`, { instance, accounts, inProgress });
        }
        else {
          // --------------------------
          // Add the transformed component data to the section update object 
          observationUpdate.body.components.push(sectionUpdate.data);
          return Promise.resolve(null);
        }
      });

      if (obsHasWorkAndSafety) {
        // -----------------------
        const prestartTimestamp = isDefinedAndInitialized(state.observationPage.observationEdit.header.inspectionTimestamp) ? state.observationPage.observationEdit.header.inspectionTimestamp : DateTime.local().toISO();
        const workAndSafetyBody = hasOverloadTake5StateForUpdate ? workAndSafetyUpdate : state?.observationPage?.workAndSafety        
        const body = {
          ...workAndSafetyBody,
          prestartTimestamp
        }

        if (!take5HasBeenCreated) {
          // --------------------------------
          try {
            enqueueSnackbar('Saving Take-5', { variant: 'info', autoHideDuration: snackBarAutoHide, anchorOrigin: anchorSnackbar });
            let createPrestartResultset = await createWorkAndSafetyTake5(body, { instance, accounts, inProgress });
            observationUpdate.header.wsPrestartId = createPrestartResultset.id;
            enqueueSnackbar('Successfully saved Take-5', { variant: 'success', autoHideDuration: snackBarAutoHide, anchorOrigin: anchorSnackbar });
          }
          catch (error) {
            enqueueSnackbar(`Take-5 failed to save\n${JSON.stringify(error)}`, { variant: 'error', autoHideDuration: snackBarAutoHide + 2000, anchorOrigin: anchorSnackbar });
          }
        }
        else {
          enqueueSnackbar('Updating Take-5', { variant: 'info', autoHideDuration: snackBarAutoHide, anchorOrigin: anchorSnackbar });
          await updateWorkAndSafetyTake5(body, { instance, accounts, inProgress });
          enqueueSnackbar('Successfully updated Take-5', { variant: 'success', autoHideDuration: snackBarAutoHide, anchorOrigin: anchorSnackbar });
        }
      }
      else {
        // -----------------------
        // Fire a cleanup request for the observation ID to the work and safety api
        await deleteWorkAndSafetyTake5ForObservation(observationUpdate.inspectionId, { instance, accounts, inProgress })
      }

      const message = (!autoSave) ? 'Saving observation' : 'Auto-saving observation';
      enqueueSnackbar(message, { variant: 'info', autoHideDuration: snackBarAutoHide, anchorOrigin: anchorSnackbar });
      let imageUploadResults = await Promise.all(promises);

      for (const imageUploadResult of imageUploadResults) {
        // --------------------------
        if (isDefinedAndInitialized(imageUploadResult)) {
          // --------------------------
          observationUpdate.body.components
            .find(component => component.componentId === imageUploadResult.filename) // Find the component to which the upload relates
            .canvasImage = imageUploadResult.location; // Add the S3 upload  URL to the component data
        }
      }

      try {
        // --------------------------
        let observationUpdateResult = await putObservation(observationUpdate, null, prePublishChange, autoSave, false, { instance, accounts, inProgress });
        enqueueSnackbar('Successfully updated observation', { variant: 'success', autoHideDuration: snackBarAutoHide, anchorOrigin: anchorSnackbar });

        // If this isn't an autosave, we want to refresh the observation (as well as the cached observation lists)
        if (!autoSave) {
          // --------------------------
          apiRefreshObservation(observationUpdateResult.inspectionId);
          queryClient.invalidateQueries(['observation']);
          queryClient.invalidateQueries(['workAndSafety']);
        }
        else {
          // ---------------------------
          if (isDefinedAndInitialized(observationUpdate?.header?.wsPrestartId)) {
            // -----------------------------------------
            apiRefreshWorkAndSafety(observationUpdate?.header?.wsPrestartId);
          }
        }
      }
      catch (error) {
        // --------------------------
        enqueueSnackbar(`Observation failed to save\n${JSON.stringify(error)}`, { variant: 'error', autoHideDuration: snackBarAutoHide + 2000, anchorOrigin: anchorSnackbar });
        throw (error);
      }
    }
    else {
      // --------------------------------
      enqueueSnackbar('Not authenticated for this action', { variant: 'error', autoHideDuration: snackBarAutoHide + 2000, anchorOrigin: anchorSnackbar });
    }
  };

  // *** Alternative save method to update state of observation ***
  const updateObservationStatusHandler = async (observationId, approvalStatus, notify = false) => {
    // ----------------------------------------
    if (isAuthed) {
      // --------------------------------
      let prePublishChange = false;
      if (['review', 'approved'].includes(approvalStatus)) { prePublishChange = true; }

      await saveObservationHandler(null, prePublishChange);
      enqueueSnackbar(
        `Updating observation status to ${approvalStatus}`,
        { variant: 'info', autoHideDuration: snackBarAutoHide, anchorOrigin: anchorSnackbar }
      );

      try {
        setShouldAutosave(false); // Pause auto-saving to allow save to minimise risk of race condition to auto-save
        const putObservationResultset = await putObservation({ inspectionId: observationId }, approvalStatus, false, false, notify, { instance, accounts, inProgress })
        await apiRefreshObservation(putObservationResultset.inspectionId);
        enqueueSnackbar(`Observation updated to ${approvalStatus}`,
          { variant: 'success', autoHideDuration: snackBarAutoHide, anchorOrigin: anchorSnackbar });
      }
      catch (error) {
        enqueueSnackbar(
          `Observation status change failed ${JSON.stringify(error)}`,
          { variant: 'error', autoHideDuration: snackBarAutoHide + 2000, anchorOrigin: anchorSnackbar });
      }
    }
    else {
      // --------------------------------
      enqueueSnackbar('Not authenticated for this action', { variant: 'error', autoHideDuration: snackBarAutoHide + 2000, anchorOrigin: anchorSnackbar });
    }
  };

  // -----------------------------------------------------------------------------------
  // UPDATE CANVAS STORE (BUT DONT CAUSE FULL RERENDER!)
  // -----------------------------------------------------------------------------------
  const updateCanvasStore = (componentId, canvasAsString, fabricCanvas) => {
    canvasStore.current = {
      ...canvasStore.current,
      [componentId]: canvasAsString,
    };
    canvas.current = {
      ...canvas.current,
      [componentId]: fabricCanvas,
    };
  };

  // -----------------------------------------------------------------------------------
  // UPDATE CANVAS STORE METADATA (BUT DONT CAUSE FULL RERENDER!)
  // -----------------------------------------------------------------------------------
  const updateCanvasStoreMetadata = (componentId, metadata) => {

    canvasStoreMetadata.current = {
      ...canvasStoreMetadata.current,
      [componentId]: metadata,
    };

    // A change has been made, switch auto-save back ON
    setShouldAutosave(true);
  };

  // -----------------------------------------------------------------------------------
  // UPDATE PILE OBS CANVAS REFERENCE (BUT DONT CAUSE FULL RERENDER!)
  // -----------------------------------------------------------------------------------
  const updatePileObsCanvasStore = (componentId, fabricCanvas) => {
    // A change has been made, switch auto-save back ON
    setShouldAutosave(true);


    pileObsCanvasStore.current = {
      ...pileObsCanvasStore.current,
      [componentId]: fabricCanvas,
    };
  };

  // -----------------------------------------------------------------------------------
  // ADD NEW COMPONENT
  // -----------------------------------------------------------------------------------
  const addNewComponent = (e, componentType, idx) => {
    // A change has been made, switch auto-save back ON
    setShouldAutosave(true);

    // Initialise an empty component and assign a UUID
    let newComponent = {
      component: components.components[componentType],
      data: {
        componentId: uuidv4(),
        componentType,
      },
    };

    switch (componentType) {
      case 'siteSketch': {
        // --------------------------------------
        newComponent.data.canvas = '';
        newComponent.data.canvasMetadata = {
          backgroundImage: `${window.location.origin}/blank_sketch_background.png`,
          hasBackgroundImage: false,
          imageHeight: 500
        };
        newComponent.data.canvasImage = null;
        newComponent.data.header = {
          keyHeader: null,
          valueHeader: null,
        };
        newComponent.data.referenceData = [];
        updateCanvasStoreMetadata(newComponent.data.componentId, newComponent.data.canvasMetadata);
        updateCanvasStore(newComponent.data.componentId, null, null);
        break;
      }
      case 'pileObservation': {
        // --------------------------------------
        newComponent.data.pileId = null;
        newComponent.data.pileCategory = 'bored pile';
        newComponent.data.pileType = 'reinforced concrete';
        newComponent.data.pileCriteria = ['minimum toe level'];
        newComponent.data.pilingRig = 'other';
        newComponent.data.drillingFluid = 'none';
        newComponent.data.externalDiameter = null;
        newComponent.data.poleDiameter = null;
        newComponent.data.steelSection = null;
        newComponent.data.hasCasing = false;
        newComponent.data.casingType = 'none';
        newComponent.data.casingDiameter = null;
        newComponent.data.topOfCasingLevel = null;
        newComponent.data.casingLength = null;
        newComponent.data.designToeLevel = null;
        newComponent.data.designGeoEmbedment = null;
        newComponent.data.referenceLevel = 'platform ground level';
        newComponent.data.otherReferenceLevelNote = '';
        newComponent.data.otherReferenceLevel = null;
        newComponent.data.pilingPlatformLevel = null;
        newComponent.data.depthToBase = null;
        newComponent.data.observedGeology = [];
        newComponent.data.observedGroundwater = [];
        newComponent.data.otherNotes = null;
        newComponent.data.pileRecommendations = [];
        newComponent.data.canvasImage = null;
        break;
      }
      case 'generalTable':
      case 'shearVaneTable':
      case 'cleggTable':
      case 'nonConformanceRegister':
      case 'healthAndSafetyTakeFive': {
        // --------------------------------------
        newComponent.data.tableIsShown = true;
        const tableFormat = config.dataTableTypes.find(tableFormat => tableFormat.tableType === newComponent.data.componentType);
        // -----------------------------------------------------
        // GENERIC HANDLING OF TABLE ADDITION (FROM CONTEXT)
        // ------------------------------------------------------
        newComponent.data.componentHeader = tableFormat.tableHeader;
        // HEADER
        // -------------
        newComponent.data.header = tableFormat.columnHeaders.map(header => ({ value: header.default }));
        // ROWS
        // -------------
        newComponent.data.rows = [
          {
            id: uuidv4(),
            values: tableFormat.columnValues.map(value => ({ value: value.default })),
          },
        ];
        break;
      }
      case 'stockpileAssessmentTable':
      case 'aialStockpileAssessmentTable': {
        // --------------------------------------
        newComponent.data.tableIsShown = true;
        const tableFormat = config.v2DataTableTypes.find(tableFormat => tableFormat.tableType === newComponent.data.componentType);
        // -----------------------------------------------------
        // GENERIC HANDLING OF TABLE ADDITION (FROM CONTEXT)
        // ------------------------------------------------------
        newComponent.data.componentHeader = tableFormat.tableHeader;
        // HEADER
        // -------------
        newComponent.data.header = tableFormat.columnHeaders.map(header => ({ value: header.default, name: header.name }));
        // ROWS
        // -------------
        newComponent.data.rows = [
          {
            id: uuidv4(),
            values: tableFormat.columnValues.map(value => ({ value: value.default, name: value.name })),
          },
        ];
        // FORMATTING
        // -------------
        newComponent.data = {
          ...newComponent.data,
          fontSize: tableFormat.fontSize,
          columnWidths: tableFormat.columnWidths,
          rowHeaderNames: tableFormat.rowHeaderNames,
          rowHeaderHeaderNames: tableFormat.rowHeaderHeaderNames
        }
        break;
      }
      case 'photo': {
        // --------------------------------------
        newComponent.data.text = null;
        newComponent.data.image = null;
        break;
      }
      case 'recommendation':
      case 'generalText':
      case 'heading': {
        // --------------------------------------
        newComponent.data.textType = componentType;
        newComponent.data.text = null;
        break;
      }
      default:
        // --------------------------------------
        break;
    }

    if (Object.keys(newComponent).length === 0) {
      return;
    }

    
    if (isDefinedAndInitialized(idx)) {
      // ----------------------------------------------------
      // Insert the new component at a particular index
      dispatch({
        type: 'addNewComponentAtIdx',
        payload: {
          newComponent: newComponent,
          idx
        }
      });
    }
    else {
      // ----------------------------------------------------
      // Append the new component
      dispatch({
        type: 'addNewComponent',
        payload: newComponent,
      });
    }
    enqueueSnackbar(`${capitalizeFirstLetters(convertCamelToSpaced(componentType))} added`,
      { variant: 'info', autoHideDuration: snackBarAutoHide, anchorOrigin: anchorSnackbar });
  };

  // -----------------------------------------------------------------------------------
  // DELETE COMPONENT
  // -----------------------------------------------------------------------------------
  const deleteComponent = index => () => {
    // A change has been made, switch auto-save back ON
    setShouldAutosave(true);


    const updatedSections = [...state.observationPage.sections];
    const deletedSection = updatedSections.splice(index, 1);

    // If the deleted section is a sketch we need to update the
    if (isDefinedAndInitialized(deletedSection[0]?.data?.componentId)
      && isDefinedAndInitialized(deletedSection[0]?.data?.componentType)
      && deletedSection[0]?.data?.componentType === 'siteSketch') {
      // ----------------------------------------------------
      const { componentId } = deletedSection[0].data;

      // Pop component from canvasStore
      let canvasStoreUpdate = {
        ...canvasStore.current
      };
      delete canvasStoreUpdate[componentId];
      canvasStore.current = canvasStoreUpdate;
      // Pop component from canvas
      let canvasUpdate = {
        ...canvas.current
      };
      delete canvasUpdate[componentId];
      canvas.current = canvasUpdate;
      // Pop component from canvasStoreMetadata
      let canvasStoreMetadataUpdate = {
        ...canvasStoreMetadata.current
      };
      delete canvasStoreMetadataUpdate[componentId];
      canvasStoreMetadata.current = canvasStoreMetadataUpdate;
    }

    dispatch({
      type: 'deleteComponent',
      payload: updatedSections,
    });
    enqueueSnackbar(`${capitalizeFirstLetters(convertCamelToSpaced(deletedSection[0].data.componentType))} deleted`,
      { variant: 'info', autoHideDuration: snackBarAutoHide, anchorOrigin: anchorSnackbar });
  };

  // -----------------------------------------------------------------------------------
  // REORDER COMPONENT
  // -----------------------------------------------------------------------------------
  const componentUpHandler = (e, componentId, up) => {
    // A change has been made, switch auto-save back ON
    setShouldAutosave(true);


    const position = state.observationPage.sections.findIndex(section => section.data.componentId === componentId);
    const shift = (up) ? -1 : 1;
    let updatedSections = [...state.observationPage.sections];
    updatedSections = arrayMove(state.observationPage.sections, position, position + shift);
    dispatch({
      type: 'updateSectionState',
      payload: updatedSections,
    });
  };

  // -----------------------------------------------------------------------------------
  // TOGGLE AUTOSAVE
  // -----------------------------------------------------------------------------------
  const handleAutoSaveToggle = () => {
    const settingsUpdate = { ...settings, autoSave: !settings.autoSave };
    localStorage.setItem('siteObservationsSettings', JSON.stringify(settingsUpdate));
    setSettings(settingsUpdate);
  }

  return (
    <Box>
      {
        !isAuthed &&
        <BasicLoader />
      }
      {
        isAuthed &&
        <>
          <Modal
            open={pdfModalOpen}
            onClose={handlePdfModalClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <Box className={classes.modal} top='50%' left='50%' position='absolute' display="flex" alignItems="center" flexDirection="column">
              <CircularProgress />
              <Typography>Downloading your observation...</Typography>
            </Box>
          </Modal>
          <ObservationContent
            handleClientObservationStateUpdate={handleClientObservationStateUpdate}
            toggleJobData={(e, componentType) => { handleClientObservationStateUpdate(e, componentType, null, null); }}
            addNewComponent={addNewComponent}
            deleteComponent={deleteComponent}
            saveObservationHandler={saveObservationHandler}
            updateObservationStatusHandler={updateObservationStatusHandler}
            downloadPdfHandler={pdfHandler}
            componentUpHandler={(e, componentId, up) => { componentUpHandler(e, componentId, up); }}
            updateCanvasStore={updateCanvasStore}
            updateCanvasStoreMetadata={updateCanvasStoreMetadata}
            updatePileObsCanvasStore={updatePileObsCanvasStore}
            canvasStore={canvasStore.current}
            canvasStoreMetadata={canvasStoreMetadata.current}
            settings={settings}
            handleAutoSaveToggle={handleAutoSaveToggle}
            match={match}
            shouldAutosave={shouldAutosave}
            pauseAutoSave={pauseAutoSave}
            restartAutoSave={restartAutoSave}
            firstLoadComplete={firstLoadComplete.current}
            apiRefreshObservationJobData={apiRefreshObservationJobData}
          />
        </>
      }
    </Box>
  );
}

ObservationContentContainer.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
}


export default withStyles(styles)(ObservationContentContainer);
