import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import { Box, Typography, TextField } from '@material-ui/core';
import DeletablePaperLarge from './deletablePaperLarge';

// Custom Config
import { config } from '../config/generalConfig';
import { isDefinedAndInitialized } from '../helpers/helpers';



const GeneralText = (props) => {
  // ----------------------------------------------------
  const { componentData, deleteHandler, componentUpHandler, handleClientObservationStateUpdate, headingCount } = props;
  const { componentType } = componentData; 
  const textFormat = config.generalTextTypes
  .find(textFormatSearch => textFormatSearch.textType === componentData.textType);

  const isHeading = componentType === 'heading';
  const inputFontSize = isHeading ? '1.2rem' : '0.9rem';
  const inputFontWeight = isHeading ? 'bold' : 'normal';

  let setHeadingCount = null;
  if (isDefinedAndInitialized(headingCount)) {
    // ----------------------------------------------
    setHeadingCount = headingCount;
  }


  // This method is called onChange for the textfield
  // When changed we mutate the DynamicInvestigationContent->Section's data object and bubble back up the component tree
  const updateText = (e) => {
    // Take the inital componentData and mutate before bubbling back up the component tree
    const componentDataUpdate = componentData;
    componentDataUpdate.text = e.target.value ? e.target.value : null;
    handleClientObservationStateUpdate(
      e, componentType, componentData.componentId, componentDataUpdate,
    );
  };

  const setPlaceholder = isDefinedAndInitialized(componentData?.placeholder) ? componentData?.placeholder : "";
  let setMinRows;
  if (isDefinedAndInitialized(componentData?.textFieldRows)) {
    // ---------------------------------------------- 
    setMinRows = componentData?.textFieldRows;
  }

  return (
    <DeletablePaperLarge
      title={ "" }
      onClick={deleteHandler}
      componentId={componentData.componentId}
      componentUpHandler={componentUpHandler}
    >
      <Box width="100%" display="flex" flexDirection="row" alignItems="center"  >
        {
          setHeadingCount &&
          <Typography style={{ fontSize: '1.2rem', fontWeight: 'bold', paddingRight: '8px' }}>
            { setHeadingCount.toString().padStart(2, '0') }
          </Typography>
        }
        <TextField
          id="standard-multiline-static"
          label={textFormat.placeholder}
          multiline
          fullWidth
          variant="outlined"
          onChange={updateText}
          value={componentData.text ? componentData.text : ''}
          placeholder={setPlaceholder}
          minRows={setMinRows}
          inputProps={{
            style: {
              fontSize: inputFontSize,
              fontWeight: inputFontWeight
            }
          }}
        />
      </Box>
    </DeletablePaperLarge>
  );
};


GeneralText.propTypes = {
  classes: PropTypes.object,
  componentData: PropTypes.object,
  deleteHandler: PropTypes.func,
  handleClientObservationStateUpdate: PropTypes.func,
  componentUpHandler: PropTypes.func,
  headingCount: PropTypes.number
};

export default GeneralText;
