import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

// MSAL imports
import { PublicClientApplication, EventType } from "@azure/msal-browser";

// Material UI
import { MuiThemeProvider, createTheme, CssBaseline, Snackbar, Button } from '@material-ui/core';
import { blue, lightBlue, yellow,  } from '@material-ui/core/colors';

// REACT-QUERY
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'

// Custom Components
import Nav from './components/nav';
import ScrollToTop from './components/scrollToTop';

// Service-worker
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';


export const msalInstance = new PublicClientApplication({
  auth: {
    clientId: window.config.AZURE_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${window.config.AZURE_TENANT_ID}`,
    navigateToLoginRequestUrl: true,
    redirectUri: `${window.location.origin}`
  },
  cache: {
    cacheLocation: 'localStorage'
  }
});

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
  }
});


const theme = createTheme({
  typography: {
    fontFamily: [
      'Noto Sans SC',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Open Sans',
      'Helvetica Neue',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: {
      main: (window.config.ENV === 'prod') ? blue[50] : yellow[600],
    },
    secondary: {
      main: (window.config.ENV === 'prod') ? lightBlue[800] : yellow[800],
    },
  },
  overrides: {
    MuiContainer: {
      root: {
        backgroundColor: '#eceff1'
      }
    },
    MuiButton: {
      textPrimary: {
        color: '#0277bd',
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: '#0277bd',
        color: 'white',
      },
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: '#0277bd',
      },
      thumb: {
        backgroundColor: '#0277bd',
      },
      noPoint: {
        backgroundColor: '#0277bd',
      },
    },
    MuiInputBase: {
      root: {
        width: '100%'
      }
    },
    MuiPickersClockNumber: {
      clockNumberSelected: {
        backgroundColor: '#0277bd',
        color: 'white',
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: "Grey"
      }
    },
    MuiDataGrid: {
      root: {
        '& .MuiDataGrid-colCellWrapper': {
          overflow: 'visible'
        }
      }
    }
  },
});


export default function App(props) {
  // Service worker registration handled at this level for now
  const [showReloadNotification, setShowReloadNotification] = useState(false);
  const [newServiceWorker, setNewServiceWorker] = useState(null);

  const queryClient = new QueryClient();

  const onSWUpdate = (registration) => {
    // --------------------------------
    setShowReloadNotification(true);
    setNewServiceWorker(registration.waiting);
  }

  useEffect(() => {
    // -------------------------------
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA
    serviceWorkerRegistration.register({ onUpdate: onSWUpdate });
  }, [])

  const reloadPage = () => {
    // ------------------------------
    newServiceWorker?.postMessage({ type: 'SKIP_WAITING' });
    setShowReloadNotification(false);
    window.location.reload({ forcedReload: true });
  }

  return (
    <QueryClientProvider client={queryClient}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <ScrollToTop>
            <SnackbarProvider maxSnack={3}>
              <div className="App" {...props} style={{backgroundColor: '#eceff1'}}>
                <Nav msalpca={msalInstance} />
              </div>
            </SnackbarProvider>
            <Snackbar
              open={showReloadNotification}
              message={`A new version of Site Observations is available`}
              onClick={reloadPage}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={reloadPage}
                >
                  Reload
                </Button>
              }
            />
          </ScrollToTop>
        </BrowserRouter>
      </MuiThemeProvider>
    </QueryClientProvider>
  );
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

