// AWS SDK v3
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import { S3Client, GetObjectCommand, PutObjectCommand } from '@aws-sdk/client-s3';
import{ getSignedUrl } from '@aws-sdk/s3-request-presigner';

// AWS Fetch Client Middleware (used for SigV4 signing)
import { AwsClient } from 'aws4fetch';
import { isEmpty } from 'lodash';

// MSAL
import { InteractionRequiredAuthError } from '@azure/msal-browser';

// lUXON
import { DateTime } from 'luxon';

// Custom Helpers
import { isDefinedAndInitialized, timeout, shouldUseUserGroupCache, shouldUseCognitoCache } from '../helpers/helpers';
import { getFileTypeExtension, getResizedImageAsBlob, readFileAsBlob, readImageFileAsBlob } from '../components/genericComponents/Files';

// EXPORT AWS CONFIGURATION
export const AWS_REGION = window.config.AWS_REGION;
export const COGNITO_REGION = window.config.AWS_REGION;
export const USER_POOL_ID = window.config.LEGACY_USER_POOL_ID;
export const IDENTITY_POOL_ID = window.config.IDENTITY_POOL_ID;
export const COGNITO_CLIENT_ID = window.location.hostname === 'localhost'
  ? window.config.LEGACY_COGNITO_LOCALHOST_CLIENT_ID : window.config.LEGACY_COGNITO_DPY_CLIENT_ID;
export const COGNITO_DOMAIN = window.config.LEGACY_USER_POOL_DOMAIN;
export const OAUTH_FLOW = window.config.AUTH_FLOW;
export const API_KEY = window.config.API_KEY;
export const JOB_API_KEY = window.config.JOB_API_KEY;
export const HS_API_KEY = window.config.HS_API_KEY;
export const GEOCODING_API_KEY = window.config.GEOCODING_API_KEY;
export const API_DOMAIN = window.config.API_DOMAIN;
export const JOB_API_DOMAIN = window.config.JOB_API_DOMAIN;
export const HS_API_DOMAIN = window.config.HS_API_DOMAIN;
export const GEOCODING_API_DOMAIN = window.config.GEOCODING_API_DOMAIN;
export const REST_API_STAGE = window.config.REST_API_STAGE;
export const ES_DOMAIN = window.config.ES_DOMAIN;
export const STATIC_ASSETS_BUCKET_NAME = window.config.STATIC_ASSETS_BUCKET_NAME;
export const LEGACY_STATIC_ASSETS_BUCKET_NAME = window.config.LEGACY_STATIC_ASSETS_BUCKET_NAME;
export const PUBLIC_BUCKET_NAME = window.config.PUBLIC_BUCKET_NAME;
export const AZURE_HEALTH_AND_SAFETY_GROUP_ID = window.config.AZURE_HEALTH_AND_SAFETY_GROUP_ID;
export const LINZ_API_KEY = window.config.LINZ_API_KEY;


export const AZURE_TENANT_ID = window.config.AZURE_TENANT_ID;
const AZURE_AD_LOGIN_PROVIDER = `login.microsoftonline.com/${AZURE_TENANT_ID}/v2.0`;


const getApiServiceError = async (response) => {
  // --------------------------------
  let responseJson = await response.json();
  return new Error(`Error in request: ${response.status}${isDefinedAndInitialized(responseJson) ? `, ${JSON.stringify(responseJson)}` : ''}`)

}

// Requires the Microsoft Authentication Library (MSAL) useMsal hook result
// This function acquires an ID token silently either from the cache
// This can be used for Microsoft Graph API calls for the provided scope (User.Read at present)
export const apiGetMSIdToken = async ({ instance, accounts, inProgress }) => {
  // -------------------------------------
  let request = {
    account: accounts[0],
    scopes: ["User.Read", "offline_access"]
  }
  let response = null;

  // If ID token expiry is coming up in 5 minutes or less, then force refresh
  // This aims to fix a AWS IAM error whereby expired ID Tokens prevent credentials being refreshed
  if ( DateTime.local().plus({ 'minutes': 5 }) > DateTime.fromSeconds(accounts[0]?.idTokenClaims.exp)) {
    request.forceRefresh = true;
  }

  if (inProgress === "none" && accounts.length > 0) {
    // Retrieve an access token
    try {
        response = await instance.acquireTokenSilent({
          ...request,
          redirectUri: `${window.location.origin}/auth.html` // Silent redirect URI (blank html page)
        });

        // ID TOKEN is used on our API side - so check if this is returned, if not, try to acquire again with a forceRefresh
        if (!('idToken' in response) || (isDefinedAndInitialized(response?.idToken) && response?.idToken === '')) {
          // -------------------------------------
          console.info('fallback token acquisition');
          request.forceRefresh = true;
          response = await instance.acquireTokenSilent(request);
        }
    }
    catch (error) {
      console.error(error);
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        sessionStorage.setItem('redirectPath', window.location.pathname);
        response = await instance.acquireTokenRedirect(request);
      }
      else {
        throw new Error(error ?? 'An unknown error has occurred');
      }
    }

    if (isDefinedAndInitialized(response?.idToken)) {
      let userGroups = await apiGetMSUserGroups({ instance, accounts, inProgress });
      localStorage.setItem('siteObservationsUserGroups', JSON.stringify(userGroups));
      localStorage.setItem('siteObservationsUserGroupsRefreshTimestamp', DateTime.local().toSeconds());
      return response.idToken;
    }
    return response;
  }
  return response;
};


// Requires the Microsoft Authentication Library (MSAL) useMsal hook result
// This function acquires an access token silently either from the cache
// This can be used for Microsoft Graph API calls for the provided scope (User.Read at present)
export const apiGetMSAccessToken = async ({ instance, accounts, inProgress }) => {
  // -------------------------------------
  let request = {
    account: accounts[0],
    scopes: ["User.Read", "offline_access"],
    forceRefresh: false
  }
  let response = null;

  if (inProgress === "none" && accounts.length > 0) {
    // Retrieve an access token
    try {
        response = await instance.acquireTokenSilent({
          ...request,
          redirectUri: `${window.location.origin}/auth.html`
        });
    }
    catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        sessionStorage.setItem('redirectPath', window.location.pathname);
        response = await instance.acquireTokenRedirect(request);
      }
      else {
        throw new Error(error ?? 'An unknown error has occurred');
      }
    }

    if (response.accessToken) {
        return response.accessToken;
    }
    return response;
  }
};


// Requires the Microsoft Authentication Library (MSAL) useMsal hook result
// This function acquires an access token silently either from the cache
// This can be used for Microsoft Graph API calls for the provided scope (User.Read at present)
export const apiGetMSUserGroups = async ({ instance, accounts, inProgress }) => {
  // -------------------------------------
  let groups = [];
  // CHECK CACHE BEFORE EXECUTING GROUP QUERY
  if( shouldUseUserGroupCache() ) {
    // ---------------------------------
    groups = JSON.parse(localStorage.getItem('siteObservationsUserGroups'))
  }
  else {
    let accessToken = await apiGetMSAccessToken({ instance, accounts, inProgress });

    let headers = new Headers();
    let bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    let options = {
      method: "GET",
      headers
    };

    const graphEndpoint = "https://graph.microsoft.com/v1.0/me/memberOf";

    let response = await fetch(graphEndpoint, options);
    let responseBody = await response.json();
    groups = responseBody.value
                              .filter(group => isDefinedAndInitialized(group?.displayName) && group.displayName.includes('Site Observations'))
                              .map(group => { return { displayName: group.displayName, description: group.description } });
  }

  return groups;
};


// Requires the Microsoft Authentication Library (MSAL) useMsal hook result
// This function acquires AWS IAM credentials for our cross-cloud-platform APIs in AWS
// This function relies on the Cognito Identity platform to acquire temporary AWS credentials
//  and is based on the OPENID Connect Standards
// Note: it also has migrated to the AWS-SDK v3 which represents a slightly more opinionated way
//    of interacting with the AWS APIs than the v2 SDK.
export const setAWSIAMCredentials = async ({ instance, accounts, inProgress }) => {
  // ------------------------------------------------------
  let credentials = null;
  // 1) Check for cached credentials which are still valid
  // CHECK CACHE BEFORE EXECUTING GROUP QUERY
  if( shouldUseCognitoCache() ) {
    // ---------------------------------
    credentials = JSON.parse(localStorage.getItem('tempCognito'));
  }
  else {
    // 2) Use function to get id_token value (refresh handled automatically if required)
    const idToken = await apiGetMSIdToken({ instance, accounts, inProgress });

    if (isDefinedAndInitialized(idToken)) {
      // -----------------------------------------------
      // Using AWS SDK v3 (refer: https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/modules/_aws_sdk_credential_provider_cognito_identity.html#fromcognitoidentitypool-1)
      credentials = await fromCognitoIdentityPool({
        client: new CognitoIdentityClient({region: AWS_REGION }),
        identityPoolId: IDENTITY_POOL_ID,
        logins: {
          [ AZURE_AD_LOGIN_PROVIDER ]: idToken
        }
      })();

      const { identityId, accessKeyId, secretAccessKey, sessionToken } = credentials;

      localStorage.setItem('tempCognito', JSON.stringify({ identityId, accessKeyId, secretAccessKey, sessionToken }));
      // Minus 5 minutes off expiry to ensure tokens remain fresh
      localStorage.setItem('tempCognitoExpiry', DateTime.fromJSDate(credentials.expiration).minus({ minutes: 5 }).toISO());
    }
  }

  return credentials;
};


// Migrated August 2021 to MSAL Authentication + AWS Authorization
export const getCurrentJobs = async ({ instance, accounts, inProgress }) => {
  // -------------------------------------
  const credentials = await setAWSIAMCredentials({ instance, accounts, inProgress });
  if (!isDefinedAndInitialized(credentials)) {
    // -----------------------------------------
    throw new Error('AWS Credentials could not be set - likely the ID token is not yet set properly');
  }
  const { accessKeyId, secretAccessKey, sessionToken } = credentials;
  const idToken = await apiGetMSIdToken({ instance, accounts, inProgress });

  const client = new AwsClient({
    accessKeyId,              // required, akin to AWS_ACCESS_KEY_ID
    secretAccessKey,          // required, akin to AWS_SECRET_ACCESS_KEY
    sessionToken,             // akin to AWS_SESSION_TOKEN if using temp credentials
    service: 'execute-api',   // AWS service, by default parsed at fetch time
    region: AWS_REGION,       // AWS region, by default parsed at fetch time
    retries: 5                // number of retries before giving up, defaults to 10, set to 0 for no retrying
  });

  // Query current jobs endpoint
  // Jobs for last 5 years considered current
  // A scalability consideration
  const offsets = [0, 1, 2, 3, 4];
  const yearQuery =`year=${offsets.map(offset => DateTime.local().year - offset).join('&year=')}`;
  const currentJobsResultSet = await client.fetch(`${JOB_API_DOMAIN}/${REST_API_STAGE}/job?format=min&${yearQuery}`, {
    method: 'get',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': JOB_API_KEY,
      'id-token': idToken
    }
  });

  if (currentJobsResultSet.ok) {
    return currentJobsResultSet.json();
  }
  else {
    throw await getApiServiceError(currentJobsResultSet);
  }
};


export const searchJob = async (query, { instance, accounts, inProgress }) => {
  // -------------------------------------
  const credentials = await setAWSIAMCredentials({ instance, accounts, inProgress });
  if (!isDefinedAndInitialized(credentials)) {
    // -----------------------------------------
    throw new Error('AWS Credentials could not be set - likely the ID token is not yet set properly');
  }
  const { accessKeyId, secretAccessKey, sessionToken } = credentials;
  const idToken = await apiGetMSIdToken({ instance, accounts, inProgress });

  const client = new AwsClient({
    accessKeyId,              // required, akin to AWS_ACCESS_KEY_ID
    secretAccessKey,          // required, akin to AWS_SECRET_ACCESS_KEY
    sessionToken,             // akin to AWS_SESSION_TOKEN if using temp credentials
    service: 'execute-api',   // AWS service, by default parsed at fetch time
    region: AWS_REGION,       // AWS region, by default parsed at fetch time
    retries: 5                // number of retries before giving up, defaults to 10, set to 0 for no retrying
  });

  const searchJobsResultSet = await client.fetch(`${JOB_API_DOMAIN}/${REST_API_STAGE}/sf-job?q=${encodeURIComponent(query)}`, {
    method: 'get',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': JOB_API_KEY,
      'id-token': idToken
    }
  });

  if (searchJobsResultSet.ok) {
    return searchJobsResultSet.json();
  }
  else {
    throw await getApiServiceError(searchJobsResultSet);
  }
};


// Migrated August 2021 to MSAL Authentication + AWS Authorization
export const getJobDetail = async (jobId, { instance, accounts, inProgress }) => {
  // -------------------------------------
  const credentials = await setAWSIAMCredentials({ instance, accounts, inProgress });
  if (!isDefinedAndInitialized(credentials)) {
    // -----------------------------------------
    throw new Error('AWS Credentials could not be set - likely the ID token is not yet set properly');
  }
  const { accessKeyId, secretAccessKey, sessionToken } = credentials;
  const idToken = await apiGetMSIdToken({ instance, accounts, inProgress });

  const client = new AwsClient({
    accessKeyId,              // required, akin to AWS_ACCESS_KEY_ID
    secretAccessKey,          // required, akin to AWS_SECRET_ACCESS_KEY
    sessionToken,             // akin to AWS_SESSION_TOKEN if using temp credentials
    service: 'execute-api',   // AWS service, by default parsed at fetch time
    region: AWS_REGION,       // AWS region, by default parsed at fetch time
    retries: 5                // number of retries before giving up, defaults to 10, set to 0 for no retrying
  });

  // Query current jobs endpoint
  const path = 'sf-job';

  const jobDetailResultSet = await client.fetch(`${JOB_API_DOMAIN}/${REST_API_STAGE}/${path}/${encodeURIComponent(jobId)}`, {
    method: 'get',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': JOB_API_KEY,
      'id-token': idToken
    }
  });

  if (jobDetailResultSet.ok) {
    return jobDetailResultSet.json();
  }
  else if (jobDetailResultSet.status === 404) {
    throw new Error(`Not found`);
  }
  else {
    throw await getApiServiceError(jobDetailResultSet);
  }
};


// Migrated August 2021 to MSAL Authentication + AWS Authorization
export const createObservation = async (body, { instance, accounts, inProgress }) => {
  // -------------------------------------
  const credentials = await setAWSIAMCredentials({ instance, accounts, inProgress });
  if (!isDefinedAndInitialized(credentials)) {
    // -----------------------------------------
    throw new Error('AWS Credentials could not be set - likely the ID token is not yet set properly');
  }
  const { accessKeyId, secretAccessKey, sessionToken } = credentials;
  const idToken = await apiGetMSIdToken({ instance, accounts, inProgress });

  const client = new AwsClient({
    accessKeyId,              // required, akin to AWS_ACCESS_KEY_ID
    secretAccessKey,          // required, akin to AWS_SECRET_ACCESS_KEY
    sessionToken,             // akin to AWS_SESSION_TOKEN if using temp credentials
    service: 'execute-api',   // AWS service, by default parsed at fetch time
    region: AWS_REGION,       // AWS region, by default parsed at fetch time
    retries: 5                // number of retries before giving up, defaults to 10, set to 0 for no retrying
  });

  // Post new observation request to observation endpoint
  const observationCreationResultSet = await client.fetch(`${API_DOMAIN}/${REST_API_STAGE}/observation`, {
    method: 'post',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
      'x-api-key': API_KEY,
    },
    body: JSON.stringify(body),
  });

  if (observationCreationResultSet.ok) {
    return observationCreationResultSet.json();
  }
  else {
    throw await getApiServiceError(observationCreationResultSet);
  }
};

// Migrated August 2021 to MSAL Authentication + AWS Authorization
export const getObservationDetail = async (observationId, { instance, accounts, inProgress }, olap=null) => {
  // -------------------------------------
  if (!observationId) {
    return null;
  }

  let olapQuery = ``;
  if (olap) {
    // -------------------------------------
    olapQuery = `?olap=true`;
  }

  const credentials = await setAWSIAMCredentials({ instance, accounts, inProgress });
  if (!isDefinedAndInitialized(credentials)) {
    // -----------------------------------------
    throw new Error('AWS Credentials could not be set - likely the ID token is not yet set properly');
  }
  const { accessKeyId, secretAccessKey, sessionToken } = credentials;
  const idToken = await apiGetMSIdToken({ instance, accounts, inProgress });

  const client = new AwsClient({
    accessKeyId,              // required, akin to AWS_ACCESS_KEY_ID
    secretAccessKey,          // required, akin to AWS_SECRET_ACCESS_KEY
    sessionToken,             // akin to AWS_SESSION_TOKEN if using temp credentials
    service: 'execute-api',   // AWS service, by default parsed at fetch time
    region: AWS_REGION,       // AWS region, by default parsed at fetch time
    retries: 5                // number of retries before giving up, defaults to 10, set to 0 for no retrying
  });

  const observationDetailResultSet = await client.fetch(`${API_DOMAIN}/${REST_API_STAGE}/observation/${observationId}${olapQuery}`, {
    method: 'get',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
      'x-api-key': API_KEY,
    },
  });

  if (observationDetailResultSet.ok) {
    return observationDetailResultSet.json();
  }
  else {
    throw await getApiServiceError(observationDetailResultSet);
  }
};

// Migrated August 2021 to MSAL Authentication + AWS Authorization
export const putObservation = async (observation, statusUpdate = null, prePublishChange=false, autoSave=false, notify=false, { instance, accounts, inProgress }) => {
  // -------------------------------------
  const credentials = await setAWSIAMCredentials({ instance, accounts, inProgress });
  if (!isDefinedAndInitialized(credentials)) {
    // -----------------------------------------
    throw new Error('AWS Credentials could not be set - likely the ID token is not yet set properly');
  }
  const { accessKeyId, secretAccessKey, sessionToken } = credentials;
  const idToken = await apiGetMSIdToken({ instance, accounts, inProgress });

  const client = new AwsClient({
    accessKeyId,              // required, akin to AWS_ACCESS_KEY_ID
    secretAccessKey,          // required, akin to AWS_SECRET_ACCESS_KEY
    sessionToken,             // akin to AWS_SESSION_TOKEN if using temp credentials
    service: 'execute-api',   // AWS service, by default parsed at fetch time
    region: AWS_REGION,       // AWS region, by default parsed at fetch time
    retries: 5                // number of retries before giving up, defaults to 10, set to 0 for no retrying
  });

  let paramList = [];

  if (statusUpdate) {
    paramList = [...paramList, { name: 'statusUpdate', value: statusUpdate }];
  }

  if (prePublishChange) {
    paramList = [...paramList, { name: 'prePublishChange', value: prePublishChange }];
  }

  if (autoSave) {
    paramList = [...paramList, { name: 'autoSave', value: autoSave }];
  }

  if (notify) {
    paramList = [...paramList, { name: 'notify', value: notify }];
  }

  let url = new URL(`${API_DOMAIN}/${REST_API_STAGE}/observation/${observation.inspectionId}`);
  paramList.forEach(param => url.searchParams.append(param.name, param.value));

  const observationUpdateResultSet = await client.fetch(url, {
    method: 'put',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
      'x-api-key': API_KEY,
      'id-token': idToken
    },
    body: JSON.stringify(observation),
  });

  if (observationUpdateResultSet.ok) {
    return observationUpdateResultSet.json();
  }
  else {
    throw await getApiServiceError(observationUpdateResultSet);
  }
};

// Migrated August 2021 to MSAL Authentication + AWS Authorization
export const deleteObservation = async (observationId, { instance, accounts, inProgress }) => {
  // -------------------------------------
  if (!observationId) {
    return null;
  }

  const credentials = await setAWSIAMCredentials({ instance, accounts, inProgress });
  if (!isDefinedAndInitialized(credentials)) {
    // -----------------------------------------
    throw new Error('AWS Credentials could not be set - likely the ID token is not yet set properly');
  }
  const { accessKeyId, secretAccessKey, sessionToken } = credentials;
  const idToken = await apiGetMSIdToken({ instance, accounts, inProgress });

  const client = new AwsClient({
    accessKeyId,              // required, akin to AWS_ACCESS_KEY_ID
    secretAccessKey,          // required, akin to AWS_SECRET_ACCESS_KEY
    sessionToken,             // akin to AWS_SESSION_TOKEN if using temp credentials
    service: 'execute-api',   // AWS service, by default parsed at fetch time
    region: AWS_REGION,       // AWS region, by default parsed at fetch time
    retries: 5                // number of retries before giving up, defaults to 10, set to 0 for no retrying
  });

  const deleteObservationResultSet = await client.fetch(`${API_DOMAIN}/${REST_API_STAGE}/observation/${observationId}`, {
    method: 'delete',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
      'x-api-key': API_KEY,
    },
  });

  if (deleteObservationResultSet.ok) {
    return {};
  }
  else {
    throw await getApiServiceError(deleteObservationResultSet);
  }
};

// Migrated August 2021 to MSAL Authentication + AWS Authorization
export const publishObservation = async (observationId, { instance, accounts, inProgress }) => {
  // -------------------------------------
  const credentials = await setAWSIAMCredentials({ instance, accounts, inProgress });
  if (!isDefinedAndInitialized(credentials)) {
    // -----------------------------------------
    throw new Error('AWS Credentials could not be set - likely the ID token is not yet set properly');
  }
  const { accessKeyId, secretAccessKey, sessionToken } = credentials;
  const idToken = await apiGetMSIdToken({ instance, accounts, inProgress });

  const client = new AwsClient({
    accessKeyId,              // required, akin to AWS_ACCESS_KEY_ID
    secretAccessKey,          // required, akin to AWS_SECRET_ACCESS_KEY
    sessionToken,             // akin to AWS_SESSION_TOKEN if using temp credentials
    service: 'execute-api',   // AWS service, by default parsed at fetch time
    region: AWS_REGION,       // AWS region, by default parsed at fetch time
    retries: 5                // number of retries before giving up, defaults to 10, set to 0 for no retrying
  });

  const publishObservationResultSet = await client.fetch(`${API_DOMAIN}/${REST_API_STAGE}/observation/${observationId}`, {
    method: 'post',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
      'x-api-key': API_KEY,
    },
  });

  if (publishObservationResultSet.ok) {
    return publishObservationResultSet.json();
  }
  else {
    throw await getApiServiceError(publishObservationResultSet);
  }
};

// No requirement to migrate to MSAL Authentication + AWS Authorization
export const readImageFilePromisified = file => new Promise((resolve, reject) => {
  // -------------------------------------------------
  const reader = new FileReader();
  reader.onload = (e) => { 
    const img = new Image(); 
    img.src = e.target.result; 
    img.onload = () => {
      return resolve(img); };
    }
  reader.onerror = (e) => {
    console.error(`Failed to read file!\n\n${reader.error}`);
    reader.abort();
    reject(e);
  };
  return reader.readAsDataURL(file);
});

// Migrated August 2021 to MSAL Authentication + AWS Authorization
export const uploadImageToS3 = async (blob, fileName, extension, albumName, { instance, accounts, inProgress }) => {
  // -----------------------------------------
  if (!blob) {
    throw new Error('Please choose a file to upload first.');
  }

  // 3) Use function to get id_token value (refresh handled automatically if required)
  const credentials = await setAWSIAMCredentials({ instance, accounts, inProgress });
  if (!isDefinedAndInitialized(credentials)) {
    // -----------------------------------------
    throw new Error('AWS Credentials could not be set - likely the ID token is not yet set properly');
  }
  // const { accessKeyId, secretAccessKey, sessionToken } = credentials;
  await apiGetMSIdToken({ instance, accounts, inProgress });

  const bucketName = STATIC_ASSETS_BUCKET_NAME;
  const s3Key = `${albumName.split('/').map(folder => encodeURIComponent(folder)).join('/')}/${fileName}.${extension}`;

  // 4) Using AWS SDK v3 (for setting credentials, refer: https://docs.aws.amazon.com/sdk-for-javascript/v3/developer-guide/loading-browser-credentials-cognito.html)
  const s3Client = new S3Client({
    region: AWS_REGION,
    credentials
  });
  const command = new PutObjectCommand({
    Bucket: bucketName,
    Key: s3Key,
    ContentLength: blob.size,
    Body: blob
  });

  await s3Client.send(command);

  return {
    filename: fileName,
    location: `https://${bucketName}.s3.${AWS_REGION}.amazonaws.com/${s3Key}`,
  };
};

// Migrated August 2021 to MSAL Authentication + AWS Authorization
export const getPresignedUrlS3GetObject = async (path, type='url', { instance, accounts, inProgress }) => {
  // -----------------------------------------
  if (!path) {
    throw new Error('Please submit a path to be presigned.');
  }

  // 1) Confirm the bucket name 
  // (support for legacy bucket + public bucket)
  let bucketName = STATIC_ASSETS_BUCKET_NAME;
  if (path.includes(LEGACY_STATIC_ASSETS_BUCKET_NAME)) {
    // ------------------------------
    bucketName = LEGACY_STATIC_ASSETS_BUCKET_NAME;
  }
  else if (path.includes('hazard-observation-media-')) {
    // ------------------------------
    bucketName = PUBLIC_BUCKET_NAME;
  }


  // This function was initially built to handle S3 URLs and make SDK calls based on them
  // It has since been updated to be configurable and use S3 object paths as well
  let s3Key = path;  
  if (type === 'url') {
    // --------------------------------
    // Need to handle different S3 object path types
    // (the more modern approach seems to have the bucketname in the path)
    s3Key = new URL(path).pathname.substring(1);
    if (s3Key.includes(bucketName)) { s3Key = s3Key.split(bucketName)[1].substring(1); }
  }

  // 3) Use function to get id_token value (refresh handled automatically if required)
  const idToken = await apiGetMSIdToken({ instance, accounts, inProgress });

  // 4) Using AWS SDK v3 (for setting credentials, refer: https://docs.aws.amazon.com/sdk-for-javascript/v3/developer-guide/loading-browser-credentials-cognito.html)
  const s3Client = new S3Client({
    region: AWS_REGION,
    credentials: fromCognitoIdentityPool({
      client: new CognitoIdentityClient({region: AWS_REGION }),
      identityPoolId: IDENTITY_POOL_ID,
      logins: {
        [ AZURE_AD_LOGIN_PROVIDER ]: idToken
      }
    })
  });
  const command = new GetObjectCommand({
    Bucket: bucketName,
    Key: s3Key
  });

  const presignedUrl = await getSignedUrl(s3Client, command, { expiresIn: 3600 });

  return {
    path,
    presigned: presignedUrl
  };
};

// Migrated August 2021 to MSAL Authentication + AWS Authorization
export const searchOLAP = async (query = null, supportingParam = null, { instance, accounts, inProgress }) => {
  // -----------------------------------------
  const idToken = await apiGetMSIdToken({ instance, accounts, inProgress });
  const credentials = await setAWSIAMCredentials({ instance, accounts, inProgress });
  if (!isDefinedAndInitialized(credentials)) {
    // -----------------------------------------
    throw new Error('AWS Credentials could not be set - likely the ID token is not yet set properly');
  }
  const { accessKeyId, secretAccessKey, sessionToken } = credentials;

  const client = new AwsClient({
    accessKeyId,              // required, akin to AWS_ACCESS_KEY_ID
    secretAccessKey,          // required, akin to AWS_SECRET_ACCESS_KEY
    sessionToken,             // akin to AWS_SESSION_TOKEN if using temp credentials
    service: 'execute-api',   // AWS service, by default parsed at fetch time
    region: AWS_REGION,       // AWS region, by default parsed at fetch time
    retries: 5                // number of retries before giving up, defaults to 10, set to 0 for no retrying
  });

  const SUPPORTED_QUERIES = {
    DEFAULT: 'lastMonthsObservations',
    LAST_MONTH: 'lastMonthsObservations',
    MY_LAST_3_MONTHS: 'myLast3MonthsObservations',
    MONTH_STATUS_SEARCH: 'monthStatusSearch',
    MONTH_TYPE_SEARCH: 'monthTypeSearch',
    MONTH_SUMMARY: 'monthSummary',
    JOB: 'jobSearch',
    STATUS: 'statusSearch',
    MONTH_OBSERVATIONS: 'calendarMonthObservations'
  };

  // Querystring Parameters
  let paramList = [
    {
      name: 'qs',
      value: (query) ? SUPPORTED_QUERIES[query] : SUPPORTED_QUERIES.DEFAULT
    }
  ];
  if (query === 'MONTH_STATUS_SEARCH') {
    supportingParam.filter(param => ['status', 'date'].includes(param.name))
      .map(param => {
        paramList.push(param);
      });
  }
  if (query === 'MONTH_TYPE_SEARCH') {
    supportingParam.filter(param => ['type', 'date'].includes(param.name))
      .map(param => {
        paramList.push(param);
      });
  }
  if (query === 'JOB') {
    paramList.push({
      name: 'jobId',
      value: supportingParam
    });
  }
  if (query === 'STATUS' && supportingParam && supportingParam.length > 0) {
    // -------------------------------------------
    supportingParam.map(param => {
      paramList.push({
        name: 'status',
        value: param
      });
    });
  }
  if (query === 'MONTH_SUMMARY' && supportingParam) {
    // -------------------------------------------
    paramList.push({
      name: 'date',
      value: supportingParam
    });
  }
  if (query === 'MONTH_OBSERVATIONS' && supportingParam) {
    // -------------------------------------------
    paramList.push({
      name: 'date',
      value: supportingParam
    });
  }

  let url = new URL(`${API_DOMAIN}/${REST_API_STAGE}/olap/observation`);
  paramList.forEach(param => url.searchParams.append(param.name, param.value));

  const esResultSet = await client.fetch(url, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': API_KEY,
      'id-token': idToken
    }
  });

  if (esResultSet.ok) {
    return esResultSet.json();
  }
  else {
    throw await getApiServiceError(esResultSet);
  }
};


// No requirement to migrate to MSAL Authentication + AWS Authorization
// -> Added callback September 2021 for loading state usage of this API utility
export const poll = async (fn, fnCondition, ms, maxRequests=10, callback=null) => {
  // -------------------------------
  let result = await fn();
  let iteration = 0;

  while (fnCondition(result) && iteration < maxRequests) {
    // -------------------------------
    await timeout(ms); // eslint-disable-line no-await-in-loop
    result = await fn(); // eslint-disable-line no-await-in-loop
    if (callback) { callback(result); }
    iteration+=1;
  }
  return result;
};

// Migrated August 2021 to MSAL Authentication + AWS Authorization
export const pollForObservationUpdateTimestampChange = (
  observationId, updateTimestamp, { instance, accounts, inProgress }, olap=true
) => {
  const conditionFn = observationResult => {
    // --------------------------------
    return !isEmpty(observationResult) && observationResult.inspectionId === observationId
    && observationResult.header.updateTimestamp === updateTimestamp;
  }
  const pollFn = () =>
    // --------------------------------
    getObservationDetail(observationId, { instance, accounts, inProgress }, olap);
  return poll(pollFn, conditionFn, 30);
};

// Migrated August 2021 to MSAL Authentication + AWS Authorization
export const pollForObservationStatusChange = (
  observationId, status, { instance, accounts, inProgress }
) => {
  const conditionFn = observationResult =>
    // --------------------------------
    !isEmpty(observationResult) && observationResult.inspectionId === observationId
    && observationResult.header.status === status;
  const pollFn = () =>
    // --------------------------------
    getObservationDetail(observationId, { instance, accounts, inProgress });
  return poll(pollFn, conditionFn, 30);
};

// Migrated August 2021 to MSAL Authentication + AWS Authorization
export const searchNcr = async ({ type, jobNumber, status, datetimeJustLt, observationId}, { instance, accounts, inProgress }) => {
  // -------------------------------------
  const idToken = await apiGetMSIdToken({ instance, accounts, inProgress });
  const credentials = await setAWSIAMCredentials({ instance, accounts, inProgress });
  if (!isDefinedAndInitialized(credentials)) {
    // -----------------------------------------
    throw new Error('AWS Credentials could not be set - likely the ID token is not yet set properly');
  }
  const { accessKeyId, secretAccessKey, sessionToken } = credentials;

  const client = new AwsClient({
    accessKeyId,              // required, akin to AWS_ACCESS_KEY_ID
    secretAccessKey,          // required, akin to AWS_SECRET_ACCESS_KEY
    sessionToken,             // akin to AWS_SESSION_TOKEN if using temp credentials
    service: 'execute-api',   // AWS service, by default parsed at fetch time
    region: AWS_REGION,       // AWS region, by default parsed at fetch time
    retries: 5                // number of retries before giving up, defaults to 10, set to 0 for no retrying
  });

  let paramList = [];

  if (isDefinedAndInitialized(type)) {
    paramList = [ ...paramList, { name: 'type', value: type } ];
  }

  if (isDefinedAndInitialized(jobNumber)) {
    paramList = [ ...paramList, { name: 'jobNumber', value: jobNumber } ];
  }

  if (isDefinedAndInitialized(status)) {
    paramList = [ ...paramList,  { name: 'status', value: status } ];
  }

  if (isDefinedAndInitialized(datetimeJustLt)) {
    paramList = [ ...paramList,  { name: 'datetimeJustLt', value: datetimeJustLt } ];
  }

  if (isDefinedAndInitialized(observationId)) {
    paramList = [ ...paramList, { name: 'observationId', value: observationId } ];
  }

  let url = new URL(`${API_DOMAIN}/${REST_API_STAGE}/ncr`);
  paramList.forEach(param => url.searchParams.append(param.name, param.value));

  const ncrSearchResultSet = await client.fetch(url, {
    method: 'get',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
      'x-api-key': API_KEY,
    },
  });

  if (ncrSearchResultSet.ok) {
    return ncrSearchResultSet.json();
  }
  else {
    throw await getApiServiceError(ncrSearchResultSet);
  }
};

// Migrated August 2021 to MSAL Authentication + AWS Authorization
export const createNcr = async (body, { instance, accounts, inProgress }) => {
  // -------------------------------------
  const idToken = await apiGetMSIdToken({ instance, accounts, inProgress });
  const credentials = await setAWSIAMCredentials({ instance, accounts, inProgress });
  if (!isDefinedAndInitialized(credentials)) {
    // -----------------------------------------
    throw new Error('AWS Credentials could not be set - likely the ID token is not yet set properly');
  }
  const { accessKeyId, secretAccessKey, sessionToken } = credentials;

  const client = new AwsClient({
    accessKeyId,              // required, akin to AWS_ACCESS_KEY_ID
    secretAccessKey,          // required, akin to AWS_SECRET_ACCESS_KEY
    sessionToken,             // akin to AWS_SESSION_TOKEN if using temp credentials
    service: 'execute-api',   // AWS service, by default parsed at fetch time
    region: AWS_REGION,       // AWS region, by default parsed at fetch time
    retries: 5                // number of retries before giving up, defaults to 10, set to 0 for no retrying
  });

  const ncrCreationResultSet = await client.fetch(`${API_DOMAIN}/${REST_API_STAGE}/ncr`, {
    method: 'post',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
      'x-api-key': API_KEY,
    },
    body: JSON.stringify(body),
  });

  if (ncrCreationResultSet.ok) {
    return ncrCreationResultSet.json();
  }
  else {
    throw await getApiServiceError(ncrCreationResultSet);
  }
};

// Migrated August 2021 to MSAL Authentication + AWS Authorization
export const batchCreateNcr = async (body, { instance, accounts, inProgress }) => {
  // -------------------------------------
  const idToken = await apiGetMSIdToken({ instance, accounts, inProgress });
  const credentials = await setAWSIAMCredentials({ instance, accounts, inProgress });
  if (!isDefinedAndInitialized(credentials)) {
    // -----------------------------------------
    throw new Error('AWS Credentials could not be set - likely the ID token is not yet set properly');
  }
  const { accessKeyId, secretAccessKey, sessionToken } = credentials;

  const client = new AwsClient({
    accessKeyId,              // required, akin to AWS_ACCESS_KEY_ID
    secretAccessKey,          // required, akin to AWS_SECRET_ACCESS_KEY
    sessionToken,             // akin to AWS_SESSION_TOKEN if using temp credentials
    service: 'execute-api',   // AWS service, by default parsed at fetch time
    region: AWS_REGION,       // AWS region, by default parsed at fetch time
    retries: 5                // number of retries before giving up, defaults to 10, set to 0 for no retrying
  });

  const ncrCreationResultSet = await client.fetch(`${API_DOMAIN}/${REST_API_STAGE}/ncr-batch`, {
    method: 'post',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
      'x-api-key': API_KEY,
    },
    body: JSON.stringify(body),
  });

  if (ncrCreationResultSet.ok) {
    return ncrCreationResultSet.json();
  }
  else {
    throw await getApiServiceError(ncrCreationResultSet);
  }
};

// Migrated August 2021 to MSAL Authentication + AWS Authorization
export const batchDeleteNcr = async (observationId, { instance, accounts, inProgress }) => {
  // -------------------------------------
  const idToken = await apiGetMSIdToken({ instance, accounts, inProgress });
  const credentials = await setAWSIAMCredentials({ instance, accounts, inProgress });
  if (!isDefinedAndInitialized(credentials)) {
    // -----------------------------------------
    throw new Error('AWS Credentials could not be set - likely the ID token is not yet set properly');
  }
  const { accessKeyId, secretAccessKey, sessionToken } = credentials;

  const client = new AwsClient({
    accessKeyId,              // required, akin to AWS_ACCESS_KEY_ID
    secretAccessKey,          // required, akin to AWS_SECRET_ACCESS_KEY
    sessionToken,             // akin to AWS_SESSION_TOKEN if using temp credentials
    service: 'execute-api',   // AWS service, by default parsed at fetch time
    region: AWS_REGION,       // AWS region, by default parsed at fetch time
    retries: 5                // number of retries before giving up, defaults to 10, set to 0 for no retrying
  });

  const deleteNcrUpdateResultSet = await client.fetch(`${API_DOMAIN}/${REST_API_STAGE}/ncr-batch?observationId=${observationId}`, {
    method: 'delete',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
      'x-api-key': API_KEY,
    }
  });

  if (deleteNcrUpdateResultSet.ok) {
    return {};
  }
  else {
    throw await getApiServiceError(deleteNcrUpdateResultSet);
  }
};

// Migrated August 2021 to MSAL Authentication + AWS Authorization
export const getNcrHistoryDetail = async (ncrUpdateId, { instance, accounts, inProgress }) => {
  // -------------------------------------
  const idToken = await apiGetMSIdToken({ instance, accounts, inProgress });
  const credentials = await setAWSIAMCredentials({ instance, accounts, inProgress });
  if (!isDefinedAndInitialized(credentials)) {
    // -----------------------------------------
    throw new Error('AWS Credentials could not be set - likely the ID token is not yet set properly');
  }
  const { accessKeyId, secretAccessKey, sessionToken } = credentials;

  const client = new AwsClient({
    accessKeyId,              // required, akin to AWS_ACCESS_KEY_ID
    secretAccessKey,          // required, akin to AWS_SECRET_ACCESS_KEY
    sessionToken,             // akin to AWS_SESSION_TOKEN if using temp credentials
    service: 'execute-api',   // AWS service, by default parsed at fetch time
    region: AWS_REGION,       // AWS region, by default parsed at fetch time
    retries: 5                // number of retries before giving up, defaults to 10, set to 0 for no retrying
  });

  const ncrHistoryDetailResultSet = await client.fetch(`${API_DOMAIN}/${REST_API_STAGE}/ncr/${ncrUpdateId}`, {
    method: 'get',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
      'x-api-key': API_KEY,
    },
  });

  if (ncrHistoryDetailResultSet.ok) {
    return ncrHistoryDetailResultSet.json();
  }
  else {
    throw await getApiServiceError(ncrHistoryDetailResultSet);
  }
};

// Migrated August 2021 to MSAL Authentication + AWS Authorization
export const putNcrUpdate = async (ncrUpdateId, payload, { instance, accounts, inProgress }) => {
  // -------------------------------------
  const idToken = await apiGetMSIdToken({ instance, accounts, inProgress });
  const credentials = await setAWSIAMCredentials({ instance, accounts, inProgress });
  if (!isDefinedAndInitialized(credentials)) {
    // -----------------------------------------
    throw new Error('AWS Credentials could not be set - likely the ID token is not yet set properly');
  }
  const { accessKeyId, secretAccessKey, sessionToken } = credentials;

  const client = new AwsClient({
    accessKeyId,              // required, akin to AWS_ACCESS_KEY_ID
    secretAccessKey,          // required, akin to AWS_SECRET_ACCESS_KEY
    sessionToken,             // akin to AWS_SESSION_TOKEN if using temp credentials
    service: 'execute-api',   // AWS service, by default parsed at fetch time
    region: AWS_REGION,       // AWS region, by default parsed at fetch time
    retries: 5                // number of retries before giving up, defaults to 10, set to 0 for no retrying
  });

  const ncrUpdateResultSet = await client.fetch(`${API_DOMAIN}/${REST_API_STAGE}/ncr/${ncrUpdateId}` , {
    method: 'put',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
      'x-api-key': API_KEY,
    },
    body: JSON.stringify(payload),
  });

  if (ncrUpdateResultSet.ok) {
    return ncrUpdateResultSet.json();
  }
  else {
    throw await getApiServiceError(ncrUpdateResultSet);
  }
};

// Migrated August 2021 to MSAL Authentication + AWS Authorization
export const deleteNcrUpdate = async (ncrUpdateId, { instance, accounts, inProgress }) => {
  // -------------------------------------
  if (!ncrUpdateId) {
    return null;
  }

  const idToken = await apiGetMSIdToken({ instance, accounts, inProgress });
  const credentials = await setAWSIAMCredentials({ instance, accounts, inProgress });
  if (!isDefinedAndInitialized(credentials)) {
    // -----------------------------------------
    throw new Error('AWS Credentials could not be set - likely the ID token is not yet set properly');
  }
  const { accessKeyId, secretAccessKey, sessionToken } = credentials;

  const client = new AwsClient({
    accessKeyId,              // required, akin to AWS_ACCESS_KEY_ID
    secretAccessKey,          // required, akin to AWS_SECRET_ACCESS_KEY
    sessionToken,             // akin to AWS_SESSION_TOKEN if using temp credentials
    service: 'execute-api',   // AWS service, by default parsed at fetch time
    region: AWS_REGION,       // AWS region, by default parsed at fetch time
    retries: 5                // number of retries before giving up, defaults to 10, set to 0 for no retrying
  });

  const deleteNcrUpdateResultSet = await client.fetch(`${API_DOMAIN}/${REST_API_STAGE}/ncr/${ncrUpdateId}`, {
    method: 'delete',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
      'x-api-key': API_KEY,
    },
  });

  if (deleteNcrUpdateResultSet.ok) {
    return {};
  }
  else {
    throw await getApiServiceError(deleteNcrUpdateResultSet);
  }
};

// Created September 2021
export const createBulkDownload = async (body={}, jobId, { instance, accounts, inProgress }) => {
  // -------------------------------------
  const idToken = await apiGetMSIdToken({ instance, accounts, inProgress });
  const credentials = await setAWSIAMCredentials({ instance, accounts, inProgress });
  if (!isDefinedAndInitialized(credentials)) {
    // -----------------------------------------
    throw new Error('AWS Credentials could not be set - likely the ID token is not yet set properly');
  }
  const { accessKeyId, secretAccessKey, sessionToken } = credentials;

  const client = new AwsClient({
    accessKeyId,              // required, akin to AWS_ACCESS_KEY_ID
    secretAccessKey,          // required, akin to AWS_SECRET_ACCESS_KEY
    sessionToken,             // akin to AWS_SESSION_TOKEN if using temp credentials
    service: 'execute-api',   // AWS service, by default parsed at fetch time
    region: AWS_REGION,       // AWS region, by default parsed at fetch time
    retries: 5                // number of retries before giving up, defaults to 10, set to 0 for no retrying
  });

  const bulkDownloadCreationResultSet = await client.fetch(`${API_DOMAIN}/${REST_API_STAGE}/observation-download?jobId=${jobId}`, {
    method: 'post',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
      'x-api-key': API_KEY,
      'id-token': idToken
    },
    body: JSON.stringify(body),
  });

  if (bulkDownloadCreationResultSet.ok) {
    return bulkDownloadCreationResultSet.json();
  }
  else {
    throw await getApiServiceError(bulkDownloadCreationResultSet);
  }
};

// Created September 2021
export const getBulkDownloadDetail = async (id, { instance, accounts, inProgress }) => {
  // -------------------------------------
  const idToken = await apiGetMSIdToken({ instance, accounts, inProgress });
  const credentials = await setAWSIAMCredentials({ instance, accounts, inProgress });
  if (!isDefinedAndInitialized(credentials)) {
    // -----------------------------------------
    throw new Error('AWS Credentials could not be set - likely the ID token is not yet set properly');
  }
  const { accessKeyId, secretAccessKey, sessionToken } = credentials;

  const client = new AwsClient({
    accessKeyId,              // required, akin to AWS_ACCESS_KEY_ID
    secretAccessKey,          // required, akin to AWS_SECRET_ACCESS_KEY
    sessionToken,             // akin to AWS_SESSION_TOKEN if using temp credentials
    service: 'execute-api',   // AWS service, by default parsed at fetch time
    region: AWS_REGION,       // AWS region, by default parsed at fetch time
    retries: 5                // number of retries before giving up, defaults to 10, set to 0 for no retrying
  });

  const bulkDownloadDetailResultSet = await client.fetch(`${API_DOMAIN}/${REST_API_STAGE}/observation-download/${id}`, {
    method: 'get',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
      'x-api-key': API_KEY,
      'id-token': idToken
    },
  });

  if (bulkDownloadDetailResultSet.ok) {
    return bulkDownloadDetailResultSet.json();
  }
  else {
    throw await getApiServiceError(bulkDownloadDetailResultSet);
  }
};

// Migrated August 2021 to MSAL Authentication + AWS Authorization
export const downloadPdf = async (observationId, { instance, accounts, inProgress }, loaderCallback=null) => {
  const publishResult = await publishObservation(observationId, { instance, accounts, inProgress });
  const presignedUrlResult = await getPresignedUrlS3GetObject(publishResult.pdf, 'url', { instance, accounts, inProgress });
  if (loaderCallback){ 
    // ---------------------------
    loaderCallback(); 
  }
  window.open(presignedUrlResult.presigned);
};

// Migrated August 2021 to MSAL Authentication + AWS Authorization
export const pollForLargerNumberOfObservationNcrs = (
  observationId, initialNumberOfNcrs, { instance, accounts, inProgress }
) => {
  const conditionFn = ncrResult => !(ncrResult.length > initialNumberOfNcrs);
  const pollFn = () =>
    // --------------------------------
    searchNcr({ observationId }, { instance, accounts, inProgress });
  return poll(pollFn, conditionFn, 300);
};

// Migrated August 2021 to MSAL Authentication + AWS Authorization
export const pollForLowerNumberOfObservationNcrs = (
  observationId, initialNumberOfNcrs, { instance, accounts, inProgress }
) => {
  const conditionFn = ncrResult => !(ncrResult.length < initialNumberOfNcrs);
  const pollFn = () =>
    // --------------------------------
    searchNcr({ observationId }, { instance, accounts, inProgress });
  return poll(pollFn, conditionFn, 300);
};

// Migrated August 2021 to MSAL Authentication + AWS Authorization
export const pollForNcrUpdateResult = (
  observationId, stringifiedNcrSearch, { instance, accounts, inProgress }
) => {
  const conditionFn = ncrSearchResult => stringifiedNcrSearch === JSON.stringify(ncrSearchResult);
  const pollFn = () =>
    // --------------------------------
    searchNcr({ observationId }, { instance, accounts, inProgress });
  return poll(pollFn, conditionFn, 300);
};


// Created September 2021
// Note: includes a callback for the rendering updates associated with loading state updates
export const pollForLoadingStateCompletionResult = (
  id, { instance, accounts, inProgress }, cb
) => {
  const conditionFn = loadingStateSearchResult => loadingStateSearchResult.percentageComplete != 100;
  const pollFn = () =>
    // --------------------------------
    getBulkDownloadDetail(id, { instance, accounts, inProgress });
  return poll(pollFn, conditionFn, 2000, 150, cb);
};


// Created June 2022
export const getGeocodedAddress = async (address, { instance, accounts, inProgress }) => {
  // -------------------------------------
  const idToken = await apiGetMSIdToken({ instance, accounts, inProgress });
  const credentials = await setAWSIAMCredentials({ instance, accounts, inProgress });
  if (!isDefinedAndInitialized(credentials)) {
    // -----------------------------------------
    throw new Error('AWS Credentials could not be set - likely the ID token is not yet set properly');
  }
  const { accessKeyId, secretAccessKey, sessionToken } = credentials;

  const client = new AwsClient({
    accessKeyId,              // required, akin to AWS_ACCESS_KEY_ID
    secretAccessKey,          // required, akin to AWS_SECRET_ACCESS_KEY
    sessionToken,             // akin to AWS_SESSION_TOKEN if using temp credentials
    service: 'execute-api',   // AWS service, by default parsed at fetch time
    region: AWS_REGION,       // AWS region, by default parsed at fetch time
    retries: 5                // number of retries before giving up, defaults to 10, set to 0 for no retrying
  });

  const geocodingApiResultSet = await client.fetch(`${GEOCODING_API_DOMAIN}/${REST_API_STAGE}/geocode?address=${encodeURIComponent(address)}`, {
    method: 'get',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
      'x-api-key': GEOCODING_API_KEY,
      'id-token': idToken
    },
  });

  if (geocodingApiResultSet.ok) {
    return geocodingApiResultSet.json();
  }
  else {
    throw await getApiServiceError(geocodingApiResultSet);
  }
};


// Created June 2022
export const postObservationBulkUpdate = async (type, data, { instance, accounts, inProgress }) => {
  // -------------------------------------
  const idToken = await apiGetMSIdToken({ instance, accounts, inProgress });
  const credentials = await setAWSIAMCredentials({ instance, accounts, inProgress });
  if (!isDefinedAndInitialized(credentials)) {
    // -----------------------------------------
    throw new Error('AWS Credentials could not be set - likely the ID token is not yet set properly');
  }
  const { accessKeyId, secretAccessKey, sessionToken } = credentials;

  const client = new AwsClient({
    accessKeyId,              // required, akin to AWS_ACCESS_KEY_ID
    secretAccessKey,          // required, akin to AWS_SECRET_ACCESS_KEY
    sessionToken,             // akin to AWS_SESSION_TOKEN if using temp credentials
    service: 'execute-api',   // AWS service, by default parsed at fetch time
    region: AWS_REGION,       // AWS region, by default parsed at fetch time
    retries: 5                // number of retries before giving up, defaults to 10, set to 0 for no retrying
  });


  let queryString = ``;

  if (type === 'resequence' && 'jobId' in data && isDefinedAndInitialized(data.jobId)) {
    queryString = `?type=resequence&jobId=${data.jobId}`
  }

  if (queryString.length === 0) {
    throw new Error('No supported bulk observation updates of this type');
  }

  const bulkUpdateResultSet = await client.fetch(`${API_DOMAIN}/${REST_API_STAGE}/bulk_observation${queryString}`, {
    method: 'post',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
      'x-api-key': API_KEY,
      'id-token': idToken
    },
  });

  if (bulkUpdateResultSet.ok) {
    return bulkUpdateResultSet.json();
  }
  else {
    throw await getApiServiceError(bulkUpdateResultSet);
  }
};


// Created October 2022
export const getJobWorkAndSafetyPlanList = async (jobId, { instance, accounts, inProgress }) => {
  // -------------------------------------
  const idToken = await apiGetMSIdToken({ instance, accounts, inProgress });
  let accessToken = await apiGetMSAccessToken({ instance, accounts, inProgress });

  const credentials = await setAWSIAMCredentials({ instance, accounts, inProgress });
  if (!isDefinedAndInitialized(credentials)) {
    // -----------------------------------------
    throw new Error('AWS Credentials could not be set - likely the ID token is not yet set properly');
  }
  const { accessKeyId, secretAccessKey, sessionToken } = credentials;

  const client = new AwsClient({
    accessKeyId,              // required, akin to AWS_ACCESS_KEY_ID
    secretAccessKey,          // required, akin to AWS_SECRET_ACCESS_KEY
    sessionToken,             // akin to AWS_SESSION_TOKEN if using temp credentials
    service: 'execute-api',   // AWS service, by default parsed at fetch time
    region: AWS_REGION,       // AWS region, by default parsed at fetch time
    retries: 5                // number of retries before giving up, defaults to 10, set to 0 for no retrying
  });

  const jobWsPlanListResultSet = await client.fetch(`${HS_API_DOMAIN}/${REST_API_STAGE}/plan?jobId=${jobId}`, {
    method: 'get',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': HS_API_KEY,
      'id-token': idToken,
      "bearer-token": accessToken
    },
  });

  if (jobWsPlanListResultSet.ok) {
    return jobWsPlanListResultSet.json();
  }
  else {
    throw await getApiServiceError(jobWsPlanListResultSet);
  }
};


export const getTeamMemberListMin = async ({ instance, accounts, inProgress }) => {
  // ------------------------------
  if (!isDefinedAndInitialized(instance) || !isDefinedAndInitialized(accounts) || !isDefinedAndInitialized(inProgress) ) {
    return [];
}

  let accessToken = await apiGetMSAccessToken({ instance, accounts, inProgress });

  if (!accessToken) { return []; }

  let headers = new Headers();
  let bearer = `Bearer ${accessToken}`;
  headers.append("Authorization", bearer);
  let options = {
    method: "GET",
    headers
  };

  const graphEndpoint = `https://graph.microsoft.com/v1.0/groups/${AZURE_HEALTH_AND_SAFETY_GROUP_ID}/members`;

  let response = await fetch(graphEndpoint, options);

  let result = await response.json();

  return result;
}


// Created November 2022
export const getWorkAndSafetyPlanHighlightedHazardList = async (planId, { instance, accounts, inProgress }) => {
  // -------------------------------------
  const idToken = await apiGetMSIdToken({ instance, accounts, inProgress });
  let accessToken = await apiGetMSAccessToken({ instance, accounts, inProgress });

  const credentials = await setAWSIAMCredentials({ instance, accounts, inProgress });
  if (!isDefinedAndInitialized(credentials)) {
    // -----------------------------------------
    throw new Error('AWS Credentials could not be set - likely the ID token is not yet set properly');
  }
  const { accessKeyId, secretAccessKey, sessionToken } = credentials;

  const client = new AwsClient({
    accessKeyId,              // required, akin to AWS_ACCESS_KEY_ID
    secretAccessKey,          // required, akin to AWS_SECRET_ACCESS_KEY
    sessionToken,             // akin to AWS_SESSION_TOKEN if using temp credentials
    service: 'execute-api',   // AWS service, by default parsed at fetch time
    region: AWS_REGION,       // AWS region, by default parsed at fetch time
    retries: 5                // number of retries before giving up, defaults to 10, set to 0 for no retrying
  });

  const wsPlanHighlightedHazardListResultSet = await client.fetch(`${HS_API_DOMAIN}/${REST_API_STAGE}/hazard?highlightedHazard=true&planId=${planId}`, {
    method: 'get',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': HS_API_KEY,
      'id-token': idToken,
      "bearer-token": accessToken
    },
  });

  if (wsPlanHighlightedHazardListResultSet.ok) {
    return wsPlanHighlightedHazardListResultSet.json();
  }
  else {
    throw await getApiServiceError(wsPlanHighlightedHazardListResultSet);
  }
};


export const createWorkAndSafetyTake5 = async (body, { instance, accounts, inProgress }) => {
  // -------------------------------------
  const idToken = await apiGetMSIdToken({ instance, accounts, inProgress });
  let accessToken = await apiGetMSAccessToken({ instance, accounts, inProgress });

  const credentials = await setAWSIAMCredentials({ instance, accounts, inProgress });
  if (!isDefinedAndInitialized(credentials)) {
    // -----------------------------------------
    throw new Error('AWS Credentials could not be set - likely the ID token is not yet set properly');
  }
  const { accessKeyId, secretAccessKey, sessionToken } = credentials;

  const client = new AwsClient({
    accessKeyId,              // required, akin to AWS_ACCESS_KEY_ID
    secretAccessKey,          // required, akin to AWS_SECRET_ACCESS_KEY
    sessionToken,             // akin to AWS_SESSION_TOKEN if using temp credentials
    service: 'execute-api',   // AWS service, by default parsed at fetch time
    region: AWS_REGION,       // AWS region, by default parsed at fetch time
    retries: 5                // number of retries before giving up, defaults to 10, set to 0 for no retrying
  });

  // Post new observation request to observation endpoint
  const take5CreationResultSet = await client.fetch(`${HS_API_DOMAIN}/${REST_API_STAGE}/prestart`, {
    method: 'post',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': HS_API_KEY,
      'id-token': idToken,
      "bearer-token": accessToken
    },
    body: JSON.stringify(body),
  });

  if (take5CreationResultSet.ok) {
    return take5CreationResultSet.json();
  }
  else {
    throw await getApiServiceError(take5CreationResultSet);
  }
};


export const updateWorkAndSafetyTake5 = async (body, { instance, accounts, inProgress }) => {
  // -------------------------------------
  const idToken = await apiGetMSIdToken({ instance, accounts, inProgress });
  let accessToken = await apiGetMSAccessToken({ instance, accounts, inProgress });

  const credentials = await setAWSIAMCredentials({ instance, accounts, inProgress });
  if (!isDefinedAndInitialized(credentials)) {
    // -----------------------------------------
    throw new Error('AWS Credentials could not be set - likely the ID token is not yet set properly');
  }
  const { accessKeyId, secretAccessKey, sessionToken } = credentials;

  const client = new AwsClient({
    accessKeyId,              // required, akin to AWS_ACCESS_KEY_ID
    secretAccessKey,          // required, akin to AWS_SECRET_ACCESS_KEY
    sessionToken,             // akin to AWS_SESSION_TOKEN if using temp credentials
    service: 'execute-api',   // AWS service, by default parsed at fetch time
    region: AWS_REGION,       // AWS region, by default parsed at fetch time
    retries: 5                // number of retries before giving up, defaults to 10, set to 0 for no retrying
  });

  // Post new observation request to observation endpoint
  const take5UpdateResultSet = await client.fetch(`${HS_API_DOMAIN}/${REST_API_STAGE}/prestart/${body.id}`, {
    method: 'put',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': HS_API_KEY,
      'id-token': idToken,
      "bearer-token": accessToken
    },
    body: JSON.stringify(body),
  });

  if (take5UpdateResultSet.ok) {
    return take5UpdateResultSet.json();
  }
  else {
    throw await getApiServiceError(take5UpdateResultSet);
  }
};


export const getWorkAndSafetyTake5Detail = async (id, { instance, accounts, inProgress }) => {
  // -------------------------------------
  const idToken = await apiGetMSIdToken({ instance, accounts, inProgress });
  let accessToken = await apiGetMSAccessToken({ instance, accounts, inProgress });

  const credentials = await setAWSIAMCredentials({ instance, accounts, inProgress });
  if (!isDefinedAndInitialized(credentials)) {
    // -----------------------------------------
    throw new Error('AWS Credentials could not be set - likely the ID token is not yet set properly');
  }
  const { accessKeyId, secretAccessKey, sessionToken } = credentials;

  const client = new AwsClient({
    accessKeyId,              // required, akin to AWS_ACCESS_KEY_ID
    secretAccessKey,          // required, akin to AWS_SECRET_ACCESS_KEY
    sessionToken,             // akin to AWS_SESSION_TOKEN if using temp credentials
    service: 'execute-api',   // AWS service, by default parsed at fetch time
    region: AWS_REGION,       // AWS region, by default parsed at fetch time
    retries: 5                // number of retries before giving up, defaults to 10, set to 0 for no retrying
  });

  // Post new observation request to observation endpoint
  const getTake5ResultSet = await client.fetch(`${HS_API_DOMAIN}/${REST_API_STAGE}/prestart/${id}`, {
    method: 'get',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': HS_API_KEY,
      'id-token': idToken,
      "bearer-token": accessToken
    }
  });

  if (getTake5ResultSet.ok) {
    return getTake5ResultSet.json();
  }
  else if (getTake5ResultSet.status === 404) {
    return null;
  }
  else {
    throw await getApiServiceError(getTake5ResultSet);
  }
};


export const deleteWorkAndSafetyTake5ForObservation = async (observationId, { instance, accounts, inProgress }) => {
  // -------------------------------------
  const idToken = await apiGetMSIdToken({ instance, accounts, inProgress });
  let accessToken = await apiGetMSAccessToken({ instance, accounts, inProgress });

  const credentials = await setAWSIAMCredentials({ instance, accounts, inProgress });
  if (!isDefinedAndInitialized(credentials)) {
    // -----------------------------------------
    throw new Error('AWS Credentials could not be set - likely the ID token is not yet set properly');
  }
  const { accessKeyId, secretAccessKey, sessionToken } = credentials;

  const client = new AwsClient({
    accessKeyId,              // required, akin to AWS_ACCESS_KEY_ID
    secretAccessKey,          // required, akin to AWS_SECRET_ACCESS_KEY
    sessionToken,             // akin to AWS_SESSION_TOKEN if using temp credentials
    service: 'execute-api',   // AWS service, by default parsed at fetch time
    region: AWS_REGION,       // AWS region, by default parsed at fetch time
    retries: 5                // number of retries before giving up, defaults to 10, set to 0 for no retrying
  });

  // Post new observation request to observation endpoint
  const bulkDeleteTake5ResultSet = await client.fetch(`${HS_API_DOMAIN}/${REST_API_STAGE}/bulk/prestart?observationId=${observationId}`, {
    method: 'delete',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': HS_API_KEY,
      'id-token': idToken,
      "bearer-token": accessToken
    }
  });

  if (bulkDeleteTake5ResultSet.ok) {
    return bulkDeleteTake5ResultSet.json();
  }
  else {
    throw await getApiServiceError(bulkDeleteTake5ResultSet);
  }
};


export const uploadBlobToS3 = async (blobUpload, { instance, accounts, inProgress }) => {
  // --------------------------------------
  const { blob, path } = blobUpload;

  let idToken = await apiGetMSIdToken({ instance, accounts, inProgress });

  if (!isDefinedAndInitialized(idToken)) {
    throw new Error('Credentials were not processable for file upload');
  }
  if (!isDefinedAndInitialized(path)) {
    throw new Error('File has no path');
  }

  const credentials = await setAWSIAMCredentials(idToken);
  let bucketName = STATIC_ASSETS_BUCKET_NAME;

  // HANDLE SPECIAL CASES WHERE DIFFERENT BUCKETS ARE USED
  // 1) Prestart - hazard observation media - save to general bucket
  if (path.includes('hazard-observation-media-')) {
    // --------------------------------
    bucketName = PUBLIC_BUCKET_NAME;
  }

  // 4) Using AWS SDK v3 (for setting credentials, refer: https://docs.aws.amazon.com/sdk-for-javascript/v3/developer-guide/loading-browser-credentials-cognito.html)
  const s3Client = new S3Client({
    region: AWS_REGION,
    credentials
  });


  const command = new PutObjectCommand({
    Bucket: bucketName,
    Key: path,
    Body: blob
  });

  let result = await s3Client.send(command);

  return result;
}


// Requires the Microsoft Authentication Library (MSAL) useMsal hook result
// This function acquires an access token silently either from the cache
// This can be used to upload a file to Amazon S3
export const uploadNodeFileToS3 = async (fileUpload, { instance, accounts, inProgress }) => {
  // -----------------------------------------
  const { name } = fileUpload;
  const extension = getFileTypeExtension(name);

  if (!isDefinedAndInitialized(fileUpload.file)) {
    // ------------------------------
    throw new Error('File is not defined');
  }

  // SPECIALLY HANDLE IMAGES TO SCALE DOWN THEIR SIZE
  let blob = null;
  if (['jpg', 'jpeg', 'png', 'gif'].includes(extension || '')) {
    let fullSizeBlob = await readImageFileAsBlob(fileUpload.file);
    // RESIZE
    blob = getResizedImageAsBlob(fullSizeBlob);
  }
  else {
    blob = await readFileAsBlob(fileUpload.file);
  }


  if (!blob) {
    throw new Error('No file content found to upload');
  }

  return uploadBlobToS3({
    blob,
    path: fileUpload.path
  },
  { instance, accounts, inProgress });
};