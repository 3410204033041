import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Material UI
import { Box, Button, Tooltip, Fab, useTheme, useMediaQuery } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

// Custom Config
import { config } from '../config/generalConfig';

// Custom Components
import EnhancedMenu from './enhancedMenu/enhancedMenu';

// Custom Helpers
import { convertCamelToSpaced, capitalizeFirstLetters, isDefinedAndInitialized } from '../helpers/helpers';


export const AddComponentButton = (props) => {
  // --------------------------------------------
  const { classes, addHandler, toggleHandler, idx } = props;
  
  let setIdx = null;
  if (isDefinedAndInitialized(idx)) {
    setIdx = idx;
  }

  // Local State
  const [anchorElement, setAnchorElement] = useState(null);

  // MUI HOOKS
  const theme = useTheme();
  const isSmallerThanXs = useMediaQuery(theme.breakpoints.down('xs'));

  const handleButtonClick = (event) => {
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorElement(null);
  };

  // Generate MenuItems from Context
  const menuItems = config.componentList.map((component) => {
    // ------------------------------------------------------------------
    const { componentType, clickHandler } = component;

    // Generate basic MenuItem
    const componentItem = {
      componentType: componentType,
      caption: capitalizeFirstLetters(convertCamelToSpaced(componentType)),
      clickHandler: props[clickHandler]
    };

    if ('subComponents' in component) {
      componentItem.subComponentItems = [];
      componentItem.subComponentItems = component.subComponents.map(subComponent => {
        const { componentType: subComponentType, clickHandler: subComponentClickHandler } = subComponent;
        const caption = subComponentType === 'healthAndSafetyTakeFive' ? `${capitalizeFirstLetters(convertCamelToSpaced(subComponentType))} (old)` : capitalizeFirstLetters(convertCamelToSpaced(subComponentType));
        
        return ({
          componentType: subComponentType,
          caption,
          clickHandler: props[subComponentClickHandler]
        });
      })
    }

    return componentItem;
  });

  return (
    <Box>
      <Tooltip title="Add component">
        <Button
          color="primary"
          aria-label="Add"
          variant="text"
          size="small"        
          startIcon={<AddIcon />}
          className={classes.button}          
          onClick={handleButtonClick}
        >
          { isSmallerThanXs ? `Add` : `Add item` }
        </Button>
      </Tooltip>
      <EnhancedMenu
        open={Boolean(anchorElement)}
        menuItems={menuItems}
        anchorElement={anchorElement}
        onClose={handleMenuClose}
        handleClientObservationStateUpdate={props.handleClientObservationStateUpdate}
        addHandler={addHandler}
        toggleHandler={toggleHandler}
        idx={setIdx}
      />
    </Box>
  );
}

AddComponentButton.propTypes = {
  classes: PropTypes.object,
  addHandler: PropTypes.func,
  toggleHandler: PropTypes.func,
  handleClientObservationStateUpdate: PropTypes.func,
  idx: PropTypes.number
}



export const AddComponentFab = (props) => {
  // --------------------------------------------
  const { classes, addHandler, toggleHandler } = props;
  // Local State
  const [anchorElement, setAnchorElement] = useState(null);

  const handleButtonClick = (event) => {
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorElement(null);
  };

  // Generate MenuItems from Context
  const menuItems = config.componentList.map((component) => {
    // ------------------------------------------------------------------
    const { componentType, clickHandler } = component;

    // Generate basic MenuItem
    const componentItem = {
      componentType: componentType,
      caption: capitalizeFirstLetters(convertCamelToSpaced(componentType)),
      clickHandler: props[clickHandler],
    };

    if ('subComponents' in component) {
      componentItem.subComponentItems = [];
      componentItem.subComponentItems = component.subComponents.map(subComponent => {
        const { componentType: subComponentType, clickHandler: subComponentClickHandler } = subComponent;
        const caption = subComponentType === 'healthAndSafetyTakeFive' ? `${capitalizeFirstLetters(convertCamelToSpaced(subComponentType))} (old)` : capitalizeFirstLetters(convertCamelToSpaced(subComponentType));
        
        return ({
          componentType: subComponentType,
          caption,
          clickHandler: props[subComponentClickHandler],
        });
      })
    }

    return componentItem;
  });

  return (
    <Box position="fixed" bottom="10%" right="2%" zIndex={1000}>
      <Fab
        variant="extended"
        color="secondary"
        aria-label="Add"
        className={classes.fab}
        onClick={handleButtonClick}
      >
        <AddIcon />
        Add
      </Fab>
      <EnhancedMenu
        open={Boolean(anchorElement)}
        menuItems={menuItems}
        anchorElement={anchorElement}
        onClose={handleMenuClose}
        handleClientObservationStateUpdate={props.handleClientObservationStateUpdate}
        addHandler={addHandler}
        toggleHandler={toggleHandler}
      />
    </Box>
  );
}

AddComponentFab.propTypes = {
  classes: PropTypes.object,
  addHandler: PropTypes.func,
  toggleHandler: PropTypes.func,
  handleClientObservationStateUpdate: PropTypes.func
}
